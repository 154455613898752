import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';

export const UPDATE_PRODUCT_LIST_LAYOUT: TableLayout[] = [
  {title: '管理番号', field: 'managementId'},
  {title: '大分類', field: 'rootCategory'},
  {title: '小分類', field: 'narrowCategory'},
  {title: '機種名', field: 'displayName'},
  {title: '型式', field: 'name'},
  {title: 'メーカー名', field: 'makerName'},
  {title: 'シリアル番号', field: 'serialNumber'},
  {title: 'ロット番号', field: 'lotNumber'},
  {title: '貸出区分', field: 'permanentlyAssigned'},
  {title: '所在（院内/院外）', field: 'isOutsideOfHospital'},
  {title: '管理部署', field: 'hospitalDepartmentName'},
  {title: '機器管理場所', field: 'hospitalRoomName'},
  {title: '親機・子機', field: 'isBaseUnit'},
  {title: '購入日', field: 'dateOfPurchase'},
  {title: 'バーコード読み取り値', field: 'optionalBarcode'},
  {title: 'GS1バーコード', field: 'gs1Barcode'},
  {title: '担当代理店', field: 'hospitalDealerName'},
  {title: '購入区分', field: 'waysOfPurchase'},
  {title: '購入元', field: 'purchasedNationalExpense'},
  {title: '納入価', field: 'deliveryPrice'},
  {title: '税込／税抜', field: 'taxIncluded'},
  {title: '税率', field: 'taxRate'},
  {title: '資産番号', field: 'assetRegisterNumber'},
  {title: '院内耐用年数', field: 'legalDurableYear'},
  {title: '廃棄日', field: 'dateOfDisposal'},
  {title: '廃棄理由', field: 'reasonOfDisposal'},
  {title: '備考1', field: 'notes'},
  {title: '備考2', field: 'notes2'},
  {title: '備考3', field: 'notes3'},
  {title: '備考4', field: 'notes4'},
  {title: '備考5', field: 'notes5'},
  {title: '備考6', field: 'notes6'},
  {title: '備考7', field: 'notes7'},
  {title: '備考8', field: 'notes8'},
  {title: '備考9', field: 'notes9'},
  {title: '備考10', field: 'notes10'},
  {title: '備考11', field: 'notes11'},
  {title: '備考12', field: 'notes12'},
  {title: '備考13', field: 'notes13'},
  {title: '備考14', field: 'notes14'},
  {title: '備考15', field: 'notes15'},
  {title: '備考16', field: 'notes16'},
  {title: '備考17', field: 'notes17'},
  {title: '備考18', field: 'notes18'},
  {title: '備考19', field: 'notes19'},
  {title: '備考20', field: 'notes20'},
  {title: 'リース管理番号', field: 'leaseId'},
  {title: 'リース企業名', field: 'leaseDealerName'},
  {title: 'リース金額(円)', field: 'leaseFee'},
  {title: 'リース開始日', field: 'leaseStartDate'},
  {title: 'リース終了予定日', field: 'leaseDueDate'},
  {title: 'リース返却日', field: 'leaseReturnDate'},
  {title: 'レンタル管理番号', field: 'rentalId'},
  {title: 'レンタル企業名', field: 'rentalDealerName'},
  {title: 'レンタル金額(円)', field: 'rentalFee'},
  {title: 'レンタル開始日', field: 'rentalStartDate'},
  {title: 'レンタル終了予定日', field: 'rentalDueDate'},
  {title: 'レンタル機器返却日', field: 'rentalReturnDate'},
  {title: 'デモの目的', field: 'purposeOfDemonstration'},
  {title: 'デモ開始日', field: 'demonstrationStartDate'},
  {title: 'デモ終了日', field: 'demonstrationEndDate'},
  {title: '保守契約（メーカー保守点検）', field: 'isMaintenanceContract'},
];

export const BULK_UPDATE_READ_SHEET_NAME = '機器台帳更新用シート';

export const START_DATA_ROW_NUMBER = 3;

export const ValidationMessages = {
  required: 'は必須項目です。空欄ではインポートできません。',
} as const;
