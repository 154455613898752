import {Button, Grid, makeStyles, Theme} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {TrainingReportCard} from './TrainingReportCard';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useFetchTrainingReportsQuery} from '@modules/training_schedules/hooks/useTrainingSchedule';
import {TrainingReport} from '@modules/training_schedules/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  TrainingReportDialog,
  TrainingReportDialogProps,
  TrainingReportDialogResult,
} from '@Apps/TrainingManagementReports/dialogs/TrainingReportDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {createTrainingReport, deleteTrainingReport} from '@modules/training_reports/api';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export const TrainingReports: React.FC = () => {
  const classes = useStyles();
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const {canEdit: canEditTraining, canDelete: canDeleteTraining} = useUserResourcesPermissions('TRAINING');
  const trainingReportsQuery = useFetchTrainingReportsQuery(myInfo.hospitalHashId, {
    trainingScheduleHashIds: [hashId ?? ''],
  });

  const handleRegisterReport = async () => {
    const reportData = await dialogHandler.open<TrainingReportDialogProps, TrainingReportDialogResult>(
      TrainingReportDialog,
      {
        isEdit: false,
        isHiddenScheduleHashId: true,
        currentUser: myInfo,
        initialValues: {trainingScheduleHashId: hashId},
      }
    );
    await createTrainingReport(myInfo.hospitalHashId, {
      ...reportData,
      numberOfAttendants: reportData.numberOfAttendants ?? 0,
    });
    await trainingReportsQuery.refetch();
    openSnackBar('実施記録を作成しました');
  };

  const handleDeleteReport = async (trainingReportHashId: string) => {
    await dialogHandler.open(AlertDialog, {
      title: '実施記録を削除しますか？',
      content: 'この実施記録を削除しようとしています。\n\nこのアクションは元に戻せません。',
      positiveButtonLabel: '実施記録を削除',
    });
    await deleteTrainingReport(myInfo.hospitalHashId, trainingReportHashId);
    await trainingReportsQuery.refetch();
    openSnackBar('実施記録を削除しました');
  };

  const isLoading = useMemo(() => trainingReportsQuery.isLoading, [trainingReportsQuery.isLoading]);
  const hasError = useMemo(() => trainingReportsQuery.isError, [trainingReportsQuery.isError]);

  const trainingReportsHistory: TrainingReport[] = useMemo(() => {
    if (isLoading && hasError) return [];

    return (trainingReportsQuery.data ?? []).sort((a, b) => {
      if (a.finishedAt < b.finishedAt) return 1;
      if (a.finishedAt > b.finishedAt) return -1;
      return 0;
    });
  }, [isLoading, hasError, trainingReportsQuery]);

  return (
    <Grid container className={classes.root}>
      <Grid container style={{justifyContent: 'flex-end'}}>
        <Grid item className={classes.actionContainer}>
          {canEditTraining && (
            <Button color="inherit" className={classes.addCommentBtn} onClick={handleRegisterReport}>
              実施記録を作成
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {trainingReportsHistory.map((innerItem, innerIdx) => (
          <Grid item className={classes.historyContainer} key={innerItem.hashId + String(innerIdx)}>
            <TrainingReportCard
              key={innerIdx}
              trainingReport={innerItem}
              onDeleteTrainingReport={handleDeleteReport}
              isAdmin={canDeleteTraining}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: '82px',
  },
  actionContainer: {
    paddingTop: '24px',
    fontSize: theme.typography.fontSize,
  },
  action: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  historyContainer: {
    paddingTop: '16px',
    fontSize: theme.typography.fontSize,
    width: '100%',
    paddingRight: '24px',
  },
  addCommentBtn: {
    backgroundColor: '#323F4E',
    borderRadius: '2px',
    padding: '8px 22px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
}));
