import {Alert, Box, Grid, styled, Typography} from '@mui/material';
import React from 'react';

const AlertInfo = styled(Alert)({
  padding: '16px',
  backgroundColor: '#E5EEFA',
  '.MuiAlert-icon,.MuiAlert-message': {width: '100%', padding: 0, color: '#172B4D'},
  '.MuiAlert-icon': {display: 'none', padding: 0},
});

export const ConfirmSharingInformationContent = () => {
  return (
    <>
      <Box sx={{color: '#172B4D', fontSize: '14px', lineHeight: '24px', marginTop: '24px'}}>
        FUJITA社への買取査定依頼にあたり、選択された機器について、以下15項目の提供が必要です。
      </Box>
      <Box sx={{color: '#172B4D', fontSize: '12px', lineHeight: '18px'}}>
        ※これらの情報は、FUJITA社による買取査定の検討のみに利用し、その他の目的での利用は行いません。
      </Box>

      <Box sx={{fontWeight: '700', color: '#172B4D', fontSize: '14px', lineHeight: '24px', mt: '16px', mb: '16px'}}>
        FUJITA社へのこれら項目の情報提供を承諾しますか？
      </Box>

      <AlertInfo severity="info">
        <Typography component={'span'} sx={{color: '#172B4D', fontSize: '14px', fontWeight: '700'}}>
          FUJITA社へ提供される項目
        </Typography>
        <Typography component={'span'} sx={{color: '#172B4D', fontSize: '12px'}}>
          （※選択された機器の情報のみ）
        </Typography>

        <Grid
          container
          direction={'row'}
          sx={{width: '100%', color: '#172B4D', fontSize: '14px', gap: '16px', mt: 1, mb: 1}}>
          <Grid item container direction={'column'} xs={3}>
            <Grid item>1. 管理番号</Grid>
            <Grid item>2. 資産管理番号</Grid>
            <Grid item>3. シリアル番号</Grid>
            <Grid item>4. 大分類</Grid>
            <Grid item>5. 小分類</Grid>
          </Grid>
          <Grid item container direction={'column'} xs={3}>
            <Grid item> 6. メーカー名</Grid>
            <Grid item> 7. 機種名</Grid>
            <Grid item> 8. 型式</Grid>
            <Grid item> 9. 承認番号</Grid>
            <Grid item>10. JANコード</Grid>
          </Grid>
          <Grid item container direction={'column'} xs={3}>
            <Grid item>11. 稼働状況</Grid>
            <Grid item>12. 機器管理場所</Grid>
            <Grid item>13. 購入日</Grid>
            <Grid item>14. 購入区分</Grid>
            <Grid item>15. 廃棄理由</Grid>
          </Grid>
        </Grid>

        <Box sx={{color: '#172B4D', fontSize: '12px', lineHeight: '18px'}}>
          ※これら15項目以外の、購入金額やレンタル・リース金額を含む価格関連情報やその他機器台帳記載の情報、
          <br />
          および、機器詳細画面のコメントなどは提供されません。
          <br />
          ※提供される15項目の情報は、現時点で機器台帳に記録されている情報のみとなり、過去の履歴は提供されません。
        </Box>
      </AlertInfo>
    </>
  );
};
