import {
  backgroundUserNotificationCategories,
  USER_NOTIFICATIONS,
  userNotificationOptionFilters,
} from '@components/organisms/Header/pc/constants';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {NotificationCategory} from '@modules/notifications/types';
import {useMemo} from 'react';

/**
 * 通知に必要な権限を取得する
 *
 * @see https://hitotsu.atlassian.net/browse/ACC-612
 *
 * | **通知項目** | **権限名** |
 * |------------|------------|
 * | 修理の担当者割当 | **canEditRepair** |
 * | 点検リマインダー | **canEditInspection** |
 * | 機器の貸出 | **canEditRental** |
 * | 機器の返却| **canEditRental** |
 * | 添付文書の更新通知| **canReadProductList** |
 * | 回収情報の発出通知| **canReadProductList** |
 * | 不具合受付情報の通知| **canEditRepair** |
 * | 返却待ち受付情報の通知| **canEditRental** |
 */
export const usePermittedNotification = () => {
  const {canEdit: canEditRepair} = useUserResourcesPermissions('REPAIR');
  const {canEdit: canEditInspection} = useUserResourcesPermissions('INSPECTION');
  const {canEdit: canEditRental} = useUserResourcesPermissions('RENTAL');
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');

  return useMemo(
    () => ({
      canEditRepair,
      canEditInspection,
      canEditRental,
      canReadProductList,
    }),
    [canEditRepair, canEditInspection, canEditRental, canReadProductList]
  );
};

/**
 * 通知で権限がある通知カテゴリーを返却する
 * @returns
 */
export const usePermittedUserNotificationCategories = () => {
  const {canEditRepair, canEditInspection, canEditRental} = usePermittedNotification();

  // 権限ごとの通知マッピング
  const permissionNotifications = useMemo(
    () => ({
      canEditRepair: [
        USER_NOTIFICATIONS.FAULT_REPAIR_PIC_NOTIFICATION, // 修理の担当者の割り当て
        USER_NOTIFICATIONS.FAULT_RECEPTION_NOTIFICATION, // 不具合受付
      ],
      canEditInspection: [
        USER_NOTIFICATIONS.INSPECTION_REMINDER, // 点検リマインダー
      ],
      canEditRental: [
        USER_NOTIFICATIONS.RENT_NOTIFICATION, // 貸出
        USER_NOTIFICATIONS.RETURN_NOTIFICATION, // 返却
        USER_NOTIFICATIONS.RETURN_WAITING_RECEPTION_NOTIFICATION, // 返却待ち受付
      ],
    }),
    []
  );

  // 権限がある通知のみを取得
  const filteredNotifications: NotificationCategory[] = useMemo(
    () => [
      ...(canEditRepair ? permissionNotifications.canEditRepair : []),
      ...(canEditInspection ? permissionNotifications.canEditInspection : []),
      ...(canEditRental ? permissionNotifications.canEditRental : []),
    ],
    [canEditRepair, canEditInspection, canEditRental, permissionNotifications]
  );

  return [...filteredNotifications, ...backgroundUserNotificationCategories];
};

/**
 * 権限に即したユーザー通知画面で表示するフィルターデータを取得する
 */
export const useFilteredUserNotificationOptionFilters = () => {
  const permittedNotifications = usePermittedUserNotificationCategories();
  // 権限のあるユーザー通知とバックグラウンド通知を結合
  const allowedNotificationCategories = [...permittedNotifications, ...backgroundUserNotificationCategories];

  const filters = useMemo(() => {
    return userNotificationOptionFilters
      .map((filter) => ({
        ...filter,
        // values配列に含まれる通知カテゴリーが allowedNotificationCategories に含まれる場合のみ残す
        values: filter.values.filter((value) => allowedNotificationCategories.includes(value)),
      }))
      .filter((filter) => filter.values.length > 0); // 空のカテゴリは削除
  }, [permittedNotifications]);

  return filters;
};
