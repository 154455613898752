import React, {useEffect} from 'react';
import {withSuspense} from '@front-libs/core';
import {Header} from './Header';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {FaultReceptionList} from './FaultReceptionList';
// 現状だとTableLayoutのキーを後から変更できないので、キーが変わったら再描画する形式にしています
const _FaultReceptions = () => {
  return (
    <ListPageLayout page="fault_reception">
      <ListPageLayout.Header>
        <Header />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <FaultReceptionList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const FaultReceptions = withSuspense(_FaultReceptions, null);
