import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {ErrorAdditionalInfo, RentalErrorDialog} from '@Apps/BaseSharedMenu/RentalErrorDialog';
import {VerticalPairTable} from '@Apps/BaseSharedMenu/VerticalPairTable';
import {RentButton} from '@Apps/BaseSharedMenu/RentDevices/RentButton';
import {RentProductTable} from '@Apps/BaseSharedMenu/RentDevices/RentProductTable';
import {RentalTargetHospitalProducts} from '@Apps/BaseSharedMenu/RentDevices/types';
import {
  canUseDeviceCameraAtom,
  defaultBarcodeScannerTypeAtom,
  lenderNameAtom,
  rentalSettingAtom,
  rentRoomAtom,
  rentStepperAtom,
} from '@Apps/BaseSharedMenu/states';
import {getAxiosErrorData, useAsyncEffect} from '@front-libs/core';
import {StrUtil} from '@front-libs/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  bulkCheckout,
  CheckoutRentalData,
  DeviceLender,
  listScanBarcodeRentalProducts,
  ListScanBarcodeRentalProductsParams,
} from '@modules/rentals/api';
import {Box, styled, SxProps, Theme} from '@mui/material';
import {contentFixedStyle} from '@templates/RentalTemplate';
import {useAtomValue, useSetAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {NoRowsContent} from '../../NoRowsContent';
import {ParentChildDeviceDialog, ParentChildDeviceDialogProps} from '../../ParentChildDeviceDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandlerV5';
import {openSnackBar} from '@front-libs/ui';
import {validateRentalTarget} from '@Apps/BaseSharedMenu/RentDevices/supportsForUnitRelation/validateRentalTarget';
import {TargetStatusForUnitRelation, UnacceptedStatus} from '@Apps/BaseSharedMenu/types';

/**
 * 貸出のバーコードリーダー画面
 * @todo 返却のバーコードリーダー画面とほぼ一緒なので共通化させたい
 */
export const RentByBarcodeReader = () => {
  const {myInfo} = useMyInfo();
  const [rentalTargetHospitalProducts, setRentalTargetHospitalProducts] = useState<RentalTargetHospitalProducts>([]);
  const [isSearching, setIsSearching] = useState(false);
  const rentRoom = useAtomValue(rentRoomAtom);
  const lenderName = useAtomValue(lenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [managementId, setManagementId] = useState('');
  const [rentalErrorDialogIsOpened, setRentalErrorDialogIsOpened] = useState(false);
  const [rentalErrorStatus, setRentalErrorStatus] = useState<UnacceptedStatus>(null);
  const [rentalErrorAdditionalInfo, setRentalErrorAdditionalInfo] = useState<ErrorAdditionalInfo>(undefined);
  const [openRentalDevicesAddedDialog, setOpenRentalDevicesAddedDialog] = useState(false);
  const [targetStatusForUnitRelation, setTargetStatusForUnitRelation] = useState<
    TargetStatusForUnitRelation | undefined
  >(undefined);
  const navigate = useNavigate();
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);
  const canUseDeviceCamera = useAtomValue(canUseDeviceCameraAtom);
  const setDefaultBarcodeScannerType = useSetAtom(defaultBarcodeScannerTypeAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? lenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? lenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, lenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      if (isSubmitting) return;

      setRentalTargetHospitalProducts(rentalTargetHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [rentalTargetHospitalProducts, isSubmitting]
  );

  const handleClickSwitchDeviceCamera = useCallback(() => {
    if (canUseDeviceCamera) {
      setDefaultBarcodeScannerType('camera');
      navigate('/shared/rental/product/camera');
    }
  }, [canUseDeviceCamera, navigate, setDefaultBarcodeScannerType]);

  // 読み取ったバーコードより貸出対象リストを生成 (親子関係対応版)
  const searchScannedBarcodeAndValidateRentalTargets = async (scannedCode: string) => {
    // 既に検索動作中の場合(イベントでの多重呼出) は処理しない
    if (isSearching) return;

    /* [動作フロー]
      読取ったバーコード用の検索APIから対象機器リスト取得
      →親子関係を分類
      →追加する機器を抽出
      →1件以上追加 かつ 親子関係がある場合は情報ダイアログ表示
     */

    const listScanBarcodeRentalProductsParams: ListScanBarcodeRentalProductsParams = {
      scanBarcode: scannedCode,
    };

    try {
      // クリティカルセクション
      setIsSearching(true);

      const listScanBarcodeRentalProductsResponse = await listScanBarcodeRentalProducts(
        myInfo.hospitalHashId,
        listScanBarcodeRentalProductsParams
      );

      const validated = validateRentalTarget(listScanBarcodeRentalProductsResponse.data);

      if (validated.validationError) {
        // エラーの場合は エラーダイアログ表示 して処理を完了
        setRentalErrorStatus(validated.validationError);
        setRentalErrorAdditionalInfo({
          managementId: validated.managementIdOfErrorOccurred,
          scannedBarcode: validated.validationError === 'target_not_found' ? scannedCode : undefined,
        });
        setRentalErrorDialogIsOpened(true);
        return;
      }

      // 貸出リストに追加する機器を抽出
      const additionalProducts = validated.additionalTargets.filter(
        (product) => !rentalTargetHospitalProducts.some((list) => product.hashId === list.hashId)
      );
      // 貸出リストにない対象機器が1件以上
      if (additionalProducts.length > 0) {
        // 貸出リストに追加
        setRentalTargetHospitalProducts([...rentalTargetHospitalProducts, ...additionalProducts]);

        // 追加の親子関係情報があれば 親子関係情報ダイアログ表示
        if (validated.unitRelationAppendStatus) {
          setTargetStatusForUnitRelation(validated.unitRelationAppendStatus);
          setOpenRentalDevicesAddedDialog(true);
        }
      }
    } catch (e: unknown) {
      console.error(e); // output to console

      let errorMessage = `予期しないエラーが発生しました。`;

      if (e instanceof Error) {
        if (e.message) {
          errorMessage += e.message;
        }
      }

      const errorData = getAxiosErrorData(e);
      if (errorData && errorData?.message) {
        errorMessage = `読取バーコードでのデータ取得時にエラーが発生しました: ${errorData?.message}`;
      }
      openSnackBar(errorMessage, 'error', {
        horizontal: 'left',
        vertical: 'bottom',
      });
    } finally {
      // クリティカルセクション解除
      setIsSearching(false);
      // NOTE: まだこのタイミングではダイアログ系の状態変数を初期化できない(関数から抜ける方が先に来る)
    }
  };

  // イベントメソッド: バーコードリーダー からの キーイベント受信時
  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (isSubmitting) return;

      // NOTE:onKeyPressが非推奨になったのでKeyDownイベントに変更したところ特殊キー(Esc/ArrowDown)等も受信するようになったのでフィルタする

      // 文字入力を検知した場合、managementIdに連結する
      if (event.key.length === 1) {
        setManagementId(managementId + event.key);
        return;
      }

      // KeyBindの制御：Enter, BackSpace以外のキーを無視する
      // BackSpaceを検知したらmanagementIdから最後の文字を削除する
      // Enterキーであっても 管理番号が空の場合は早期リターン
      if (event.key === 'Backspace') {
        setManagementId(managementId.slice(0, -1));
        return;
      } else if (event.key !== 'Enter') {
        return;
      } else if (managementId.length === 0) {
        return;
      }

      // Enterを検知したら、managementIdをもとに機器情報を検索する
      let parameterManagementId = StrUtil.toHankaku(managementId);
      // TODO: FIX ME 以下コメントと処理の内容がズレている
      // (コメントでは "!123(45" を "45" に変換するが、実際は "(45" と変換されている。slice に指定する位置が間違っている。仕様把握している者の修正を求む)
      if (parameterManagementId[0] === '!') {
        parameterManagementId = parameterManagementId.slice(0, -1);
        const index = parameterManagementId.indexOf('('); // `(` のインデックスを取得
        if (index !== -1) {
          // `(` が見つかった場合
          parameterManagementId = parameterManagementId.slice(index); // `(` の次の文字から末尾までを取得
        }
      }

      setManagementId('');

      // 親子関係を考慮したリストアップ制御を行う
      await searchScannedBarcodeAndValidateRentalTargets(parameterManagementId);
    },
    [managementId, myInfo.hospitalHashId, isSubmitting]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };
  // 貸出登録処理の開始
  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    const rentData: CheckoutRentalData = {
      hospitalRoomHashId: rentRoom?.hashId,
      isForcedRental: false,
      checkoutDeviceLender: deviceLender,
      checkoutUserHashId: myInfo.hashId,
      hospitalProductHashIds: rentalTargetHospitalProducts.map((item) => item.hashId),
    };
    await bulkCheckout(myInfo.hospitalHashId, rentData);
    navigate('/shared/complete');
    // 状態変数の初期化
    setRentalStepper(3);
  }, [isSubmitting]);

  const focusInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      // ダイアログ表示している場合は何もしない
      if (rentalErrorDialogIsOpened || openRentalDevicesAddedDialog) {
        return;
      }
      // ダイアログ非表示時に、イベント発生元がフォーカスを失った場合は再度フォーカスする(使用元: StyledBarcodeInput バーコード入力欄-隠し)
      e.target.focus();
    },
    [rentalErrorDialogIsOpened, openRentalDevicesAddedDialog]
  );

  useEffect(() => {
    setRentalStepper(2);
  }, [setRentalStepper]);

  const RentalTableView = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={rentalTableContainerStyle}>
          <RentProductTable
            rentRoom={rentRoom?.name ?? '未定'}
            rentalTargetHospitalProducts={rentalTargetHospitalProducts}
            onClickCancel={handleClickCancel}
          />
        </Box>
        <Box sx={operationContainerStyle}>
          {lenderName && (
            <Box sx={operatorTableStyle}>
              <VerticalPairTable label="持出者" name={lenderName} />
            </Box>
          )}
          <Box>
            <RentButton label={'貸出する'} onChange={handleSubmit} />
          </Box>
        </Box>
      </Box>
    );
  };

  // 親子関係情報ダイアログ表示
  useEffect(() => {
    if (!openRentalDevicesAddedDialog || !targetStatusForUnitRelation) return;

    // 即値関数で表示実行
    (async () => {
      try {
        await dialogHandler.open<ParentChildDeviceDialogProps, unknown>(ParentChildDeviceDialog, {
          status: targetStatusForUnitRelation,
        });
      } catch (e) {
        console.error(e); // only output to console
      } finally {
        // 状態変数の初期化
        setOpenRentalDevicesAddedDialog(false);
        setTargetStatusForUnitRelation(undefined);
      }
    })();
  }, [openRentalDevicesAddedDialog, targetStatusForUnitRelation]);

  return (
    <>
      <Header title={'貸出'} />
      <Box sx={contentFixedStyle}>
        {/* rentalTargetHospitalProducts が 0件 の場合は NoRowsContent(バーコードリーダーの使い方) を表示する */}
        {
          <Box sx={rootStyle}>
            {rentalTargetHospitalProducts.length === 0 ? <NoRowsContent /> : <RentalTableView />}
          </Box>
        }
      </Box>
      <Footer
        text={'貸出する医療機器の\nバーコードを読み取って下さい'}
        nextButtonLabel={canUseDeviceCamera ? 'カメラで読み取る' : undefined}
        onClickNextButton={handleClickSwitchDeviceCamera}
      />
      <RentalErrorDialog
        open={rentalErrorDialogIsOpened}
        onClickButton={() => {
          // 閉じるタイミングで状態変数の初期化を行う
          setRentalErrorDialogIsOpened(false);
          setRentalErrorStatus(null);
          setRentalErrorAdditionalInfo(undefined);
        }}
        status={rentalErrorStatus}
        additionalInfo={rentalErrorAdditionalInfo}
        type="rent"
      />
      <StyledBarcodeInput
        type="text"
        autoFocus
        readOnly
        onBlur={focusInput}
        value={managementId}
        onKeyDown={handleBarcodeReaderInput}
      />
    </>
  );
};

const rootStyle: SxProps = {margin: '0 80px', width: '100%'};

const rentalTableContainerStyle: SxProps<Theme> = (theme) => ({
  maxHeight: '100%',
  overflow: 'auto',
  height: 'fit-content',
  width: '65%',
  [theme.breakpoints.up('laptop')]: {
    width: '70%',
  },
});

const StyledBarcodeInput = styled('input')({
  position: 'absolute',
  top: 20,
  marginTop: '-550px',
  '@media screen and (orientation: portrait)': {
    marginTop: '0px',
    marginLeft: '-5500px',
  },
});

const operationContainerStyle: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '25%',
  height: '398px',
  overflow: 'auto',
  gap: '20px',
  [theme.breakpoints.up('laptop')]: {
    paddingBottom: '16px',
    paddingTop: '16px',
  },
});

const operatorTableStyle: SxProps<Theme> = (theme) => ({
  width: '100%',
  marginTop: '48px',
  [theme.breakpoints.up('laptop')]: {
    marginTop: '28px',
  },
});
