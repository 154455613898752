import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles, Grid, Theme, Button, Popper} from '@material-ui/core';
import {ArrowDropDown, Settings} from '@material-ui/icons';
import {FilterPopper} from './FilterPopper';
import {NotificationCategory, NotificationOptionFilter} from '@modules/notifications/types';
import {useNavigate} from 'react-router-dom';

export type CheckedMapKey = NotificationCategory | 'all';

export type CheckedMap = {
  [key in CheckedMapKey]: boolean;
};

type Props = {
  open: boolean;
  hasUnread: boolean;
  onClickAllRead: () => void;
  onCategoryFilterChange: (checkedValues: CheckedMap) => void;
  onClose: () => void;
  optionFilters: NotificationOptionFilter[];
};

export const DrawerAction: React.FC<Props> = ({
  open: openDrawer,
  hasUnread,
  onClickAllRead,
  onCategoryFilterChange,
  onClose,
  optionFilters,
}) => {
  const classes = useStyles();

  const [checkedValues, setCheckedValues] = useState<CheckedMap>(() => {
    return optionFilters
      .flatMap((filter) => filter.values)
      .reduce<CheckedMap>(
        (acc, value) => {
          acc[value] = false;
          return acc;
        },
        {all: false} as CheckedMap
      );
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = useMemo(() => !!anchorEl && openDrawer, [openDrawer, anchorEl]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!anchorEl && !openDrawer) {
      setAnchorEl(null);
    }
  }, [anchorEl, openDrawer]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(anchorEl ? anchorEl : event.currentTarget),
    [anchorEl]
  );

  const handleClickNavigationSetting = useCallback(() => {
    navigate('/account/notification');
    setAnchorEl(null);
    onClose();
  }, [navigate, onClose]);

  const handleSubmit = useCallback(
    (checkedValue: CheckedMap) => {
      setCheckedValues(checkedValue);
      onCategoryFilterChange(checkedValue);
      setAnchorEl(null);
    },
    [onCategoryFilterChange]
  );

  if (optionFilters.length === 0) return null;

  return (
    <Grid container className={classes.actionContainer} justifyContent={'space-between'} alignItems={'center'}>
      <Grid item style={{marginRight: '16px'}}>
        <Button variant="outlined" className={classes.filterButton} onClick={handleClick}>
          フィルター
          <ArrowDropDown fontSize="small" />
        </Button>
        <Popper open={open} anchorEl={anchorEl} className={classes.popper} placement={'bottom-start'}>
          <FilterPopper
            onSubmit={handleSubmit}
            onCancel={() => setAnchorEl(null)}
            checkedMap={checkedValues}
            optionFilters={optionFilters}
          />
        </Popper>
      </Grid>
      {hasUnread && (
        <Grid item>
          <Button onClick={onClickAllRead} className={classes.allReadButton}>
            すべて既読にする
          </Button>
        </Grid>
      )}
      <div className={classes.flex} />
      <Grid item>
        <Button color="inherit" className={classes.settingLinkIcon} onClick={handleClickNavigationSetting}>
          <Settings />
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    padding: '12px 20px',
    fontSize: '14px',
  },
  filterButton: {
    width: '120px',
    border: '1px solid #CDD6E1',
    backgroundColor: '#EBF1F5',
    padding: '8px 6px',
  },
  allReadButton: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  settingLinkIcon: {
    color: theme.palette.secondary.dark,
  },
  flex: {
    flexGrow: 1,
  },
  popper: {
    zIndex: theme.zIndex.drawer + 2,
  },
}));
