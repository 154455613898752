import {useAtomValue} from 'jotai';
import {pageSizeAtom} from '../state';
import {FetchHospitalProductsParams, useFetchHospitalProductsQuery} from '@modules/hospital_products/api';
import {useMemo} from 'react';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {PurchaseCandidateProductComponent} from './useColumns';
import {CategoryFormatter} from '@modules/categories/helpers';

const convertToPurchaseCandidateProductComponent = (
  data: HospitalProductIndex[]
): PurchaseCandidateProductComponent[] => {
  return data.map<PurchaseCandidateProductComponent>((item) => {
    const rootCategory = CategoryFormatter.getRootCategory(item.categories);
    const narrowCategory = CategoryFormatter.getNarrowCategory(item.categories);
    return {
      ...item,
      rootCategory: rootCategory?.name ?? '',
      narrowCategory: narrowCategory?.name ?? '',
    };
  });
};

export const usePurchaseCandidateProductsQuery = (hospitalHashId: string, param: FetchHospitalProductsParams) => {
  const pageSize = useAtomValue(pageSizeAtom);
  const query = useFetchHospitalProductsQuery(hospitalHashId, param);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [query.totalCount, pageSize]);

  const data = useMemo(() => {
    return convertToPurchaseCandidateProductComponent(query.data);
  }, [query.data]);

  return {...query, data, totalPage};
};
