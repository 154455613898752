import React from 'react';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {PageHeader} from './PageHeader';
import {ReportList} from './ReportList';
import {
  TrainingReportDialog,
  TrainingReportDialogProps,
  TrainingReportDialogResult,
} from './dialogs/TrainingReportDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useCreateTrainingReportMutation, useSearchVariables} from './hooks';
import {useEffectOnce} from 'react-use';
import {isNullish} from '@front-libs/helpers';
import {useLocation} from 'react-router-dom';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type ReportRouteState = {
  trainingPlanHashId?: string;
  trainingScheduleHashId?: string;
};

export const TrainingManagementReports: React.FC = () => {
  const {canEdit: canEditTraining} = useUserResourcesPermissions('TRAINING');

  const {myInfo} = useMyInfo();
  const {mutate} = useCreateTrainingReportMutation();
  const {setTrainingPlanHashIds, setTrainingScheduleHashIds} = useSearchVariables();
  const {state} = useLocation();

  useEffectOnce(() => {
    const trainingPlanHashId = (state as ReportRouteState)?.trainingPlanHashId;
    const trainingScheduleHashId = (state as ReportRouteState)?.trainingScheduleHashId;
    if (isNullish(trainingPlanHashId) && isNullish(trainingScheduleHashId)) return;

    if (!isNullish(trainingPlanHashId)) setTrainingPlanHashIds([trainingPlanHashId]);
    if (!isNullish(trainingScheduleHashId)) setTrainingScheduleHashIds([trainingScheduleHashId]);
  });

  const handleRegisterReport = async () => {
    const reportData = await dialogHandler.open<TrainingReportDialogProps, TrainingReportDialogResult>(
      TrainingReportDialog,
      {isEdit: false, currentUser: myInfo}
    );
    mutate({...reportData, numberOfAttendants: reportData.numberOfAttendants ?? 0});
  };

  return (
    <ListPageLayout page="training_managements_reports">
      <ListPageLayout.Header>
        <PageHeader onRegisterReport={handleRegisterReport} canCreateReport={canEditTraining} />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <ReportList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};
