import React, {useMemo} from 'react';
import {Table} from '@molecules/Table';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Column} from '@molecules/Table/props';
import dayjs from 'dayjs';
import {Pagination} from '@material-ui/lab';
import {hospitalProductPerPage} from '../hooks';
import {Grid} from '@material-ui/core';

type Props = {
  layout: TableLayoutResult;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any[];
  // 0-based index
  page: number;
  totalCount: number;
  onPageChange: (newPage: number) => void;
};

export const HospitalProductTable: React.FC<Props> = ({layout, totalCount, data, page, onPageChange}) => {
  const totalPage = useMemo(() => Math.ceil(totalCount / hospitalProductPerPage), [totalCount]);

  const tableLayout = useMemo(() => {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const l = (layout?.tableLayout ?? []).map<Column<any>>((item) => {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const tableColumn: Column<any> = {...item, noBodyWrap: true, render: undefined};
      if (item.field === 'dateOfDisposal') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({dateOfDisposal}: any) =>
          dateOfDisposal !== '' ? dayjs(dateOfDisposal).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'dateOfPurchase') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({dateOfPurchase}: any) =>
          dateOfPurchase !== '' ? dayjs(dateOfPurchase).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'isSpecificMaintain') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({isSpecificMaintained}: any) => isSpecificMaintained;
      }
      if (item.field === 'rentalStartDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({rentalStartDate}: any) =>
          rentalStartDate !== '' ? dayjs(rentalStartDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'rentalDueDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({rentalDueDate}: any) =>
          rentalDueDate !== '' ? dayjs(rentalDueDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'rentalReturnDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({rentalReturnDate}: any) =>
          rentalReturnDate !== '' ? dayjs(rentalReturnDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'leaseStartDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({leaseStartDate}: any) =>
          leaseStartDate !== '' ? dayjs(leaseStartDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'leaseDueDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({leaseDueDate}: any) =>
          leaseDueDate !== '' ? dayjs(leaseDueDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'leaseReturnDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({leaseReturnDate}: any) =>
          leaseReturnDate !== '' ? dayjs(leaseReturnDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'demonstrationStartDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({demonstrationStartDate}: any) =>
          demonstrationStartDate !== '' ? dayjs(demonstrationStartDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'demonstrationEndDate') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({demonstrationEndDate}: any) =>
          demonstrationEndDate !== '' ? dayjs(demonstrationEndDate).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'dateOfDisposal') {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        tableColumn.render = ({dateOfDisposal}: any) =>
          dateOfDisposal !== '' ? dayjs(dateOfDisposal).format('YYYY/MM/DD') ?? '' : '';
      }
      return tableColumn;
    });
    return l;
  }, [layout]);

  return (
    <>
      <Table
        data={data}
        columns={tableLayout}
        showSelection={false}
        stickyHeader
        paperProps={{style: {maxHeight: 'calc(100vh - 180px)'}}}
      />
      <Grid container justifyContent="center" style={{marginTop: 24}}>
        <Pagination
          page={page}
          count={totalPage}
          shape="rounded"
          color="primary"
          onChange={(_, newPage) => onPageChange(newPage)}
        />
      </Grid>
    </>
  );
};
