import React from 'react';
import {purchaseRequestStateAtom, PurchaseRequestStep} from '../states';
import {CreatePurchaseRequestFormData} from '../types';
import {useAtomValue} from 'jotai';
import {OverviewIntroductionContent} from '../components/OverviewIntroductionContent';
import {ConfirmSharingInformationContent} from '../components/ConfirmSharingInformationContent';
import {ContactInformationContent} from '../components/ContactInformationContent';
import {ConfirmContent} from '../components/ConfirmContent';
import {Control} from 'react-hook-form';

export type UseCurrentStepContentProps = {
  control: Control<CreatePurchaseRequestFormData>;
};

export const useCurrentStepContent = (props: UseCurrentStepContentProps) => {
  const {control} = props;
  const {currentStep} = useAtomValue(purchaseRequestStateAtom);
  switch (currentStep) {
    case PurchaseRequestStep.overviewIntroduction.key:
      return <OverviewIntroductionContent />;
    case PurchaseRequestStep.confirmSharingInformation.key:
      return <ConfirmSharingInformationContent />;
    case PurchaseRequestStep.contactInformation.key:
      return <ContactInformationContent control={control} />;
    case PurchaseRequestStep.confirmContent.key:
      return <ConfirmContent />;
    default:
      return undefined;
  }
};
