import React, {useCallback, useMemo, useState} from 'react';
import {useAtom} from 'jotai';
import {tabAtom} from '@Apps/InspectionResult/pc/ViewInspectionResult/states';
import {Grid, Tabs, Tab, makeStyles, Theme, createStyles, Divider, Button} from '@material-ui/core';
import {TabKey, tabs} from '@Apps/InspectionResult/pc/ViewInspectionResult/types';
import {InspectionTableIndex} from '@modules/inspections/types';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {Items} from '@Apps/InspectionResult/pc/ViewInspectionResult/Items';
import {FilesForm} from '@Apps/InspectionResult/pc/common/FilesForm';
import {useFormikContext} from 'formik';
import {onlineManager} from 'react-query';
import styled from '@emotion/styled';
import {useFetchHospitalSettingQuery} from '@modules/hospital_settings/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {allowUpdateCompletedInspectionResultStatusKey} from '@modules/hospital_settings/types';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      padding: '32px',
    },
    main: {
      paddingTop: '32px',
    },
    divider: {
      width: '100%',
      marginLeft: '0px',
      marginRight: '0px',
    },
  })
);

const ButtonWrapDiv = styled('div')({
  '& > :first-of-type': {
    marginRight: '32px',
  },
});

type MainContentProps = {
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
};

/**
 * 点検のメイン
 * @param props
 * @returns
 */
export const MainContent: React.FC<MainContentProps> = (props) => {
  const {myInfo} = useMyInfo();
  const {canEdit: canEditInspection} = useUserResourcesPermissions('INSPECTION');
  const {inspectionTable, inspectionResult} = props;
  const classes = useStyles();
  const formikContext = useFormikContext();
  const [tab, setTab] = useAtom(tabAtom);
  const [changeEditButton, setChangeEditButton] = useState(false);

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key as TabKey);
    },
    [setTab]
  );

  /** 保存押下時 */
  const submitClick = useCallback(
    async (e: React.MouseEvent) => {
      await formikContext.submitForm();
      formikContext.resetForm();
      setChangeEditButton(false);
    },
    [formikContext]
  );

  /** 編集ボタンを保存ボタンに切り替える */
  const EditButton = useMemo(() => {
    if (!onlineManager.isOnline() || tab !== 'items') return null;
    if (!changeEditButton) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setChangeEditButton(true);
          }}>
          編集
        </Button>
      );
    }

    return (
      <ButtonWrapDiv>
        <Button
          variant="contained"
          onClick={() => {
            formikContext.resetForm();
            setChangeEditButton(false);
          }}>
          キャンセル
        </Button>{' '}
        <Button variant="contained" color="primary" disabled={!formikContext.dirty} onClick={submitClick}>
          保存
        </Button>
      </ButtonWrapDiv>
    );
  }, [changeEditButton, formikContext, submitClick, tab]);
  const enableQuery = useFetchHospitalSettingQuery(
    myInfo.hospitalHashId,
    allowUpdateCompletedInspectionResultStatusKey
  );
  const isEditable = enableQuery?.data?.value === 'true' && canEditInspection;

  return (
    <Grid container className={classes.root}>
      <Grid item container justifyContent="flex-end">
        {isEditable && EditButton}
      </Grid>
      <Grid item container style={{marginTop: '8px'}}>
        <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
          {tabs.map((item) => (
            <Tab key={item.value} label={item.label} value={item.value} />
          ))}
        </Tabs>
      </Grid>
      <Divider variant="middle" className={classes.divider} />
      <Grid item container className={classes.main}>
        {tab === 'items' && (
          <Items
            isEditable={isEditable && changeEditButton}
            inspectionTable={inspectionTable}
            inspectionResult={inspectionResult}
          />
        )}
        {tab === 'files' && <FilesForm inspectionResult={inspectionResult} isEditView={false} />}
      </Grid>
    </Grid>
  );
};
