import {Brightness1} from '@material-ui/icons';
import React from 'react';
import {getProductStatusByValue} from '@modules/hospital_products/constants';
import {ProductStatusConst} from '@modules/hospital_products/types';
import {styled} from '@material-ui/styles';

// colorはTheme側ですでに使われているのでFillとしている
const Brightness = styled(Brightness1)(({fill}: {fill: string}) => ({
  width: '10px',
  marginRight: '8px',
  position: 'relative',
  height: 'auto',
  color: fill,
  fill,
}));

const StyledSpan = styled('span')(({top}: {top?: string}) => ({
  marginTop: top || '-8px', // デフォルトは-8px
}));

const StyledColorSpan = styled('span')(({fill}: {fill: string}) => ({
  color: fill,
}));

type Props = {
  productStatus: ProductStatusConst;
  isForcedRental: boolean;
  marginTop?: string;
};

export const ProductStatusIcon = ({productStatus}: Props) => {
  const productStatusConst = getProductStatusByValue(productStatus);
  return <Brightness fill={productStatusConst.color} />;
};

export const ProductStatus = ({productStatus, isForcedRental, marginTop}: Props) => {
  const status = isForcedRental && productStatus === 'working' ? 'forceRental' : productStatus;
  const productStatusConst = getProductStatusByValue(status);

  return (
    <StyledSpan top={marginTop}>
      <ProductStatusIcon productStatus={status} isForcedRental={isForcedRental} />
      <StyledColorSpan fill={productStatusConst.color}>{productStatusConst.label}</StyledColorSpan>
    </StyledSpan>
  );
};
