import React, {useMemo, useCallback} from 'react';
import dayjs from 'dayjs';
import {Grid, Button, styled} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionResultStatusBadge} from '@components/atoms/InspectionResultStatusBadge';
import {
  StartInspectionDialog,
  StartInspectionDialogProps,
  StartInspectionDialogResult,
} from '@components/organisms/StartInspectionDialog';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type Props = {
  wholeProductHashId: string;
  inspectionResult: InspectionResultIndex;
};

export const InspectionListItem = ({inspectionResult, wholeProductHashId}: Props) => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const {canEdit: canEditInspection} = useUserResourcesPermissions('INSPECTION');

  const inspectionName =
    inspectionResult?.inspection?.name !== '' ? inspectionResult?.inspection?.name : '該当点検表なし';
  const scheduledTime = useMemo(
    () => (inspectionResult.scheduledTime ? dayjs(inspectionResult.scheduledTime).format('YYYY/MM/DD') : ''),
    [inspectionResult.scheduledTime]
  );
  const inspectionSettingName =
    inspectionResult?.inspectionProduct?.name !== '' ? inspectionResult?.inspectionProduct?.name : '';

  const handleClickInputInspectionButton = useCallback(async () => {
    try {
      const res = await dialogHandler.open<StartInspectionDialogProps, StartInspectionDialogResult>(
        StartInspectionDialog,
        {
          hospitalHashId: myInfo.hospitalHashId,
          inspectionType: inspectionResult.type ?? undefined,
          wholeProductHashId: wholeProductHashId,
          hospitalProductHashId: inspectionResult.hospitalProductHashId,
          inspectionResultHashId: inspectionResult.hashId,
          defaultInspectorHashId: myInfo.hashId,
          defaultInspectionHashId: inspectionResult.inspectionHashId,
          showsSelectInspection: inspectionResult.inspectionHashId === null,
        }
      );

      navigate(`/inspections/${res.inspection}/result/${res.inspectionResult}`, {
        state: {
          status: 'uncompleted',
          inspectionHashId: res.inspection,
          inspectorHashId: res.inspector,
        },
      });
    } catch (_) {
      // empty block
    }
  }, [
    myInfo.hospitalHashId,
    myInfo.hashId,
    wholeProductHashId,
    inspectionResult.inspectionHashId,
    inspectionResult.hashId,
    inspectionResult.type,
    inspectionResult.hospitalProductHashId,
    navigate,
  ]);

  const handleClickTitleButton = useCallback(() => {
    if (inspectionResult?.status === 'unplanned') {
      return handleClickInputInspectionButton();
    }
    navigate(`/inspections/${inspectionResult.inspectionHashId}/result/${inspectionResult.hashId}`);
  }, [
    handleClickInputInspectionButton,
    inspectionResult.hashId,
    inspectionResult.inspectionHashId,
    inspectionResult?.status,
    navigate,
  ]);

  return (
    <InspectionContent>
      <InspectionTitleSection item container justifyContent="space-between">
        <InspectionTitleButton
          onClick={canEditInspection ? handleClickTitleButton : undefined}
          disabled={!canEditInspection}>
          {inspectionSettingName}
        </InspectionTitleButton>
        {inspectionResult?.status !== 'completed' && canEditInspection && (
          <InspectionActionButton size="small" onClick={handleClickInputInspectionButton}>
            点検開始
          </InspectionActionButton>
        )}
      </InspectionTitleSection>
      <InspectionItemGrid item>
        <Label>点検表名</Label>：{inspectionName}
      </InspectionItemGrid>
      <InspectionStatus item>
        <Label>ステータス</Label>： <InspectionResultStatusBadge size="small" status={inspectionResult.status} />
      </InspectionStatus>
      <InspectionItemGrid item>
        <Label>点検予定日</Label>：{scheduledTime}
      </InspectionItemGrid>
    </InspectionContent>
  );
};

const InspectionContent = styled(Grid)({
  marginBottom: '24px',
  fontSize: '14px',
});

const InspectionTitleSection = styled(Grid)({
  marginBottom: '7px',
});

const InspectionTitleButton = styled(Button)({
  padding: '0 4px',
  color: '#172B4D',
  fontWeight: 700,
  marginLeft: '-4px',
  '&.Mui-disabled': {
    color: 'unset',
  },
});

const InspectionActionButton = styled(Button)({
  padding: '0 4px',
  color: '#0052CC',
  fontWeight: 400,
  marginRight: '-4px',
});

const InspectionStatus = styled(Grid)({
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  height: '19px',
  lineHeight: '19px',
});

const InspectionItemGrid = styled(Grid)({
  display: 'flex',
  marginBottom: '8px',
});

const Label = styled('div')({
  minWidth: '70px',
});
