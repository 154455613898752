export type HospitalSettingsIndex = {
  data: {
    hospitalHashId: string;
    key: HospitalSettingKey;
    value: string;
  }[];
};

export type HospitalSettingsDetail = HospitalSettingsIndex;

export type HospitalSettingKey =
  | typeof allowTypeStatusKey
  | typeof automationCreateStatusKey
  | typeof allowUpdateCompletedInspectionResultStatusKey
  | (typeof rentalSettings)['use_device_lender']['key']
  | (typeof rentalSettings)['allow_undefined_room']['key']
  | (typeof rentalSettings)['skip_floor_selection']['key']
  | (typeof rentalSettings)['pre_inspection_rental_restriction_period']['key']
  | (typeof rentalSettings)['allow_force_rental_for_pre_inspection_restriction_period']['key']
  | (typeof rentalSettings)['restrict_lending_on_overdue_inspection']['key']
  | (typeof rentalSettings)['allow_force_rental_for_restriction_overdue_inspection']['key']
  | (typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings)['daily']['key']
  | (typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings)['pre_use']['key']
  | (typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings)['in_use']['key']
  | (typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings)['post_use']['key']
  | (typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings)['periodic']['key']
  | typeof allowJointingPostUseInspectionResult
  | typeof periodForJointingPostUseInspectionResultKey
  | (typeof faultReceptionSettings)['show_rentable_devices']['key']
  | (typeof faultReceptionSettings)['show_fault_receptions']['key']
  | (typeof faultReceptionSettings)['show_return_waiting_reception']['key'];

export type UpdateHospitalSettingsParams = {
  hospitalHashId: string;
  key: HospitalSettingKey;
  value: string;
};

export type HospitalRentalSetting = 'not_need' | 'by_barcode' | 'by_direct_input';

export const prefixAllowApplyPreviousInspectionResult = 'allow_apply_previous_inspection_result_';

export const inspectionTypeTokAllowApplyPreviousInspectionResultSettings = {
  daily: {
    field: 'allowApplyPreviousInspectionResultDaily',
    key: 'allow_apply_previous_inspection_result_daily',
  },
  pre_use: {
    field: 'allowApplyPreviousInspectionResultPreUse',
    key: 'allow_apply_previous_inspection_result_pre_use',
  },
  in_use: {
    field: 'allowApplyPreviousInspectionResultInUse',
    key: 'allow_apply_previous_inspection_result_in_use',
  },
  post_use: {
    field: 'allowApplyPreviousInspectionResultPostUse',
    key: 'allow_apply_previous_inspection_result_post_use',
  },
  periodic: {
    field: 'allowApplyPreviousInspectionResultPeriodic',
    key: 'allow_apply_previous_inspection_result_periodic',
  },
} as const;

export const rentalSettings = {
  use_device_lender: {
    field: 'needManageMedicalDeviceLenderStatus',
    key: 'need_manage_medical_device_lender_status',
  },
  allow_undefined_room: {
    field: 'allowRentalRoomUndefined',
    key: 'allow_rental_room_undefined',
  },
  skip_floor_selection: {
    field: 'allowSkipRentalFloorSelection',
    key: 'allow_skip_rental_floor_selection',
  },
  enable_pre_inspection_rental_restriction: {
    field: 'enableInspectionRentalRestriction',
  },
  pre_inspection_rental_restriction_period: {
    field: 'preInspectionRentalRestrictionPeriod',
    key: 'pre_inspection_rental_restriction_period',
  },
  allow_force_rental_for_pre_inspection_restriction_period: {
    field: 'allowForceRentalForPreInspectionRestrictionPeriod',
    key: 'allow_force_rental_for_pre_inspection_restriction_period',
  },
  restrict_lending_on_overdue_inspection: {
    field: 'restrictLendingOnOverdueInspection',
    key: 'restrict_lending_on_overdue_inspection',
  },
  allow_force_rental_for_restriction_overdue_inspection: {
    field: 'allowForceRentalForRestrictionOverdueInspection',
    key: 'allow_force_rental_for_restriction_overdue_inspection',
  },
} as const;

export const faultReceptionSettings = {
  show_rentable_devices: {
    field: 'showRentableDevices',
    key: 'show_rentable_devices',
  },
  show_fault_receptions: {
    field: 'showFaultReceptions',
    key: 'show_fault_receptions',
  },
  show_return_waiting_reception: {
    field: 'showReturnWaitingReception',
    key: 'show_return_waiting_reception',
  },
} as const;

export const allowTypeStatusKey = 'allow_type_status' as const;
export const automationCreateStatusKey = 'automation_create_status' as const;
export const allowUpdateCompletedInspectionResultStatusKey = 'allow_update_completed_inspection_result' as const;
export const allowJointingPostUseInspectionResult = 'allow_jointing_post_use_inspection_result' as const;
export const periodForJointingPostUseInspectionResultKey = 'period_for_jointing_post_use_inspection_result' as const;

export type ReturnWaitingReceptionCategory = {
  hashId: string;
  isAcceptable: boolean;
  name: string;
};

type ReturnWaitingReceptionRootCategory = {
  narrowCategories: ReturnWaitingReceptionCategory[];
  rootCategoryHashId: string;
  rootCategoryName: string;
};

/** 設定 > 病棟ユーザーメニュー > 「返却待ち受付」の詳細設定の 受付可能な機器（小分類）の設定 APIレスポンス */
export type ReturnWaitingReceptionResponse = ReturnWaitingReceptionRootCategory[];

export type ReturnWaitingReceptionPutParams = {
  narrowCategoryHashId: string;
  isAcceptable: boolean;
};
