import React, {useCallback} from 'react';
import {createStyles, makeStyles, Theme, Grid, Button, Typography} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {useUpdateDashboardSubject} from '../hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {SetBoardVisibilityDialog, SetBoardVisibilityDialogProps} from './SetBoardVisibilityDialog';
import {openSnackBar} from '@components/molecules/SnackBar';
import {createOrUpdateDashboardLayoutSettings} from '@modules/dashboard/api';
import {DashboardLayoutSetting} from '@modules/dashboard/types';
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query';
import {PapersType} from '@Apps/Dashboard/types';

type DashboardHeaderProps = {
  currentLayout: DashboardLayoutSetting;
  permittedPaperType: PapersType[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<DashboardLayoutSetting, unknown>>;
};

/**
 * ダッシュボードのページヘッダー
 * @param props
 * @returns
 */
export const Header = (props: DashboardHeaderProps) => {
  const {currentLayout, refetch, permittedPaperType} = props;
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {notify} = useUpdateDashboardSubject();

  const handleClickAllUpdate = useCallback(() => {
    notify('all');
  }, [notify]);

  const setBoardVisibility = useCallback(async (): Promise<string | null> => {
    try {
      const res = await dialogHandler.open<SetBoardVisibilityDialogProps, DashboardLayoutSetting>(
        SetBoardVisibilityDialog,
        {
          currentLayout: currentLayout,
          permittedPaperType: permittedPaperType,
        }
      );

      await createOrUpdateDashboardLayoutSettings(myInfo.hospitalHashId, {layout: res});
      refetch();
      openSnackBar('ダッシュボードの設定が完了しました。', 'center', 'top');
    } catch (error) {
      if (error === undefined) {
        return null;
      }
      openSnackBar('ダッシュボードの設定に失敗しました。', 'center', 'top', 'error');
      throw error;
    }

    return null;
  }, [currentLayout, myInfo.hospitalHashId, refetch]);

  const handleClickDashboardSettings = useCallback(async () => {
    await setBoardVisibility();
  }, [setBoardVisibility]);

  return (
    <Grid container>
      <Grid item>
        <Grid container>
          <PageRoutingMenu>
            <Typography id="tutorial-dashboard" variant={'h5'} className={classes.pageTitle}>
              ダッシュボード
            </Typography>
            <ArrowDropDown />
          </PageRoutingMenu>
        </Grid>
      </Grid>
      <div className={classes.flex} />
      <Grid item>
        <Grid container>
          <Button
            variant={'outlined'}
            color={'primary'}
            style={{background: '#FFFFFF', marginRight: '8px'}}
            onClick={handleClickDashboardSettings}>
            ダッシュボード設定
          </Button>
          <Button
            variant={'contained'}
            color={'primary'}
            className={classes.updateButton}
            onClick={handleClickAllUpdate}>
            更新
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.primary.dark,
    },
    inspectionTypeMenu: {
      width: '204px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '40px',
      border: '2px solid #DFE1E5',
    },
    flex: {
      flexGrow: 1,
    },
    updateButton: {
      marginLeft: '8px',
    },
  })
);
