import {useAtomValue} from 'jotai';
import {purchaseRequestStateAtom, PurchaseRequestStep} from '../states';

export const useNextButtonLabel = () => {
  const {currentStep} = useAtomValue(purchaseRequestStateAtom);

  switch (currentStep) {
    case PurchaseRequestStep.overviewIntroduction.key:
      return '確認して次へ';
    case PurchaseRequestStep.confirmSharingInformation.key:
      return '承諾して次へ';
    case PurchaseRequestStep.contactInformation.key:
      return '内容確認へ';
    case PurchaseRequestStep.confirmContent.key:
      return '依頼する';
    default:
      return '';
  }
};
