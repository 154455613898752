import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Button, Grid, styled} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import {useAtom, useAtomValue} from 'jotai';
import {splitAtom} from 'jotai/utils';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import EditableColumnModal from '../EditableColumnModal';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {productsAtom, InitialValue, Product} from '../../state';
import {HospitalProductRequiredPropertyItemValueMapByTableFormRowId} from '../constants';
import {TableFormRowId, PotentiallyRequiredTableFormRowId} from '../types';
import {TableFormContents} from './TableFormContents';
import {v4 as uuidv4} from 'uuid';
import {ProductIndex} from '@modules/products/types';
import {
  NewRegistrationDialogProps,
  NewRegistrationDialogResult,
  NewRegistrationGS1Dialog,
} from '@Apps/ProductsList/pc/Dialogs/NewRegistrationGS1Dialog';
import {ApplicationIndicator, extractCodeFromGS1Barcode} from '@front-libs/helpers';
import {openSnackBar} from '@molecules/SnackBar';
import {RequiredLabelBadge} from '@atoms/RequiredLabelBadge';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {HospitalProductRequiredProperty} from '@modules/hospital_product_required_properties/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  MAX_NOTE_NUM,
  useHospitalProductNoteSettings,
} from '@modules/hospital_products/hooks/useHospitalProductNoteSettings';
import {InnerLoading} from '@molecules/Loading';
import {useHospitalDealerOption} from '@modules/hospital_dealer/api';
import {useHospitalDepartmentOption} from '@modules/hospital_departments/api';

type TableFormRow = {
  id: string;
  label: string;
  required?: boolean;
  visible?: boolean;
};

/**
 * テーブルのヘッダー行に表示する名称とID
 * 追加する際、TableFormContents側の並び順と合わせる必要がある
 */
const rows: Array<TableFormRow> = [
  // 1.管理番号
  {
    label: '管理番号',
    id: TableFormRowId.ManagementId,
    required: true,
  },
  // 2.シリアル番号
  {
    label: 'シリアル番号',
    id: TableFormRowId.SerialNumber,
  },
  // 3.ロット番号
  {
    label: 'ロット番号',
    id: TableFormRowId.LotNumber,
  },
  // 4.貸出区分
  {
    label: '貸出区分',
    id: TableFormRowId.PermanentlyAssigned,
  },
  // 5.管理部署
  {
    label: '管理部署',
    id: TableFormRowId.HospitalDepartmentHashId,
  },
  // 6.稼働状況
  {
    label: '稼働状況',
    id: TableFormRowId.Status,
  },
  // 7.所在（院内/院外）
  {
    label: '所在（院内/院外）',
    id: TableFormRowId.IsOutsideOfHospital,
  },
  // 8.機器管理場所
  {
    label: '機器管理場所',
    id: TableFormRowId.HospitalRoomHashId,
  },
  // 9.購入日
  {
    label: '購入日',
    id: TableFormRowId.DateOfPurchase,
  },
  // 10.親機・子機
  {
    label: '親機・子機',
    id: TableFormRowId.IsBaseUnit,
  },
  // 11.購入区分
  {
    label: '購入区分',
    id: TableFormRowId.WaysOfPurchase,
  },
  // 12.院内耐用年数（年）
  {
    label: '院内耐用年数（年）',
    id: TableFormRowId.LegalDurableYear,
  },
  // 13.保守契約
  {
    label: '保守契約',
    id: TableFormRowId.IsMaintenanceContract,
  },
  // 14.担当代理店
  {
    label: '担当代理店',
    id: TableFormRowId.HospitalDealerHashId,
  },
  // 15.資産番号
  {
    label: '資産番号',
    id: TableFormRowId.AssetRegisterNumber,
  },
  // {
  //   label: '減価償却費（円）',
  //   id: 'depreciationAmount',
  // },
  // {
  //   label: '帳簿価格（円）',
  //   id: 'bookValue',
  // },
  // 16.廃棄日
  {
    label: '廃棄日',
    id: TableFormRowId.DateOfDisposal,
  },
  // 17.廃棄理由
  {
    label: '廃棄理由',
    id: TableFormRowId.ReasonOfDisposal,
  },
  // 18.備考1
  {
    label: '備考1',
    id: TableFormRowId.Notes,
  },
  // 19.備考2−20
  ...Array.from({length: 19}, (_, index) => ({
    label: `備考${index + 2}`,
    id: `notes${index + 2}`,
  })),
  // 28.バーコード読み取り値
  {
    label: 'バーコード読み取り値',
    id: TableFormRowId.OptionalBarcode,
  },
  // 29.GS1-128
  {
    label: 'GS1-128',
    id: TableFormRowId.RawBarcode,
  },
];

const defaultParams = {
  page: 0,
  perPage: 100,
};

const productAtomsAtom = splitAtom(productsAtom);

type TableFormProps = {
  hashId: string;
  wholeProduct: ProductIndex | undefined;
  hospitalProductRequiredProperties: HospitalProductRequiredProperty[];
  fromGs1?: boolean;
};

/**
 * 製品データを表形式で表示・管理するためのUIコンポーネント
 * 製品の一覧表示、選択、編集、削除、複製の機能を有する
 *
 * 病院の機器情報を表示および編集するためのテーブル
 * テーブルの項目には各種Formが配置される
 *
 * @param {string} props.hashId - ハッシュID
 * @param { ProductIndex | undefined } props.wholeProduct - プロダクト情報
 * @param {boolean} props.fromGs1  - GS1からの情報かどうか (オプション)
 * @returns {React.ReactElement} テーブルフォーム全体のReact要素
 */
export const TableForm = ({
  hashId,
  wholeProduct,
  hospitalProductRequiredProperties,
  fromGs1 = false,
}: TableFormProps) => {
  const {myInfo} = useMyInfo();
  const [selected, setSelected] = useState<string[]>([]);
  const [tableRows, setTableRows] = useState<TableFormRow[]>(rows);
  const [products, setProducts] = useAtom(productsAtom);
  const productAtoms = useAtomValue(productAtomsAtom);
  const {data: hospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId);
  const {data: noteData, isLoading: isLoadingNoteSettings} = useHospitalProductNoteSettings(myInfo.hospitalHashId);

  // FIXME 何も指定しない場合20件表示。21件以上の場合表示できないincident発生したため、一時的に100件表示に変更
  const {hospitalDealerOptions: hospitalDealerOptionsList} = useHospitalDealerOption(
    myInfo.hospitalHashId,
    defaultParams
  );
  const {hospitalDepartmentOptions} = useHospitalDepartmentOption(myInfo.hospitalHashId, defaultParams);

  useEffect(() => {
    if (!noteData) return;
    const noteSettings = noteData.noteSettings;
    const tempTableFormRow: TableFormRow[] = rows.concat();

    // API取得の汎用項目データをもとにヘッダーの表示名と表示・非表示のKeyValue追加
    for (let i = 1; i <= MAX_NOTE_NUM; i++) {
      const numString = i === 1 ? '' : String(i);
      const data = tempTableFormRow.find((v) => v.id === `notes${numString}`);
      if (!data) continue;
      data.label = noteSettings[`notes${numString}Name`];
      data.visible = noteSettings[`notes${numString}Visible`];
    }

    // 必須項目の設定を反映
    const requiredProperties = hospitalProductRequiredProperties.map((p) => p.property);
    tempTableFormRow.forEach((row) => {
      const isPotentiallyRequiredRow = row.id in HospitalProductRequiredPropertyItemValueMapByTableFormRowId;
      if (!isPotentiallyRequiredRow) {
        return;
      }
      const isRequiredRow = requiredProperties.includes(
        HospitalProductRequiredPropertyItemValueMapByTableFormRowId[row.id as PotentiallyRequiredTableFormRowId]
      );
      if (!isRequiredRow) {
        row.required = false;
        return;
      }
      row.required = true;
    });

    setTableRows(tempTableFormRow);
  }, [noteData, hospitalProductRequiredProperties]);

  /**
   * 一括チェック
   * @param allData
   */
  const handleSelectAllClick = (allData: Product[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = allData.map((n) => n.uuid);
      setSelected(newSelected as string[]);
      return;
    }
    setSelected([]);
  };

  /**
   * 項目チェックボックスクリック
   * @param event マウスイベント未使用
   * @param key 選択
   */
  const handleClick = (_event: React.MouseEvent<unknown>, key: string) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = useMemo(
    () => (key: string) => {
      return selected.indexOf(key) !== -1;
    },
    [selected]
  );

  /**
   * 一括編集
   * @param e
   * @param targets 選択された行のuuid
   */
  const handleEdit = useCallback(
    async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>, targets: string[]) => {
      const value = await dialogHandler.open(EditableColumnModal, {
        hospitalRooms: hospitalRooms,
        hospitalDealerOptionsList: hospitalDealerOptionsList,
        hospitalDepartmentOptions: hospitalDepartmentOptions,
        selected: targets,
      });

      const updateProducts = products.map((product) => {
        if (targets.includes(product?.uuid + '')) {
          return {
            ...product,
            ...value,
          };
        }
        return product;
      });

      setProducts(updateProducts);
      setSelected([]);
    },
    [hospitalDealerOptionsList, hospitalDepartmentOptions, hospitalRooms, products, setProducts]
  );
  /**
   * 一括削除
   * @param e
   * @param targets 選択された行のuuid
   */
  const handleDelete = async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>, targets: string[]) => {
    try {
      await dialogHandler.open(AlertDialog, {
        title: 'リストから削除',
        content: `このリストから${selected.length}件の情報が削除されますが、よろしいですか？`,
        positiveButtonLabel: '削除',
      });
      // 削除対象のProduct以外を抽出し、setterで書き換える。
      const restProducts = products.filter((product) => targets.indexOf(product?.uuid + '') === -1);
      setProducts(restProducts);
    } finally {
      setSelected([]);
    }
  };

  /**
   * チェックが入っている機器の複製
   * @param e
   * @param targets
   */
  const handleReplication = async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>, targets: string[]) => {
    const updateProducts = products
      .filter((v) => v.uuid && targets.includes(v.uuid))
      .map((product) => {
        return {
          ...product,
          uuid: uuidv4(),
        };
      });

    setProducts([...products, ...updateProducts]);
    setSelected([]);
  };

  /** 行の追加 */
  const handleAdd = () => {
    setProducts((oldValue) => [...oldValue, {...InitialValue, uuid: uuidv4(), wholeProductHashId: hashId}]);
  };

  /** GS1で機器追加 */
  const handleAddByGs1 = async () => {
    const {gs1Barcode} = await dialogHandler.open<NewRegistrationDialogProps, NewRegistrationDialogResult>(
      NewRegistrationGS1Dialog,
      {}
    );

    // Memo: 現状は、異なる機種を同時登録できないので、一旦エラーで返す
    const gtinIndicator = extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.gtinIndicator);
    if (wholeProduct?.newJanCode !== gtinIndicator && wholeProduct?.janCode !== gtinIndicator) {
      openSnackBar('異なる機種を同時に登録することはできません。', 'left', 'bottom', 'error');
      return;
    }

    // シリアル番号とロット番号が同一の場合、重複での登録になるのでエラー
    const serialNumber = extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.serialNumberIndicator);
    const lotNumber = extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.lotNumberIndicator);
    if (products.some((item) => item.serialNumber === serialNumber && item.lotNumber === lotNumber)) {
      openSnackBar('読み込まれた機種は既に登録情報が入力されています。', 'left', 'bottom', 'error');
      return;
    }

    setProducts((oldValue) => [
      ...oldValue,
      {...InitialValue, uuid: uuidv4(), wholeProductHashId: hashId, serialNumber, lotNumber, rawBarcode: gs1Barcode},
    ]);
  };

  const IconButtons = [
    {label: '編集', icon: <CreateIcon />, action: handleEdit},
    {
      label: '削除',
      icon: <DeleteIcon />,
      action: handleDelete,
    },
    {label: '複製', icon: <CreateIcon />, action: handleReplication},
  ];

  if (isLoadingNoteSettings || !noteData) {
    return <InnerLoading />;
  }
  return (
    <StyledRoot>
      <StyledPaper>
        <TableContainer>
          <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              onSelectAllClick={handleSelectAllClick(products)}
              rowCount={productAtoms.length}
              selected={selected}
              iconButtons={IconButtons}
              tableRows={tableRows}
            />
            <TableBody>
              {productAtoms.map((productAtom, index) => {
                return (
                  <TableFormContents
                    key={`form${index}`}
                    productAtom={productAtom}
                    index={index}
                    handleClick={handleClick}
                    isSelected={isSelected}
                    noteSettings={noteData.noteSettings}
                    hospitalDealerOptions={hospitalDealerOptionsList}
                    hospitalDepartmentOptions={hospitalDepartmentOptions}
                  />
                );
              })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </StyledPaper>
      <Box>
        <Button onClick={handleAdd} color={'primary'}>
          +さらに行を追加
        </Button>
        {fromGs1 && (
          <Button onClick={handleAddByGs1} color={'primary'}>
            +さらに同機種のGS1-128を読み込み
          </Button>
        )}
      </Box>
    </StyledRoot>
  );
};

interface EnhancedTableHeadProps {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  selected: string[];
  tableRows: TableFormRow[];
  iconButtons: {
    label: string;
    icon: JSX.Element;
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selected: string[]) => void;
  }[];
}

/**
 * テーブルヘッド
 * @param props EnhancedTableHeadProps
 * @returns
 */
const EnhancedTableHead = ({onSelectAllClick, rowCount, selected, tableRows, iconButtons}: EnhancedTableHeadProps) => {
  const numSelected = selected.length;

  return numSelected > 0 ? (
    <StyledTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        <StyledTableCell>
          <Typography>{numSelected}件が選択されました</Typography>
        </StyledTableCell>
        <StyledTableCell colSpan={2}>
          <Grid container direction={'row'} justifyContent={'space-around'}>
            {iconButtons.map((iconButton) => {
              return (
                <Button
                  key={iconButton.label}
                  color={'primary'}
                  startIcon={iconButton.icon}
                  onClick={(e) => {
                    iconButton.action(e, selected);
                  }}>
                  {iconButton.label}
                </Button>
              );
            })}
          </Grid>
        </StyledTableCell>
      </TableRow>
    </StyledTableHead>
  ) : (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        {tableRows.map((headCell) => (
          <StyledHeadCell key={headCell.id} padding={'none'} visible={headCell.visible + ''}>
            {headCell.label}
            {headCell.required === true && (
              <StyledRequiredBadgeContainer>
                <RequiredLabelBadge />
              </StyledRequiredBadgeContainer>
            )}
          </StyledHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const StyledRoot = styled('div')({
  width: '100%',
});

const StyledPaper = styled(Paper)(({theme}: {theme: Theme}) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const StyledTable = styled(Table)({
  minWidth: 750,
});

const StyledTableHead = styled(TableHead)({
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
});

const StyledTableCell = styled(TableCell)({
  minWidth: 200,
  padding: 'initial',
});

const StyledHeadCell = styled(TableCell)(({visible}: {visible: string}) => ({
  paddingRight: 8,
  paddingLeft: 8,
  display: visible === 'false' ? 'none' : '',
}));

const StyledRequiredBadgeContainer = styled('div')({
  display: 'inline-flex',
  marginLeft: '12px',
  '& > span': {
    lineHeight: '14.4px',
    marginBottom: '0.6px',
  },
});
