import React, {useCallback, useMemo, useState} from 'react';

import ExcelJs from 'exceljs';
import {openSnackBar} from '@molecules/SnackBar';
import {convertDisplaySimpleDate, isNullish} from '@front-libs/helpers';
import {yup} from '@front-libs/core';
import {
  HospitalProductNoteSettings,
  HospitalProductNoteSettingsOptions,
  NoteSettings,
  ProductStatusConst,
  ProductStatusLabel,
  WaysOfPurchaseConst,
  WaysOfPurchaseLabel,
} from '@modules/hospital_products/types';
import {uploadFile as uploadFileApi} from '@modules/files/api';
import {requestImportTask} from '@modules/hospital_products/api';
import {useHospitalProductRequiredPropertyStore} from '@modules/hospital_product_required_properties/hooks';
import {
  HospitalProductRequiredProperty,
  HospitalProductRequiredPropertyItemValue,
} from '@modules/hospital_product_required_properties/types';
import dayjs from 'dayjs';
import {
  formatApprovalNumber,
  formatAssetRegisterNumber,
  formatBarcode,
  formatGS1Barcode,
  formatJANCode,
  formatLotNumber,
  formatManagementId,
  formatSerialNumber,
} from '@modules/hospital_products/utls';
import {getCellValue} from '@organisms/HospitalProductImporter';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {ValidationMessages} from './constants';
import {useHospitalProductNoteSettings} from '@modules/hospital_products/hooks/useHospitalProductNoteSettings';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';

export const testInteger = (value: string | undefined) => {
  // 必須でないので空の値は許容する
  if (!value) return true;

  // 正規表現を使用して整数のみを許容
  const isInteger = /^[+]?\d+$/.test(value);
  return isInteger; // 整数であればtrueを返す
};

export const testDate = (value: string | undefined) => {
  // 必須でないので空の値は許容する
  if (!value) return true;

  // 文字列をDateオブジェクトに変換して検証
  const date = new Date(value);
  return !Number.isNaN(date.getTime()); // 有効な日付であればtrueを返す
};

// FIXME 共通化する
// JMDNコード の 値チェック処理
const testJmdnCode = (value: string | undefined) => {
  // 必須でないので空の値は許容する
  if (!value) return true;

  // 空値以外は 正規表現で 10000000 - 99999999 チェック
  return /^[1-9]\d\d\d\d\d\d\d$/.test(value); // 8桁かつ 先頭が 1-9 で 残り7文字 が数 であれば true
};

export const EIGHT_DIGIT_NUMBER_ERROR_MESSAGE = 'JMDNコードには8桁の数値を入力してください。';

const objectSchema = yup.object({
  managementId: yup.string().required('管理番号' + ValidationMessages.required),
  serialNumber: yup.string(),
  rootCategory: yup.string(),
  narrowCategory: yup.string(),
  makerName: yup.string(),
  displayName: yup.string(),
  name: yup.string(),
  permanentlyAssigned: yup.string().oneOf(['貸出不可', '貸出可', ''], '貸出区分が正しくありません。'),
  hospitalRoomName: yup.string(),
  hospitalDealerName: yup.string(),
  hospitalDepartmentName: yup.string(),
  status: yup
    .string()
    .oneOf(['待機中', '貸出中', '点検待ち', '修理中', '廃棄済み', ''], '稼働状況が正しくありません。'),
  isSpecificMaintained: yup.string().oneOf(['該当', '非該当', ''], '特定保守製品が正しくありません。'),
  className: yup
    .string()
    .oneOf(
      ['一般医療機器', '管理医療機器', '高度管理医療機器（クラス3）', '高度管理医療機器（クラス4）', ''],
      'クラス分類が正しくありません。'
    ),
  jmdnCode: yup.string().test('jmdnCode', EIGHT_DIGIT_NUMBER_ERROR_MESSAGE, testJmdnCode),
  janCode: yup.string(),
  approvalNumber: yup.string(),
  isBaseUnit: yup.string().oneOf(['親機', '子機', '非該当', ''], '親機・子機が正しくありません。'),
  isOutsideOfHospital: yup.string().oneOf(['院内', '院外', ''], '所属（院内/院外）の形式が正しくありません。'),
  dateOfPurchase: yup.string().test('dateOfPurchase', '購入日の日付形式が正しくありません。', testDate),
  waysOfPurchase: yup
    .string()
    .oneOf(
      ['購入', 'リース', 'レンタル', '代替品', 'デモ機', '在宅用', '寄贈', '移管', ''],
      '購入区分が正しくありません。'
    ),
  purchasedNationalExpense: yup.string().oneOf(['国費', '院費', ''], '購入元が正しくありません。'),
  deliveryPrice: yup.string(),
  legalDurableYear: yup.string(),
  isMaintenanceContract: yup.string().oneOf(['保守契約あり', '保守契約なし', ''], '保守契約の形式が正しくありません。'),
  lotNumber: yup.string(),
  assetRegisterNumber: yup.string(),
  rawBarcode: yup.string(),
  dateOfDisposal: yup.string().test('dateOfDisposal', '廃棄日の日付形式が正しくありません。', testDate),
  reasonOfDisposal: yup.string(),

  taxIncluded: yup.string().oneOf(['税込', '税抜き', ''], '税込/税抜きが正しくありません。'),

  rentalId: yup.string(),
  rentalDealerName: yup.string(),
  rentalFee: yup.string(),
  rentalStartDate: yup.string().test('rentalStartDate', 'レンタル開始日の日付形式が正しくありません。', testDate),
  rentalDueDate: yup.string().test('rentalDueDate', 'レンタル終了予定日の日付形式が正しくありません。', testDate),
  rentalReturnDate: yup.string().test('rentalReturnDate', 'レンタル機器返却日の日付形式が正しくありません。', testDate),

  leaseId: yup.string(),
  leaseDealerName: yup.string(),
  leaseFee: yup.string(),
  leaseStartDate: yup.string().test('leaseStartDate', 'リース開始日の日付形式が正しくありません。', testDate),
  leaseDueDate: yup.string().test('leaseDueDate', 'リース終了予定日の日付形式が正しくありません。', testDate),
  leaseReturnDate: yup.string().test('leaseReturnDate', 'リース機器返却日の日付形式が正しくありません。', testDate),

  purposeOfDemonstration: yup.string(),
  demonstrationStartDate: yup
    .string()
    .test('demonstrationStartDate', 'デモ開始日の日付形式が正しくありません。', testDate),
  demonstrationEndDate: yup.string().test('demonstrationEndDate', 'デモ終了日の日付形式が正しくありません。', testDate),

  notes: yup.string(),
  notes2: yup.string(),
  notes3: yup.string(),
  notes4: yup.string(),
  notes5: yup.string(),
  notes6: yup.string(),
  notes7: yup.string(),
  notes8: yup.string(),
  notes9: yup.string(),
  notes10: yup.string(),
  notes11: yup.string(),
  notes12: yup.string(),
  notes13: yup.string(),
  notes14: yup.string(),
  notes15: yup.string(),
  notes16: yup.string(),
  notes17: yup.string(),
  notes18: yup.string(),
  notes19: yup.string(),
  notes20: yup.string(),
});

const addNotesSettingValidation = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  schema: any,
  key: number,
  noteName?: string,
  noteType?: 'text' | 'date' | 'select',
  options?: HospitalProductNoteSettingsOptions
) => {
  const propKey = key === 1 ? '' : key;
  switch (noteType) {
    case 'text':
      schema = schema.shape({
        [`notes${propKey}`]: yup.string(),
      });
      break;
    case 'date':
      schema = schema.shape({
        [`notes${propKey}`]: yup
          .string()
          .test(`notes${propKey}`, `${noteName ? noteName : `備考${key}`}の日付形式が正しくありません。`, testDate),
      });
      break;
    case 'select':
      schema = schema.shape({
        [`notes${propKey}`]: yup
          .string()
          .oneOf(
            (options?.values ?? []).map((o) => o.name).concat(''),
            `${noteName ? noteName : `備考${key}`}の形式が正しくありません。`
          ),
      });
      break;
  }

  return schema;
};

const createValidationSchema = (
  hospitalProductRequiredProperties: HospitalProductRequiredProperty[],
  noteSettings: NoteSettings | undefined
) => {
  let schema = objectSchema.clone();

  Array.from({length: 20}, (_, i) => i + 1).forEach((num) => {
    const noteKey = num === 1 ? ('notes' as const) : (`notes${num}` as const);
    const nameKey = `${noteKey}Name` as const;
    const typeKey = `${noteKey}Type` as const;
    const optionKey = `${noteKey}TypeOption` as const;

    schema = addNotesSettingValidation(
      schema,
      num,
      noteSettings?.[nameKey],
      noteSettings?.[typeKey],
      noteSettings?.[optionKey] ?? undefined
    );
  });

  // 必須項目のバリデーションを追加
  hospitalProductRequiredProperties.forEach((p) => {
    switch (p.property) {
      case HospitalProductRequiredPropertyItemValue.SerialNumber:
        schema = schema.shape({
          serialNumber: yup.string().required('シリアル番号' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.LotNumber:
        schema = schema.shape({
          lotNumber: yup.string().required('ロット番号' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.AssetRegisterNumber:
        schema = schema.shape({
          assetRegisterNumber: yup.string().required('資産番号' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.DateOfPurchase:
        schema = schema.shape({
          dateOfPurchase: yup
            .string()
            .required('購入日' + ValidationMessages.required)
            .test('dateOfPurchase', '購入日の日付形式が正しくありません。', testDate),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.OptionalBarcode:
        schema = schema.shape({
          optionalBarcode: yup.string().required('バーコード読み取り値' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.WaysOfPurchase:
        schema = schema.shape({
          waysOfPurchase: yup
            .string()
            .required('購入区分' + ValidationMessages.required)
            .oneOf(
              ['購入', 'リース', 'レンタル', '代替品', 'デモ機', '在宅用', '寄贈', '移管', ''],
              '購入区分が正しくありません。'
            ),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.LegalDurableYear:
        schema = schema.shape({
          legalDurableYear: yup.string().required('院内耐用年数' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.DateOfDisposal:
        schema = schema.shape({
          dateOfDisposal: yup
            .string()
            .required('廃棄日' + ValidationMessages.required)
            .test('dateOfDisposal', '廃棄日の日付形式が正しくありません。', testDate),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.ReasonOfDisposal:
        schema = schema.shape({
          reasonOfDisposal: yup.string().required('廃棄理由' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.Gs1Barcode:
        schema = schema.shape({
          rawBarcode: yup.string().required('GS1バーコード' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.HospitalDepartment:
        schema = schema.shape({
          hospitalDepartmentName: yup.string().required('管理部署' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.HospitalRoom:
        schema = schema.shape({
          hospitalRoomName: yup.string().required('機器管理場所' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.HospitalDealer:
        schema = schema.shape({
          hospitalDealerName: yup.string().required('担当代理店' + ValidationMessages.required),
        });
        break;
      case HospitalProductRequiredPropertyItemValue.Status:
        // 現状(2024/07/18時点)、「稼働状況」はテンプレートファイルに項目がないため何もしない
        break;
    }
  });

  return yup.array(schema).required();
};

export const ProductStatusMap: {[key: string]: ProductStatusConst | undefined} = {
  待機中: 'ready',
  貸出中: 'working',
  修理中: 'repairing',
  点検待ち: 'uninspected',
  廃棄済み: 'disabled',
  '': 'ready',
};

export const WaysOfPurchaseMap: {[key: string]: WaysOfPurchaseConst | undefined} = {
  購入: 'purchase',
  レンタル: 'rental',
  リース: 'lease',
  代替品: 'alternative',
  デモ機: 'demo',
  寄贈: 'gift',
  移管: 'transfer_control',
};

export const productClassNameMap = {
  一般医療機器: 'one',
  管理医療機器: 'two',
  '高度管理医療機器（クラス3）': 'three',
  '高度管理医療機器（クラス4）': 'four',
  '': undefined,
} as const;
export type ProductClassNameKey = keyof typeof productClassNameMap;

export const baseUnitMap = {
  親機: true,
  子機: false,
  非該当: null,
  '': undefined,
} as const;
export type BaseUnitKey = keyof typeof baseUnitMap;

export const permanentlyAssignedMap = {
  貸出不可: true,
  貸出可: false,
  '': undefined,
};
export type PermanentlyAssignedKey = keyof typeof permanentlyAssignedMap;

export const isSpecificMaintainedMap = {
  特定保守製品: true,
  該当: true,
  非該当: false,
  '': false,
};
export type IsSpecificMaintainedKey = keyof typeof isSpecificMaintainedMap;

export const isOutsideOfHospitalMap = {
  院外: true,
  院内: false,
  '': false,
};
export type IsOutsideOfHospitalKey = keyof typeof isOutsideOfHospitalMap;

export const purchasedNationalExpenseMap = {
  国費: true,
  院費: false,
  '': undefined,
};
export type PurchasedNationalExpenseKey = keyof typeof purchasedNationalExpenseMap;

export const taxIncludedMap = {
  税込: true,
  税抜き: false,
  '': undefined,
};
export type TaxIncludeKey = keyof typeof taxIncludedMap;

export const isMaintenanceContractMap = {
  保守契約あり: true,
  保守契約なし: false,
  保守契約: true,
  保守契約外: false,
  '': undefined,
};
export type IsMaintenanceContractKey = keyof typeof isMaintenanceContractMap;

export const isDisposed = (dateOfDisposal: string, reasonOfDisposal: string) => {
  return dateOfDisposal !== '' || reasonOfDisposal !== '';
};

export const hospitalProductPerPage = 20;

type HospitalProductTable = {
  registersNewProduct: boolean;
  managementId: string;
  serialNumber: string;
  rootCategory: string;
  narrowCategory: string;
  makerName: string;
  displayName: string;
  name: string;
  catalogPrice?: string;
  permanentlyAssigned: PermanentlyAssignedKey;
  status: ProductStatusLabel | '';
  isSpecificMaintained: IsSpecificMaintainedKey;
  className: ProductClassNameKey;
  jmdnCode: string; // JMDNコード
  janCode: string;
  approvalNumber: string;
  isBaseUnit: BaseUnitKey;
  isOutsideOfHospital: IsOutsideOfHospitalKey;
  hospitalRoomName: string;
  hospitalDepartmentName: string;
  hospitalDealerName: string;
  dateOfPurchase: string;
  waysOfPurchase: WaysOfPurchaseLabel | '';
  purchasedNationalExpense: PurchasedNationalExpenseKey;
  deliveryPrice: string;
  legalDurableYear: string;
  isMaintenanceContract: IsMaintenanceContractKey;
  lotNumber: string;
  assetRegisterNumber: string;
  optionalBarcode: string;
  rawBarcode: string;
  dateOfDisposal: string;
  reasonOfDisposal: string;

  taxIncluded: TaxIncludeKey;
  taxRate: string;

  rentalId: string;
  rentalDealerName: string;
  rentalFee: string;
  rentalStartDate: string;
  rentalDueDate: string;
  rentalReturnDate: string;

  leaseId: string;
  leaseDealerName: string;
  leaseFee: string;
  leaseStartDate: string;
  leaseDueDate: string;
  leaseReturnDate: string;

  purposeOfDemonstration: string; // デモの目的
  demonstrationStartDate: string; // デモ開始日時
  demonstrationEndDate: string; // デモ終了日時

  notes: string;
  notes2: string;
  notes3: string;
  notes4: string;
  notes5: string;
  notes6: string;
  notes7: string;
  notes8: string;
  notes9: string;
  notes10: string;
  notes11: string;
  notes12: string;
  notes13: string;
  notes14: string;
  notes15: string;
  notes16: string;
  notes17: string;
  notes18: string;
  notes19: string;
  notes20: string;
};

const START_DATA_ROW_NUMBER = 4;

/**
 * 選択され備考タイプに応じて、値を適切な形式に変換します。
 * ・dateタイプの場合はYYYY-MM-DD形式に変換されます。
 * ・その他のタイプの場合は元の値がそのまま返されます。
 *
 * @param value - 変換する値
 * @param noteType - 備考のタイプ ('text' | 'date' | 'select')
 * @returns {string} 変換された値。dateタイプの場合はYYYY-MM-DD形式に変換され、
 *                   その他のタイプの場合は元の値がそのまま返されます。
 */
const convertNoteData = (value: string, noteType: 'text' | 'date' | 'select' | undefined) => {
  if (noteType === 'date' && value !== '') {
    return convertDisplaySimpleDate(dayjs(value).toDate(), 'YYYY-MM-DD') ?? '';
  }
  return value;
};

const validateAndBind = async (
  data: {dataSheet: ExcelJs.Worksheet},
  hospitalProductRequiredProperties: HospitalProductRequiredProperty[],
  noteData: HospitalProductNoteSettings | undefined
): Promise<{
  isValid: boolean;
  hospitalRoomTableData: HospitalProductTable[];
  errorMessages: {line: number; propertyName: string; message: string}[];
}> => {
  const errorMessages: {line: number; propertyName: string; message: string}[] = [];
  const {dataSheet} = data;

  const hospitalProductTableData: HospitalProductTable[] = [];
  const noteSettings = noteData?.noteSettings;

  dataSheet.eachRow((row, rowNumber) => {
    if (rowNumber >= START_DATA_ROW_NUMBER) {
      hospitalProductTableData.push({
        // 半角に変換
        registersNewProduct: (getCellValue(row.getCell(1), 'xlsx') as string) === '新機種', // 新機種フラグ
        managementId: formatManagementId((getCellValue(row.getCell(2), 'xlsx') as string) ?? ''), // 管理番号
        rootCategory: (getCellValue(row.getCell(3), 'xlsx') as string) ?? '', // 大分類
        narrowCategory: (getCellValue(row.getCell(4), 'xlsx') as string) ?? '', // 小分類
        displayName: (getCellValue(row.getCell(5), 'xlsx') as string) ?? '', // 機種名
        name: (getCellValue(row.getCell(6), 'xlsx') as string) ?? '', // 型式
        makerName: (getCellValue(row.getCell(7), 'xlsx') as string) ?? '', // メーカー名
        approvalNumber: formatApprovalNumber((getCellValue(row.getCell(8), 'xlsx') as string) ?? ''), // 承認番号
        jmdnCode: (getCellValue(row.getCell(9), 'xlsx') as string) ?? '', // JMDNコード
        janCode: formatJANCode((getCellValue(row.getCell(10), 'xlsx') as string) ?? ''), // JANコード
        isSpecificMaintained: (getCellValue(row.getCell(11), 'xlsx') as string as IsSpecificMaintainedKey) ?? '', // 特定保守製品
        className: (getCellValue(row.getCell(12), 'xlsx') as string as ProductClassNameKey) ?? '', // クラス分類
        catalogPrice: (getCellValue(row.getCell(13), 'xlsx') as string) ?? undefined, // 定価
        serialNumber: formatSerialNumber((getCellValue(row.getCell(14), 'xlsx') as string) ?? ''), // シリアル番号
        lotNumber: formatLotNumber((getCellValue(row.getCell(15), 'xlsx') as string) ?? ''), // ロット番号
        permanentlyAssigned: (getCellValue(row.getCell(16), 'xlsx') as string as PermanentlyAssignedKey) ?? '', // 貸出区分
        isOutsideOfHospital: (getCellValue(row.getCell(17), 'xlsx') as string as IsOutsideOfHospitalKey) ?? '', // 所在（院内／院外）
        hospitalDepartmentName: (getCellValue(row.getCell(18), 'xlsx') as string) ?? '', // 管理部署
        hospitalRoomName: (getCellValue(row.getCell(19), 'xlsx') as string) ?? '', // 機器管理場所
        isBaseUnit: (getCellValue(row.getCell(20), 'xlsx') as string as BaseUnitKey) ?? '非該当', // 親機・子機
        dateOfPurchase: (getCellValue(row.getCell(21), 'xlsx') as string) ?? '', // 購入日
        optionalBarcode: formatBarcode((getCellValue(row.getCell(22), 'xlsx') as string) ?? ''), // バーコード読み取り値
        rawBarcode: formatGS1Barcode((getCellValue(row.getCell(23), 'xlsx') as string) ?? ''), // GS1バーコード
        hospitalDealerName: (getCellValue(row.getCell(24), 'xlsx') as string) ?? '', // 担当代理店
        waysOfPurchase: (getCellValue(row.getCell(25), 'xlsx') as string as WaysOfPurchaseLabel) ?? '', // 購入区分
        purchasedNationalExpense:
          (getCellValue(row.getCell(26), 'xlsx') as string as PurchasedNationalExpenseKey) ?? '', // 購入元
        deliveryPrice: (getCellValue(row.getCell(27), 'xlsx') as string) ?? '', // 納入価
        taxIncluded: (getCellValue(row.getCell(28), 'xlsx') as string as TaxIncludeKey) ?? '', // 税込/税抜き
        taxRate: (getCellValue(row.getCell(29), 'xlsx') as string) ?? '', // 税率
        assetRegisterNumber: formatAssetRegisterNumber((getCellValue(row.getCell(30), 'xlsx') as string) ?? ''), // 資産番号
        legalDurableYear: (getCellValue(row.getCell(31), 'xlsx') as string) ?? '', // 院内耐用年数
        dateOfDisposal: (getCellValue(row.getCell(32), 'xlsx') as string) ?? '', // 廃棄日
        reasonOfDisposal: (getCellValue(row.getCell(33), 'xlsx') as string) ?? '', // 廃棄理由
        status:
          (getCellValue(row.getCell(32), 'xlsx') as string) || (getCellValue(row.getCell(33), 'xlsx') as string)
            ? '廃棄済み'
            : '待機中', // 稼働状況
        notes: convertNoteData((getCellValue(row.getCell(34), 'xlsx') as string) ?? '', noteSettings?.notesType), // 備考1
        notes2: convertNoteData((getCellValue(row.getCell(35), 'xlsx') as string) ?? '', noteSettings?.notes2Type), // 備考2
        notes3: convertNoteData((getCellValue(row.getCell(36), 'xlsx') as string) ?? '', noteSettings?.notes3Type), // 備考3
        notes4: convertNoteData((getCellValue(row.getCell(37), 'xlsx') as string) ?? '', noteSettings?.notes4Type), // 備考4
        notes5: convertNoteData((getCellValue(row.getCell(38), 'xlsx') as string) ?? '', noteSettings?.notes5Type), // 備考5
        notes6: convertNoteData((getCellValue(row.getCell(39), 'xlsx') as string) ?? '', noteSettings?.notes6Type), // 備考6
        notes7: convertNoteData((getCellValue(row.getCell(40), 'xlsx') as string) ?? '', noteSettings?.notes7Type), // 備考7
        notes8: convertNoteData((getCellValue(row.getCell(41), 'xlsx') as string) ?? '', noteSettings?.notes8Type), // 備考8
        notes9: convertNoteData((getCellValue(row.getCell(42), 'xlsx') as string) ?? '', noteSettings?.notes9Type), // 備考9
        notes10: convertNoteData((getCellValue(row.getCell(43), 'xlsx') as string) ?? '', noteSettings?.notes10Type), // 備考10
        notes11: convertNoteData((getCellValue(row.getCell(44), 'xlsx') as string) ?? '', noteSettings?.notes11Type), // 備考11
        notes12: convertNoteData((getCellValue(row.getCell(45), 'xlsx') as string) ?? '', noteSettings?.notes12Type), // 備考12
        notes13: convertNoteData((getCellValue(row.getCell(46), 'xlsx') as string) ?? '', noteSettings?.notes13Type), // 備考13
        notes14: convertNoteData((getCellValue(row.getCell(47), 'xlsx') as string) ?? '', noteSettings?.notes14Type), // 備考14
        notes15: convertNoteData((getCellValue(row.getCell(48), 'xlsx') as string) ?? '', noteSettings?.notes15Type), // 備考15
        notes16: convertNoteData((getCellValue(row.getCell(49), 'xlsx') as string) ?? '', noteSettings?.notes16Type), // 備考16
        notes17: convertNoteData((getCellValue(row.getCell(50), 'xlsx') as string) ?? '', noteSettings?.notes17Type), // 備考17
        notes18: convertNoteData((getCellValue(row.getCell(51), 'xlsx') as string) ?? '', noteSettings?.notes18Type), // 備考18
        notes19: convertNoteData((getCellValue(row.getCell(52), 'xlsx') as string) ?? '', noteSettings?.notes19Type), // 備考19
        notes20: convertNoteData((getCellValue(row.getCell(53), 'xlsx') as string) ?? '', noteSettings?.notes20Type), // 備考20
        leaseId: (getCellValue(row.getCell(54), 'xlsx') as string) ?? '', // リース管理番号
        leaseDealerName: (getCellValue(row.getCell(55), 'xlsx') as string) ?? '', // リース企業名
        leaseFee: (getCellValue(row.getCell(56), 'xlsx') as string) ?? '', // リース金額（円）
        leaseStartDate: (getCellValue(row.getCell(57), 'xlsx') as string) ?? '', // リース開始日
        leaseDueDate: (getCellValue(row.getCell(58), 'xlsx') as string) ?? '', // リース終了予定日
        leaseReturnDate: (getCellValue(row.getCell(59), 'xlsx') as string) ?? '', // リース機器返却日
        rentalId: (getCellValue(row.getCell(60), 'xlsx') as string) ?? '', // レンタル管理番号
        rentalDealerName: (getCellValue(row.getCell(61), 'xlsx') as string) ?? '', // レンタル企業名
        rentalFee: (getCellValue(row.getCell(62), 'xlsx') as string) ?? '', // レンタル金額（円）
        rentalStartDate: (getCellValue(row.getCell(63), 'xlsx') as string) ?? '', // レンタル開始日
        rentalDueDate: (getCellValue(row.getCell(64), 'xlsx') as string) ?? '', // レンタル終了予定日
        rentalReturnDate: (getCellValue(row.getCell(65), 'xlsx') as string) ?? '', // レンタル機器返却日
        purposeOfDemonstration: (getCellValue(row.getCell(66), 'xlsx') as string) ?? '', // デモの目的
        demonstrationStartDate: (getCellValue(row.getCell(67), 'xlsx') as string) ?? '', // デモ開始日
        demonstrationEndDate: (getCellValue(row.getCell(68), 'xlsx') as string) ?? '', // デモ終了日
        isMaintenanceContract: (getCellValue(row.getCell(69), 'xlsx') as string as IsMaintenanceContractKey) ?? '', // 保守契約
      });
    }
  });

  const validationSchema = createValidationSchema(hospitalProductRequiredProperties, noteSettings);
  const isValid = await validationSchema.isValid(hospitalProductTableData);
  try {
    await validationSchema.validate(hospitalProductTableData, {abortEarly: false});
  } catch (e) {
    (e as {inner: {path: string; message: string}[]}).inner.forEach((error) => {
      // [1].managementId;
      // 正規表現を使用してインデックスとプロパティ名を抽出
      const match = error.path.match(/\[(\d+)\]\.(.+)/);

      let index = -1;
      let propertyName = '';
      if (match) {
        index = Number(match[1]); // インデックス部分
        propertyName = match[2]; // プロパティ名部分
        // 添え字が0からスタートするので+1、1～3行目はヘッダーとサンプル行なので+3
        errorMessages.push({line: index + 4, propertyName: propertyName, message: error.message});
      } else {
        // biome-ignore lint/suspicious/noConsoleLog: 正規表現チェック用
        console.log('No match found');
      }
    });
  }

  return {isValid: isValid, hospitalRoomTableData: hospitalProductTableData, errorMessages: errorMessages};
};

export const formatDate = (date: string) => {
  return date === '' ? undefined : convertDisplaySimpleDate(dayjs(date).toDate(), 'YYYY-MM-DD') ?? undefined;
};

export const useImportHospitalProductsExcel = (hospitalHashId: string) => {
  const wb = useMemo(() => new ExcelJs.Workbook(), []);
  const reader = useMemo(() => new FileReader(), []);

  const [hospitalProductData, setHospitalProductData] = useState<HospitalProductTable[]>([]);
  const [isValidFile, setIsValidFile] = useState(false);
  const [hospitalProductsPage, setHospitalProductsPage] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const {dispatchListHospitalProductRequiredProperties} = useHospitalProductRequiredPropertyStore();
  const {data: noteData} = useHospitalProductNoteSettings(hospitalHashId);
  const [layout] = useTableLayout('appendImportProductList');

  const layoutWithNotes = useMemo(() => {
    const tableLayout = layout.tableLayout.map<TableLayout>((item) => {
      if (!item.field.includes('notes')) return item;

      const notesFieldName = `${item.field}Name` as keyof NoteSettings;
      const title = (noteData?.noteSettings[notesFieldName] as string) ?? item.title;
      return {...item, title};
    });
    return {
      ...layout,
      tableLayout,
    };
  }, [layout, noteData]);

  const reset = useCallback(() => {
    setHospitalProductData([]);
    setIsValidFile(false);
    setHospitalProductsPage(0);
    setUploadedFile(null);
  }, []);

  const uploadFile = useCallback(
    async (acceptedFile: File) => {
      reader.readAsArrayBuffer(acceptedFile);
      reader.onload = async () => {
        const buffer = reader.result;

        if (isNullish(buffer)) return;
        openSnackBar('アップロードされたファイルを読み込んでいます', 'left', 'bottom', 'info');

        const workbook = await wb.xlsx.load(buffer as ArrayBuffer);
        const dataSheet = workbook.getWorksheet('医療機器管理台帳');

        if (isNullish(dataSheet)) return;

        const {data: hospitalProductRequiredProperties} =
          await dispatchListHospitalProductRequiredProperties(hospitalHashId);
        const {isValid, hospitalRoomTableData, errorMessages} = await validateAndBind(
          {dataSheet},
          hospitalProductRequiredProperties,
          noteData
        );

        if (!isValid) {
          openSnackBar('アップロードされたファイルの形式が異なります。', 'left', 'bottom', 'error');
          await dialogHandler.open(AlertDialog, {
            title: 'アップロードされたファイルにエラーが含まれます',
            content: (
              <>
                <p>
                  アップロードされたファイルでは登録処理を開始できません。
                  <br />
                  下記の異常についてファイルを修正の上、再度アップロードしてください。
                </p>
                {errorMessages.slice(0, 15).map((message, index) => (
                  <span key={`messages${index}`}>
                    {`・${message.line}行目：${message.message}`}
                    <br />
                  </span>
                ))}
                {errorMessages.length > 15 && <p>他{errorMessages.length - 15}件のエラーが検出されました。</p>}
              </>
            ),
            positiveButtonLabel: '閉じる',
            negativeButtonLabel: '',
          });

          return;
        }
        setHospitalProductData(hospitalRoomTableData);
        setIsValidFile(true);
        setUploadedFile(acceptedFile);
        openSnackBar('ファイルの読み込みが完了しました。登録内容を確認してください。');
      };
    },
    [reader, wb, noteData]
  );

  const displayHospitalProducts = useMemo(() => {
    // ファイルがインプットされてない or WholeProductが確認されてないならReturn
    if (!isValidFile || hospitalProductData.length === 0) return [];

    // for 1-based index and ignore header row
    const startRowNumber = hospitalProductPerPage * hospitalProductsPage;
    return hospitalProductData.slice(startRowNumber, startRowNumber + hospitalProductPerPage);
  }, [hospitalProductData, hospitalProductsPage, isValidFile]);

  const submitHospitalProductData = useCallback(async () => {
    if (isNullish(uploadedFile)) return;

    const res = await uploadFileApi({
      file: uploadedFile,
      fileName: uploadedFile.name,
      category: 'hospital_product_import_file',
    });

    await requestImportTask(hospitalHashId, {
      fileHashId: res.data.hashId,
      rawHospitalProducts: hospitalProductData,
      hospitalProducts: hospitalProductData.map((item) => {
        return {
          ...item,
          catalogPrice: item.catalogPrice === '' ? undefined : Number(item.catalogPrice),
          permanentlyAssigned: permanentlyAssignedMap[item.permanentlyAssigned],
          jmdnCode: Number(item.jmdnCode) ? Number(item.jmdnCode) : undefined,
          isBaseUnit: baseUnitMap[item.isBaseUnit] ?? undefined,
          isOutsideOfHospital: isOutsideOfHospitalMap[item.isOutsideOfHospital],
          purchasedNationalExpense: purchasedNationalExpenseMap[item.purchasedNationalExpense],
          deliveryPrice: item.deliveryPrice === '' ? undefined : Number(item.deliveryPrice),
          legalDurableYear: item.legalDurableYear === '' ? undefined : Number(item.legalDurableYear),
          isMaintenanceContract: isMaintenanceContractMap[item.isMaintenanceContract],
          lotNumber: item.lotNumber === '' ? undefined : item.lotNumber,
          assetRegisterNumber: item.assetRegisterNumber === '' ? undefined : item.assetRegisterNumber,
          rawBarcode: item.rawBarcode === '' ? undefined : item.rawBarcode,
          dateOfDisposal: formatDate(item.dateOfDisposal),
          reasonOfDisposal: item.reasonOfDisposal === '' ? undefined : item.reasonOfDisposal,
          taxIncluded: taxIncludedMap[item.taxIncluded],
          taxRate: item.taxRate === '' ? undefined : Number(item.taxRate),
          rentalId: item.rentalId === '' ? undefined : item.rentalId,
          rentalDealerName: item.rentalDealerName === '' ? undefined : item.rentalDealerName,
          rentalFee: item.rentalFee === '' ? undefined : Number(item.rentalFee),
          rentalStartDate: formatDate(item.rentalStartDate),
          rentalDueDate: formatDate(item.rentalDueDate),
          rentalReturnDate: formatDate(item.rentalReturnDate),
          leaseId: item.leaseId === '' ? undefined : item.leaseId,
          leaseDealerName: item.leaseDealerName === '' ? undefined : item.leaseDealerName,
          leaseFee: item.leaseFee === '' ? undefined : Number(item.leaseFee),
          leaseStartDate: formatDate(item.leaseStartDate),
          leaseDueDate: formatDate(item.leaseDueDate),
          leaseReturnDate: formatDate(item.leaseReturnDate),
          demonstrationEndDate: formatDate(item.demonstrationStartDate),
          demonstrationStartDate: formatDate(item.demonstrationStartDate),
          purposeOfDemonstration: item.purposeOfDemonstration === '' ? undefined : item.purposeOfDemonstration,
          isSpecificMaintained: item.isSpecificMaintained === '該当',
          className: productClassNameMap[item.className],
          waysOfPurchase: WaysOfPurchaseMap[item.waysOfPurchase],
          status: isDisposed(item.dateOfDisposal, item.reasonOfDisposal) ? 'disabled' : ProductStatusMap[item.status],
          dateOfPurchase: formatDate(item.dateOfPurchase),
        };
      }),
    });
    reset();
  }, [hospitalProductData, hospitalHashId, reset, uploadedFile]);

  return {
    layout: layoutWithNotes,
    uploadFile,
    isValidFile,
    hospitalProductData,
    displayHospitalProducts,
    hospitalProductsPage,
    setHospitalProductsPage,
    submitHospitalProductData,
  } as const;
};
