import React, {useState} from 'react';
import {styled, Box} from '@mui/material';
import {Tabs} from './Tabs';
import {TraineeFileLists} from './TraineeFileLists';
import {TraineeList} from './TraineeList';
import {initTabValue} from './constants';
import {TabValue} from './types';

type Props = {
  trainingScheduleHashId: string;
};

const Container = styled(Box)({
  height: '100%',
  width: '100%',
});
const Contents = styled(Box)({
  position: 'relative', // NOTE: タブの位置固定に利用する。
  height: '100%',
  width: '100%',
});
const TabSelect = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
});

const TraineeContents = ({trainingScheduleHashId}: Props) => {
  const [tabValue, setTabValue] = useState<TabValue>(initTabValue);

  const handleTabChange = (newValue: TabValue) => {
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 'file':
        return <TraineeFileLists trainingScheduleHashId={trainingScheduleHashId} />;
      case 'list':
        return <TraineeList trainingScheduleHashId={trainingScheduleHashId} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Contents>
        <TabSelect>
          <Tabs value={tabValue} onChange={handleTabChange} />
        </TabSelect>
        {renderTabContent()}
      </Contents>
    </Container>
  );
};

export default TraineeContents;
