import React, {useCallback, useMemo, useState} from 'react';
import {Button, ButtonGroup, createStyles, Grid, makeStyles, styled, Theme, Typography} from '@material-ui/core';
import {useAtom} from 'jotai';
import {ArrowDropDown} from '@material-ui/icons';
import {ViewList, ViewColumn} from '@material-ui/icons';
import {repairTab} from './jotai';
import {useFaultRepairsQuery, useUpdateKanbanStatusSubject} from './hooks';
import {
  RegistrationRepairDialog,
  RegistrationRepairDialogProps,
} from '@organisms/FaultRepairs/dialogs/RegistrationRepairDialog';
import {createFaultRepair} from '@modules/repairs/api/faultRepairApi';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {useNavigate} from 'react-router-dom';
import {useFaultReceptionsQuery} from '@modules/fault_receptions/api/faultReceptionApi';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {FaultRepairConfirmDialog} from '@components/organisms/FaultRepairConfirmDialog/FaultRepairConfirmDialog';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type RepairPageHeaderProps = {
  onAddFaultRepair?: () => void;
};

export const RepairPageHeader: React.FC<RepairPageHeaderProps> = ({onAddFaultRepair}) => {
  const classes = useStyles();
  const [openRepairDialog, setOpenRepairDialog] = useState(false);
  const [tab, setTab] = useAtom(repairTab);
  const {myInfo} = useMyInfo();
  const {hospitalUsers} = useHospitalUsers();
  const faultRepairsQuery = useFaultRepairsQuery();
  const {notify} = useUpdateKanbanStatusSubject();
  const {canEdit: canEditRepair} = useUserResourcesPermissions('REPAIR');
  const navigate = useNavigate();

  const faultReceptionCountQuery = useFaultReceptionsQuery(myInfo.hospitalHashId, {
    statuses: 'before_confirmation',
    page: 0,
    perPage: 100,
  });

  const statusQuery = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const handleClickTabBtn = useCallback((switchingTab: 'kanban' | 'list') => setTab(switchingTab), [setTab]);
  const registrationActions = useMemo(
    () => ({
      resolve: async (result?: Partial<RegistrationRepairDialogProps>) => {
        if (!result?.hospitalProductHashId) return;

        result.statusHashId = result.statusHashId
          ? result.statusHashId
          : statusQuery.data.find((item) => item.statusType === 'start')?.hashId ?? statusQuery.data[0].hashId;
        const {repairRegistrationNumber} = await createFaultRepair(
          myInfo.hospitalHashId,
          result.hospitalProductHashId,
          result
        );

        faultRepairsQuery.refetch();
        notify([result.statusHashId]);

        if (onAddFaultRepair) onAddFaultRepair();

        setOpenRepairDialog(false);
        await dialogHandler.open(FaultRepairConfirmDialog, {repairRegistrationNumber});
      },
      reject: async () => {
        setOpenRepairDialog(false);
      },
    }),
    [faultRepairsQuery, myInfo.hospitalHashId, notify, onAddFaultRepair, statusQuery.data]
  );
  const handleClickFaultReceptionListLink = useCallback(() => {
    navigate(`/repairs/fault_receptions`);
  }, [navigate]);

  return (
    <>
      <Grid container>
        <Grid item>
          <div>
            <PageRoutingMenu>
              <Typography id="tutorial-repair-list" variant={'h5'} className={classes.pageTitle}>
                修理
              </Typography>
              <ArrowDropDown />
            </PageRoutingMenu>
          </div>
        </Grid>
        <Grid item>
          <ButtonGroup size="small" color="primary" className={classes.buttonGroup}>
            <Button
              className={tab === 'kanban' ? classes.activeButton : undefined}
              onClick={() => handleClickTabBtn('kanban')}>
              <ViewColumn />
            </Button>
            <Button
              className={tab === 'list' ? classes.activeButton : undefined}
              onClick={() => handleClickTabBtn('list')}>
              <ViewList />
            </Button>
          </ButtonGroup>
        </Grid>
        <div className={classes.flex} />
        <Grid item>
          <FaultReceptionListLinkButton variant="outlined" color="primary" onClick={handleClickFaultReceptionListLink}>
            不具合受付を確認
            {faultReceptionCountQuery.data.length !== 0 && (
              <FaultReceptionsCount>{faultReceptionCountQuery.data.length}</FaultReceptionsCount>
            )}
          </FaultReceptionListLinkButton>
          {canEditRepair && (
            <Button variant={'contained'} color={'primary'} onClick={() => setOpenRepairDialog(true)}>
              修理を新規登録
            </Button>
          )}
        </Grid>
      </Grid>
      <RegistrationRepairDialog
        open={openRepairDialog}
        actions={registrationActions}
        hospitalHashId={myInfo.hospitalHashId}
        hospitalUsers={hospitalUsers}
        repairStatuses={statusQuery.data ?? []}
      />
    </>
  );
};

const FaultReceptionListLinkButton = styled(Button)(() => ({
  background: '#FFFFFF',
  marginRight: '8px',
  fontSize: '14px',
}));

export const FaultReceptionsCount = styled('span')(() => ({
  backgroundColor: '#0052CC',
  borderRadius: '14px',
  fontSize: '14px',
  color: 'white',
  padding: '0px 8px',
  marginLeft: '8px',
  minWidth: '14px',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.primary.dark,
    },
    buttonGroup: {
      marginLeft: '16px',
      padding: ' 6px 8px',
    },
    activeButton: {
      borderColor: '#0A52CC',
      backgroundColor: '#CDD6E1',
    },
    totalCount: {
      paddingLeft: 8,
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.grey[600],
    },
    flex: {
      flexGrow: 1,
    },
  })
);
