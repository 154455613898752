import {
  TrainingScheduleTraineeState,
  TrainingScheduleTraineeAction,
  TrainingScheduleTraineeActionType,
} from '@modules/training_schedule_trainees/types';

export const initialState: TrainingScheduleTraineeState = {
  trainees: [],
};

export const reducer = (
  state: TrainingScheduleTraineeState,
  action: TrainingScheduleTraineeAction
): TrainingScheduleTraineeState => {
  switch (action.type) {
    case TrainingScheduleTraineeActionType.TRAINING_SCHEDULE_TRAINEE_SET_LIST: {
      return {
        ...state,
        trainees: action.payload.trainees,
      };
    }
    default:
      return state;
  }
};
