import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {Box, Divider, Grid, Stack} from '@mui/material';
import React from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@components/molecules/Loading';
import {useParams} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import {CreatePurchaseRequestFormData} from '../types';
import {isEmpty} from 'lodash';
import {StrUtil} from '@front-libs/helpers';

export const ConfirmContent = () => {
  const {hospitalProductHashId} = useParams();
  const {myInfo} = useMyInfo();
  const {
    data: hospitalProduct,
    isLoading,
    isFetching,
  } = useFetchHospitalProductQuery(myInfo.hospitalHashId, hospitalProductHashId ?? '');

  const {getValues} = useFormContext<CreatePurchaseRequestFormData>();
  const values = getValues();

  if (isLoading || isFetching) {
    return <InnerLoading />;
  }

  return (
    <>
      <Grid container direction={'row'} gap={'48px'} sx={{mt: '24px'}}>
        <Grid item container direction={'column'} xs={4} sx={{minWidth: '240px'}}>
          <Grid item sx={styles.sectionTitle}>
            依頼者情報
          </Grid>
          <Divider sx={styles.divider} />
          <Stack sx={{gap: '16px'}}>
            <Grid item>
              <Box sx={styles.fieldTitle}>医療機関名</Box>
              <Box sx={styles.fieldValue}>{values.hospitalName}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>氏名</Box>
              <Box sx={styles.fieldValue}>{values.requesterName}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>所属部署名</Box>
              <Box sx={styles.fieldValue}>{values.departmentName}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>ご希望の連絡方法</Box>
              <Box sx={styles.fieldValue}>{values.contactMethod === 'email' ? 'メール' : '電話'}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>メールアドレス</Box>
              <Box sx={styles.fieldValue}>{isEmpty(values.email) ? '未記入' : values.email}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>電話番号</Box>
              <Box sx={styles.fieldValue}>{isEmpty(values.phoneNumber) ? '未記入' : values.phoneNumber}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>連絡に関するご要望</Box>
              <Box sx={styles.fieldValue}>
                {isEmpty(values.contactRemarks) ? '未記入' : StrUtil.nl2br(values.contactRemarks ?? '')}
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item container direction={'column'} xs={4} sx={{minWidth: '240px'}}>
          <Grid item sx={styles.sectionTitle}>
            査定依頼対象の機器情報
          </Grid>
          <Divider sx={styles.divider} />
          <Stack sx={{gap: '16px'}}>
            <Grid item>
              <Box sx={styles.fieldTitle}>機種名</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.displayName ?? ''}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>型式</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.name ?? ''}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>管理番号</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.managementId ?? ''}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>資産管理番号</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.assetRegisterNumber ?? ''}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>シリアル番号</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.serialNumber ?? ''}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>大分類</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.categories.at(0)?.name ?? ''}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>小分類</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.categories.at(1)?.name ?? ''}</Box>
            </Grid>
            <Grid item>
              <Box sx={styles.fieldTitle}>メーカー名</Box>
              <Box sx={styles.fieldValue}>{hospitalProduct?.maker?.name ?? ''}</Box>
            </Grid>
            <Grid item sx={styles.fieldValue}>
              他7項目
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const styles = {
  sectionTitle: {
    color: '#172B4D',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  divider: {mt: '8px', mb: '8px'},
  fieldTitle: {
    color: 'rgba(23, 43, 77, 0.40)',
    fontSize: '12px',
    lineHeight: '20px',
  },
  fieldValue: {
    color: '#172B4D',
    fontSize: '14px',
    lineHeight: '20px',
  },
};
