import React, {useCallback, useEffect} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Box, Typography} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import CloseIcon from '@material-ui/icons/Close';
import barcode from '@assets/barcode.png';
import {useBarcodeReader} from '@front-libs/core';
import {openSnackBar} from '@molecules/SnackBar';

export type NewRegistrationDialogProps = DialogProps;

export type NewRegistrationDialogResult = {
  gs1Barcode: string | null;
};

export const NewRegistrationGS1Dialog: React.FC<NewRegistrationDialogProps> = (props) => {
  const {open} = props;

  const handleClose = useCallback(
    (_: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  const handleInputBarcode = async (inputBarcode: string) => {
    // バーコードがこういう形式で飛んでくる (01)4580211772348(10)2104K504GR
    if (!inputBarcode.includes('(01)')) {
      openSnackBar('バーコードの形式がGS1-128ではありません', 'left', 'bottom', 'error');
      return;
    }
    props.actions.resolve({gs1Barcode: inputBarcode});
  };

  const {BarcodeInput} = useBarcodeReader({
    forcedFocus: open,
    onInputData: handleInputBarcode,
  });

  return (
    <Dialog open={!!open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
      <DialogTitle sx={{pt: 3, px: 4, pb: 0}}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            sx={{color: '#172B4D', fontSize: '20px', fontWeight: 'bold'}}
            variant="h2">{`GS1-128のバーコードを読み込み`}</Typography>
          <IconButton sx={{p: 0}} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{py: 2, px: 3, mt: 3}}>
        <Box
          sx={{
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800',
            color: '#C77700',
            p: 2,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '5px',
          }}>
          <InfoOutlinedIcon sx={{color: '#C77700'}} fontSize="medium" />
          <Box sx={{ml: 2}}>
            <Typography component={'h1'} sx={{fontSize: '14px', fontWeight: 700}}>
              機器台帳に登録された正しい「JANコード（機種情報）」あるいは「GS1バーコード（機器情報）」と情報が一致した場合は、
              登録済みの院内機種がヒットします。それ以外の機種は、院内で未登録の機種として扱われます。
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 5,
          py: 0,
          px: 4,
          '& > div:not(:first-child)': {
            marginTop: '24px',
          },
        }}>
        <img src={barcode} alt="バーコードの使い方" />
      </DialogContent>
      {BarcodeInput}
    </Dialog>
  );
};
