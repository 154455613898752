import {Box, SxProps} from '@mui/material';
import {FilterButtons} from './FilterButtons';
import React from 'react';
import {CategoryIndex} from '@modules/categories/types';
import {ProductStatusConst} from '@modules/hospital_products/types';
import {DetailFilter} from '../types';

const rootStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const buttonContainerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
};

type ListTableHeaderProps = {
  totalCount: number;
  rootCategories: CategoryIndex[];
  narrowCategories: CategoryIndex[];
  onStatusChange: (status: ProductStatusConst[]) => void;
  onNameChange: (name: string) => void;
  onRootCategoriesChange: (rootCategories: string[]) => void;
  onNarrowCategoriesChange: (narrowCategories: string[]) => void;
  onDetailFilterChange: (detailFilter: Partial<DetailFilter>) => void;
};

/**
 * 査定受付テーブルのヘッダー
 * 日付タブとフィルターボタン、アクションメニューボタンを表示
 *
 * @param {Function} props.handleFilterChange - フィルター変更時に呼び出される関数。
 * @param {Function} props.handleActionMenuClick - アクションメニュークリック時に呼び出される関数。
 * @returns {JSX.Element} リストテーブルのヘッダーコンポーネント。
 */
export const ListTableHeader = ({
  rootCategories,
  narrowCategories,

  onNameChange,
  onStatusChange,
  onRootCategoriesChange,
  onNarrowCategoriesChange,
}: ListTableHeaderProps) => {
  return (
    <>
      <Box sx={rootStyle}>
        <Box sx={buttonContainerStyle}>
          <FilterButtons
            rootCategories={rootCategories}
            narrowCategories={narrowCategories}
            onNameChange={onNameChange}
            onStatusChange={onStatusChange}
            onRootCategoriesChange={onRootCategoriesChange}
            onNarrowCategoriesChange={onNarrowCategoriesChange}
            onClickDetailFilterButton={() => {}}
          />
        </Box>
      </Box>
    </>
  );
};
