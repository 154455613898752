import {useReducer} from 'react';
import {
  TrainingScheduleTraineeState,
  TrainingScheduleTraineeActionType,
  ListTrainingScheduleTraineesRequest,
  ListTrainingScheduleTraineesResponse,
} from '@modules/training_schedule_trainees/types';
import {listTrainingScheduleTrainees} from '@modules/training_schedule_trainees/api';
import {reducer, initialState} from '@modules/training_schedule_trainees/reducers';

type TrainingScheduleTraineeStoreType = {
  state: TrainingScheduleTraineeState;
  dispatchListTrainingScheduleTrainees: (
    hospitalHashId: string,
    trainingScheduleHashId: string,
    params: ListTrainingScheduleTraineesRequest
  ) => Promise<ListTrainingScheduleTraineesResponse>;
};

export const useTrainingScheduleTraineeStore = (): TrainingScheduleTraineeStoreType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchListTrainingScheduleTrainees = async (
    hospitalHashId: string,
    trainingScheduleHashId: string,
    params: ListTrainingScheduleTraineesRequest
  ) => {
    const res = await listTrainingScheduleTrainees(hospitalHashId, trainingScheduleHashId, params);
    dispatch({
      type: TrainingScheduleTraineeActionType.TRAINING_SCHEDULE_TRAINEE_SET_LIST,
      payload: {trainees: res.data},
    });
    return res;
  };

  return {
    state,
    dispatchListTrainingScheduleTrainees,
  };
};
