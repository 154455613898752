import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {getMeKey, getUserRolesKey} from '@constants/api';
import {useAtomValue} from 'jotai';
import {accessToken} from '../../auth/jotai';
import {fetchMyInfoForCache, getOrganizationRoleUsers} from '../api';
import {useLogout} from '@modules/auth/hooks/useLogout';
import {useFetchHospitalQuery} from '@modules/hospital/api';
import {isNullish} from '@front-libs/helpers';

export const applicationHashIdMap = {
  account: 'qoazbEp5ep2ZeJx',
  asset: 'orjE0VpWV1Lk9aK',
  link: 'bvXOw61QzpQ83xG',
};

export const useMyInfo = () => {
  const token = useAtomValue(accessToken);
  const logout = useLogout();
  const query = useQuery([getMeKey, token], () => fetchMyInfoForCache(), {
    suspense: true,
    refetchOnMount: false,
    onError: (_e) => {
      alert('このユーザーではAssetを利用できません。Asset利用設定済のユーザーでログインしてください。');
      logout();
    },
    // dataプロパティの変更があったときのみuseMemoの変更が走る
    notifyOnChangeProps: ['data'],
  });

  const myHospitalInfo = useFetchHospitalQuery(query.data?.data.hospitalHashId);

  const roleUsers = useQuery(
    [getUserRolesKey, token],
    () => getOrganizationRoleUsers(myHospitalInfo.data?.organizationHashId ?? ''),
    {
      enabled: !isNullish(myHospitalInfo.data?.organizationHashId),
    }
  );

  return useMemo(
    () => ({
      ...query,
      // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
      myInfo: query.data!.data!,
      myHospitalInfo: myHospitalInfo.data,
      // カスタム内部権限
      myResource: (query.data?.data.roleResources ?? []).find(
        (v) => v.role.applicationHashId === applicationHashIdMap.asset
      )?.resources,
      myRole: roleUsers.data?.find(
        (item) =>
          item.role.applicationHashId === applicationHashIdMap.asset &&
          item.users.some((i) => i.user.hashId === query.data?.data.hashId)
      ),
      // NOTE: 厳密にroleUsersがundefinedの場合とデータがある場合で処理を分けるようにしている。アクセス権限の判定に使うため。
      myRoles:
        roleUsers.data !== undefined
          ? roleUsers.data?.filter(
              (item) =>
                item.role.applicationHashId === applicationHashIdMap.asset &&
                item.users.some((i) => i.user.hashId === query.data?.data.hashId)
            ) ?? []
          : undefined,
    }),
    [query, myHospitalInfo.data, roleUsers.data]
  );
};
