import {atom} from 'jotai';
import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {
  ConditionValue,
  DateRangeConditionValue,
  ResultType,
  SelectorConditionValue,
  TextConditionValue,
  NumberConditionValue,
} from '@molecules/Drawers/FilterDrawer/types';
import {FetchHospitalProductsParams} from '@modules/hospital_products/api';
import {SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {Order} from '@molecules/Table/props';
import {productStatusOptionsWODisabled} from '@constants/constants';
import {convertDateToRFC3339, isNullish} from '@front-libs/helpers';

const LOCAL_STORAGE_KEY_PRODUCTS_LIST = 'hitotsu/products_page_size_status';
export const includeDisposedAtom = atom<boolean>(false);
export const searchNameAtom = atom<string | null>(null);
export const orderKeyAtom = atom<Order | null>(null);
export const searchStatusesAtom = atom<SelectOptionProps[]>([]);
export const searchRootCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchSubCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchFilterResultsAtom = atom<ResultType[]>([]);
export const pageAtom = atomWithReset<number>(1);
export const pageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_PRODUCTS_LIST, 20);

type DetailFilter = {
  permanentlyAssigneds: boolean;
  waysOfPurchases: string;
  hospitalWardHashId: string;
  hospitalRoomHashId: string;
  hospitalDealerHashIds: string[];
  hospitalDepartmentHashIds: string[];
  notes: string;
  notes2: string;
  notes3: string;
  notes4: string;
  notes4From: string;
  notes4To: string;
  notes5: string;
  notes5From: string;
  notes5To: string;
  notes6: string;
  notes6From: string;
  notes6To: string;
  notes7: string;
  notes7From: string;
  notes7To: string;
  notes8: string;
  notes8From: string;
  notes8To: string;
  notes9: string;
  notes9From: string;
  notes9To: string;
  notes10: string;
  notes10From: string;
  notes10To: string;
  notes11: string;
  notes11From: string;
  notes11To: string;
  notes12: string;
  notes12From: string;
  notes12To: string;
  notes13: string;
  notes13From: string;
  notes13To: string;
  notes14: string;
  notes14From: string;
  notes14To: string;
  notes15: string;
  notes15From: string;
  notes15To: string;
  notes16: string;
  notes16From: string;
  notes16To: string;
  notes17: string;
  notes17From: string;
  notes17To: string;
  notes18: string;
  notes18From: string;
  notes18To: string;
  notes19: string;
  notes19From: string;
  notes19To: string;
  notes20: string;
  notes20From: string;
  notes20To: string;
  managementId: string;
  serialNumber: string;
  lotNumber: string;
  name: string;
  displayName: string;
  makerName: string;
  reasonOfDisposal: string;
  assetRegisterNumber: string;
  rentalId: string;
  leaseId: string;
  optionalBarcode: string;
  rawBarcode: string;
  rentalDealerName: string;
  leaseDealerName: string;
  isMaintenanceContract: boolean;
  isBaseUnit: boolean;
  isOutsideOfHospital: boolean;
  purchasedNationalExpense: boolean;
  createdByHashId: string;
  rentHospitalWardHashId: string;
  rentHospitalRoomHashId: string;
  dateOfPurchaseFrom: string;
  dateOfPurchaseTo: string;
  catalogPriceFrom: number;
  catalogPriceTo: number;
  legalDurableYearFrom: number;
  legalDurableYearTo: number;
  periodicInspectionStartDateFrom: string;
  periodicInspectionStartDateTo: string;
  dateOfDisposalFrom: string;
  dateOfDisposalTo: string;
  createdAtFrom: string;
  createdAtTo: string;
  rentalStartDateFrom: string;
  rentalStartDateTo: string;
  rentalFeeFrom: number;
  rentalFeeTo: number;
  leaseStartDateFrom: string;
  leaseStartDateTo: string;
  leaseFeeFrom: number;
  leaseFeeTo: number;
};

export const hospitalProductsVariables = atom<FetchHospitalProductsParams>((get) => {
  const includeDisposed = get(includeDisposedAtom);
  const rootCategories = get(searchRootCategoriesAtom);
  const subCategories = get(searchSubCategoriesAtom);
  const detailFilter = convertFilterResults(get(searchFilterResultsAtom));
  const order = get(orderKeyAtom);
  const statuses = get(searchStatusesAtom);

  const hospitalDealerHashIds =
    (detailFilter.hospitalDealerHashIds ?? []).length > 0
      ? (detailFilter.hospitalDealerHashIds ?? []).join(',')
      : undefined;
  const hospitalDepartmentHashIds =
    (detailFilter.hospitalDepartmentHashIds ?? []).length > 0
      ? (detailFilter.hospitalDepartmentHashIds ?? []).join(',')
      : undefined;

  const isIncludedForcedRental = statuses.some((item) => item.value === 'forceRental');
  const isIncludedRental = statuses.some((item) => item.value === 'working');

  // 強制貸出と貸出は両方とも稼働状況が'working'になるので、下記のように判定する
  // ・強制貸出が検索条件であり、貸出が検索条件にない場合、isForcedRentalはtrue
  // ・強制貸出が検索条件になく、貸出が検索条件にある場合、isForcedRentalはfalse
  // ・強制貸出も貸出も検索条件にある場合、isForcedRentalはundefined
  const isForcedRentalParam =
    !isIncludedForcedRental && isIncludedRental
      ? false
      : isIncludedForcedRental && !isIncludedRental
        ? true
        : undefined;

  return {
    ...detailFilter,
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    name: get(searchNameAtom) ?? undefined,
    makerName: detailFilter.makerName,
    isForcedRental: isForcedRentalParam,
    statuses:
      statuses.length > 0
        ? statuses.map((item) => (item.value === 'forceRental' ? 'working' : item.value)).join(',')
        : !includeDisposed
          ? productStatusOptionsWODisabled
              .map((item) => (item.value === 'forceRental' ? 'working' : item.value))
              .join(',')
          : undefined,
    categoryHashIds:
      subCategories.length > 0
        ? subCategories.map((item) => item.value).join(',')
        : rootCategories.length > 0
          ? rootCategories.map((item) => item.value).join(',')
          : undefined,
    order: order ? `${order.direction === 'desc' ? '-' : ''}${order.fieldId}` : 'managementId',
    hospitalDealerHashIds,
    hospitalDepartmentHashIds,
  };
});

const isSelectorConditionValue = (v: ConditionValue): v is SelectorConditionValue => {
  if (!Array.isArray(v)) return false;

  const sv: SelectorConditionValue[number] = {label: '', value: ''};
  return v.every((item) => {
    const keys = Object.keys(item);
    if (keys.length !== Object.keys(sv).length) return false;
    if (!keys.every((key) => key in sv)) return false;

    return true;
  });
};

const convertFilterResultValueForNotes = (v: ConditionValue): string => {
  // 単一条件選択の場合
  if (isSelectorConditionValue(v) && v.length === 1) {
    return v[0].value as string;
  }
  return v as TextConditionValue;
};

const convertFilterResults = (filterResults: ResultType[]): Partial<DetailFilter> => {
  const detailFilter: Partial<DetailFilter> = {};
  filterResults.forEach((item) => {
    switch (item.key) {
      case 'permanentlyAssigneds':
        detailFilter.permanentlyAssigneds = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'waysOfPurchases':
        detailFilter.waysOfPurchases = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'hospitalWardHashId':
        detailFilter.hospitalWardHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'hospitalRoomHashId':
        detailFilter.hospitalRoomHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'notes':
        detailFilter.notes = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes2':
        detailFilter.notes2 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes3':
        detailFilter.notes3 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes4':
        detailFilter.notes4 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes5':
        detailFilter.notes5 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes6':
        detailFilter.notes6 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes7':
        detailFilter.notes7 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes8':
        detailFilter.notes8 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes9':
        detailFilter.notes9 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes10':
        detailFilter.notes10 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes11':
        detailFilter.notes11 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes12':
        detailFilter.notes12 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes13':
        detailFilter.notes13 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes14':
        detailFilter.notes14 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes15':
        detailFilter.notes15 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes16':
        detailFilter.notes16 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes17':
        detailFilter.notes17 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes18':
        detailFilter.notes18 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes19':
        detailFilter.notes19 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'notes20':
        detailFilter.notes20 = convertFilterResultValueForNotes(item.resultValue);
        break;
      case 'managementId':
        detailFilter.managementId = item.resultValue as TextConditionValue;
        break;
      case 'serialNumber':
        detailFilter.serialNumber = item.resultValue as TextConditionValue;
        break;
      case 'lotNumber':
        detailFilter.lotNumber = item.resultValue as TextConditionValue;
        break;
      case 'name':
        detailFilter.name = item.resultValue as TextConditionValue;
        break;
      case 'displayName':
        detailFilter.displayName = item.resultValue as TextConditionValue;
        break;
      case 'makerName':
        detailFilter.makerName = item.resultValue as TextConditionValue;
        break;
      case 'reasonOfDisposal':
        detailFilter.reasonOfDisposal = item.resultValue as TextConditionValue;
        break;
      case 'assetRegisterNumber':
        detailFilter.assetRegisterNumber = item.resultValue as TextConditionValue;
        break;
      case 'rentalId':
        detailFilter.rentalId = item.resultValue as TextConditionValue;
        break;
      case 'leaseId':
        detailFilter.leaseId = item.resultValue as TextConditionValue;
        break;
      case 'optionalBarcode':
        detailFilter.optionalBarcode = item.resultValue as TextConditionValue;
        break;
      case 'rawBarcode':
        detailFilter.rawBarcode = item.resultValue as TextConditionValue;
        break;
      case 'rentalDealerName':
        detailFilter.rentalDealerName = item.resultValue as TextConditionValue;
        break;
      case 'leaseDealerName':
        detailFilter.leaseDealerName = item.resultValue as TextConditionValue;
        break;
      case 'isMaintenanceContract':
        detailFilter.isMaintenanceContract = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'isBaseUnit':
        detailFilter.isBaseUnit = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'isOutsideOfHospital':
        detailFilter.isOutsideOfHospital = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'purchasedNationalExpense':
        detailFilter.purchasedNationalExpense = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'createdByHashId':
        detailFilter.createdByHashId = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'rentHospitalWardHashId':
        detailFilter.rentHospitalWardHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'rentHospitalRoomHashId':
        detailFilter.rentHospitalRoomHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'dateOfPurchase': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.dateOfPurchaseFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.dateOfPurchaseTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 点検開始日
      case 'periodicInspectionStartDate': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.periodicInspectionStartDateFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.dateOfPurchaseTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 廃棄日
      case 'dateOfDisposal': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.dateOfDisposalFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.dateOfDisposalTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 登録日
      case 'createdAt': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.createdAtFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.createdAtTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // レンタル開始日
      case 'rentalStartDate': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.rentalStartDateFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.rentalStartDateTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // リース開始日
      case 'leaseStartDate': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.leaseStartDateFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.leaseStartDateTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 定価
      case 'catalogPrice': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.catalogPriceFrom = typedValue.from;
        detailFilter.catalogPriceTo = typedValue.to;
        break;
      }
      // 耐用年数
      case 'legalDurableYear': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.legalDurableYearFrom = typedValue.from;
        detailFilter.legalDurableYearTo = typedValue.to;
        break;
      }
      // レンタル金額
      case 'rentalFee': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.rentalFeeFrom = typedValue.from;
        detailFilter.rentalFeeTo = typedValue.to;
        break;
      }
      // リース金額
      case 'leaseFee': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.leaseFeeFrom = typedValue.from;
        detailFilter.leaseFeeTo = typedValue.to;
        break;
      }

      // 備考4のTypeがDateの場合
      case 'notes4Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes4From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes4To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考5のTypeがDateの場合
      case 'notes5Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes5From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes5To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考6のTypeがDateの場合
      case 'notes6Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes6From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes6To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考7のTypeがDateの場合
      case 'notes7Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes7From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes7To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考8のTypeがDateの場合
      case 'notes8Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes8From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes8To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考9のTypeがDateの場合
      case 'notes9Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes9From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes9To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考10のTypeがDateの場合
      case 'notes10Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes10From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes10To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考11のTypeがDateの場合
      case 'notes11Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes11From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes11To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考12のTypeがDateの場合
      case 'notes12Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes12From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes12To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考13のTypeがDateの場合
      case 'notes13Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes13From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes13To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考14のTypeがDateの場合
      case 'notes14Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes14From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes14To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考15のTypeがDateの場合
      case 'notes15Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes15From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes15To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考16のTypeがDateの場合
      case 'notes16Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes16From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes16To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考17のTypeがDateの場合
      case 'notes17Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes17From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes17To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考18のTypeがDateの場合
      case 'notes18Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes18From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes18To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考19のTypeがDateの場合
      case 'notes19Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes19From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes19To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 備考20のTypeがDateの場合
      case 'notes20Date': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.notes20From = convertDateToQueryString(typedValue?.from);
        detailFilter.notes20To = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 担当代理店
      case 'hospitalDealerHashIds': {
        if (isSelectorConditionValue(item.resultValue)) {
          detailFilter.hospitalDealerHashIds = item.resultValue.map((item) => item.value);
        }
        break;
      }
      // 管理部署
      case 'hospitalDepartmentHashIds': {
        if (isSelectorConditionValue(item.resultValue)) {
          detailFilter.hospitalDepartmentHashIds = item.resultValue.map((item) => item.value);
        }
        break;
      }
      default:
        break;
    }
  });
  return detailFilter;
};

const convertDateToQueryString = (date: Date | undefined): string | undefined => {
  return !isNullish(date) ? convertDateToRFC3339(date) : undefined;
};
