import React from 'react';
import {Grid} from '@mui/material';
import {FaultReceptionForm} from './FaultReceptionForm';
import {Formik} from 'formik';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateHospitalSettings, useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {InnerLoading} from '@components/molecules/Loading';
import {
  faultReceptionSettings,
  HospitalSettingKey,
  UpdateHospitalSettingsParams,
} from '@modules/hospital_settings/types';
import {openSnackBar} from '@front-libs/ui';

export type FaultReceptionData = {
  label: string;
  name: FaultReceptionName;
  visible: boolean;
};

export type FaultReceptionName = 'showRentableDevices' | 'showReturnWaitingReception' | 'showFaultReceptions';

export const receptionMenuOptions: {label: string; name: string}[] = [
  {
    label: '貸出可能機器の閲覧',
    name: 'showRentableDevices',
  },
  {
    label: '返却待ち受付',
    name: 'showReturnWaitingReception',
  },
  {
    label: '不具合受付',
    name: 'showFaultReceptions',
  },
];

export const SettingsFaultReception = () => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  if (isLoading) {
    return <InnerLoading />;
  }

  const initialData: FaultReceptionData[] = receptionMenuOptions.map((option) => {
    const setting = Object.values(faultReceptionSettings).find((s) => s.field === option.name);

    return {
      ...option,
      name: option.name as FaultReceptionName,
      visible: data?.data?.find((x) => x.key === setting?.key)?.value === 'true' || false,
    };
  });

  const handleSubmit = async (res: FaultReceptionData[]) => {
    const updatedData: UpdateHospitalSettingsParams[] = res.map((item) => {
      const setting = Object.values(faultReceptionSettings).find((s) => s.field === item.name);

      return {
        hospitalHashId: myInfo.hospitalHashId,
        key: setting?.key as HospitalSettingKey,
        value: `${item.visible}`,
      };
    });

    try {
      await Promise.all(
        updatedData.map((d) => {
          return updateHospitalSettings(myInfo.hospitalHashId, d);
        })
      );
      await refetch();
      openSnackBar('設定を変更しました。');
    } catch (error) {
      openSnackBar('設定の更新に失敗しました', 'error');
      throw error;
    }
  };

  return (
    <Grid container>
      <Formik initialValues={initialData} onSubmit={handleSubmit}>
        <FaultReceptionForm />
      </Formik>
    </Grid>
  );
};
