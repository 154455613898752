import React, {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {SharedFooter} from '@components/organisms/SharedFooter';
import {isRentalOnlyUser} from '@modules/hospital_users/hooks/useUserPermissions';

export const RentalFooterHeight = '126px';

type Props = {
  text: string;
  backButtonText?: string;
  backButtonSize?: 'small' | 'large';
} & (NotHaveNextButton | HaveNextButton);

type NotHaveNextButton = {
  nextButtonLabel?: undefined;
};

type HaveNextButton = {
  nextButtonLabel: string;
  onClickNextButton: () => void;
  isDisabled?: boolean;
};

/**
 * 貸出・返却メニューのフッター
 */
export const Footer: React.FC<Props> = ({text, backButtonText = '戻る', ...props}) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const isRentalOnly = isRentalOnlyUser();
  const handleClickTop = useCallback(() => {
    if (path === '/shared') {
      navigate('/rentals');
    } else {
      navigate(-1);
    }
  }, [navigate, path]);

  // NOTE: 共通アカウントの場合、貸出返却の最初の画面から機器管理画面に遷移できないようにする
  const isAvailableBtn = path !== '/shared' || !isRentalOnly;

  return (
    <SharedFooter
      text={text}
      backButton={isAvailableBtn ? {label: backButtonText, buttonHandler: handleClickTop} : undefined}
      nextButton={
        props.nextButtonLabel
          ? {
              label: props.nextButtonLabel,
              disabled: props.isDisabled,
              buttonHandler: props.onClickNextButton,
            }
          : undefined
      }
    />
  );
};
