import {useCallback, useMemo, useState} from 'react';
import {useGetHospitalCategories, useGetHospitalSomeDescendantCategories} from '@modules/categories/api';
import {narrowCategoryHashIds, rootCategoryHashIds} from '../constants';

export const usePurchaseCandidateCategoriesQuery = (hospitalHashId: string) => {
  const [selectedRootCategory, setSelectedRootCategory] = useState<string[]>([]);
  const rawRootCategoryQuery = useGetHospitalCategories(hospitalHashId, {depth: 0});
  const rootCategoryQuery = useMemo(() => {
    return {
      ...rawRootCategoryQuery,
      data: rawRootCategoryQuery.data.filter((item) => rootCategoryHashIds.some((i) => i === item.hashId)),
    };
  }, [rawRootCategoryQuery, rootCategoryHashIds]);
  const rawNarrowCategoryQuery = useGetHospitalCategories(hospitalHashId, {depth: 1});
  const narrowCategoryQuery = useMemo(() => {
    return {
      ...rawNarrowCategoryQuery,
      data: rawNarrowCategoryQuery.data.filter((item) => narrowCategoryHashIds.some((i) => i === item.hashId)),
    };
  }, [narrowCategoryHashIds, rawNarrowCategoryQuery]);

  const rawDescendantCategoriesQueries = useGetHospitalSomeDescendantCategories(hospitalHashId, selectedRootCategory);
  const descendantCategoriesQueries = useMemo(() => {
    return rawDescendantCategoriesQueries.map((query) => {
      return {
        ...query,
        data: (query.data?.data ?? []).filter(
          (item) =>
            (item.depth === 0 && rootCategoryHashIds.some((i) => i === item.hashId)) ||
            (item.depth === 1 && narrowCategoryHashIds.some((i) => i === item.hashId))
        ),
      };
    });
  }, [rawDescendantCategoriesQueries, selectedRootCategory]);

  const selectRootCategory = useCallback((categoryHashIds: string[] | null) => {
    setSelectedRootCategory(categoryHashIds ?? []);
  }, []);

  return {rootCategoryQuery, narrowCategoryQuery, descendantCategoriesQueries, selectRootCategory};
};
