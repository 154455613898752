import React, {useEffect} from 'react';
import {createStyles, makeStyles, Paper, Theme} from '@material-ui/core';
import {RentableTable} from './RentableTable';
import {PaperHeader} from '../PaperHeader';
import {papers} from '../consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '0px 16px 16px',
      borderTop: `4px solid ${theme.palette.primary.dark}`,
      height: 'calc(100% - 20px)',
    },
  })
);

export const Rentables: React.FC = () => {
  const classes = useStyles();
  const paper = papers.rentables;

  return (
    <Paper className={classes.paper}>
      <PaperHeader title={paper.label} />
      <RentableTable />
    </Paper>
  );
};
