import {TraineeListPaginationState, TraineeListPaginationAction, TraineeListPaginationActionType} from './types';

export const traineeListPaginationInitialState: TraineeListPaginationState = {
  totalCount: 0,
  page: 1,
  perPage: 20,
  orders: 'name',
};

export const traineeListPaginationReducer = (
  state: TraineeListPaginationState,
  action: TraineeListPaginationAction
): TraineeListPaginationState => {
  switch (action.type) {
    case TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PAGE: {
      return {
        ...state,
        page: action.payload.page,
      };
    }
    case TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload.perPage,
      };
    }
    case TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_TOTAL_COUNT: {
      return {
        ...state,
        totalCount: action.payload.totalCount,
      };
    }
    default:
      return state;
  }
};
