import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {HospitalRoom, HospitalWard} from '@modules/hospital_wards/types';
import {HospitalRentalSetting, BarcodeScannerType, HospitalFloorType} from './types';
import {ReturnsReservationRequest} from '@modules/returns_reservation/type';

export const rentalSettingAtom = atomWithReset<HospitalRentalSetting>('not_need');
export const rentStepperAtom = atomWithReset<number>(0);
export const lenderNameAtom = atomWithReset<string | null>(null);
export const returnLenderNameAtom = atomWithReset<string | null>(null);
export const rentWardAtom = atomWithStorage<HospitalWard | null>('rentWard', null);
export const rentFloorAtom = atomWithStorage<HospitalFloorType | null>('rentFloor', null);
export const rentRoomAtom = atomWithStorage<HospitalRoom | null>('rentRoom', null);
export const rentOrReturnAtom = atomWithStorage<'rent' | 'return' | null>('rentOrReturn', null);
export const rentOrReturnSettingAtom = atomWithStorage<'bothMenu' | 'onlyRentalMenu' | 'onlyReturnMenu'>(
  'rentOrReturnSetting',
  'bothMenu'
);
export const defaultBarcodeScannerTypeAtom = atomWithStorage<BarcodeScannerType>(
  'hitotsu/defaultBarcodeScannerType',
  'barcode_reader'
);
export const canUseDeviceCameraAtom = atomWithReset<boolean>(false);
export const allowRentalRoomUndefinedDataAtom = atomWithReset<boolean>(false);

export const selectedReturnWaitingReceptionAtom = atomWithStorage<
  Partial<ReturnsReservationRequest> & {narrowCategoryName?: string}
>('selectedReturnWaitingReceptionAtom', {});

// 大エリア、階数、小エリアの選択画面での選択情報
export const selectedReturnWaitingAreaInfoAtom = atomWithStorage<{
  ward?: HospitalWard;
  floor?: HospitalFloorType;
  room?: HospitalRoom;
}>('selectedReturnWaitingAreaInfoAtom', {});
