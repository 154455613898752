import {SelectOptionProps, StandardSelect} from '@molecules/Formik/fields/StandardSelectorField';
import {StandardTextField} from '@molecules/Formik/fields/StandardTextField';
import {RepairIndex} from '@modules/repairs/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {StandardDateTimeField} from '@molecules/Formik/fields/StandardDateTimeField';
import {StandardDateField} from '@molecules/Formik/fields/StandardDateField';
import {UserIndex} from '@modules/hospital_users/types';
import {
  dealtCategoryOptions,
  rootCauseCategoryOptions,
  symptomCategoryOptions,
  symptomDetailCategoryOptions,
} from '@modules/repairs/constants';

export type repairField = {
  type: string;
  label: string;
  name: keyof RepairIndex;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  InputComponent: React.ComponentType<any>;
  hidden?: (repair: RepairIndex) => boolean | boolean;
  options?: SelectOptionProps[];
  multiline?: boolean;
  clearable?: boolean;
}[];

type hospitalDepartmentOptions = {
  label: string;
  value: string;
}[];

/**
 * 修理のフォーム 修理詳細の左ナビや点検の新規作成ダイアログで使用している
 * @param hospitalUsers
 * @param hospitalDepartmentOptions
 * @returns
 */
export const formSection: (
  hospitalUsers: UserIndex[],
  hospitalDepartmentOptions?: hospitalDepartmentOptions
) => {
  sectionName: string;
  fields: repairField;
}[] = (hospitalUsers, hospitalDepartmentOptions) => [
  {
    sectionName: 'この修理について',
    fields: [
      {
        type: 'select',
        label: '依頼者の所属部署',
        name: 'hospitalDepartmentHashId' as keyof RepairIndex,
        InputComponent: StandardSelect,
        options: hospitalDepartmentOptions,
      },
      {
        type: 'text',
        label: '依頼者',
        name: 'requestUserName',
        InputComponent: StandardTextField,
      },
      {
        type: 'text',
        label: '依頼事項',
        name: 'requests',
        InputComponent: StandardTextField,
        multiline: true,
      },
      {
        type: 'datetime-local',
        label: '修理受付日時',
        name: 'requestForRepairAt',
        InputComponent: StandardTextField,
      },
      {
        type: 'datetime',
        label: '完了年月日',
        name: 'fixedAt',
        InputComponent: StandardDateTimeField,
      },
      {
        type: 'select',
        label: '担当者',
        name: 'personInChargeHashId',
        InputComponent: StandardSelect,
        options: UserFormatter.getOptions(hospitalUsers, {withAlias: false, withSubLabel: false}),
      },
      {
        type: 'select',
        label: '事象区分',
        name: 'symptomCategory',
        InputComponent: StandardSelect,
        options: symptomCategoryOptions,
      },
      {
        type: 'select',
        label: '事象詳細区分（内部故障）',
        name: 'symptomDetailCategory',
        InputComponent: StandardSelect,
        options: symptomDetailCategoryOptions,
        hidden: (repair) => {
          return repair.symptomCategory !== 'internal_failure';
        },
      },
      {
        type: 'text',
        label: '事象の詳細',
        name: 'symptomDetail',
        InputComponent: StandardTextField,
        multiline: true,
      },
      {
        type: 'select',
        label: '原因区分',
        name: 'rootCauseCategory',
        InputComponent: StandardSelect,
        options: rootCauseCategoryOptions,
      },
      {
        type: 'text',
        label: '原因の詳細',
        name: 'causeOfIssueDetail',
        InputComponent: StandardTextField,
        multiline: true,
      },
      {
        type: 'text',
        label: '事務への申請内容',
        name: 'applicationToSuppliesDepartment',
        InputComponent: StandardTextField,
        multiline: true,
      },
      {
        type: 'datetime',
        label: '事務への申請年月日',
        name: 'appliedAtToSuppliesDepartment',
        InputComponent: StandardDateTimeField,
        clearable: true, // クリア可能として設定
      },
      {
        type: 'select',
        label: '代替の有無',
        name: 'necessityOfSubstitute',
        InputComponent: StandardSelect,
        options: [
          {label: '有り', value: true},
          {label: '無し', value: false},
        ],
      },
      {
        type: 'text',
        label: '代替の内容',
        name: 'substitutionDetail',
        InputComponent: StandardTextField,
        multiline: true,
      },
      {
        type: 'select',
        label: '対応区分',
        name: 'dealtCategory',
        InputComponent: StandardSelect,
        options: dealtCategoryOptions,
      },
      {
        type: 'text',
        label: '対応の詳細',
        name: 'dealDetail',
        InputComponent: StandardTextField,
        multiline: true,
      },
      {
        type: 'number',
        label: '修理見積額（円）',
        name: 'estimatedCostOfFix' as keyof RepairIndex,
        InputComponent: StandardTextField,
      },
      {
        type: 'number',
        label: '修理請求額（円）',
        name: 'costOfFix',
        InputComponent: StandardTextField,
      },
    ],
  },
  {
    sectionName: '廃棄情報',
    fields: [
      {
        type: 'text',
        label: '廃棄日',
        name: 'dateOfDisposal',
        InputComponent: StandardDateField,
      },
      {
        type: 'text',
        label: '廃棄理由',
        name: 'reasonOfDisposal',
        InputComponent: StandardTextField,
        multiline: true,
      },
    ],
  },
  {
    sectionName: 'メーカー担当者情報',
    fields: [
      {
        type: 'text',
        label: 'メーカー担当者名',
        name: 'makerPicName',
        InputComponent: StandardTextField,
      },
      {
        type: 'text',
        label: '担当者連絡先（電話番号）',
        name: 'makerContactTel',
        InputComponent: StandardTextField,
      },
      {
        type: 'text',
        label: '担当者連絡先（メールアドレス）',
        name: 'makerContactEmail',
        InputComponent: StandardTextField,
      },
      {
        type: 'datetime',
        label: 'メーカーへの連絡日',
        name: 'makerContactAt',
        InputComponent: StandardDateTimeField,
      },
    ],
  },
];

export type historyCardType = 'comment' | 'status_change';
