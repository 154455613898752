import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {zodResolver} from '@hookform/resolvers/zod';
import {ReturnsReservationRequest, returnWaitingReceptionsSchema} from '@modules/returns_reservation/type';
import {useAtom} from 'jotai';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {ReceptionSelectionMap} from '../consts';
import {selectedReturnWaitingReceptionAtom} from '../states';
import {FormContainer} from './FormContainer';

/**
 * 返却待ち受付入力画面
 */
export const ReturnWaitingReceptionInput: React.FC = () => {
  const navigate = useNavigate();
  const [selectItem, setSelectItem] = useAtom(selectedReturnWaitingReceptionAtom);
  const {control, handleSubmit, formState} = useForm<ReturnsReservationRequest>({
    resolver: zodResolver(returnWaitingReceptionsSchema),
    defaultValues: {
      ...selectItem,
    },
  });

  const onSubmit = (data: ReturnsReservationRequest) => {
    setSelectItem({...selectItem, ...data});
    navigate(ReceptionSelectionMap.path.preview);
  };

  React.useEffect(() => {
    if (!selectItem.narrowCategoryHashId || !selectItem.roomHashId) navigate(ReceptionSelectionMap.rootPath);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WardAssetMenuTemplate
        reservationDeviceName={selectItem?.narrowCategoryName}
        headerProps={{title: ReceptionSelectionMap.title}}
        footerProps={{
          text: ReceptionSelectionMap.footerTitle.input,
          backButtonHandler: () => navigate(ReceptionSelectionMap.path.roomSelection),
          nextButton: {
            label: ReceptionSelectionMap.label.nextPreview,
            buttonHandler: handleSubmit(onSubmit),
            disabled: !formState.isValid,
          },
        }}>
        <FormContainer control={control} />
      </WardAssetMenuTemplate>
    </form>
  );
};
