import React, {useCallback, useState, useEffect} from 'react';
import {Button, Grid, makeStyles, styled, Theme, Typography} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {CommentDialog} from '@molecules/Dialogs/CommentDialog';
import {RepairIndex} from '@modules/repairs/types';
import {createFaultRepairLog, deleteFaultRepairLog, updateFaultRepairLog} from '@modules/repairs/api';
import {openSnackBar} from '@molecules/SnackBar';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {useFaultHistory} from './hooks';
import {InnerCardItem} from './InnerCardItem';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type Props = {
  faultRepair: RepairIndex;
};

/**
 * 機器管理の履歴表示
 *
 * @param  {RepairIndex} faultRepair
 * @returns {React.FC<Props>}
 */
export const ProductTransactionHistory: React.FC<Props> = ({faultRepair}) => {
  const {canEdit: canEditRepair, canDelete: canDeleteRepair} = useUserResourcesPermissions('REPAIR');
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const {monthlyProductHistory, faultLogQuery} = useFaultHistory({
    hospitalHashId: myInfo.hospitalHashId,
    faultRepair,
  });
  const handleClickAddComment = useCallback(async () => {
    setOpenCommentDialog(true);
    try {
      const comment = await dialogHandler.open(CommentDialog, {});
      await createFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, {
        description: comment,
        logType: 'comment',
      });
      faultLogQuery.refetch();
      openSnackBar('コメントを追加しました');
    } finally {
      setOpenCommentDialog(false);
    }
  }, [faultLogQuery, faultRepair.hashId, myInfo.hospitalHashId]);

  const handleUpdatePin = useCallback(
    async (logHashId: string | undefined, currentPinned: boolean | undefined) => {
      if (!logHashId) return;
      try {
        await updateFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, logHashId, {
          pinned: !currentPinned,
        });
        await faultLogQuery.refetch();
        if (currentPinned) {
          openSnackBar('コメントのピンどめを外しました');
        } else {
          openSnackBar('コメントをピンどめしました');
        }
      } catch (_error) {
        openSnackBar('コメントのピンどめに失敗しました', 'left', 'bottom', 'error');
      }
    },
    [faultLogQuery, faultRepair.hashId, myInfo.hospitalHashId]
  );

  const handleUpdateComment = useCallback(
    async (logHashId: string | undefined, pinned: boolean | undefined, comment: string) => {
      if (!logHashId) return;
      try {
        await updateFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, logHashId, {
          pinned: pinned ? pinned : false,
          description: comment,
        });
        await faultLogQuery.refetch();
        openSnackBar('コメントを更新しました');
      } catch (_error) {
        openSnackBar('コメントの更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [faultLogQuery, faultRepair.hashId, myInfo.hospitalHashId]
  );

  const handleDeleteComment = useCallback(
    async (logHashId: string) => {
      try {
        const ok = await dialogHandler
          .open(AlertDialog, {
            title: 'コメントを削除しますか？',
            content: 'コメントを削除しようとしています。\n\nこのアクションは元に戻せません。',
            positiveButtonLabel: 'コメントを削除',
          })
          .then(() => true)
          .catch(() => false);

        if (!ok) {
          return;
        }

        await deleteFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, logHashId);
        await faultLogQuery.refetch();
        openSnackBar('コメントを削除しました');
      } catch (_error) {
        openSnackBar('コメントの削除に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [faultLogQuery, faultRepair.hashId, myInfo.hospitalHashId]
  );
  useEffect(() => {
    return () => {
      if (openCommentDialog) dialogHandler.close(CommentDialog, {});
    };
  }, [openCommentDialog]);
  return (
    <Grid container>
      <Grid container style={{justifyContent: 'flex-end'}}>
        <Grid item className={classes.actionContainer}>
          {canEditRepair && (
            <Button color="inherit" className={classes.addCommentBtn} onClick={handleClickAddComment}>
              コメントを追加
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {monthlyProductHistory.map((outer, outerIdx) => (
          <React.Fragment key={outer.section + String(outerIdx)}>
            <Typography style={{paddingTop: '40px', paddingBottom: '8px'}}>{outer.section}</Typography>
            {outer.productHistories.map((innerItem) => (
              <RootContainer key={innerItem.logHashId}>
                <InnerCardItem
                  innerItem={innerItem}
                  isReadOnly={!canEditRepair}
                  isAdmin={canDeleteRepair}
                  onDeleteComment={() => handleDeleteComment(innerItem.logHashId)}
                  onUpdateComment={(comment) => handleUpdateComment(innerItem.logHashId, innerItem.pinned, comment)}
                  onUpdatePinned={() => handleUpdatePin(innerItem.logHashId, innerItem.pinned)}
                />
              </RootContainer>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  backgroundColor: 'white',
  marginTop: '16px',
});

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    paddingTop: '24px',
    fontSize: theme.typography.fontSize,
  },
  action: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  historyContainer: {
    paddingTop: '16px',
    fontSize: theme.typography.fontSize,
    width: '100%',
    paddingRight: '24px',
  },
  addCommentBtn: {
    backgroundColor: '#323F4E',
    borderRadius: '2px',
    padding: '8px 22px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
}));
