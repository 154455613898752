import {Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography} from '@mui/material';
import React from 'react';
import {Control, Controller, useFormContext} from 'react-hook-form';
import {CreatePurchaseRequestFormData} from '../types';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {RequiredLabelBadge} from '@components/atoms/RequiredLabelBadge';

export type ContactInformationContentProps = {
  control: Control<CreatePurchaseRequestFormData>;
};

export const ContactInformationContent = (props: ContactInformationContentProps) => {
  const {control} = props;

  const {
    watch,
    formState: {errors},
  } = useFormContext<CreatePurchaseRequestFormData>();

  // propが更新されたらフォームをリセットする
  const contactMethod = watch('contactMethod');

  return (
    <Box sx={{maxWidth: '660px', marginTop: '24px'}}>
      <RequiredLabel>医療機関名</RequiredLabel>
      <Controller
        name="hospitalName"
        control={control}
        render={({field}) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            margin="normal"
            error={!!errors.hospitalName}
            helperText={errors.hospitalName?.message}
            sx={{marginTop: '8px', marginBottom: '16px'}}
          />
        )}
      />
      <RequiredLabel>氏名</RequiredLabel>
      <Controller
        name="requesterName"
        control={control}
        render={({field}) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            margin="normal"
            error={!!errors.requesterName}
            helperText={errors.requesterName?.message}
            sx={{marginTop: '8px', marginBottom: '16px'}}
          />
        )}
      />
      <RequiredLabel>所属部署名</RequiredLabel>
      <Controller
        name="departmentName"
        control={control}
        render={({field}) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            margin="normal"
            error={!!errors.departmentName}
            helperText={errors.departmentName?.message}
            sx={{marginTop: '8px', marginBottom: '16px'}}
          />
        )}
      />
      <Divider />
      <FormControl component="fieldset" margin="normal" fullWidth>
        <RequiredLabel>ご希望の連絡方法</RequiredLabel>
        <Controller
          name="contactMethod"
          control={control}
          render={({field}) => (
            <RadioGroup {...field}>
              <FormControlLabel value="email" control={<Radio />} label="メール" />
              <FormControlLabel value="phone" control={<Radio />} label="電話" />
            </RadioGroup>
          )}
        />
        {errors.contactMethod && (
          <Typography color="error" variant="caption">
            {errors.contactMethod.message}
          </Typography>
        )}
      </FormControl>

      <label>メールアドレス（FUJITA社からの連絡用）</label>
      {contactMethod === 'email' && <RequiredLabelBadge />}
      <Controller
        name="email"
        control={control}
        render={({field}) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={{marginTop: '8px', marginBottom: '16px'}}
          />
        )}
      />

      <label>電話番号（FUJITA社からの連絡用）</label>
      {contactMethod === 'phone' && <RequiredLabelBadge />}
      <Controller
        name="phoneNumber"
        control={control}
        render={({field}) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            margin="normal"
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            sx={{marginTop: '8px', marginBottom: '16px'}}
          />
        )}
      />

      <label>連絡に関するご要望</label>
      <Controller
        name="contactRemarks"
        control={control}
        render={({field}) => (
          <TextField
            {...field}
            size="small"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            error={!!errors.contactRemarks}
            helperText={errors.contactRemarks?.message}
            placeholder="例）17時以降に連絡ください"
            sx={{marginTop: '8px'}}
          />
        )}
      />
      <Typography>※FUJITA社の対応可能時間は平日9～18時となります</Typography>
    </Box>
  );
};
