import React from 'react';
import {styled, TableRow, TableCell} from '@mui/material';

const Th = styled(TableCell)({
  border: 0,
  padding: '0px 16px 0px 0px',
  lineHeight: 'unset',
  backgroundColor: '#F9F9F9',
  color: 'inherit',
});

export const TraineeListTableHeadRow = () => {
  return (
    <TableRow>
      <Th sx={{padding: 0, width: '56px'}} />
      <Th align="left">職員番号</Th>
      <Th align="left">研修対象者・受講者名</Th>
      <Th align="left">所属部署</Th>
    </TableRow>
  );
};
