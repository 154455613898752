import React from 'react';
import {useMobileMonthlyProductHistory} from '@Apps/ProductDetail/hooks';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {
  MobileCommentCard,
  MobileInspectionResultCard,
  MobileProductRegistrationCard,
  MobileRentHistoryCard,
  MobileReturnHistoryCard,
  MobileTimelineSection,
} from '@molecules/MobileTimelines';
import {MobileRepairCommentCard} from '@molecules/MobileTimelines/MobileRepairCommentCard';
import {MobileRepairRegistrationCard} from '@molecules/MobileTimelines/MobileRepairRegistrationCard';
import {MobileRepairStatusChangeCard} from '@molecules/MobileTimelines/MobileRepairStatusChangeCard';
import {MobileMoveTargetBuildingHistoryCard} from '@molecules/MobileTimelines/MobileMoveTargetBuildingHistoryCard';
import {MobileFaultReceptionHistoryCard} from '@molecules/MobileTimelines/MobileFaultReceptionHistoryCard';
import {MobileFaultReceptionLogHistoryCard} from '@molecules/MobileTimelines/MobileFaultReceptionLogHistoryCard';

export type MobileTimelineProps = {
  hospitalProductDetail: HospitalProductDetail;
};

export const MobileTimeline = (props: MobileTimelineProps) => {
  const {hospitalProductDetail} = props;

  // TODO:setter使われおらず空配列のままだけど問題ない?
  const userHashIds: string[] = [];
  const selectedOptions: string[] = [];

  const {monthlyProductHistory} = useMobileMonthlyProductHistory({
    productHashId: hospitalProductDetail.hashId,
    userHashIds,
    selectedOptions,
    hospitalProduct: hospitalProductDetail,
  });

  return (
    <div>
      {monthlyProductHistory.map((monthlyHistory, index) => (
        <React.Fragment key={`${monthlyHistory.section}_${index}`}>
          <MobileTimelineSection key={monthlyHistory.section}>{monthlyHistory.section}</MobileTimelineSection>
          {monthlyHistory.productHistories.map((productHistory, historyIndex) => {
            if (productHistory.cardType === 'registration')
              return (
                <MobileProductRegistrationCard
                  displayDate={productHistory.displayDate}
                  createdBy={productHistory.hospitalProduct?.createdBy}
                />
              );
            if (productHistory.cardType === 'comment')
              return (
                <MobileCommentCard
                  user={productHistory.user}
                  displayDate={productHistory.displayDate}
                  comment={productHistory.description ?? ''}
                  updateBy={productHistory.updateBy}
                  updateAt={productHistory.updatedAt}
                  isArchived={productHistory.isArchived}
                  key={`MobileCommentCard${historyIndex}`}
                />
              );
            if (productHistory.cardType === 'rental' && productHistory.contentSlot?.at(0)?.value === '貸出中')
              return (
                <MobileRentHistoryCard
                  displayDate={productHistory.displayDate}
                  rentalDate={productHistory.contentSlot?.at(1)?.value ?? ''}
                  deviceLenderUserName={productHistory.contentSlot?.at(2)?.value ?? ''}
                  rentalRoom={productHistory.contentSlot?.at(3)?.value ?? ''}
                  key={`MobileRentHistoryCard${historyIndex}`}
                />
              );
            if (productHistory.cardType === 'rental' && productHistory.contentSlot?.at(0)?.value === '返却済み')
              return (
                <MobileReturnHistoryCard
                  displayDate={productHistory.displayDate}
                  returnedDate={productHistory.contentSlot?.at(1)?.value ?? ''}
                  deviceLenderUserName={productHistory.contentSlot?.at(2)?.value ?? ''}
                  key={`MobileReturnHistoryCard${historyIndex}`}
                />
              );
            if (productHistory.cardType === 'inspection_result')
              return (
                <MobileInspectionResultCard
                  displayDate={productHistory.displayDate}
                  status={productHistory.contentSlot?.at(0)?.value ?? ''}
                  completedAt={productHistory.contentSlot?.at(1)?.value ?? ''}
                  inspectorFullName={productHistory.contentSlot?.at(2)?.value ?? ''}
                  inspectionName={productHistory.contentSlot?.at(3)?.value ?? ''}
                  key={`MobileInspectionResultCard${historyIndex}`}
                />
              );
            if (productHistory.cardType === 'repair_registration')
              return (
                <MobileRepairRegistrationCard
                  displayDate={productHistory.displayDate}
                  repairRegistrationNumber={productHistory.repairRegistrationNumber}
                />
              );
            if (productHistory.cardType === 'repair_status_change')
              return (
                <MobileRepairStatusChangeCard
                  displayDate={productHistory.displayDate}
                  fromStatusName={productHistory.contentSlot?.at(0)?.value ?? ''}
                  toStatusName={productHistory.contentSlot?.at(1)?.value ?? ''}
                  key={`MobileRepairStatusChangeCard${historyIndex}`}
                />
              );
            if (productHistory.cardType === 'repair_comment')
              return (
                <MobileRepairCommentCard
                  displayDate={productHistory.displayDate}
                  createdUser={productHistory.user}
                  comment={productHistory.description ?? ''}
                  key={`MobileRepairCommentCard${historyIndex}`}
                />
              );
            if (productHistory.cardType === 'move_target_building') {
              return (
                <MobileMoveTargetBuildingHistoryCard
                  displayDate={productHistory.displayDate}
                  hospitalRoom={productHistory.contentSlot?.at(0)?.value ?? ''}
                  movedAt={productHistory.contentSlot?.at(1)?.value ?? ''}
                  registerFullName={productHistory.contentSlot?.at(2)?.value ?? ''}
                  key={`productHistories${historyIndex}`}
                />
              );
            }
            if (productHistory.cardType === 'fault_reception') {
              return (
                <MobileFaultReceptionHistoryCard
                  displayDate={productHistory.displayDate}
                  receptionID={productHistory.contentSlot?.at(0)?.value ?? ''}
                  reporterName={productHistory.contentSlot?.at(1)?.value ?? ''}
                  key={`productHistories${historyIndex}`}
                />
              );
            }
            if (productHistory.cardType === 'fault_reception_comment') {
              return (
                <MobileFaultReceptionLogHistoryCard
                  displayDate={productHistory.displayDate}
                  comment={productHistory.contentSlot?.at(0)?.value ?? ''}
                  userFullName={productHistory.contentSlot?.at(1)?.value ?? ''}
                  key={`productHistories${historyIndex}`}
                />
              );
            }
            return '';
          })}
        </React.Fragment>
      ))}
    </div>
  );
};
