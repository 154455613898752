import {useReducer} from 'react';
import {useFetchHospitalDepartments} from './api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {fetchHospitalDepartments, GetDepartmentsParams} from '@modules/hospital_departments/api';
import {
  HospitalDepartmentState,
  HospitalDepartmentActionType,
  HospitalDepartMent,
} from '@modules/hospital_departments/types';
import {hospitalDepartmentReducer, hospitalDepartmentInitialState} from '@modules/hospital_departments/reducers';

export const useHospitalDepartmentsOptions = (selectableHospitalDepartmentHashID?: string, showDeleted = false) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useFetchHospitalDepartments(myInfo.hospitalHashId, {
    showDeleted: showDeleted,
    page: 0,
    perPage: 100,
  });

  // showDeletedがfalseの場合には削除された部署を除外する
  const currentSelectableHospitalDepartments = !showDeleted ? data : data?.filter((item) => !item.deletedBy);

  // 修理情報の詳細表示時に、削除済みでも選択された部署を表示するための処理
  if (selectableHospitalDepartmentHashID) {
    const isSelectable = currentSelectableHospitalDepartments.some(
      (data) => data.hashId === selectableHospitalDepartmentHashID
    );
    const selectableHospitalDepartment = data.find((data) => data.hashId === selectableHospitalDepartmentHashID);

    if (!isSelectable && selectableHospitalDepartment) {
      currentSelectableHospitalDepartments.push(selectableHospitalDepartment);
    }
  }

  const hospitalDepartmentOptions = currentSelectableHospitalDepartments
    ? currentSelectableHospitalDepartments.map((department) => ({
        label: department.name,
        value: department.hashId,
      }))
    : [];

  return {hospitalDepartmentOptions, isLoading};
};

type HospitalDepartmentStoreType = {
  state: HospitalDepartmentState;
  dispatchListHospitalDepartment: (
    hospitalHashId: string,
    params: GetDepartmentsParams
  ) => Promise<HospitalDepartMent[]>;
  get: (hashId: string) => HospitalDepartMent | null;
};

export const useHospitalDepartmentStore = (): HospitalDepartmentStoreType => {
  const [state, dispatch] = useReducer(hospitalDepartmentReducer, hospitalDepartmentInitialState);

  const dispatchListHospitalDepartment = async (hospitalHashId: string, params: GetDepartmentsParams) => {
    const res = await fetchHospitalDepartments(hospitalHashId, params);
    dispatch({
      type: HospitalDepartmentActionType.HOSPITAL_DEPARTMENT_SET_LIST,
      payload: {departments: res.data},
    });
    dispatch({
      type: HospitalDepartmentActionType.HOSPITAL_DEPARTMENT_SET_MAP,
      payload: {map: new Map(res.data.map((item) => [item.hashId, item]))},
    });
    return res.data;
  };

  const get = (hashId: string) => {
    return state.map.get(hashId) ?? null;
  };

  return {
    state,
    dispatchListHospitalDepartment,
    get,
  };
};
