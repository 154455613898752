export const rootCategoryHashIds = [
  'pqGOdVoJ25BW3oR', // 呼吸補助
  'pnw1R5YoYlBJWD3', // 内視鏡
  '3KmPB5qbyVL9dZj', // 手術装置
  'm9Z2KVeRglDGEaY', // エネルギーデバイス
  'G0gO3lGvkV8xw2k', // 生理検査
  'NGRwn5KRPzOogZr', // 放射線
  'pZmyO5OeOle1Ykb', // 診療・福祉備品
];

export const narrowCategoryHashIds = [
  'b7NreVQnrln4y8K', // 人工呼吸器
  'Yg1oDlxja5vKJyX', // 軟性鏡
  'DyaBo5ppN5PvbkQ', // ビデオシステム
  'NGRwn5KjPzOogZr', // 光源装置
  'xoMywzaaDzRaOrD', // 内視鏡モニタ
  'pZmyO5OxOVe1Ykb', // 内視鏡（その他装置）
  'MJqEYlRqKV4Rarx', // 麻酔器
  'eyYa0l6xB57GMkr', // 電気メス
  'LvWoM54MOVZm7Pq', // 超音波検査装置
  'jedxYVBvvVn17aB', // 眼検査装置
  'eBv2R5bagzdQ9oD', // X線撮影装置
  'o03pY51n3VwDbka', // X線CT装置
  '6moRX53or57rx2P', // X線TV装置
  'MJqEYlR9BV4Rarx', // MR装置
  'pqGOdVoG7VBW3oR', // 血管撮影装置
  'X8KbdVP4KV29GgD', // 放射線治療装置
  'eyYa0l6PM57GMkr', // 放射線（その他装置）
  'G0gO3lG46z8xw2k', // 電動ベッド
];
