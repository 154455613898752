import {
  HospitalDepartmentState,
  HospitalDepartmentAction,
  HospitalDepartmentActionType,
} from '@modules/hospital_departments/types';

export const hospitalDepartmentInitialState: HospitalDepartmentState = {
  departments: [],
  map: new Map(),
};

export const hospitalDepartmentReducer = (
  state: HospitalDepartmentState,
  action: HospitalDepartmentAction
): HospitalDepartmentState => {
  switch (action.type) {
    case HospitalDepartmentActionType.HOSPITAL_DEPARTMENT_SET_LIST: {
      return {
        ...state,
        departments: action.payload.departments,
      };
    }
    case HospitalDepartmentActionType.HOSPITAL_DEPARTMENT_SET_MAP: {
      return {
        ...state,
        map: action.payload.map,
      };
    }
    default:
      return state;
  }
};
