import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {styled, Box, Button} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import {openSnackBar} from '@molecules/SnackBar';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TrainingScheduleTrainee, UpdateTrainingScheduleTraineeRequest} from '@modules/training_schedule_trainees/types';
import {AlertDialog} from './AlertDialog';
import {TraineeEditDialog} from './TraineeEditDialog';
import {TraineeListPaginationTable} from './TraineeListPaginationTable';
import {TraineeUpdateMessages, TraineeDeleteMessages} from './constants';
import {useTraineeListStore} from './hooks';
import {TraineeDataRow} from './types';

type Props = {
  trainingScheduleHashId: string;
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});
const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '16px',
  width: '100%',
});
const Body = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  width: '100%',
});

export const TraineeList = ({trainingScheduleHashId}: Props) => {
  const [loading, setLoading] = useState(true);
  const {
    departmentState,
    traineeState,
    paginationState,
    initTraineeListStore,
    findHospitalDepartmentByHashId,
    updatePage,
    updatePerPage,
    updateTrainee,
    deleteTrainee,
  } = useTraineeListStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const init = async () => {
    setLoading(true);
    const queryPage = searchParams.get('page');
    const page = queryPage ? Number(queryPage) : undefined;
    await initTraineeListStore(trainingScheduleHashId, page);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const trainees: TraineeDataRow[] = useMemo(() => {
    if (loading) return [];

    return traineeState.trainees.map(({hashId, name, employeeNumber, hospitalDepartmentHashId}): TraineeDataRow => {
      const department = findHospitalDepartmentByHashId(hospitalDepartmentHashId ?? '');
      const departmentName = department?.name ?? '';
      return {hashId, name, employeeNumber, departmentName};
    });
  }, [loading, traineeState.trainees]);

  const handlePageChange = async (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams([...searchParams.entries()]);
    await updatePage(trainingScheduleHashId, page);
  };
  const handlePerPageChange = async (perPage: number) => {
    searchParams.set('page', '1');
    setSearchParams([...searchParams.entries()]);
    await updatePerPage(trainingScheduleHashId, perPage);
  };

  // 研修対象者・受講者の更新
  const openEditDialog = async (
    trainee: TrainingScheduleTrainee,
    onSubmit: (req: UpdateTrainingScheduleTraineeRequest) => void
  ) => {
    const traineeDepartment = findHospitalDepartmentByHashId(trainee.hospitalDepartmentHashId ?? '');
    const defaultDepartmentHashId = traineeDepartment?.hashId ?? null;
    try {
      await dialogHandler.open(TraineeEditDialog, {
        trainee,
        departments: departmentState.departments,
        defaultDepartmentHashId,
        onSubmit,
      });
    } catch (_error) {
      return false;
    }
    return true;
  };
  const handleEditMenuClick = async (hashId: string) => {
    const trainee = traineeState.trainees.find((t) => t.hashId === hashId);
    if (!trainee) return;

    let updateReq: UpdateTrainingScheduleTraineeRequest = {
      name: trainee.name,
      employeeNumber: trainee.employeeNumber,
      hospitalDepartmentHashId: trainee.hospitalDepartmentHashId,
    };
    const result = await openEditDialog(trainee, (req) => {
      updateReq = req;
    });
    if (!result) return;

    try {
      await updateTrainee(hashId, trainingScheduleHashId, updateReq);
      openSnackBar(TraineeUpdateMessages.succeeded);
    } catch (error) {
      openSnackBar(TraineeUpdateMessages.failed, 'left', 'bottom', 'error');
      console.error('Failed to update a trainee', error);
    }
  };

  // 研修対象者・受講者の削除
  const openDeleteAlertDialog = async () => {
    try {
      await dialogHandler.open(AlertDialog, {
        title: '研修対象者・受講者情報の削除',
        content: 'この操作を実行しますか？実行すると元に戻せません。',
      });
    } catch (_error) {
      return false;
    }
    return true;
  };
  const handleDeleteMenuClick = async (hashId: string) => {
    const result = await openDeleteAlertDialog();
    if (!result) return;

    try {
      await deleteTrainee(hashId, trainingScheduleHashId);
      openSnackBar(TraineeDeleteMessages.succeeded);
    } catch (error) {
      openSnackBar(TraineeDeleteMessages.failed, 'left', 'bottom', 'error');
      console.error('Failed to delete a trainee', error);
    }
  };

  if (loading) return null;

  return (
    <Container>
      <Header>
        <Button
          component={Link}
          variant="contained"
          sx={{height: '40px', backgroundColor: '#0052CC'}}
          to={`/training_managements/schedules/${trainingScheduleHashId}/trainees/import`}>
          Excelから一括登録
        </Button>
      </Header>
      <Body>
        <TraineeListPaginationTable
          trainees={trainees}
          {...paginationState}
          onPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          onEditMenuClick={handleEditMenuClick}
          onDeleteMenuClick={handleDeleteMenuClick}
        />
      </Body>
    </Container>
  );
};
