import React, {useCallback} from 'react';
import {Table} from '@molecules/Table';
import {
  useCreateTrainingScheduleMutation,
  useDeleteTrainingPlanMutation,
  useSearchVariables,
  useTableData,
  useUpdateTrainingPlanMutation,
} from '../hooks';
import {RowAction} from '@molecules/Table/props';
import {PlanTableData} from '.';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TrainingPlanDialogProps, TrainingPlanDialogResult, TrainingPlanDialog} from '../dialogs/TrainingPlanDialog';
import {planTypeOptions, trainingTypeOptions} from '@modules/training_plans/constants';
import {useNavigate} from 'react-router-dom';
import {
  TrainingScheduleDialog,
  TrainingScheduleDialogProps,
  TrainingScheduleDialogResult,
} from '@organisms/TrainingManagements/TrainingScheduleDialog';
import {openSnackBar} from '@components/molecules/SnackBar';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export const PlanTable: React.FC = () => {
  const navigate = useNavigate();
  const {tableColumns, planTableData, isLoading} = useTableData();
  const {mutate} = useUpdateTrainingPlanMutation();
  const {mutate: deleteTrainingPlanMutate} = useDeleteTrainingPlanMutation();
  const {mutate: createTrainingScheduleMutate} = useCreateTrainingScheduleMutation();
  const {setOrder} = useSearchVariables();
  const {canEdit: canEditTraining, canDelete: canDeleteTraining} = useUserResourcesPermissions('TRAINING');

  const handleEdit = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      const result = await dialogHandler.open<TrainingPlanDialogProps, TrainingPlanDialogResult>(TrainingPlanDialog, {
        isEdit: true,
        initialValues: {
          name: data.name,
          // optionの方は決まっているので、基本的にここに存在しないパラメータが来ることはない。
          // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
          trainingTypes: data.trainingTypes.map((item) => trainingTypeOptions.find((option) => option.value === item)!),
          planType: planTypeOptions.find((item) => item.value === data.planType) ?? planTypeOptions[0],
          scheduleTimes: data.scheduleTimes,
        },
      });
      mutate({...result, hashId: data.hashId});
    },
    [mutate]
  );

  const handleDeletePlan = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      try {
        await dialogHandler.open(AlertDialog, {
          title: '研修計画マスタを削除しますか？',
          content:
            'この研修計画マスタを削除しようとしています。\n\n研修計画マスタに紐づく、研修予定や実施済みの研修も削除されます。\n\nこのアクションは元に戻せません。',
          positiveButtonLabel: '研修計画マスタを削除',
        });
        deleteTrainingPlanMutate(data.hashId);
        openSnackBar('研修計画マスタを削除しました');
      } catch (_e) {
        return;
      }
    },
    [deleteTrainingPlanMutate]
  );

  const handleNavigateTrainingSchedule = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      navigate('/training_managements/schedules', {
        state: {
          trainingPlanHashId: data.hashId,
        },
      });
    },
    [navigate]
  );

  const handleNavigateTrainingReport = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      navigate('/training_managements/reports', {
        state: {
          trainingPlanHashId: data.hashId,
        },
      });
    },
    [navigate]
  );

  const handleRegisterSchedule = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      const result = await dialogHandler.open<TrainingScheduleDialogProps, TrainingScheduleDialogResult>(
        TrainingScheduleDialog,
        {
          isEdit: false,
          sourceTrainingPlanHashId: data.hashId,
        }
      );
      createTrainingScheduleMutate({
        ...result,
        trainingPlanTrainingTypes: data.trainingTypes,
        wholeProductHashIds: [],
      });
    },
    [createTrainingScheduleMutate]
  );

  const rowsActions: RowAction<PlanTableData>[] = [
    {
      type: 'menu',
      label: 'アクション',
      items: (() => {
        const baseItems = [
          {label: '予定一覧を表示', onClick: handleNavigateTrainingSchedule},
          {label: '実施済み一覧を表示', onClick: handleNavigateTrainingReport},
        ];

        if (canDeleteTraining) {
          return [
            {label: '編集', onClick: handleEdit},
            {label: '削除', onClick: handleDeletePlan},
            {label: '予定を登録', onClick: handleRegisterSchedule},
            ...baseItems,
          ];
        }

        if (canEditTraining) {
          return [
            {label: '編集', onClick: handleEdit},
            {label: '予定を登録', onClick: handleRegisterSchedule},
            ...baseItems,
          ];
        }

        return baseItems;
      })(),
    },
  ];

  return (
    <Table<PlanTableData>
      data={planTableData}
      isLoading={isLoading}
      columns={tableColumns}
      showSelection={false}
      rowActions={rowsActions}
      onOrderChange={(columnIndex: number, orderDirection: 'desc' | 'asc') => {
        setOrder(tableColumns[columnIndex].field, orderDirection);
      }}
    />
  );
};
