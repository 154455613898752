import React, {useCallback, useState, useEffect} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FileIndex} from '@modules/files/types';
import {FileList} from '@organisms/Files/FileList';
import {makeStyles, createStyles, Grid} from '@material-ui/core';
import {createFaultRepairFiles, listFaultRepairFiles} from '@modules/repairs/api';
import {useQuery} from 'react-query';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type FilesFormProps = {
  faultRepairHashId: string;
};

export const Files: React.FC<FilesFormProps> = ({faultRepairHashId}) => {
  const {canEdit: canEditRepair} = useUserResourcesPermissions('REPAIR');
  const classes = useStyles();
  const [tableLayout] = useTableLayout('fileList');
  const [order, setOrder] = useState<string>();
  const {myInfo} = useMyInfo();

  const {data, refetch, isLoading} = useQuery(
    ['listFaultRepairFiles', myInfo.hospitalHashId, faultRepairHashId, order],
    () => listFaultRepairFiles(myInfo.hospitalHashId, faultRepairHashId, {order})
  );

  const [files, setFiles] = useState(data);
  useEffect(() => {
    setFiles(data || []);
  }, [data]);

  const handleUploadFile = useCallback(
    async (newFile: FileIndex) => {
      await createFaultRepairFiles(myInfo.hospitalHashId, faultRepairHashId, {
        fileHashIds: [newFile.hashId],
      });
      refetch();
    },
    [myInfo.hospitalHashId, faultRepairHashId, refetch]
  );
  const handleRefetch = () => refetch();

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout.currentLayout[columnIndex].field)}`);
    },
    [tableLayout.currentLayout]
  );

  return (
    <Grid className={classes.root} container justifyContent="center">
      <FileList
        files={files}
        fileCategory={'fault_repair'}
        isLoading={isLoading}
        disableEdit={!canEditRepair}
        disableUpload={!canEditRepair}
        onDeleteFile={handleRefetch}
        onEditFileName={handleRefetch}
        onUploadFile={handleUploadFile}
        onOrderChange={handleOrderChange}
      />
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '24px 0px 16px',
      width: '100%',
    },
  })
);
