import {ListPageLayout} from '@components/layouts/ListPageLayout';
import React from 'react';
import {Header} from './components/Header';
import {ListTable} from './components/ListTable';

export const PurchaseCandidateProductList = () => {
  return (
    <ListPageLayout page="purchase_candidate_product_list">
      <ListPageLayout.Header>
        <Header />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <ListTable />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};
