import {CheckCircle} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export const PurchaseRequestComplete = () => {
  const navigate = useNavigate();

  const handleBackToPurchaseRequestList = () => {
    navigate('/products/purchase_candidates');
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 126px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center', // 中央寄せ
        gap: 2, // アイコンやテキスト間の余白
        py: 4, // 上下の余白
      }}>
      {/* アイコン */}
      <CheckCircle
        sx={{
          width: '80px',
          height: '80px',
          color: '#4caf50', // お好みのカラーに変更可
        }}
      />

      {/* 1行目 */}
      <Typography
        sx={{
          color: '#172B4D',
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: '72px',
        }}>
        買取査定の依頼が完了しました
      </Typography>

      {/* 2行目以降 */}
      <Typography
        sx={{
          color: '#172B4D',
          fontSize: '16px',
          lineHeight: '24px',
          mb: '40px',
        }}>
        提供いただいた機器情報に基づき、入力いただいたご連絡先へ、
        <br />
        FUJITA社より平日9~18時に順次ご連絡差し上げます。
        <br />
        <br />
        今しばらくお待ち下さい。
        <br />
        <br />
        （２営業日が経過してもFUJITA社から連絡が無い場合には、HITOTSU社までLinkでご連絡ください。）
      </Typography>

      {/* ボタン */}
      <Button variant="contained" color="primary" sx={{fontSize: '16px'}} onClick={handleBackToPurchaseRequestList}>
        機器売却査定一覧へ戻る
      </Button>
    </Box>
  );
};
