import {SxProps} from '@mui/material';
import {Theme} from '@mui/system';

export const styles: Record<string, SxProps<Theme>> = {
  root: {
    position: 'fixed',
    width: '100%',
    height: '48px',
    background: '#F2F6FC',
    padding: '24px 32px',
    gap: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '0',
  },
};
