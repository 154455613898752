import {axios} from '@front-libs/core';
import {CreatePurchaseRequestData} from './type';

export const createPurchaseRequest = async (reqData: CreatePurchaseRequestData) => {
  const {hospitalHashId, hospitalProductHashId} = reqData;
  const sendPurchaseProductRequestBaseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/purchase_request`;
  const {data} = await axios.post(sendPurchaseProductRequestBaseUrl, reqData);
  return data;
};
