import {ProductList} from '@Apps/ProductsList/sp';
import {InspectionResultList} from '@Apps/InspectionResultList/sp';
import React, {useEffect} from 'react';
import {InspectionResult} from '@Apps/InspectionResult/sp';
import {MobileProductDetail} from './Apps/ProductDetail';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {EmptyPage} from '@Apps/EmptyPage/sp';
import {SpPermissionRouter} from '@spPermissionRouter';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const SpRoutesAuthManager = () => {
  const {canRead: canAccessAsset} = useUserResourcesPermissions('LOGIN');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!canAccessAsset) {
      const currentUrl = `${location.pathname}${location.search}${location.hash}`;
      if (!currentUrl.includes('empty')) {
        navigate('/sp/empty', {replace: true});
      }
    }
  }, [canAccessAsset, location, navigate]);

  return <Outlet />;
};

// NOTE:スマートフォン用のルーティング
export const spRoutes = {
  path: '/sp',
  element: <SpRoutesAuthManager />,
  children: [
    {
      key: 'spProducts',
      path: 'products',
      element: (
        <SpPermissionRouter resource="PRODUCT_LIST">
          <ProductList />
        </SpPermissionRouter>
      ),
    },
    {
      key: 'spInspectionResults',
      path: 'inspection/results',
      element: (
        <SpPermissionRouter resource="INSPECTION">
          <InspectionResultList />
        </SpPermissionRouter>
      ),
    },
    {
      key: 'spInspectionResult',
      path: 'inspections/:inspectionHashId/result/:inspectionResultHashId',
      element: (
        <SpPermissionRouter resource="INSPECTION">
          <InspectionResult />
        </SpPermissionRouter>
      ),
    },
    {
      key: 'spProductDetail',
      path: 'products/:hashId',
      element: (
        <SpPermissionRouter resource="PRODUCT_LIST">
          <MobileProductDetail />
        </SpPermissionRouter>
      ),
    },
    {
      key: 'empty',
      path: 'empty',
      element: <EmptyPage />,
    },
  ],
};
