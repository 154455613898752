import React, {useState, useCallback, useMemo} from 'react';
import {Button, makeStyles, Grid, Popover} from '@material-ui/core';
import {Calendar} from '@material-ui/pickers';
import dayjs, {Dayjs} from 'dayjs';

const useDayjs = (d?: Date) => {
  return useMemo(() => (d ? dayjs(d).startOf('day') : undefined), [d]);
};

const useStyles = makeStyles((_theme) => ({
  container: {
    flex: 1,
  },
  dateButton: {
    width: '100%',
    height: '32px',
    backgroundColor: '#FAFBFC',
  },
}));

type DatePickerProps = {
  date?: Date;
  min?: Date;
  max?: Date;
  onChange?: (date: Date | null) => void;
};

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {date, min, max, onChange} = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickButton = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClickPopover = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    return false;
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange = useCallback(
    // eslint-disable-next-line no-shadow
    (date: Dayjs | null, _isFinish?: boolean) => {
      onChange && onChange(date ? date.startOf('day').toDate() : null);
      handleClose();
    },
    [handleClose, onChange]
  );

  const dayjsDate = useDayjs(date);
  const dayjsMin = useDayjs(min);
  const dayjsMax = useDayjs(max);
  const buttonText = useMemo(() => (dayjsDate ? dayjsDate.format('YYYY/MM/DD') : ''), [dayjsDate]);

  return (
    <Grid className={classes.container}>
      <Button className={classes.dateButton} variant="outlined" onClick={handleClickButton}>
        {buttonText}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClick={handleClickPopover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Calendar date={dayjsDate ?? dayjs()} minDate={dayjsMin} maxDate={dayjsMax} onChange={handleChange} />
      </Popover>
    </Grid>
  );
};
