import * as z from 'zod';

export const createPurchaseRequestRequestSchema = z
  .object({
    hospitalName: z.string({required_error: '医療機関名は必須です'}).min(1, '医療機関名を空にすることはできません'),
    requesterName: z.string({required_error: '氏名は必須です'}).min(1, '氏名を空にすることはできません'),
    departmentName: z.string({required_error: '所属部署名は必須です'}).min(1, '所属部署名を空にすることはできません'),

    // 連絡方法は "email" または "phone" のみを許容
    contactMethod: z.enum(['email', 'phone'], {required_error: 'ご希望の連絡方法は必須です'}),

    email: z.string().optional(),

    // phoneNumberは入力されていれば空文字でないことをチェック
    phoneNumber: z.string().optional(),

    // オプショナルなフィールド
    contactRemarks: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.contactMethod === 'phone' && !data.phoneNumber) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '電話番号は必須です',
        path: ['phoneNumber'],
      });
    }
    if (data.contactMethod === 'email' && !data.email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'メールアドレスは必須です',
        path: ['email'],
      });
    }
  });

export type CreatePurchaseRequestFormData = z.infer<typeof createPurchaseRequestRequestSchema>;
