import {useInitialSettingRedirect} from '@Apps/BaseProductManger/useInitalSettingRedirect';
import {Header} from '@components/organisms/Header/pc';
import {withSuspense} from '@front-libs/core';
import {Grid} from '@material-ui/core';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useTemplate} from '@templates/NoSideBarLayoutForPC';
import React from 'react';
import {Outlet} from 'react-router-dom';

type BaseProductManagerProps = {
  showHeader: boolean;
  children?: React.ReactNode;
};

const _BaseProductManager = ({children, showHeader}: BaseProductManagerProps) => {
  const templateClasses = useTemplate();
  const {isAdmin} = useMyRole();

  useInitialSettingRedirect();
  return (
    <Grid container className={templateClasses.root}>
      {showHeader && <Header className={templateClasses.header} canSetting={isAdmin} />}
      <Grid container className={showHeader ? templateClasses.content : templateClasses.noHeaderContent}>
        {children}
        <Outlet />
      </Grid>
    </Grid>
  );
};

export const BaseProductManager = withSuspense(_BaseProductManager, null);
