import React, {useCallback, useMemo} from 'react';
import {FolderOpen, ShowChart, Apps} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {AppMenu} from './AppMenu';
import {makeStyles, createStyles} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {BarcodeScan} from '@atoms/Icons/barcodeScan';
import {HospitalProductMenuDialog} from './HospitalProductMenu/HospitalProductMenuDialog';
import {useCanAccessMgmtDashboard, useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export const GeneralMenu: React.FC = () => {
  const {canRead: canReadWardService} = useUserResourcesPermissions('WARD_SERVICE');
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');
  const {canEdit: canEditInspection} = useUserResourcesPermissions('INSPECTION');
  const {canRead: canReadRepair} = useUserResourcesPermissions('REPAIR');
  const canReadManagementDashboard = useCanAccessMgmtDashboard();
  const classes = useStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();

  const menus = useMemo(() => {
    return [
      {icon: <FolderOpen />, label: 'ファイル一覧', value: 'fileList', isPermitted: true},
      {
        icon: <ShowChart />,
        label: '経営ダッシュボード',
        value: 'managementDashboard',
        isPermitted: canReadManagementDashboard,
      },
      {
        icon: <BarcodeScan />,
        label: '機器メニュー',
        value: 'hospitalProductMenus',
        isPermitted: canReadProductList || canEditInspection || canReadRepair,
      },
      {icon: <Apps />, label: '病棟ユーザーメニュー', value: 'wardMenus', isPermitted: canReadWardService},
    ] as const;
  }, [myInfo, canReadWardService]);

  type MenuKeys = (typeof menus)[number]['value'];

  const menuLinkMap: Record<MenuKeys, string> = {
    fileList: '/general_menu/file_list',
    managementDashboard: '/general_menu/management_dashboard',
    hospitalProductMenus: '',
    wardMenus: '/shared/reception_menu',
  };

  const openHospitalProductMenuDialog = async () => {
    try {
      await dialogHandler.open(HospitalProductMenuDialog, {});
    } catch (_e) {
      return false;
    }
    return true;
  };

  const openMenuDialog = (key: MenuKeys) => {
    switch (key) {
      case 'hospitalProductMenus':
        openHospitalProductMenuDialog();
        break;
    }
  };

  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      const key = item.value as MenuKeys;
      const link = menuLinkMap[key];
      if (!link) {
        openMenuDialog(key);
        return;
      }
      navigate(link);
    },
    [navigate]
  );

  const menuItemList = useMemo(
    () =>
      menus
        .filter((item) => item.isPermitted)
        .map((item) => ({
          label: (
            <>
              {item.icon}
              <span style={{paddingLeft: 8}}>{item.label}</span>
            </>
          ),
          value: item.value,
        })),
    [menus]
  );

  return (
    <PopperMenuButton
      menuItemList={menuItemList}
      onMenuClick={handleMenuClick}
      hiddenArrow
      buttonProps={{variant: 'text', size: 'small', disableElevation: true, className: classes.generalMenuButton}}>
      <AppMenu />
    </PopperMenuButton>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    generalMenuButton: {
      color: '#46484B',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      minWidth: '24px',
    },
  })
);
