import {Paper, styled} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {DashboardMenu, TabMenuKeys} from './components';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {usePermittedRedirectPath, useCanAccessMgmtDashboard} from '@modules/hospital_users/hooks/useUserPermissions';

const tabRouteMap: Record<TabMenuKeys, string> = {
  // cost_management: '/general_menu/management_dashboard/cost_management',
  rental_ratio: '/general_menu/management_dashboard/rental_ratio',
  // repair_ratio: '/general_menu/management_dashboard/repair_ratio',
};

const routeTabMap: Record<string, TabMenuKeys> = {
  // '/general_menu/management_dashboard/cost_management': 'cost_management',
  '/general_menu/management_dashboard/rental_ratio': 'rental_ratio',
  // '/general_menu/management_dashboard/repair_ratio': 'repair_ratio',
};

export const ManagementDashboard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabMenu, setTabMenu] = useState<TabMenuKeys>(routeTabMap[location.pathname] ?? 'rental_ratio');
  const redirectPath = usePermittedRedirectPath();
  const canAccessDashboard = useCanAccessMgmtDashboard();

  const handleChangeTab = useCallback(
    (value: TabMenuKeys) => {
      setTabMenu(value);
      navigate(tabRouteMap[value]);
    },
    [navigate]
  );

  useEffect(() => {
    // MEMO:初回遷移
    handleChangeTab(tabMenu);
  }, []);

  if (!canAccessDashboard) {
    return <Navigate to={redirectPath} replace />;
  }
  return (
    <DashboardRoot>
      <PageTitle>経営ダッシュボード</PageTitle>
      <Paper style={{width: '100%'}}>
        <DashboardMenu value={tabMenu} onChangeTab={handleChangeTab} />
        <Outlet />
      </Paper>
    </DashboardRoot>
  );
};

const DashboardRoot = styled('div')({
  margin: 24,
  width: '100%',
});

const PageTitle = styled('div')({
  fontSize: 20,
  fontWeight: 700,
  marginBottom: 24,
});
