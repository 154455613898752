import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {useHeaderNavLinkItems} from '@Apps/BaseProductManger/useHeaderNavLinkItems';

/**
 * ページ内ヘッダーの項目名クリック時に各種機能に遷移できるポップアップメニュー
 * @param children
 * @returns
 */
export const PageRoutingMenu: React.FC = ({children}) => {
  const navigate = useNavigate();
  const navLinkItems = useHeaderNavLinkItems();
  const pageRoutingMenuItems = React.useMemo(
    () =>
      navLinkItems.map((v) => ({
        label: v.label,
        value: v.to,
      })),
    [navLinkItems]
  );

  const handlePageRoutingMenuClick = useCallback(
    (item: MenuItemType) => {
      if (item.value) {
        navigate(item.value);
      } else {
        navigate('/');
      }
    },
    [navigate]
  );

  return (
    <PopperMenuButton
      placement="bottom-start"
      hiddenArrow={true}
      buttonProps={{variant: 'text', style: {width: '100%'}}}
      menuItemList={pageRoutingMenuItems}
      itemProps={{style: {minWidth: '350px'}}}
      onMenuClick={handlePageRoutingMenuClick}>
      {children}
    </PopperMenuButton>
  );
};
