import React from 'react';
import {Grid, Theme, styled} from '@material-ui/core';
import {Outlet} from 'react-router-dom';
import {withSuspense} from '@front-libs/core';
import {Helmet} from 'react-helmet-async';
import {PermissionRouter} from '@permissionRouter';
import {useInitialSettingRedirect} from '@Apps/BaseProductManger/useInitalSettingRedirect';

export type ChildCommonProps = {
  onEnableNextLink: () => void;
  onDisableNextLink: () => void;
  onSetNextLink: (path: string) => void;
};

/**
 *
 * @todo Mui V4とV5の混在でスタイルが適用されないことがあるのでMuiV4のBaseを一旦親とする。 MuiV5統一時にBaseWardMenuV5と入れ替える
 */
const _BaseWardMenu: React.FC = () => {
  useInitialSettingRedirect();

  return (
    <PermissionRouter resource="WARD_SERVICE">
      <StyledGrid>
        <Helmet>
          <style>
            {`
              iframe {
                display: none !important;
              }
          `}
          </style>
        </Helmet>
        <Outlet />
      </StyledGrid>
    </PermissionRouter>
  );
};

export const BaseWardMenu = withSuspense(_BaseWardMenu);

const StyledGrid = styled(Grid)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.grey[50],
  overflow: 'hidden',
}));
