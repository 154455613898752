import React from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme, Grid, Button, Paper, useMediaQuery} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import {HITOTSULogo} from '@atoms/HITOTSULogo';
import {HITOTSULogoSmall} from '@atoms/HITOTSULogo/Small';
import {NavLink, useNavigate} from 'react-router-dom';
import {HeaderMenu} from './HeaderMenu';
import {UserNotification} from './UserNotification';
import {ProfileMenu} from './ProfileMenu';

import {GeneralMenu} from './GeneralMenu';
import {PmdaNotification} from './PmdaNotification';
import {SupportPageButton} from '@components/molecules/Buttons/SupportPageButton';
import {LinkNotificationLogo} from '@components/molecules/LinkNotificationLogo';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {useNotificationSetting} from '@Apps/Account/NotificationSetting/consts';

export type NavLinkType = {
  to: string;
  label: string;
  childLinks?: {
    to: string;
    label: string;
    hasPermission?: boolean;
  }[];
  hasPermission?: boolean;
};

type Props = {
  className?: string;
  canSetting: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: theme.palette.common.white,
      paddingLeft: '16px',
      paddingRight: '4px',
      [theme.breakpoints.up('laptop')]: {
        paddingRight: '48px',
      },
      [theme.breakpoints.up('tabletH')]: {
        paddingRight: '16px',
      },
    },
    logoArea: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '4px',
      cursor: 'pointer',
      [theme.breakpoints.up('tabletH')]: {
        paddingRight: '16px',
      },
    },

    linkContent: {
      textDecoration: 'none',
      color: theme.palette.common.black,
    },
    linkIcon: {
      color: theme.palette.grey[700],
    },

    linkedButtonIcon: {
      height: '100%',
      borderRadius: 0,
      paddingLeft: '8px',
      paddingRight: '8px',
      minWidth: '24px',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    verticalBorder: {
      marginTop: '8px',
      marginBottom: '8px',
      // FIXME: 存在しないグレー
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    flex: {
      flexGrow: 1,
    },
  })
);

export const Header = ({className, canSetting}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');
  const handleLogoClick = () => {
    navigate('/');
  };

  const matches = useMediaQuery('(max-width:1024px)');

  return (
    <Paper elevation={2} square className={className ? clsx(classes.root, className) : classes.root}>
      <Grid container style={{height: 'inherit'}}>
        <Grid item className={classes.logoArea} onClick={handleLogoClick}>
          {matches ? <HITOTSULogoSmall /> : <HITOTSULogo />}
        </Grid>
        <Grid item>
          <HeaderMenu />
        </Grid>
        <div className={classes.flex} />
        <LinkNotificationLogo tooltipOffset={[0, -20]} hasBorder={true} />
        <span className={classes.verticalBorder} />
        <GeneralMenu />
        {/* 機器一覧の閲覧権限がある場合にPMDA通知を表示 */}
        {canReadProductList && <PmdaNotification />}
        <UserNotification />
        <SupportPageButton />
        {canSetting && (
          <>
            {!matches && <span className={classes.verticalBorder} />}
            <NavLink to={'/settings/hospital'} className={classes.linkContent}>
              <Button
                color="inherit"
                className={classes.linkedButtonIcon}
                size="small"
                data-testid="global-nav-settings">
                <Settings className={classes.linkIcon} />
              </Button>
            </NavLink>
          </>
        )}
        <ProfileMenu smallViewport={matches} />
      </Grid>
    </Paper>
  );
};
