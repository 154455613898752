import {useInitialSettingRedirect} from '@Apps/BaseProductManger/useInitalSettingRedirect';
import {withSuspense} from '@front-libs/core';
import {Grid, SxProps, Theme} from '@mui/material';
import {PermissionRouter} from '@permissionRouter';
import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Outlet} from 'react-router-dom';

export type ChildCommonProps = {
  onEnableNextLink: () => void;
  onDisableNextLink: () => void;
  onSetNextLink: (path: string) => void;
};

const _BaseSharedMenu: React.FC = () => {
  useInitialSettingRedirect();
  return (
    <PermissionRouter resource="LENDING_RETURN_RECEPTION">
      <Grid container sx={rootStyle}>
        <Helmet>
          <style>
            {`
              iframe {
                display: none ;
              }
          `}
          </style>
        </Helmet>
        <Outlet />
      </Grid>
    </PermissionRouter>
  );
};

export const BaseSharedMenu = withSuspense(_BaseSharedMenu);

const rootStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.grey[50],
  overflow: 'hidden',
  '@media (orientation: portrait)': {
    height: '100vw ',
    width: '100vh ',
    minHeight: '100vw ',
    transform: 'rotate(90deg)',
    position: 'absolute',
    top: 'calc((100vh - 100vw) / 2)',
    right: 'calc(0px - (100vh - 100vw) / 2)',
    overflow: 'auto',
  },
});
