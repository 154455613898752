import React from 'react';
import {Button, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    managementId: {
      padding: '0px',
      display: 'inline',
      color: theme.palette.info.dark,
      fontSize: '14px',
      fontWeight: 'bold',
    },
    managementIdNotLink: {
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '0px',
      display: 'inline',
      fontSize: '14px',
    },
  })
);

type Props = {
  managementId: string;
  faultHospitalProductHashId: string;
};

/**
 * 修理の管理番号押下時の対応
 * @param props
 * @returns
 */
export const RouterLinkColumn = ({managementId, faultHospitalProductHashId}: Props) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/products/${faultHospitalProductHashId}`);
  };

  return (
    <Button onClick={canReadProductList ? onClick : undefined} className={classes.managementId}>
      <Typography className={canReadProductList ? classes.managementId : classes.managementIdNotLink}>
        {managementId}
      </Typography>
    </Button>
  );
};
