import {useCallback, useMemo, useState} from 'react';
import {useAtomValue} from 'jotai';
import {hospitalProductsVariables, pageSizeAtom, searchFilterResultsAtom} from './jotai';
import {useFetchHospitalProductsQuery} from '@modules/hospital_products/api';
import {useGetHospitalCategories, useGetHospitalSomeDescendantCategories} from '@modules/categories/api';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {BulkRentDialog} from './Dialogs/BulkRentDialog';
import {HospitalRentalSetting, HospitalSettingsIndex, rentalSettings} from '@modules/hospital_settings/types';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {openSnackBar} from '@front-libs/ui';
import {bulkCheckout, CheckoutRentalData} from '@modules/rentals/api';
import {undefinedRentRoom} from '@modules/hospital_places/types';
import {isNullish} from '@front-libs/helpers';
import {ForceRentalAlertDialog} from './Dialogs/ForceRentalConfirmDialog';
import {isNumber} from 'lodash';

export const useHospitalProductsQuery = (hospitalHashId: string) => {
  const variables = useAtomValue(hospitalProductsVariables);
  const pageSize = useAtomValue(pageSizeAtom);
  const query = useFetchHospitalProductsQuery(hospitalHashId, variables);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [query.totalCount, pageSize]);

  return {...query, totalPage};
};

export const useIsDetailFilterActive = () => {
  const searchFilterResults = useAtomValue(searchFilterResultsAtom);
  return searchFilterResults.length > 0;
};

export const useCategoryQuery = (hospitalHashId: string) => {
  const [selectedRootCategory, setSelectedRootCategory] = useState<string[]>([]);
  const rootCategoryQuery = useGetHospitalCategories(hospitalHashId, {depth: 0});
  const narrowCategoryQuery = useGetHospitalCategories(hospitalHashId, {depth: 1});

  const descendantCategoriesQueries = useGetHospitalSomeDescendantCategories(hospitalHashId, selectedRootCategory);

  const selectRootCategory = useCallback((categoryHashIds: string[] | null) => {
    setSelectedRootCategory(categoryHashIds ?? []);
  }, []);
  return {rootCategoryQuery, narrowCategoryQuery, descendantCategoriesQueries, selectRootCategory};
};

type UseBulkCheckoutHospitalProductsProps = {
  hospitalSettings?: HospitalSettingsIndex;
  myInfo: {hospitalHashId: string; hashId: string};
};

export const useBulkCheckoutHospitalProducts = (props: UseBulkCheckoutHospitalProductsProps) => {
  const {hospitalSettings, myInfo} = props;

  // 病院の設定から、機器の貸出に関する設定を取得
  const {
    deviceLenderSetting,
    canForceRentalForPreInspectionPeriod,
    canForceRentalForOverdueInspection,
    isRestrictLendingOnOverdueInspection,
    preInspectionRentalRestrictionPeriod,
  } = useMemo(() => {
    const deviceLenderSetting = (hospitalSettings?.data?.find((x) => x.key === rentalSettings.use_device_lender.key)
      ?.value ?? 'not_need') as HospitalRentalSetting;

    const canForceRentalForPreInspectionPeriod =
      hospitalSettings?.data?.find(
        (x) => x.key === rentalSettings.allow_force_rental_for_pre_inspection_restriction_period.key
      )?.value === 'true';
    const canForceRentalForOverdueInspection =
      hospitalSettings?.data?.find(
        (x) => x.key === rentalSettings.allow_force_rental_for_restriction_overdue_inspection.key
      )?.value === 'true';
    const isRestrictLendingOnOverdueInspection =
      hospitalSettings?.data?.find((x) => x.key === rentalSettings.restrict_lending_on_overdue_inspection.key)
        ?.value === 'true';
    const preInspectionRentalRestrictionPeriod = hospitalSettings?.data?.find(
      (x) => x.key === rentalSettings.pre_inspection_rental_restriction_period.key
    )?.value;

    return {
      deviceLenderSetting,
      canForceRentalForPreInspectionPeriod,
      canForceRentalForOverdueInspection,
      isRestrictLendingOnOverdueInspection,
      preInspectionRentalRestrictionPeriod: isNumber(preInspectionRentalRestrictionPeriod)
        ? Number(preInspectionRentalRestrictionPeriod)
        : undefined,
    };
  }, [hospitalSettings?.data]);

  const bulkCheckoutHospitalProducts = useCallback(
    async (hospitalProducts: HospitalProductIndex[]) => {
      const res = await dialogHandler.open(BulkRentDialog, {
        lenderSetting: deviceLenderSetting,
      });

      const rentData = hospitalProducts.filter((item) => item.status === 'ready' && !item.permanentlyAssigned);
      if (rentData.length === 0) {
        openSnackBar('選択された機器の中で、貸出可能な機器がありません。', 'error');
        return;
      }

      // 強制貸出が許可されていない場合は早期リターン
      const needForceRental =
        (isRestrictLendingOnOverdueInspection || (preInspectionRentalRestrictionPeriod ?? 0) > 0) &&
        rentData.some((item) => (item.numberPeriodInspectionResultsInPeriod ?? 0) > 0);

      if (needForceRental && !canForceRentalForPreInspectionPeriod && !canForceRentalForOverdueInspection) {
        openSnackBar('貸出登録に失敗しました。', 'error');
        return;
      }

      // 強制貸出が許可されている場合、強制貸出を行うか確認する
      if (needForceRental) {
        try {
          await dialogHandler.open(ForceRentalAlertDialog, {
            includeNearInspection: canForceRentalForPreInspectionPeriod,
          });
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        } catch (_e: any) {
          return;
        }
      }

      const param: CheckoutRentalData = {
        hospitalRoomHashId: res.hospitalRoomHashId === undefinedRentRoom.value ? undefined : res.hospitalRoomHashId,
        isForcedRental: needForceRental,
        checkoutDeviceLender:
          isNullish(res.checkoutDeviceLenderName) || res.checkoutDeviceLenderName === ''
            ? undefined
            : {
                userName: res.checkoutDeviceLenderName,
              },
        checkoutUserHashId: myInfo.hashId,
        hospitalProductHashIds: rentData.map((item) => item.hashId),
      };

      try {
        await bulkCheckout(myInfo.hospitalHashId, param);
        openSnackBar('貸出登録が完了しました。');
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } catch (_e: any) {
        openSnackBar('貸出登録に失敗しました。', 'error');
      }
    },
    [
      deviceLenderSetting,
      canForceRentalForPreInspectionPeriod,
      canForceRentalForOverdueInspection,
      isRestrictLendingOnOverdueInspection,
      preInspectionRentalRestrictionPeriod,
      myInfo.hashId,
      myInfo.hospitalHashId,
    ]
  );

  return {bulkCheckoutHospitalProducts};
};
