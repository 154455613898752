import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {
  HospitalSettingKey,
  HospitalSettingsDetail,
  ReturnWaitingReceptionPutParams,
  ReturnWaitingReceptionResponse,
  UpdateHospitalSettingsParams,
} from './types';

const getHospitalSettings = async (hospitalHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/settings`;
  const {data} = await axios.get<HospitalSettingsDetail>(baseUrl);
  return data;
};

/**
 * 設定の病棟ユーザーメニューのメニューの表示/非表示設定
 * @param hospitalHashId
 * @param data
 * @returns
 */
export const updateHospitalSettings = async (hospitalHashId: string, data: UpdateHospitalSettingsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/settings`;
  return axios.post<HospitalSettingsDetail>(baseUrl, data);
};

export const useFetchHospitalSettingsQuery = (hospitalHashId: string) => {
  return useQuery([hospitalHashId, 'hospitalSettings'], () => getHospitalSettings(hospitalHashId), {
    // 以下の変更があったときのみ再レンダリングする
    notifyOnChangeProps: ['data', 'isLoading', 'refetch'],
    refetchOnWindowFocus: false,
  });
};

export const useFetchHospitalSettingQuery = (hospitalHashId: string, key: HospitalSettingKey) => {
  const query = useFetchHospitalSettingsQuery(hospitalHashId);

  return {
    ...query,
    data: query.data ? query.data.data.find((setting) => setting.key === key) : null,
  };
};

/**
 * 設定 > 病棟ユーザーメニュー > 「返却待ち受付」の詳細設定の 受付可能な機器（小分類）の設定
 * @param hospitalHashId
 * @returns
 */
const getAcceptableProductsForReturnWaitingReceptions = async (hospitalHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/settings/return_waiting_receptions/acceptable_products`;
  const {data} = await axios.get<ReturnWaitingReceptionResponse>(baseUrl);
  return data;
};

/**
 * 設定 > 病棟ユーザーメニュー > 「返却待ち受付」の詳細設定の 受付可能な機器（小分類）の設定のPost
 * @param hospitalHashId
 * @param param
 * @returns
 */
export const putAcceptableProductsForReturnWaitingReceptions = async (
  hospitalHashId: string,
  param: ReturnWaitingReceptionPutParams
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/settings/return_waiting_receptions/acceptable_products`;
  return await axios.put(baseUrl, param);
};

/**
 * 設定 > 病棟ユーザーメニュー > 「返却待ち受付」の詳細設定の 受付可能な機器（小分類）の設定のカスタムフック
 * @param hospitalHashId
 * @returns
 */
export const useFetchAcceptableProductsForReturnWaitingReceptions = (hospitalHashId: string) => {
  return useQuery(
    [hospitalHashId, 'acceptableProductsForReturnWaitingReceptions'],
    () => getAcceptableProductsForReturnWaitingReceptions(hospitalHashId),
    {
      enabled: !!hospitalHashId,
    }
  );
};
