import React, {useState, useEffect, useMemo} from 'react';
import {Box, IconButton} from '@mui/material';
import {MenuBookOutlined} from '@mui/icons-material';
import {PmdaIcon} from '@assets/PmdaIcon';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export type ProductTabType = 'pmdaFile' | 'file' | 'trainingSchedule';
type Tab = {id: ProductTabType; label: string; icon: JSX.Element};

type ProductTabsProps = {
  onChangeTab: (tab: ProductTabType) => void;
  defaultTab: ProductTabType;
};
export const ProductTabs = ({onChangeTab, defaultTab}: ProductTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<ProductTabType>(defaultTab);
  const {canRead: canReadTraining} = useUserResourcesPermissions('TRAINING');

  const tabs: Tab[] = useMemo(() => {
    const defaultTab = [
      // FIXME: 機種共通ファイルの実装は初期リリーススコープ外
      //   {
      //     id: 'file',
      //     label: '機種共通ファイル',
      //     icon: <FolderOutlinedIcon sx={{width: '20px'}} />,
      //   },
      {
        id: 'pmdaFile' as ProductTabType,
        label: '添付文書',
        icon: <PmdaIcon sx={{width: '20px'}} />,
      },
    ];
    if (canReadTraining) {
      defaultTab.push({
        id: 'trainingSchedule' as ProductTabType,
        label: '研修',
        icon: <MenuBookOutlined sx={{width: '20px'}} />,
      });
    }
    return defaultTab;
  }, [canReadTraining]);

  useEffect(() => {
    onChangeTab(selectedTab);
  }, [selectedTab]);

  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
      {tabs.map((tab) => (
        <IconButton
          key={tab.id}
          onClick={() => setSelectedTab(tab.id)}
          sx={{
            borderRadius: '4px',
            padding: '2px 4px',
            marginBottom: '16px',
            fontSize: '14px',
            backgroundColor: selectedTab === tab.id ? '#DAE5F7' : 'transparent',
            fontWeight: selectedTab === tab.id ? 'bold' : 'normal',
            color: selectedTab === tab.id ? '#0052CC' : 'rgba(0, 0, 0, 0.87)',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: selectedTab === tab.id ? '#DAE5F7' : '#d5d5d5',
            },
            '& svg': {margin: '0 4px'},
          }}
          aria-label={tab.label}>
          {tab.icon}
          {tab.label}
        </IconButton>
      ))}
    </Box>
  );
};
