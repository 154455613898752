import {atom} from 'jotai';
export const onFocusAtom = atom(false);
// NOTE:@components/molecules/SearchBox/sp/index.tsxの検索文言を保持
export const searchTextChangeAtom = atom<string | null>(null);

/** スマホの稼働状況フィルターのチェック状態を保持する */
export const filterCheckListAtom = atom<{[key: string]: boolean}>({});
// NOTE:@Apps/ProductsList/sp/ToolBar.tsxのCallbackで受け取る検索文言を保持
export const searchNameAtom = atom<string | null>(null);
export const orderAtom = atom<string>('managementId');
