import React, {useCallback, useMemo} from 'react';
import {Tabs, Tab, Grid, makeStyles, Box} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import qs from 'qs';
import {useSearchParams} from '@front-libs/core';
import {TrainingReports} from './TrainingReports';
import TraineeContents from './Trainee';
import {Materials} from './Materials';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import {drawerAtom} from '../state';
import {useUpdateAtom} from 'jotai/utils';

const tabs = [
  {
    label: '実施記録',
    value: 'history',
  },
  {
    label: '研修対象者・受講者',
    value: 'traineeList',
  },
  {
    label: '研修資材',
    value: 'materials',
  },
];

const useStyles = makeStyles((theme) => {
  const tabsContainerHeight = '48px';

  return {
    root: {
      alignContent: 'flex-start',
      padding: '24px',
      height: '100%',
    },
    tabsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: tabsContainerHeight,
      width: '100%',
    },
    tabContent: {
      height: `calc(100% - ${tabsContainerHeight})`,
      width: '100%',
    },
    traineeList: {
      marginTop: '16px',
      height: `calc(100% - 16px)`,
    },
    drawerBtn: {
      [theme.breakpoints.up('laptop')]: {
        display: 'none',
      },
    },
  };
});

type Props = {
  trainingScheduleHashId?: string;
};

export const TrainingScheduleDetail: React.FC<Props> = ({trainingScheduleHashId}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {tab = tabs[0].value} = useSearchParams();
  const changeTab = useCallback(
    (_, newTab: string) => {
      navigate({search: qs.stringify({tab: newTab}, {arrayFormat: 'brackets'})});
    },
    [navigate]
  );
  const setDrawerOpen = useUpdateAtom(drawerAtom);

  const handleOnClick = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const tabContent = useMemo(() => {
    if (tab === 'history') {
      return <TrainingReports />;
    } else if (tab === 'traineeList') {
      return (
        <Box className={classes.traineeList}>
          <TraineeContents trainingScheduleHashId={trainingScheduleHashId ?? ''} />
        </Box>
      );
    } else if (tab === 'materials') {
      return <Materials trainingScheduleHashId={trainingScheduleHashId ?? ''} />;
    }
  }, [tab, trainingScheduleHashId]);

  return (
    <Grid container className={classes.root}>
      <Box className={classes.tabsContainer}>
        <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
          {tabs.map((item, index) => (
            <Tab label={item.label} value={item.value} key={index} />
          ))}
        </Tabs>
        <VerticalSplitIcon fontSize="large" className={classes.drawerBtn} onClick={handleOnClick} />
      </Box>
      <Box className={classes.tabContent}>{tabContent}</Box>
    </Grid>
  );
};
