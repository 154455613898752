import React from 'react';
import {Box, styled} from '@mui/material';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {useNavigateRentOrReturn} from './hooks';
import {useDetectCameraEffect} from '../hooks/useDetectCameraEffect';
import {rentOrReturnSettingAtom} from '../states';
import {useAtomValue} from 'jotai';
import {SquareButton} from '@components/molecules/Buttons/SquareButton';

export const RentOrReturn = () => {
  const {proceedRent, proceedReturn} = useNavigateRentOrReturn();
  const rentOrReturnSetting = useAtomValue(rentOrReturnSettingAtom);

  useDetectCameraEffect();

  return (
    <>
      <Header showLogo />
      <ContainerGrid>
        {rentOrReturnSetting !== 'onlyReturnMenu' && (
          <ItemGrid>
            <SquareButton label={'貸出'} onChange={proceedRent} />
          </ItemGrid>
        )}
        {rentOrReturnSetting !== 'onlyRentalMenu' && (
          <ItemGrid>
            <SquareButton label={'返却'} onChange={proceedReturn} />
          </ItemGrid>
        )}
      </ContainerGrid>
      <Footer text={'選択してください'} backButtonText={'貸出・返却一覧に戻る'} backButtonSize="large" />
    </>
  );
};

const ContainerGrid = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '96px',
  width: '100%',
  // Header104px Footer148px
  height: 'calc(100vh - 104px - 148px)',
  [theme.breakpoints.up('laptop')]: {
    height: 'calc(100vh - 104px - 168px)',
    gridGap: '128px',
  },
}));

const ItemGrid = styled(Box)({
  width: '100%',
  height: '100%',
  maxWidth: '384px',
  maxHeight: '368px',
});
