import {useMutation, useQuery} from 'react-query';
import {bulkUpdateNotifications, BulkUpdateNotificationsParams, getNotifications} from './api';
import {NotificationCategory, NotificationStatus} from './types';

export const useNotificationQuery = (
  key: string,
  hospitalHashId: string,
  hospitalUserHashId: string,
  params: {
    categories?: NotificationCategory[];
    statuses?: NotificationStatus[];
    createdAtFrom?: Date | null;
    createdAtTo?: Date | null;
  } = {categories: [], statuses: [], createdAtFrom: null, createdAtTo: null},
  page = 0,
  perPage = 100
) => {
  const enabled = import.meta.env.VITE_APP_ENV !== 'local' || import.meta.env.VITE_DEBUG_USER_NOTIFICATION === 'true';
  const getNotificationsQuery = useQuery(
    [key, hospitalUserHashId, params.categories],
    () =>
      getNotifications(
        hospitalHashId,
        hospitalUserHashId,
        params.statuses,
        params.categories,
        params.createdAtFrom,
        params.createdAtTo,
        page,
        perPage
      ),
    {enabled: enabled && params && params.categories && params.categories.length > 0}
  );
  return getNotificationsQuery;
};

export const useNotificationMutation = () => {
  const bulkUpdateNotificationsMutation = useMutation((param: BulkUpdateNotificationsParams) =>
    bulkUpdateNotifications(param)
  );
  return {bulkUpdateNotificationsMutation};
};
