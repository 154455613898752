import {HeaderBackwardButton} from '@components/molecules/Buttons/HeaderBackwardButton';
import {ErrorOutline} from '@mui/icons-material';
import {Alert, Box, SxProps, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const headerLabelStyle: SxProps = {
  fontSize: '18px',
  fontWeight: 'bold',
  textWrap: 'no-wrap',
};

const headerComponentStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '32px',
};

export const Header = () => {
  const navigate = useNavigate();
  return (
    <Box sx={headerComponentStyle}>
      <Box>
        <HeaderBackwardButton label="機器一覧" onClick={() => navigate('/products')} />
        <Typography variant="h1" sx={headerLabelStyle}>
          機器売却査定
        </Typography>
      </Box>
      <Box>
        <Alert
          severity={'warning'}
          icon={<ErrorOutline />}
          sx={{
            color: '#C77700',
            border: '1px solid #C77700',
            padding: '0 8px',
            fontSize: '14px',
            '.MuiAlert-icon': {mr: '4px'},
          }}>
          買取査定可能な機器の一覧を表示しています。
          <Typography component="span" fontWeight={700} fontSize="14px" color="#C77700">
            査定・買取は無料です。
          </Typography>
          「買取査定依頼へ進む」ボタンから今後の流れの確認、および、必要事項入力の上、査定を依頼いただけます。
        </Alert>
      </Box>
    </Box>
  );
};
