import React from 'react';
import {FolderOutlined, PeopleOutlined} from '@mui/icons-material';
import {Options} from '@components/molecules/DisplayNumberSelect';
import {MEIDAI_HIT_5278_TRAINING_SCHEDULE_TRAINEES} from '@constants/constants';
import {Tab} from './types';

export enum localStorageKeys {
  traineesPerPage = 'hitotsu/training_schedule_trainees_page_size_status',
}

export const tabValues = ['list', 'file'] as const;

export const tabs: readonly Tab[] = [
  ...(MEIDAI_HIT_5278_TRAINING_SCHEDULE_TRAINEES
    ? ([
        {
          icon: <PeopleOutlined />,
          label: 'リスト',
          value: 'list',
        },
      ] as Tab[])
    : []),
  {
    icon: <FolderOutlined />,
    label: 'ファイル',
    value: 'file',
  },
] as const;

export const initTabValue = tabs[0].value;

export const perPageOptionValues: readonly number[] = [20, 50, 100] as const;

export const perPageOptions: Options = perPageOptionValues.map((value) => ({
  label: `${value}件`,
  value,
}));

export const TraineeUpdateMessages = {
  succeeded: '研修対象者・受講者情報を保存しました。',
  failed: '研修対象者・受講者情報の保存に失敗しました。',
} as const;
export const TraineeDeleteMessages = {
  succeeded: '研修対象者・受講者情報を削除しました。',
  failed: '研修対象者・受講者情報の削除に失敗しました。',
} as const;
