import React from 'react';
import {Navigate} from 'react-router-dom';
import {usePermittedForSp} from './modules/hospital_users/hooks/useUserPermissions';
import {PermissionResourceKey} from './modules/hospital_users/types';

type SpPermissionRouterRouteProps = {
  children: JSX.Element;
  resource: PermissionResourceKey;
};

/**
 * SP用権限管理を行うルーティングコンポーネント
 * packages/front-app-pc/src/spRoutes.tsxで使用することを想定
 *
 * 指定されたリソースと権限レベルに基づき、ユーザーがアクセス可能かどうかを判定します。
 * 権限が不足している場合、権限のあるページに遷移します
 *
 * @param children - 権限がある場合に表示するコンテンツ。
 * @param resource - 権限を確認する対象のリソース名（PermissionResourceKey 型）。
 *
 *
 * @example
 * ```tsx
 * export const spRoutes = {
 *   {
 *     key: 'spProducts',
 *     path: 'products',
 *     element: (
 *       <SpPermissionRouter resource="PRODUCT_LIST">
 *         <ProductList />
 *       </SpPermissionRouter>
 *     ),
 *   },
 * ```
 */
export const SpPermissionRouter = ({children, resource}: SpPermissionRouterRouteProps) => {
  const {canReadProductList, canReadInspection} = usePermittedForSp();

  // どちらのリソースも閲覧不可の場合空ページにリダイレクト
  if (!canReadProductList && !canReadInspection) {
    return <Navigate to="/sp/empty" replace />;
  }

  if ((canReadProductList && resource === 'PRODUCT_LIST') || (canReadInspection && resource === 'INSPECTION')) {
    return children;
  }

  const redirectPath = canReadProductList ? '/sp/products' : '/sp/inspection/results';
  return <Navigate to={redirectPath} replace />;
};
