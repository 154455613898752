import {EnhancedColumn} from '@front-libs/ui';
import {ReturnWaitingReceptionsResponse} from '@modules/returns_reservation/type';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import EditIcon from '@mui/icons-material/Edit';
import {Button, SxProps} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {StatusIcon} from '../BaseSharedMenu/StatusIcon';
import {TooltipMessage} from '../BaseSharedMenu/TooltipMessage';
import {Typography} from '@material-ui/core';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export type ResponseAddComponent = ReturnWaitingReceptionsResponse & {editButton?: string};
type ColumnType = EnhancedColumn<ResponseAddComponent>;

type EditButtonProps = {
  item: ResponseAddComponent;
  onClick: (responseAddComponent: ResponseAddComponent) => void;
};
const EditButton = ({onClick, item}: EditButtonProps) => {
  return (
    <Button sx={{padding: 0, color: '#0052CC'}} startIcon={<EditIcon />} onClick={() => onClick(item)}>
      編集
    </Button>
  );
};

const StatusIconSx: SxProps = {
  width: '80px',
  fontSize: '12px',
};
const StatusTextSx: SxProps = {
  paddingTop: '2px',
};

export const useColumns = () => {
  const [tableLayout, setTableLayout] = useTableLayout('returnWaitingReceptionList', {
    status: true,
    receptionAt: true,
    narrowCategoryName: true,
    useRoom: true,
  });
  const [editItem, setEditItem] = React.useState<ResponseAddComponent | null>(null);
  const {canEdit: canEditRentals} = useUserResourcesPermissions('RENTAL');
  const editButtonLayout: ColumnType[] = canEditRentals
    ? [
        {
          title: '対応内容の編集',
          field: 'editButton',
          sx: {width: '100px', borderRight: '1px solid #E0E0E0'},
          fieldValue: (item) => (
            <EditButton
              item={item}
              onClick={(item) => {
                setEditItem(item);
              }}
            />
          ),
        },
      ]
    : [];
  const columns = React.useMemo(() => {
    if (!tableLayout.currentLayout || tableLayout.currentLayout.length === 0) return [];
    const _columns = Object.assign<ColumnType[], TableLayout[]>([], tableLayout.currentLayout).map((column) => {
      switch (column.field) {
        case 'receptionAt':
          column.sx = {width: '160px'};
          column.fieldValue = (row) => (
            <Typography variant="inherit">{dayjs(row.receptionAt).format('YYYY年MM月DD日 HH:mm')}</Typography>
          );
          break;
        case 'status':
          column.sx = {width: '80px'};
          column.fieldValue = (row) => <StatusIcon status={row.status} sx={StatusIconSx} nameSx={StatusTextSx} />;
          break;
        case 'narrowCategoryName':
          column.fieldValue = (row) => <TooltipMessage message={row.narrowCategoryName} />;
          break;
        case 'useRoom':
          column.fieldValue = (row) => <TooltipMessage message={row.useRoom ? row.useRoom.name : ''} />;
          break;
        case 'contactInformation':
          column.fieldValue = (row) => <TooltipMessage message={row.contactInformation} />;
          break;
        case 'transitionMessage':
          column.fieldValue = (row) => <TooltipMessage message={row.transitionMessage?.replace(/[\r\n]+/g, '')} />;
          break;
        case 'comment':
          column.fieldValue = (row) => <TooltipMessage message={row.comment} />;
          break;
        case 'contactPerson':
          column.fieldValue = (row) => {
            if (!row.contactPerson) return <TooltipMessage message={''} />;
            return <TooltipMessage message={`${row.contactPerson.lastName} ${row.contactPerson.firstName}`} />;
          };
          break;
        default:
          break;
      }
      column.sorting = true;
      return column;
    });
    return [...editButtonLayout, ..._columns].map((column) => ({
      ...column,
      noBodyWrap: true,
    }));
  }, [tableLayout.currentLayout]);

  return {columns, tableLayout, setTableLayout, editItem, setEditItem};
};
