import {useEffect, useMemo} from 'react';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {UPDATE_PRODUCT_LIST_LAYOUT} from './constants';
import {BulkUpdateProductListElement} from './types';
import {Column} from '@molecules/Table/props';
import {Table} from '@molecules/Table';
import React from 'react';
import {Grid, Pagination} from '@mui/material';
import {hospitalProductPerPage} from '../ProductImport/hooks';
import dayjs from 'dayjs';
import {HospitalProductNoteSettings} from '@modules/hospital_products/types';

type HospitalProductTableProps = {
  hospitalProducts: BulkUpdateProductListElement[];
  page: number;
  totalCount: number;
  onPageChange: (newPage: number) => void;
  noteData?: HospitalProductNoteSettings;
  isLoading: boolean;
};

const dateFields = [
  'dateOfPurchase',
  'dateOfDisposal',
  'leaseStartDate',
  'leaseDueDate',
  'leaseReturnDate',
  'rentalStartDate',
  'rentalDueDate',
  'rentalReturnDate',
  'demonstrationStartDate',
  'demonstrationEndDate',
];

const getNumString = (field: string) => {
  const numberMatch = field.match(/\d+$/);
  return numberMatch ? numberMatch[0] : '';
};

const setNoteData = (tableLayout: TableLayout[], noteSettingData: HospitalProductNoteSettings) => {
  tableLayout.forEach((v) => {
    if (v.field.includes('notes')) {
      v.title = noteSettingData.noteSettings[`notes${getNumString(v.field)}Name`] + `(${v.title})`;
      v.visible = noteSettingData.noteSettings[`notes${getNumString(v.field)}Visible`];
    }
  });
};

export const HospitalProductTable = ({
  hospitalProducts,
  page,
  totalCount,
  onPageChange,
  noteData,
  isLoading,
}: HospitalProductTableProps) => {
  const totalPage = useMemo(() => Math.ceil(totalCount / hospitalProductPerPage), [totalCount]);

  useEffect(() => {
    if (isLoading || !noteData) return;
    setNoteData(UPDATE_PRODUCT_LIST_LAYOUT, noteData);
  }, [isLoading, noteData]);

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<BulkUpdateProductListElement>[], TableLayout[]>(
      [],
      UPDATE_PRODUCT_LIST_LAYOUT
    );

    return tableColumn.map((item) => {
      if (dateFields.includes(item.field)) {
        item.render = (element: BulkUpdateProductListElement) => {
          const dateValue = element[item.field as keyof BulkUpdateProductListElement];
          return dateValue !== '' ? dayjs(dateValue).format('YYYY/MM/DD') ?? '' : '';
        };
      }
      item.noBodyWrap = true;
      return item;
    });
  }, []);

  return (
    <div>
      <Table<BulkUpdateProductListElement>
        stickyHeader={true}
        showSelection={false}
        isLoading={false}
        columns={serializedTableColumn}
        data={hospitalProducts}
        tableSize="small"
      />
      <Grid container justifyContent="center" style={{marginTop: 24}}>
        <Pagination
          page={page}
          count={totalPage}
          shape="rounded"
          color="primary"
          onChange={(_, newPage) => onPageChange(newPage)}
        />
      </Grid>
    </div>
  );
};
