import React from 'react';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {WholeProductsListHeader} from './Header';
import {WholeProductsListContent} from './Content';
import {WholeProductFilterDrawer} from './FilterDrawer';

/**
 * 院内機種一覧ページ
 */
export const WholeProductsList = () => {
  return (
    <ListPageLayout page="wholeProducts">
      <WholeProductsListHeader />
      <WholeProductsListContent />
      <WholeProductFilterDrawer />
    </ListPageLayout>
  );
};
