export type UserIndex = {
  hashId: string;
  hospitalHashId: string;
  email: string;
  username: string;
  thumbnailUrl?: string;
  thumbnailFile?: {
    hashId: string;
    hospitalHashId: string;
    category: string;
    driver: string;
    fileType: string;
    originalName: string;
    fileName: string;
    path: string;
    url: string;
  };
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  isShared: boolean;
  lastSignInAt?: Date;
  nextInitializeStep?: number;
  initialized?: boolean;
  occupations?: string[];
  isSafetyControlManager?: boolean;
  isUserAdmin: boolean;
  createdAt: string;
  createdBy: UserIndex | null;
  updatedAt: string;
  updatedBy: UserIndex | null;
  deletedBy: UserIndex | null;
  deletedAt: string | null;
  permissions?: string[];
  // NOTE:直接アクセスせずに useMyInfoのmyResourceから取得する
  roleResources?: RoleResource[];
  // 経営ダッシュボードにアクセスできるか
  canAccessMgmtDashboard?: boolean;
  sessionExpirationTimeSec: number | null;
  rentalLayoutPreference: 'default_order' | 'customize';
};

export type UserResource = {
  hashId: string;
  name: string;
  applicationHashId: string;
  ownerOrganizationHashId: string;
};

type Role = {
  hashId: string;
  name: string;
  ownerOrganizationHashId: string;
  applicationHashId: string;
  isShared: boolean;
};

export type RoleUsers = {
  role: Role;
  users: {user: UserIndex}[];
};

export enum HospitalUserActionType {
  HOSPITAL_USER_SET_LIST = 'HOSPITAL_USER_SET_LIST',
}

type HospitalUserSetListAction = {
  type: HospitalUserActionType.HOSPITAL_USER_SET_LIST;
  payload: {
    users: UserIndex[];
  };
};

export type HospitalUserAction = HospitalUserSetListAction;

export type HospitalUserState = {
  users: UserIndex[];
};

export enum PermissionLevel {
  NONE = 0,
  READ_ONLY = 1,
  READ_EDIT = 2,
  READ_EDIT_DELETE = 3,
}
export const PERMISSION_RESOURCES = {
  // ログイン時に権限が必須
  LOGIN: {id: 'login'},
  // ダッシュボード
  DASHBOARD: {id: 'dashboard'},
  // 経営ダッシュボード
  MANAGEMENT_DASHBOARD: {id: 'management_dashboard'},
  // 機器一覧 TODO:PRODUCT＿LISTに名前を変える
  DEVICE_LIST: {id: 'device_list'},
  PRODUCT_LIST: {id: 'device_list'},
  // チャンネル一覧
  CHANNEL_LIST: {id: 'channel_list'},
  // 点検
  INSPECTION: {id: 'inspection'},
  // 修理
  REPAIR: {id: 'repair'},
  // 貸出・返却  TODO:実装に合わせRENTALに名前を変える
  LENDING_RETURN: {id: 'lending_return'},
  RENTAL: {id: 'lending_return'},
  // 貸出・返却（専用画面）実装に合わせRENTAL_ONLYに名前を変える
  LENDING_RETURN_RECEPTION: {id: 'lending_return_reception'},
  RENTAL_ONLY: {id: 'lending_return_reception'},
  // 病棟メニュー
  WARD_SERVICE: {id: 'ward_service'},
  // 研修
  TRAINING: {id: 'training'},
  // NOTE:スーパーユーザーだが使われていない 削除予定
  FULL_ACCESS_TO_MGMT_SECTION: {id: 'full_access_to_mgmt_section'},
} as const;

// ResourceKey型: RESOURCESのキー
export type PermissionResourceKey = keyof typeof PERMISSION_RESOURCES;

// ResourceName型: RESOURCESのidプロパティをUnion型として抽出
export type PermissionResourceName = (typeof PERMISSION_RESOURCES)[PermissionResourceKey]['id'];

export type PermissionResource = {
  canDelete: boolean;
  isReadOnly: boolean;
  resource: {
    hashId: string;
    name: PermissionResourceName;
    applicationHashId: string;
    ownerOrganizationHashId: string;
    createdAt: string;
    createdBy?: string;
    updatedAt: string;
    updatedBy?: string;
    deletedBy?: string;
  };
};

export type RoleResource = {
  role: Role;
  resources: PermissionResource[];
};
