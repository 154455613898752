import React, {useMemo} from 'react';
import {styled, Box, Tooltip, Divider, Button} from '@mui/material';
import {InnerLoading} from '@components/molecules/Loading';
import {PercentBadge} from '@components/atoms/PercentBadge';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchRentalCapabilityNarrowCategoryQuery} from '@modules/dashboard/api';
import {useHospitalCategoryTreesQuery} from '@modules/categories/hooks/useCategoryTrees';
import {RentalCapabilityNarrowCategory, RentalCapabilityNarrowCategoryItem} from '@modules/dashboard/types';
import {useNavigate} from 'react-router-dom';
import {useSetAtom} from 'jotai';
import {selectedReturnWaitingReceptionAtom} from '../BaseSharedMenu/states';

const ReturnWaitButton = ({onClick}: {onClick: VoidFunction}) => {
  return (
    <Button
      sx={{
        backgroundColor: '#0052CC',
        color: 'white',
        borderRadius: '10px',
        fontSize: '16px',
        boxShadow: 'inset 0px -5px 0px 0px rgba(14, 56, 112, 1)',
        padding: '4px 16px',
        '&:hover': {
          backgroundColor: '#0052CC',
        },
      }}
      onClick={() => onClick()}>
      返却待ち受付
    </Button>
  );
};

const HeaderRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 40px 0',
  padding: '16px',
});

const HeaderDivider = styled(Divider)({
  backgroundColor: '#5D6B82',
});

const ProductDivider = styled(Divider)({
  marginTop: '12px',
});

const BaseCell = styled(Box)({
  fontSize: 18,
  fontWeight: 400,
  color: '#172B4D',
  whiteSpace: 'nowrap',
});

const BaseCellAlignCenter = styled(BaseCell)({
  textAlign: 'center',
});

const RentableContainer = styled(Box)({
  overflowX: 'hidden',
  padding: '0 40px',
  maxHeight: 'calc(100vh - 225px)',
  maxWidth: 'calc(100% - 80px)',
  overflow: 'auto',
  scrollbarGutter: 'stable', // スクロールバーの表示有無にかかわらず同じ幅になるように
  '& > :last-child': {
    display: 'none',
  },
});

const RentableCell = styled(BaseCell)({
  paddingTop: '4px',
  paddingBottom: '4px',
  display: 'flex',
});

const RentableCellAlignCenter = styled(RentableCell)({
  textAlign: 'center',
});

const CategoryCell = styled(BaseCell)({
  padding: '16px 16px 4px',
  color: '#8B95A6',
});

const NarrowCategoryCell = styled(RentableCell)({
  paddingLeft: '34px',
});

const NarrowCategoryRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const RentableNum = styled(Box)({
  fontSize: 24,
  color: '#2A96E8',
  fontWeight: 800,
  paddingRight: '4px',
});

const ProductNum = styled(Box)({
  color: '#616C82',
  lineHeight: '24px',
});

/**
 * 貸出可能機器の分類と台数の表示部分
 * @param isReturnWaitButton 返却待ち受付ボタンを表示するかどうか
 * @returns
 */
export const Content = ({isReturnWaitButton}: {isReturnWaitButton?: boolean}) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useFetchRentalCapabilityNarrowCategoryQuery(myInfo.hospitalHashId);
  const {data: tree} = useHospitalCategoryTreesQuery(myInfo.hospitalHashId);

  const rows = useMemo(() => {
    return tree.map((item) => {
      const narrowCategoriesArray = item.narrowCategories.map((category) => category.hashId);
      const narrowCategoryItems: RentalCapabilityNarrowCategoryItem[] = [];
      let totalRentableNum = 0;
      let totalProductNum = 0;
      data?.forEach((d) => {
        if (narrowCategoriesArray.includes(d.category.hashId)) {
          narrowCategoryItems.push(d);
          totalRentableNum += d.readyProductAmount;
          totalProductNum += d.totalProductAmount;
        }
      });

      return {
        categoryName: item.rootCategory.name,
        totalRentableNum: totalRentableNum,
        totalProductNum: totalProductNum,
        narrowCategoryItems: narrowCategoryItems.sort(
          (a, b) => b.readyProductAmount / b.totalProductAmount - a.readyProductAmount / a.totalProductAmount
        ),
      };
    });
  }, [data, tree]);

  return (
    <>
      <HeaderRow>
        <BaseCell width="50%">機器分類</BaseCell>
        <BaseCell width="30%">貸出可能数/保有数</BaseCell>
        <BaseCellAlignCenter width="20%">貸出率</BaseCellAlignCenter>
      </HeaderRow>
      <HeaderDivider />
      {isLoading || rows === undefined ? (
        <InnerLoading />
      ) : (
        <RentableContainer>
          {rows.map(
            (row, index) =>
              row.totalProductNum !== 0 && (
                <RentableRow row={row} key={`${row.categoryName}${index}`} isReturnWaitButton={isReturnWaitButton} />
              )
          )}
        </RentableContainer>
      )}
    </>
  );
};

type RentableTableRowProp = {
  row: {
    categoryName: string;
    totalRentableNum: number;
    totalProductNum: number;
    narrowCategoryItems: RentalCapabilityNarrowCategory;
  };
  isReturnWaitButton?: boolean;
};

const RentableRow = (props: RentableTableRowProp) => {
  const {row, isReturnWaitButton} = props;
  const navigate = useNavigate();
  const setSelectedReturnWaitingReceptionAtom = useSetAtom(selectedReturnWaitingReceptionAtom);
  const onClick = (item: RentalCapabilityNarrowCategoryItem) => {
    setSelectedReturnWaitingReceptionAtom({
      narrowCategoryName: item.category.name,
      narrowCategoryHashId: item.category.hashId,
    });
    navigate('/shared/return_waiting_reception/ward_selection');
  };

  const showReturnWaitButton = (item: RentalCapabilityNarrowCategoryItem) => {
    return isReturnWaitButton && item.showReturnWaitingReception;
  };
  return (
    <>
      <CategoryCell key={row.categoryName}>
        <EllipsisText text={row.categoryName} />
      </CategoryCell>
      {row.narrowCategoryItems.map((item) => (
        <NarrowCategoryRow key={item.category.name}>
          <NarrowCategoryCell width="47%">
            <EllipsisText text={item.category.name} />
          </NarrowCategoryCell>
          <RentableCell width="12%" sx={{alignItems: 'flex-end'}}>
            <RentableNum component="span">{item.readyProductAmount}</RentableNum>
            <ProductNum component="span">/{item.totalProductAmount}台</ProductNum>
          </RentableCell>
          <RentableCell width="20%">
            {showReturnWaitButton(item) && (
              <ReturnWaitButton
                onClick={() => {
                  onClick(item);
                }}
              />
            )}
          </RentableCell>
          <RentableCellAlignCenter>
            <PercentBadge percent={(1 - item.readyProductAmount / item.totalProductAmount) * 100} />
          </RentableCellAlignCenter>
        </NarrowCategoryRow>
      ))}
      <ProductDivider />
    </>
  );
};

type EllipsisTextProps = {
  text: string;
};
// 20文字を超えるテキストの場合、21文字目以降を「…」で置き換え
const EllipsisText = (props: EllipsisTextProps) => {
  const MAX_TEXT_LENGTH = 20;
  const {text} = props;
  const isLongText = text.length > MAX_TEXT_LENGTH;

  return isLongText ? (
    <Tooltip title={text}>
      <span>{text.substring(0, MAX_TEXT_LENGTH) + '…'}</span>
    </Tooltip>
  ) : (
    <span>{text}</span>
  );
};
