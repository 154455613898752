import {Box, SxProps, Theme, Typography} from '@mui/material';
import React from 'react';

type PurchaseRequestPageHeaderProps = {
  stepLabel: string;
  showSubtitle?: boolean;
};

export const PurchaseRequestPageHeader = (props: PurchaseRequestPageHeaderProps) => {
  const {stepLabel, showSubtitle = false} = props;

  return (
    <Box>
      <Typography sx={titleStyle}>{stepLabel}</Typography>
      {showSubtitle && <Typography sx={subtitleStyle}>(FUJITA社よりご連絡させていただきます)</Typography>}
    </Box>
  );
};

const titleStyle: SxProps<Theme> = {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
};

const subtitleStyle: SxProps<Theme> = {
  fontSize: '14px',
  lineHeight: '24px',
};
