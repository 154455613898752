import {atomWithReducer} from 'jotai/utils';

export const PurchaseRequestStep = {
  overviewIntroduction: {
    key: 'overviewIntroduction',
    sequence: 1,
    label: '買取査定概要',
  },
  confirmSharingInformation: {
    key: 'confirmSharingInformation',
    sequence: 2,
    label: '情報共有承諾',
  },
  contactInformation: {
    label: '連絡先入力',
    sequence: 3,
    key: 'contactInformation',
  },
  confirmContent: {
    label: '依頼内容の確認',
    sequence: 4,
    key: 'confirmContent',
  },
} as const;

export type PurchaseRequestStepKey = keyof typeof PurchaseRequestStep;

export type PurchaseRequestStateType = {
  /** 買取査定の依頼までの行程 */
  currentStep: PurchaseRequestStepKey;
  /** 入力フォームの内容 */
};

const initialState: PurchaseRequestStateType = {
  currentStep: 'overviewIntroduction',
};

export const actionType = {
  proceedStep: 'PROCEED_STEP',
  returnToPreviousStep: 'RETURN_TO_PREVIOUS_STEP',
  cancel: 'CANCEL',
} as const;

type Action = {
  type: 'PROCEED_STEP' | 'RETURN_TO_PREVIOUS_STEP' | 'CANCEL';
};

const getStepIndex = (step: PurchaseRequestStepKey) => {
  return Object.keys(PurchaseRequestStep).indexOf(step);
};

const purchaseRequestStateReducer = (prev: PurchaseRequestStateType, action: Action): PurchaseRequestStateType => {
  switch (action.type) {
    case 'PROCEED_STEP': {
      const currentStepIndex = getStepIndex(prev.currentStep);
      const nextStepIndex = currentStepIndex + 1;
      if (nextStepIndex > Object.keys(PurchaseRequestStep).length) {
        return prev;
      }

      if (nextStepIndex === Object.keys(PurchaseRequestStep).length) {
        return {...initialState};
      }
      const nextStepKey = Object.keys(PurchaseRequestStep)[nextStepIndex] as PurchaseRequestStepKey;

      return {...prev, currentStep: nextStepKey};
    }
    case 'RETURN_TO_PREVIOUS_STEP': {
      const currentStepIndex = getStepIndex(prev.currentStep);
      const previousStepIndex = currentStepIndex - 1;
      if (previousStepIndex < 0) {
        return prev;
      }

      const previousStepKey = Object.keys(PurchaseRequestStep)[previousStepIndex] as PurchaseRequestStepKey;

      return {...prev, currentStep: previousStepKey};
    }
    case 'CANCEL':
      return {...initialState};
    default:
      return prev;
  }
};

export const purchaseRequestStateAtom = atomWithReducer<PurchaseRequestStateType, Action>(
  initialState,
  purchaseRequestStateReducer
);
