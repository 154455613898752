import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import {ArrowDropDown, Search, Tune, AssignmentReturned, Edit} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {Column} from '@molecules/Table/props';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {openSnackBar} from '@molecules/SnackBar';
import {TableLayout, TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useDebounceCallback, useOnlyOnce} from '@front-libs/core';
import {HospitalProductIndex, HospitalProductNoteSettings, NoteSettings} from '@modules/hospital_products/types';
import {NoteFieldDataTypeValues} from '@modules/hospital_products/constants';
import {ProductStatus} from '@molecules/ProductStatus';
import {FilterDrawer} from '@molecules/Drawers/FilterDrawer';
import {FilterOption, ResultType} from '@molecules/Drawers/FilterDrawer/types';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {productStatusOptions, productStatusOptionsWODisabled} from '@constants/constants';
import {useSetAtom} from 'jotai';
import {CategoryFormatter} from '@modules/categories/helpers';
import {
  NestedMenuListItems,
  RegistrationValueType,
  getWaysOfPurchaseOptions,
  productFilterConditions,
  NoteFieldFilterOptionType,
} from './constants';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {InitialValue, productsAtom} from './ProductsRegistrationDialog/state';
import {
  ApplicationIndicator,
  convertDateToRFC3339,
  convertDateToSimpleDateTime,
  extractCodeFromGS1Barcode,
  isNullish,
  StrUtil,
} from '@front-libs/helpers';
import {useAtom, useAtomValue} from 'jotai';
import {
  hospitalProductsVariables,
  includeDisposedAtom,
  orderKeyAtom,
  pageAtom,
  pageSizeAtom,
  searchFilterResultsAtom,
  searchNameAtom,
  searchRootCategoriesAtom,
  searchStatusesAtom,
  searchSubCategoriesAtom,
} from './jotai';
import {Table} from '@molecules/Table';
import {useBulkCheckoutHospitalProducts, useHospitalProductsQuery, useIsDetailFilterActive} from './hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useCategoryQuery} from './hooks';
import {CategoryIndex} from '@modules/categories/types';
import {isNaN} from 'lodash';
import {
  NewRegistrationGS1Dialog,
  NewRegistrationDialogProps,
  NewRegistrationDialogResult,
} from './Dialogs/NewRegistrationGS1Dialog';
import {createWholeProduct, getProducts} from '@modules/products/api';
import {CreateWholeProduct, ProductIndex} from '@modules/products/types';
import {patchHospitalProducts, requestExportTask} from '@modules/hospital_products/api';
import {useFetchHospitalRooms, useFetchHospitalWards} from '@modules/hospital_places/api';
import {BulkRentDialog} from './Dialogs/BulkRentDialog';
import EditableColumnModal from './Dialogs/EditableColumnModal';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {HospitalRentalSetting, rentalSettings} from '@modules/hospital_settings/types';
import {onlineManager} from 'react-query';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import dayjs from 'dayjs';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {styled} from '@material-ui/styles';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';
import {UserIndex} from '@modules/hospital_users/types';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {TwoColumns} from '@components/layouts/columns/TwoColumns';
import {BulkMoveBuildingDialog} from '@Apps/ProductsList/pc/Dialogs/BulkMoveBuildingDialog';
import {bulkCreateMoveTargetBuildings} from '@modules/move_target_buildings/api';
import {MoveTargetBuildingSVG} from '@components/atoms/Icons/moveTargetBuildingSVG';
import {useHospitalDealerOption} from '@modules/hospital_dealer/api';
import {useHospitalDepartmentOption} from '@modules/hospital_departments/api';
import {
  MAX_NOTE_NUM,
  useHospitalProductNoteSettings,
} from '@modules/hospital_products/hooks/useHospitalProductNoteSettings';
import {WholeProductConfirmDialog, WholeProductConfirmDialogProps} from '@organisms/WholeProductConfirmDialog';
import {recommendWholeProduct} from '@modules/whole_products/api';
import {WholeProductEditDialog, WholeProductEditDialogProps} from '@organisms/WholeProductEditDialog';
import {fetchCompanies} from '@modules/companies/api';
import {WholeProductEditDialogResult} from '@components/organisms/WholeProductEditDialog/type';
import {NestedMenuList, NestedMenuListItemType} from '@components/molecules/NestedMenuList';
import {useWholeProductUnregisteredSearch} from '@Apps/WholeProduct/WholeProductUnregisteredSearch/hooks/hooks';
import {RecommendWholeProductResult} from '@modules/whole_products/type';
import {PatchHospitalProducts} from '@Apps/ProductBulkUpdates/types';
import {snakeCase} from 'lodash';
import {EditableColumnModalProps} from './Dialogs/EditableColumnModal/EditableColumnModal';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const baseActionMenuItems = [
  {
    label: '表示項目を変更',
    value: 'changeLayout',
  },
];

const usePage = () => {
  const [page, setPage] = useAtom(pageAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const getInitPage = useCallback((): number => {
    const queryPage = searchParams.get('page');
    if (queryPage === null) {
      return 1;
    }

    const parsed = Number.parseInt(queryPage);
    if (!isNaN(parsed) && parsed >= 1) {
      return parsed;
    }

    return 1;
  }, [searchParams]);

  // 初期化時クエリからページを読み込む
  useOnlyOnce(() => {
    setPage(getInitPage());
  });

  const updatePage = useCallback(
    (newPage: number) => {
      setPage(newPage);

      searchParams.set('page', newPage.toString());
      setSearchParams([...searchParams.entries()]);
    },
    [searchParams, setPage, setSearchParams]
  );

  return [page, updatePage] as const;
};

/**
 * fieldから数値をStringで返す
 * tableLayout.fieldのnotes2~notes20から数値を取り出す為
 * @param field
 */
const getNumString = (field: string) => {
  const numberMatch = field.match(/\d+$/);
  return numberMatch ? numberMatch[0] : '';
};

/**
 * テーブルレイアウトの項目名と表示状態をHospitalProductNoteSettingsの値を元に変更する
 * @param tableLayout
 * @param noteData
 */
const setNoteData = (tableLayout: TableLayout[], noteData: HospitalProductNoteSettings) => {
  tableLayout.forEach((v) => {
    if (v.field.includes('notes')) {
      v.title = noteData.noteSettings[`notes${getNumString(v.field)}Name`];
      v.visible = noteData.noteSettings[`notes${getNumString(v.field)}Visible`];
    }
  });
};

/**
 * 機器一覧ページ
 * TODO:コンポーネント分割してリファクタリング
 */
export const ProductsList = () => {
  const {myInfo} = useMyInfo();
  const [dataComp, setDataComp] = useState(false);
  const [tableLayout, setTableLayout] = useTableLayout('productList');
  const {data: noteData, isLoading: isLoadingNoteSettings} = useHospitalProductNoteSettings(myInfo.hospitalHashId);

  useEffect(() => {
    if (isLoadingNoteSettings || !noteData || !tableLayout) return;
    // 汎用項目のタイトル名を適用、Visibleを与える
    setNoteData(tableLayout.tableLayout, noteData);
    // 現在のレイアウトにも適用する
    setNoteData(tableLayout.currentLayout, noteData);
    // 現在のレイアウトのVisibleがfalseのものは含めない(Tableに表示しない)
    tableLayout.currentLayout = tableLayout.currentLayout.filter((v) => v.visible !== false);

    setTableLayout({...tableLayout});
    setDataComp(true);
  }, [isLoadingNoteSettings, noteData, setTableLayout, tableLayout]);

  if (myInfo === undefined || tableLayout.currentLayout.length === 0 || dataComp === false) return null;
  return (
    <InnerProductsList myInfo={myInfo} tableLayout={tableLayout} setTableLayout={setTableLayout} noteData={noteData} />
  );
};

type Props = {
  myInfo: UserIndex;
  tableLayout: TableLayoutResult;
  noteData: HospitalProductNoteSettings | undefined;
  setTableLayout: (layout: TableLayoutResult | undefined) => void;
};

const InnerProductsList = ({myInfo, tableLayout, noteData, setTableLayout}: Props) => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1023px)');
  const [openDrawer, setOpenDrawer] = useState(false);
  const setProducts = useSetAtom(productsAtom);

  const {canEdit: canEditDeviceList} = useUserResourcesPermissions('PRODUCT_LIST');
  const {canRead: canReadChannelList} = useUserResourcesPermissions('CHANNEL_LIST');
  const navigate = useNavigate();
  const isOnline = onlineManager.isOnline();
  const actionMenuItems = useMemo(() => {
    if (matches && isOnline) {
      return [
        ...baseActionMenuItems,
        {
          label: '機器台帳をエクスポート',
          value: 'export',
        },
        {
          label: '詳細で絞り込む',
          value: 'openDetailFilter',
        },
      ];
    } else if (matches) {
      return [...baseActionMenuItems];
    } else {
      return [
        ...baseActionMenuItems,
        {
          label: '機器台帳をエクスポート',
          value: 'export',
        },
        ...(canEditDeviceList
          ? [
              {
                label: 'Excelから一括更新',
                value: 'bulkUpdate',
              },
            ]
          : []),
      ];
    }
  }, [matches, isOnline]);

  const handleActionMenuClick = (item: MenuItemType) => {
    switch (item.value) {
      case 'export':
        handleExport();
        break;

      case 'changeLayout':
        handleChangeLayout();
        break;

      case 'openDetailFilter':
        setOpenDrawer(true);
        break;

      case 'bulkUpdate':
        navigate(`/products/bulk-updates`);
        break;
    }
  };

  const {data: hospitalSettings} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  const {rootCategoryQuery, narrowCategoryQuery, selectRootCategory, descendantCategoriesQueries} = useCategoryQuery(
    myInfo.hospitalHashId
  );

  const {hospitalDealerOptions: hospitalDealerOptionsList} = useHospitalDealerOption(myInfo.hospitalHashId);
  const {hospitalDepartmentOptions} = useHospitalDepartmentOption(myInfo.hospitalHashId);

  const {data: hospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId);
  const hospitalRoomOptions = useMemo(
    () =>
      hospitalRooms.map((room) => ({
        label: HospitalRoomFormatter.getFullRoom(room),
        value: room.hashId,
      })),
    [hospitalRooms]
  );

  const {data: hospitalWards} = useFetchHospitalWards(myInfo.hospitalHashId);
  const hospitalWardOptions = useMemo(
    () =>
      hospitalWards.map((room) => ({
        label: room.name,
        value: room.hashId,
      })),
    [hospitalWards]
  );

  const {hospitalUsers} = useHospitalUsers();
  const userOptions = useMemo(
    () => UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}),
    [hospitalUsers]
  );

  const [page, setPage] = usePage();
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const [includeDisposed, setIncludeDisposed] = useAtom(includeDisposedAtom);
  const [searchFilterResults, setSearchFilterResults] = useAtom(searchFilterResultsAtom);
  const [searchStatuses, setSearchStatuses] = useAtom(searchStatusesAtom);
  const [searchRootCategories, setSearchRootCategories] = useAtom(searchRootCategoriesAtom);
  const [searchSubCategories, setSearchSubCategories] = useAtom(searchSubCategoriesAtom);
  const [order, setOrder] = useAtom(orderKeyAtom);
  const isDetailFilterActive = useIsDetailFilterActive();
  const variables = useAtomValue(hospitalProductsVariables);
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);
  // 新規登録ダイアログ用
  const {handleEditBlankWholeProduct} = useWholeProductUnregisteredSearch({});
  const query = useHospitalProductsQuery(myInfo.hospitalHashId);

  const isFieldOfHospitalProductIndex = (
    field: string,
    obj: HospitalProductIndex
  ): field is keyof HospitalProductIndex => {
    return field in obj;
  };
  const isFieldOfHospitalProductIndexNotes = (
    field: string,
    obj: HospitalProductIndex
  ): field is keyof HospitalProductIndex & `notes${string}` => {
    if (!isFieldOfHospitalProductIndex(field, obj)) return false;
    return /^notes(\d*)$/.test(field);
  };

  const {bulkCheckoutHospitalProducts} = useBulkCheckoutHospitalProducts({hospitalSettings, myInfo});

  /** 単一選択タイプの汎用項目フィールド名一覧を返す。(形式: ["notes", "notes2", "notes3", ...]) */
  const getSelectTypeNoteFields = (
    noteSettings: NoteSettings,
    obj: HospitalProductIndex
  ): (keyof HospitalProductIndex & `notes${string}`)[] => {
    const suffix = 'Type';
    const typeName = 'select';

    return Object.entries(noteSettings)
      .filter(([k, v]) => k.endsWith(suffix) && v === typeName) // 単一選択タイプの汎用項目タイプのみを抽出
      .map(([k, _]) => k.replace(suffix, '')) // フィールド名からsuffixを削除し、'notes(\d*)'の形式に変換
      .filter((v) => isFieldOfHospitalProductIndexNotes(v, obj)) as (keyof HospitalProductIndex & `notes${string}`)[]; // 機器情報に存在するフィールドのみを抽出
  };

  /** 汎用項目設定を汎用項目の値に適用した機器一覧情報を返す */
  const applyNoteSettingsToNotes = (
    src: HospitalProductIndex[],
    noteSettings: NoteSettings
  ): HospitalProductIndex[] => {
    if (src.length === 0) return src;

    const selectTypeNoteFields = getSelectTypeNoteFields(noteSettings, src[0]);
    if (selectTypeNoteFields.length === 0) return src;

    // NOTE: 汎用項目フィールドは適用対象(src)直下のstring型なので、deep copyではなく、shallow copyを利用している
    const ret: HospitalProductIndex[] = src.map((item) => ({...item}));

    // 単一選択タイプの選択項目の値を反映
    selectTypeNoteFields.forEach((f) => {
      // 汎用項目設定から選択項目一覧を取得
      const options = noteSettings[`${f}TypeOption`]?.values;
      if (!options) return;
      if (options.length === 0) return;
      // 選択項目のvalueとnameのマップを作成(valueは選択項目を識別する値, nameは選択項目の表示名)
      const optionMap = new Map<string, string>(options.map((o) => [o.value.toString(), o.name]));

      ret.forEach((item) => {
        item[f] = optionMap.get(item[f]) ?? '';
      });
    });

    return ret;
  };

  /** APIから取得した機器一覧情報を機器一覧Tableに表示する情報に変換する */
  const queryDataToTableData = (
    src: HospitalProductIndex[],
    noteData: HospitalProductNoteSettings | undefined
  ): HospitalProductIndex[] => {
    if (src.length === 0) return src;

    let ret = src;
    if (noteData) {
      ret = applyNoteSettingsToNotes(ret, noteData.noteSettings);
    }
    return ret;
  };

  const handleSwitchIncludeDisposed = useCallback(() => {
    setIncludeDisposed(!includeDisposed);

    // 次のステータスに合わせて、「廃棄を含まない」から判定
    if (includeDisposed) {
      setSearchStatuses(searchStatuses.filter((status) => status.value !== 'disabled'));
    } else {
      // 現在、!includeDisposedなら廃棄を含む様になるので、切り替え時点で廃棄ステータスを表示できるようにする

      // フィルタがかかっていない場合は自動的に廃棄も含むので何もしない
      if (searchStatuses.length === 0) {
        return;
      }
      const disposedOption = productStatusOptions.find((status) => status.value === 'disabled');
      if (isNullish(disposedOption)) {
        // unreachable
        return;
      }

      setSearchStatuses([...searchStatuses, disposedOption]);
    }
  }, [includeDisposed, searchStatuses, setIncludeDisposed, setSearchStatuses]);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [query.totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const handleSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPage(1);
      setSearchName(e.target.value);
    },
    300,
    [setPage, setSearchName]
  );

  const descendantCategoryOptions = useMemo(() => {
    if (descendantCategoriesQueries.length > 0) {
      let catQuery: CategoryIndex[] = [];
      for (const descendantCategoriesQuery of descendantCategoriesQueries) {
        const filteredCategories = (descendantCategoriesQuery.data?.data ?? []).filter((item) => item.depth === 1);
        catQuery = [...catQuery, ...filteredCategories];
      }
      return CategoryFormatter.getOptions(catQuery);
    } else {
      return CategoryFormatter.getOptions(narrowCategoryQuery.data);
    }
  }, [descendantCategoriesQueries, narrowCategoryQuery.data]);

  const deviceLenderSetting = useMemo(() => {
    return (hospitalSettings?.data?.find((x) => x.key === rentalSettings.use_device_lender.key)?.value ??
      'not_need') as HospitalRentalSetting;
  }, [hospitalSettings?.data]);

  const handleAdditionalFilterResults = useCallback(
    (results: ResultType[]) => {
      setPage(1);
      setSearchFilterResults(results);
    },
    [setPage, setSearchFilterResults]
  );

  const handleChangeStatuses = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      setPage(1);
      setSearchStatuses(values ?? []);
    },
    300,
    [setPage, setSearchStatuses]
  );

  const handleChangeRootCategories = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      setPage(1);
      selectRootCategory(values && values.length > 0 ? values.map((i) => i.value) : null);
      setSearchRootCategories(values ?? []);
    },
    300,
    [setPage, setSearchRootCategories]
  );

  const handleChangeSubCategories = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      setPage(1);
      setSearchSubCategories(values ?? []);
    },
    300,
    [setPage, setSearchSubCategories]
  );

  /** ページネーション */
  const handleChangePage = useCallback(
    (_e: React.ChangeEvent<unknown>, p: number) => {
      setPage(p);
    },
    [setPage]
  );

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrder(null);
      } else {
        // TODO: 暫定でカラム名(備考1~10)に合わせて対応した。いずれはフィールド名(notes,notes_2,..)なりできちんと対応する。
        let fieldName = tableLayout.currentLayout[columnIndex].field;
        if (fieldName.includes('notes') && fieldName !== 'notes') {
          fieldName = fieldName.replace('notes', 'notes_');
        }

        setOrder({
          fieldId: fieldName,
          direction: orderDirection,
        });
      }
    },
    [setOrder, tableLayout.currentLayout]
  );
  /** 該当データなし */
  const noDataComponent = useMemo(() => {
    if (variables.name) {
      return (
        <div>
          <FontBoldP>現在の検索条件に一致する機器はありません。</FontBoldP>
          <p>検索条件を変えて、再度検索してみてください。</p>
        </div>
      );
    } else {
      return (
        <div>
          <FontBoldP>機器を登録しましょう。</FontBoldP>
          <p>機器を登録して、ここで機器の情報を管理します。</p>
        </div>
      );
    }
  }, [variables.name]);

  const handleExport = useCallback(async () => {
    await requestExportTask(myInfo.hospitalHashId);
    openSnackBar('ファイルのエクスポートを受け付けました。\n処理完了後、通知をご確認ください。', 'center', 'top');
  }, [myInfo.hospitalHashId]);

  const handleChangeLayout = async () => {
    try {
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: tableLayout.tableLayout?.filter((v) => ('visible' in v ? v.visible : true)) ?? [],
        defaultOptions: tableLayout.currentLayout?.filter((v) => ('visible' in v ? v.visible : true)) ?? [],
      });

      if (!currentLayout) {
        console.error('currentLayout is undefined');
        return;
      }

      const newTableLayout = {
        tableLayout: tableLayout.tableLayout ?? [],
        currentLayout: currentLayout,
      };
      setTableLayout(newTableLayout);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickRow = (_event?: React.MouseEvent, rowData?: HospitalProductIndex) => {
    if (isNullish(rowData)) return;
    navigate(`/products/${rowData.hashId}`);
  };

  /**
   * GS1-128バーコードからJANコードを抽出する関数
   * @param {string} gs1Barcode - GS1-128バーコード文字列
   * @returns {string | null} - 抽出されたJANコード、または抽出できなかった場合はnull
   */
  function extractJanCode(gs1Barcode: string): string | null {
    try {
      // (01)の後に続く14桁または13桁のGTINを抽出
      const gtinMatch = gs1Barcode.match(/\(01\)(\d{14})/);
      let janCode = null;

      if (gtinMatch) {
        // 14桁のGTINが見つかった場合は、先頭の0を除いて13桁のJANコードに変換
        janCode = gtinMatch[1].startsWith('0') ? gtinMatch[1].substring(1) : gtinMatch[1];
      } else {
        // 13桁のGTINを探す
        const gtin13Match = gs1Barcode.match(/\(01\)(\d{13})/);
        if (gtin13Match) {
          janCode = gtin13Match[1];
        }
      }

      return janCode;
    } catch (error) {
      console.error('Failed to extract JAN code:', error);
      return null;
    }
  }

  const searchProducts = useCallback(async (): Promise<string | null> => {
    try {
      const {gs1Barcode} = await dialogHandler.open<NewRegistrationDialogProps, NewRegistrationDialogResult>(
        NewRegistrationGS1Dialog,
        {}
      );
      const {data} = await getProducts({gs1Barcode: gs1Barcode ?? '', isShared: false});
      if (data.length !== 0) {
        // 院内機種が見つかった場合
        return handleExistingProduct(data[0], gs1Barcode);
      } else {
        // 院内機種が見つからなかった場合
        return handleNewProduct(gs1Barcode);
      }
    } catch (_e) {
      console.error(_e);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfo.hashId, myInfo.hospitalHashId, navigate, setProducts]);

  const handleExistingProduct = async (product: ProductIndex, gs1Barcode: string | null): Promise<string | null> => {
    try {
      await dialogHandler.open<WholeProductConfirmDialogProps>(WholeProductConfirmDialog, {
        wholeProduct: product,
      });
      setAndNavigate(product.hashId, gs1Barcode);
      return null;
    } catch (_e) {
      return null;
    }
  };

  const handleNewProduct = async (gs1Barcode: string | null): Promise<string | null> => {
    const janCode = extractJanCode(gs1Barcode ?? '');

    if (janCode !== null) {
      // google類推から検索
      const {data} = await recommendWholeProduct(myInfo.hospitalHashId, {janCode});
      if (data.recommendDisplayName === '') {
        // 検索結果が見つからなかった場合
        return await handleManualEntry(gs1Barcode);
      } else {
        // 検索結果が見つかった場合
        return await handleGoogleSuggestion(data, gs1Barcode);
      }
    } else {
      return await handleManualEntry(gs1Barcode);
    }
  };

  const handleManualEntry = async (gs1Barcode: string | null): Promise<string | null> => {
    try {
      const formData = await dialogHandler.open<WholeProductEditDialogProps, WholeProductEditDialogResult>(
        WholeProductEditDialog,
        {
          title: '読み込み内容の確認',
          content: '機器情報が自動類推されませんでした。登録する機種情報を手入力してください。',
          alertContent: true,
        }
      );

      const newProduct = createProductFromFormData(formData, gs1Barcode);
      const res = await createWholeProduct(myInfo.hospitalHashId, newProduct);
      setAndNavigate(res.data.hashId, gs1Barcode);
      return null;
    } catch (_e) {
      return null;
    }
  };

  const handleGoogleSuggestion = async (
    data: RecommendWholeProductResult,
    gs1Barcode: string | null
  ): Promise<string | null> => {
    try {
      const companies = await fetchCompanies(myInfo.hospitalHashId, {
        name: data.recommendMakerName,
        is_perfect_match_search: true,
      });

      const isCompanyExist = companies && companies.data.length > 0;

      const formData = await dialogHandler.open<WholeProductEditDialogProps>(WholeProductEditDialog, {
        title: '読み込み内容の確認',
        content: `以下の機種情報が自動類推されました（院内機種として未登録です）。\n確認・修正し、よろしければ「機種を確定して次へ」をクリックしてください。`,
        recommendResult: {
          rootCategoryHashId: data.recommendRootCategory.hashId || '',
          narrowCategoryHashId: data.recommendNarrowCategory.hashId || '',
          displayName: data.recommendDisplayName || '',
          name: '',
          makerHashId: isCompanyExist ? companies.data[0].hashId : null,
          newMakerName: data.recommendMakerName || null,
          approvalNumber: data.recommendApprovalNumber || null,
          jmdnCode: Number(data.recommendJmdnCode) || null,
          janCode: data.recommendJanCode || null,
          isSpecificMaintain: null,
          className: null,
          catalogPrice: null,
        },
      });

      const newProduct = createProductFromFormData(formData, gs1Barcode);
      const res = await createWholeProduct(myInfo.hospitalHashId, newProduct);
      setAndNavigate(res.data.hashId, gs1Barcode);
      return null;
    } catch (_e) {
      return null;
    }
  };

  const createProductFromFormData = (
    formData: WholeProductEditDialogResult,
    gs1Barcode: string | null
  ): CreateWholeProduct => {
    const isNew = Boolean(formData.newMakerName);

    return {
      rootCategoryHashId: formData.rootCategoryHashId,
      narrowCategoryHashId: formData.narrowCategoryHashId,
      makerHashID: isNew ? undefined : formData.makerHashId,
      makerName: isNew ? formData.newMakerName ?? undefined : undefined,
      name: formData.name,
      displayName: formData.displayName,
      approvalNumber: formData.approvalNumber,
      jmdnCode: formData.jmdnCode,
      janCode: formData.janCode,
      isSpecificMaintain: formData.isSpecificMaintain,
      className: formData.className,
      catalogPrice: formData.catalogPrice,
      newJanCode: isNullish(gs1Barcode)
        ? null
        : extractCodeFromGS1Barcode(gs1Barcode, ApplicationIndicator.gtinIndicator),
    };
  };

  const setAndNavigate = (hashId: string, gs1Barcode: string | null) => {
    setProducts([
      {
        ...InitialValue,
        uuid: uuidv4(),
        serialNumber: extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.serialNumberIndicator),
        lotNumber: extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.lotNumberIndicator),
        rawBarcode: gs1Barcode,
        wholeProductHashID: hashId,
      },
    ]);
    navigate(`/products/registration/Step2/${hashId}`, {
      state: {
        fromGs1: true,
      },
    });
  };

  const handleBulkRentProducts = useCallback(
    async (_e: React.MouseEvent, selectedData: HospitalProductIndex[]) => {
      await bulkCheckoutHospitalProducts(selectedData);
      await query.refetch();
    },
    [deviceLenderSetting, myInfo.hashId, myInfo.hospitalHashId, query]
  );

  const handleBulkMoveTargetBuildingProducts = useCallback(
    async (_e: React.MouseEvent, selectedData: HospitalProductIndex[]) => {
      const res = await dialogHandler.open(BulkMoveBuildingDialog, {
        defaultUserHashId: myInfo.hashId,
        defaultMovedDate: convertDateToSimpleDateTime(dayjs().toDate()),
      });

      const moveProduct = selectedData.filter((item) => item.status === 'working');
      if (moveProduct.length === 0) {
        openSnackBar('選択された機器の中で、貸出可能な機器がありません。', 'left', 'bottom', 'error');
        return;
      }

      try {
        await bulkCreateMoveTargetBuildings(
          myInfo.hospitalHashId,
          moveProduct.map((item) => ({
            hospitalProductHashId: item.hashId,
            moveTargetBuildingAt: convertDateToRFC3339(res.movedAt),
            moveTargetBuildingHashId: res.hospitalRoomHashId,
            registererHashId: res.registererHashId,
          }))
        );
        await query.refetch();
        openSnackBar('転棟処理が完了しました。');
      } catch (__e) {
        openSnackBar('転棟処理に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo.hashId, myInfo.hospitalHashId, query]
  );

  /** HIT-4268用メニューボタン押下 */
  const nestedMenuListClick = (item: NestedMenuListItemType<RegistrationValueType>) => {
    switch (item.value) {
      //機種を検索して登録
      case 'search_registration':
        navigate(`/products/whole_products/search`);
        break;
      //機種を手入力して登録
      case 'input_search_registration':
        handleEditBlankWholeProduct();
        break;
      //院内機種一覧から選択して登録
      case 'select_registration':
        navigate(`/products/whole_products?isDisplayRegistrationCautionBar=true`);
        break;
      // Excelで一括登録
      case 'excel_import': {
        navigate(`/products/registration/import`);
        break;
      }
      // GS1-128を読み込んで登録
      case 'gs1_128':
        try {
          searchProducts();
        } catch (_e) {
          return null;
        }
        break;
    }
  };

  /**
   * テーブル並び順のシリアライズ
   */
  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<HospitalProductIndex>[], TableLayout[]>(
      [],
      tableLayout?.currentLayout ?? []
    );
    const map = tableColumn.map<Column<HospitalProductIndex>>((item) => {
      if (item.field === 'status') {
        item.render = ({status, isForcedRental}) => (
          <ProductStatus productStatus={status} isForcedRental={isForcedRental} />
        );
      } else if (item.field === 'isOutsideOfHospital') {
        item.render = ({isOutsideOfHospital}) => (isOutsideOfHospital ? '院外' : '院内');
      } else if (item.field === 'rootCategory') {
        item.render = ({categories}) => CategoryFormatter.getRootCategory(categories)?.name || '';
      } else if (item.field === 'narrowCategory') {
        item.render = ({categories}) => CategoryFormatter.getNarrowCategory(categories)?.name || '';
      } else if (item.field === 'catalogPrice') {
        item.render = ({catalogPrice}) => (isNullish(catalogPrice) ? '' : `${catalogPrice.toLocaleString()}円`);
      } else if (item.field === 'maker') {
        item.render = ({maker}) => maker?.name || '';
      } else if (item.field === 'permanentlyAssigned') {
        item.render = ({permanentlyAssigned}) =>
          isNullish(permanentlyAssigned) ? '' : permanentlyAssigned ? '貸出不可' : '貸出可';
      } else if (item.field === 'isBaseUnit') {
        item.render = ({isBaseUnit}) => (isNullish(isBaseUnit) ? '非該当' : isBaseUnit ? '親機' : '子機');
      } else if (item.field === 'purchasedNationalExpense') {
        item.render = ({purchasedNationalExpense}) => {
          return isNullish(purchasedNationalExpense) ? '' : purchasedNationalExpense ? '国費' : '院費';
        };
      } else if (item.field === 'waysOfPurchase') {
        item.render = ({waysOfPurchase}) => getWaysOfPurchaseOptions(waysOfPurchase);
      } else if (item.field === 'deliveryPrice') {
        item.render = ({deliveryPrice, waysOfPurchase}) =>
          waysOfPurchase === 'purchase' && !isNullish(deliveryPrice) ? `${StrUtil.addComma(deliveryPrice)}円` : '';
      } else if (item.field === 'taxIncluded') {
        item.render = ({taxIncluded, waysOfPurchase}) =>
          waysOfPurchase === 'purchase' && !isNullish(taxIncluded) ? (taxIncluded ? '税込' : '税抜き') : '';
      } else if (item.field === 'taxRate') {
        item.render = ({taxRate, waysOfPurchase}) =>
          waysOfPurchase === 'purchase' && !isNullish(taxRate) ? `${taxRate}%` : '';
      } else if (item.field === 'isMaintenanceContract') {
        item.render = ({isMaintenanceContract}) =>
          isNullish(isMaintenanceContract) ? '' : isMaintenanceContract ? '保守契約' : '保守契約外';
      } else if (item.field === 'depreciationAmount') {
        item.render = ({depreciationAmount}) =>
          isNullish(depreciationAmount) ? '' : StrUtil.addComma(depreciationAmount);
        item.justifyContentBody = 'right';
      } else if (item.field === 'bookValue') {
        item.render = ({bookValue}) => (isNullish(bookValue) ? '' : StrUtil.addComma(bookValue));
        item.justifyContentBody = 'right';
      } else if (item.field === 'hospitalRoom') {
        item.render = ({hospitalRoom}) => HospitalRoomFormatter.getFullRoom(hospitalRoom);
      } else if (item.field === 'rentHospitalRoom') {
        item.render = ({rentHospitalRoom}) => HospitalRoomFormatter.getFullRoom(rentHospitalRoom);
      } else if (item.field === 'hospitalDealer') {
        item.render = ({hospitalDealer}) => hospitalDealer?.name ?? '';
      } else if (item.field === 'rentalFee') {
        item.render = ({rentalFee}) => (isNullish(rentalFee) ? '' : `${rentalFee.toLocaleString()}円`);
        item.justifyContentBody = 'right';
      } else if (item.field === 'leaseFee') {
        item.render = ({leaseFee}) => (isNullish(leaseFee) ? '' : `${leaseFee.toLocaleString()}円`);
        item.justifyContentBody = 'right';
      } else if (item.field === 'createdAt') {
        item.render = ({createdAt}) => (createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : '');
      } else if (item.field === 'createdBy') {
        item.render = ({createdBy}) => UserFormatter.getFullName(createdBy);
      } else if (item.field === 'hospitalDepartment') {
        item.render = ({hospitalDepartment}) => hospitalDepartment?.name ?? '';
      }
      item.noBodyWrap = true;

      return item;
    });
    return map;
  }, [tableLayout]);

  const handleDrawerClose = useCallback(() => setOpenDrawer(false), []);

  const getFilterOptionTypeFromNoteSettings = (
    noteSettings: NoteSettings,
    numStr: string
  ): FilterOption['optionType'] => {
    const defaultType = 'text';

    const optType = noteSettings[`notes${numStr}Type`];
    if (optType === null) return defaultType;
    if (typeof optType !== 'string') return defaultType;

    const dataType = NoteFieldDataTypeValues.find((v) => v === optType) ?? defaultType;
    return NoteFieldFilterOptionType[dataType];
  };

  const getFilterOptionsFromNoteSettings = (
    noteSettings: NoteSettings,
    numStr: string
  ): {label: string; value: string}[] => {
    const opt = noteSettings[`notes${numStr}TypeOption`];
    if (opt === null) return [];
    if (typeof opt === 'string') return [];
    if (typeof opt === 'boolean') return [];

    return opt.values.map((v) => ({label: v.name, value: v.value.toString()}));
  };

  /**
   * 備考1~20の、フィルター条件を更新する。
   *
   * @param {NoteSettings} noteSettings - ノートの表示設定を含むオブジェクト。
   * @param {FilterOption[]} conditions - 更新するフィルター条件の配列。
   * @returns {FilterOption[]} 更新されたフィルター条件の配列。
   */
  const updateFilterOptions = useCallback((noteSettings: NoteSettings, conditions: FilterOption[]) => {
    // NOTE: NoteSettingsのデータ取得が同じ様になっているのでUtil関数を作ったほうが良いかも
    // 備考1~20のデータを取得するため 1からスタート Notes1 のみ Notes に変換する必要あり
    for (let i = 1; i <= MAX_NOTE_NUM; i++) {
      const numString = i === 1 ? '' : String(i);
      const condition = conditions.find((v) => v.value === `notes${numString}`);
      if (!condition) continue;

      condition.visible = noteSettings[`notes${numString}Visible`];
      condition.optionType = getFilterOptionTypeFromNoteSettings(noteSettings, numString);
      condition.label = noteSettings[`notes${numString}Name`];

      switch (condition.optionType) {
        case 'dateRange':
          condition.value = `${condition.value}Date`;
          break;
        case 'selector':
          condition.options = getFilterOptionsFromNoteSettings(noteSettings, numString);
          break;
      }
    }
    return conditions;
  }, []);

  const filterDrawerOptions = useMemo<FilterOption[]>(() => {
    const conditions = productFilterConditions({
      hospitalRoomOptions,
      hospitalWardOptions,
      createdByOptions: userOptions,
      hospitalDealerOptions: hospitalDealerOptionsList,
      hospitalDepartmentOptions: hospitalDepartmentOptions,
    });
    if (noteData) {
      return updateFilterOptions(noteData.noteSettings, conditions);
    }
    return conditions;
  }, [hospitalRoomOptions, hospitalWardOptions, userOptions, noteData, updateFilterOptions]);

  const handleBulkUpdateProducts = useCallback(
    async (_e: React.MouseEvent, selectedData: HospitalProductIndex[]) => {
      const res = await dialogHandler.open<EditableColumnModalProps, string[]>(EditableColumnModal, {
        hospitalRooms: hospitalRooms,
        hospitalDealerOptionsList: hospitalDealerOptionsList,
        hospitalDepartmentOptions: hospitalDepartmentOptions,
        selected: selectedData.map((item) => item.hashId),
        notesDataList: filterDrawerOptions.filter((v) => v.value.includes('notes')), // 汎用項目のデータのみ渡す
      });

      try {
        // resのキーを取得（キーが1つである前提）
        if (Object.keys(res).length !== 1 || !Object.keys(res)[0]) {
          openSnackBar('編集に失敗しました。', 'left', 'bottom', 'error');
          return;
        }
        const key = Object.keys(res)[0];
        const snakeCaseKey = snakeCase(key);
        const patchProducts: PatchHospitalProducts = {
          ...res,
          updateColumn: snakeCaseKey, // resのキーをupdateColumnに設定
          hospitalProductHashIds: selectedData.map((item) => item.hashId),
        };
        await patchHospitalProducts(myInfo.hospitalHashId, patchProducts);

        await query.refetch();
        openSnackBar('編集が完了しました。');
      } catch (__e) {
        openSnackBar('編集に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [
      filterDrawerOptions,
      hospitalDealerOptionsList,
      hospitalDepartmentOptions,
      hospitalRooms,
      myInfo.hospitalHashId,
      query,
    ]
  );

  const {canEdit: canEditLendingReturn} = useUserResourcesPermissions('LENDING_RETURN');
  const selectionButtons = useMemo(() => {
    const buttons = [];

    if (canEditDeviceList) {
      buttons.push({
        label: '編集',
        IconComponent: Edit,
        onClick: handleBulkUpdateProducts,
      });
    }

    if (canEditLendingReturn) {
      buttons.push(
        {
          label: '貸出登録',
          IconComponent: AssignmentReturned,
          onClick: handleBulkRentProducts,
        },
        {
          label: '転棟',
          IconComponent: MoveTargetBuildingSVG,
          onClick: handleBulkMoveTargetBuildingProducts,
        }
      );
    }

    return buttons;
  }, [
    canEditDeviceList,
    canEditLendingReturn,
    handleBulkMoveTargetBuildingProducts,
    handleBulkRentProducts,
    handleBulkUpdateProducts,
  ]);
  // NOTE:データが揃ってから動作開始
  if (
    !hospitalSettings ||
    !hospitalRooms ||
    !hospitalWards ||
    !searchFilterResults ||
    !searchStatuses ||
    !searchRootCategories ||
    !searchSubCategories ||
    !variables ||
    !pageSize ||
    !query
  ) {
    return null;
  }

  return (
    <ListPageLayout page="products">
      <ListPageLayout.Header>
        <Grid container>
          <Grid item>
            <div>
              <PageRoutingMenu>
                <PageTitleTypography id="tutorial-products-list" variant={'h5'}>
                  機器
                </PageTitleTypography>
                <ArrowDropDown />
              </PageRoutingMenu>
            </div>
          </Grid>
          <FlexDiv />
          {canReadChannelList && (
            <Grid item>
              <NavButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/channels');
                }}>
                チャンネル一覧
              </NavButton>
            </Grid>
          )}
          <Grid item>
            <NavButton
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate('/products/parts');
              }}>
              部品・交換品一覧
            </NavButton>
          </Grid>

          <Grid item>
            <NavButton
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate('/products/whole_products');
              }}>
              院内機種一覧
            </NavButton>
          </Grid>
          <Grid item>
            {isOnline && canEditDeviceList && (
              <NestedMenuList
                buttonProps={{
                  sx: {
                    width: '100%',
                    color: 'white',
                    backgroundColor: '#0A52CC',
                    '&:hover': {
                      backgroundColor: '#0A52CC',
                    },
                  },
                }}
                menuItemList={NestedMenuListItems}
                onMenuClick={nestedMenuListClick}>
                機器を新規登録 <ArrowDropDown fontSize="small" />
              </NestedMenuList>
            )}
          </Grid>
        </Grid>
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <TableViewLayout>
          <TableViewLayout.Header>
            {/* Content */}
            {!isOnline && (
              <Typography variant="body1" style={{fontSize: '8pt', color: 'red'}}>
                オフラインでは一部機能が利用できません。
              </Typography>
            )}

            <TwoColumns alignItems="center" style={{marginTop: 8}}>
              <TwoColumns.Left>
                <Grid item style={{width: '400px', marginRight: '24px'}}>
                  <SearchTextField
                    label={'機種名・型式・管理番号・シリアル番号・GS1で検索'}
                    variant={'outlined'}
                    fullWidth
                    size={'small'}
                    InputProps={{
                      endAdornment: <Search />,
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                      },
                    }}
                    defaultValue={searchName}
                    onChange={handleSearchName}
                  />
                </Grid>

                {isOnline && (
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <PopperSelectBoxButton
                          buttonLabel={'稼働状況'}
                          options={includeDisposed ? productStatusOptions : productStatusOptionsWODisabled}
                          isMulti={true}
                          onChange={handleChangeStatuses}
                          searchable={true}
                          initialOption={searchStatuses}
                        />
                      </Grid>
                      <Grid item>
                        <PopperSelectBoxButton
                          buttonLabel={'大分類'}
                          options={CategoryFormatter.getOptions(rootCategoryQuery.data)}
                          isMulti={true}
                          onChange={handleChangeRootCategories}
                          searchable={true}
                          initialOption={searchRootCategories}
                        />
                      </Grid>
                      <Grid item>
                        <PopperSelectBoxButton
                          buttonLabel={'小分類'}
                          options={descendantCategoryOptions}
                          isMulti={true}
                          onChange={handleChangeSubCategories}
                          searchable={true}
                          initialOption={searchSubCategories}
                        />
                      </Grid>
                      {!matches && (
                        <Grid item>
                          <Button
                            color="inherit"
                            onClick={() => setOpenDrawer(true)}
                            className={isDetailFilterActive || openDrawer ? classes.filterBtnActive : undefined}>
                            <Tune className={classes.actionMenu} />
                            <span className={classes.actionMenu}>詳細で絞り込む</span>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </TwoColumns.Left>
              <TwoColumns.Right>
                {isOnline && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={includeDisposed}
                          onChange={handleSwitchIncludeDisposed}
                          name="disposed"
                          color="primary"
                        />
                      }
                      label={<FontSize14Span>廃棄機器を含む</FontSize14Span>}
                    />
                  </Grid>
                )}
                <Grid item style={{minWidth: '100px'}}>
                  <PopperMenuButton
                    buttonProps={{variant: 'contained', disableElevation: true, className: classes.actionBtn}}
                    menuItemList={actionMenuItems}
                    onMenuClick={handleActionMenuClick}>
                    アクション
                  </PopperMenuButton>
                </Grid>
                <Grid item>
                  <Button
                    style={{background: '#FF9800', color: '#FFF'}}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      navigate('/products/purchase_candidates');
                    }}>
                    機器売却査定
                  </Button>
                </Grid>
              </TwoColumns.Right>
            </TwoColumns>
          </TableViewLayout.Header>

          <TableViewLayout.Body>
            <Table<HospitalProductIndex>
              stickyHeader={true}
              columns={serializedTableColumn}
              isLoading={query.isLoading}
              data={queryDataToTableData(query.data, noteData)}
              showSelection={canEditDeviceList}
              selectionButtons={isOnline ? selectionButtons : []}
              onRowClick={handleClickRow}
              onOrderChange={handleOrderChange}
              noDataComponent={noDataComponent}
              defaultOrder={
                order ?? {
                  fieldId: 'managementId',
                  direction: 'asc',
                }
              }
              tableSize="small"
            />
          </TableViewLayout.Body>

          <TableViewLayout.Footer container justifyContent="space-between">
            <PageDescriptionGrid item>
              {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
            </PageDescriptionGrid>
            <PaginationContainerGrid item>
              <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
              <DisplayNumberSelect
                pageSize={pageSize}
                update={(selectNum) => {
                  setPageSize(selectNum);
                  setPage(1);
                }}
              />
            </PaginationContainerGrid>
            <EmptyGrid />
          </TableViewLayout.Footer>
        </TableViewLayout>
      </ListPageLayout.Content>

      <FilterDrawer
        open={openDrawer}
        onDrawerClose={handleDrawerClose}
        currentCount={query.totalCount}
        filterOptions={filterDrawerOptions}
        onFilterChange={handleAdditionalFilterResults}
        defaultFilterResults={searchFilterResults}
      />
    </ListPageLayout>
  );
};

const FontBoldP = styled('p')({
  fontWeight: 700,
});

const PageTitleTypography = styled(Typography)(({theme}: {theme: Theme}) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.primary.dark,
}));

const FontSize14Span = styled('span')({
  fontSize: 14,
});

const EmptyGrid = styled(Grid)({
  flex: 1,
});

const FlexDiv = styled('div')({
  flexGrow: 1,
});

const PaginationContainerGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
});

const PageDescriptionGrid = styled(Grid)({
  margin: 'auto 0px',
  flex: 1,
});

const SearchTextField = styled(TextField)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));

const NavButton = styled(Button)(({theme}: {theme: Theme}) => ({
  marginRight: '16px',
  backgroundColor: theme.palette.common.white,
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBtnActive: {
      backgroundColor: theme.palette.primary.light,
      '&&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    actionMenu: {
      fontWeight: 700,
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
  })
);
