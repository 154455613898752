import React, {useCallback, useEffect, useState} from 'react';
import {
  Accordion as MUIAccordion,
  AccordionSummary as MUIAccordionSummary,
  AccordionDetails as MUIAccordionDetails,
  Divider,
  Box,
  IconButton,
  SxProps,
} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';

const containerStyle: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const accordionStyle: SxProps = {
  margin: '0px',
  width: '100%',
  height: 'auto',
  background: 'transparent',
  boxShadow: 'none',
  '&.Mui-expanded': {
    margin: '0px',
  },
};

const accordionSummaryStyle: SxProps = {
  padding: '0px',
  height: '0px',
  minHeight: '0px',
  '&.Mui-expanded': {
    height: '0px',
    minHeight: '0px',
  },
  '& > div.MuiAccordionSummary-content': {
    margin: '0px',
  },
};

const accordionDetailStyle: SxProps = {
  padding: '0px',
};

const dividerContainerStyle: SxProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
};

const leftDividerStyle: SxProps = {
  width: '24px',
  backgroundColor: '#c6cbd4',
};

const dividerIconContainerStyle: SxProps = {
  cursor: 'pointer',
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  background: 'white',
  border: '1px solid #C6CBD4',
  '& > svg': {
    color: '#172B4D',
    width: '18px',
  },
  '&:hover': {
    background: '#2A96E8',
  },
  '&:hover > svg': {
    color: 'white',
    width: '18px',
  },
};

const rightDividerStyle: SxProps = {
  flex: '1 1',
  backgroundColor: '#c6cbd4',
};

// プロパティ定義
type Props = {
  children: React.ReactNode;
  defaultOpen?: boolean;
  onChange?: (open: boolean) => void;
};

/**
 * 上下矢印アイコンを持つアコーディオン
 * @components/molecules/Accordion/index.tsxのMuiV5対応版
 */
export const Accordion = ({children, defaultOpen = false, onChange}: Props) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  const handleClickDivider = useCallback(() => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);
    onChange && onChange(newExpanded);
  }, [expanded, onChange]);

  useEffect(() => {
    setExpanded(defaultOpen);
  }, [defaultOpen]);

  return (
    <Box sx={containerStyle}>
      <MUIAccordion sx={accordionStyle} expanded={expanded}>
        <MUIAccordionSummary sx={accordionSummaryStyle} />
        <MUIAccordionDetails sx={accordionDetailStyle}>{children}</MUIAccordionDetails>
      </MUIAccordion>

      <Box sx={dividerContainerStyle}>
        <Divider sx={leftDividerStyle} />
        <IconButton sx={dividerIconContainerStyle} onClick={handleClickDivider}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Divider sx={rightDividerStyle} />
      </Box>
    </Box>
  );
};
