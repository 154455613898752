import React, {useCallback, useState} from 'react';
import {Tab, Tabs, styled} from '@material-ui/core';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {InspectionTab} from '@organisms/InspectionTab';
import {RepairTab} from '@organisms/RepairTab';
import {useFetchHospitalProductFaultRepairHistoriesQuery} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchInspectionResultsQuery} from '@modules/inspection_results/api';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type Props = {
  hospitalProduct: HospitalProductIndex;
};

type TabType = 'inspection' | 'repair';

/**
 * 機器詳細右側の点検・修理予定タブ
 */
export const InspectionAndRepairTab = ({hospitalProduct}: Props) => {
  const {canRead: canReadInspection} = useUserResourcesPermissions('INSPECTION');
  const {canRead: canReadRepair} = useUserResourcesPermissions('REPAIR');
  const [tab, setTab] = useState<TabType>(canReadInspection ? 'inspection' : 'repair');
  const {myInfo} = useMyInfo();
  const faultQuery = useFetchHospitalProductFaultRepairHistoriesQuery(myInfo.hospitalHashId, hospitalProduct.hashId);
  const {
    data: inspectionResults,
    totalCount: inspectionResultsTotalCount,
    isFetched: inspectionResultsIsFetched,
  } = useFetchInspectionResultsQuery(myInfo.hospitalHashId, 'inspectionHashId', {
    hospitalProductHashId: hospitalProduct.hashId,
    statuses: 'unplanned,uncompleted',
    order: 'scheduledTime',
  });

  const tabs = React.useMemo(
    () =>
      [
        {
          label: '点検予定',
          count: inspectionResultsTotalCount ?? 0,
          value: 'inspection',
          isPermitted: canReadInspection,
        },
        {
          label: '修理予定',
          count: faultQuery.data?.totalCount ?? 0,
          value: 'repair',
          isPermitted: canReadRepair,
        },
      ].filter((v) => v.isPermitted),
    [canReadInspection, canReadRepair]
  );

  const handleChangeTab = useCallback(
    (_, key: TabType) => {
      setTab(key);
    },
    [setTab]
  );

  return (
    <div>
      <Tabs
        value={tab}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#172B4D',
          },
        }}
        onChange={handleChangeTab}
        style={{borderBottom: 'solid 1px #F0F2F5'}}>
        {tabs.map((item, index) => (
          <Tab
            label={
              <div>
                <span style={item.value === tab ? {color: '#172B4D', fontWeight: '600'} : {}}>{item.label}</span>
                {item.count > 0 && <StyledBadge>{item.count}</StyledBadge>}
              </div>
            }
            value={item.value}
            key={index}
          />
        ))}
      </Tabs>
      {tab === 'inspection' && (
        <InspectionTab
          wholeProductHashId={hospitalProduct?.wholeProductHashId || ''}
          hospitalProductHashId={hospitalProduct.hashId}
          isFetched={inspectionResultsIsFetched}
          inspectionResults={inspectionResults}
        />
      )}
      {tab === 'repair' && <RepairTab hospitalProductHashId={hospitalProduct.hashId} faultQuery={faultQuery} />}
    </div>
  );
};

const StyledBadge = styled('span')({
  backgroundColor: '#172B4D',
  color: 'white',
  marginLeft: '8px',
  padding: '1px 6px',
  borderRadius: '16px',
});
