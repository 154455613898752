import {PermissionLevel, PermissionResource, PERMISSION_RESOURCES} from '../types';
import {useMyInfo} from './useMyInfo';
import {useHeaderNavLinkItems} from '@Apps/BaseProductManger/useHeaderNavLinkItems';

type PermissionResult = {
  canRead: boolean;
  canEdit: boolean;
  canDelete: boolean;
};

const checkPermission = (isReadOnly: boolean, canDelete: boolean): PermissionLevel => {
  if (!isReadOnly) {
    return canDelete
      ? PermissionLevel.READ_EDIT_DELETE // 編集・削除可能
      : PermissionLevel.READ_EDIT; // 編集のみ可能
  }

  return canDelete
    ? PermissionLevel.NONE // 削除可能だが読み取り専用(この組み合わせは無いはず)
    : PermissionLevel.READ_ONLY; // 読み取りのみ可能
};

const checkResourcePermission = (resource: PermissionResource, resourceName: string): PermissionLevel => {
  if (resource.resource.name !== resourceName) return PermissionLevel.NONE;
  return checkPermission(resource.isReadOnly, resource.canDelete);
};

/**
 * 第二引数の対象ページ名から権限を返す
 * @param roleResources
 * @param resourceName
 * @returns
 */
const getResourcePermissionLevel = (roleResources: PermissionResource[], resourceName: string): PermissionLevel => {
  const result = roleResources.reduce((maxLevel, resource) => {
    const level = checkResourcePermission(resource, resourceName);
    return Math.max(maxLevel, level);
  }, PermissionLevel.NONE);

  return result;
};

const getPermissionFlags = (level: PermissionLevel): PermissionResult => ({
  canRead: level >= PermissionLevel.READ_ONLY,
  canEdit: level >= PermissionLevel.READ_EDIT,
  canDelete: level >= PermissionLevel.READ_EDIT_DELETE,
});

/**
 * 引数の機能に対するユーザーの権限を判定するカスタムフック。
 *
 * このフックは、ユーザーのロールとリソースの権限情報を取得し、
 * 指定されたリソースに対するユーザーのアクセスレベルを判定します。
 * 判定結果として、`canAccess`, `canRead`, `canEdit`, `canDelete` の4つのフラグを返します。
 *
 * @todo useUserPermissions.tsがすでにあるのでリネームする
 *
 * @param resource - 権限をチェックする対象のリソースのキー。
 *                   `RESOURCES` オブジェクトのキーである必要があります。
 *
 * @returns {PermissionResult} 指定されたリソースに対するユーザーの権限を示すフラグを含むオブジェクト:
 * - `canRead`: ユーザーがページを表示する権限を持つ場合に `true`
 * - `canEdit`: ユーザーがページ内の何項目を編集する権限を持つ場合に `true`
 * - `canDelete`: ユーザーがページ内の項目をを削除する権限を持つ場合に `true`
 *
 */
export const useUserResourcesPermissions = (resource: keyof typeof PERMISSION_RESOURCES): PermissionResult => {
  const {myResource} = useMyInfo();
  if (!myResource) return getPermissionFlags(PermissionLevel.NONE);

  const resourceKey = PERMISSION_RESOURCES[resource];
  if (!resourceKey) return getPermissionFlags(PermissionLevel.NONE);

  const level = getResourcePermissionLevel(myResource, resourceKey.id);
  return getPermissionFlags(level);
};

/**
 * Asset貸出返却専用ユーザーであればtrueを返す
 */
export const isRentalOnlyUser = () => {
  const accessibleResources = useAccessibleResources();
  return (
    accessibleResources.length === 1 && accessibleResources.some((v) => v.resource.name === 'lending_return_reception')
  );
};

/**
 * Asset病棟ユーザーであればtrueを返す
 */
export const isWardOnlyUser = () => {
  const accessibleResources = useAccessibleResources();
  return accessibleResources.length === 1 && accessibleResources.some((v) => v.resource.name === 'ward_service');
};

/**
 * 経営ダッシュボードにアクセスできるか
 * @returns
 */
export const useCanAccessMgmtDashboard = () => {
  const {myInfo} = useMyInfo();
  const {canRead: canReadManagementDashboard} = useUserResourcesPermissions('MANAGEMENT_DASHBOARD');
  return myInfo.canAccessMgmtDashboard && canReadManagementDashboard;
};

/**
 * ユーザーがアクセス可能なリソースを返す関数
 *
 * @param roleResources - 現在のユーザーに紐づいたリソース情報のリスト
 * @returns {ResourceItem[]} ユーザーがアクセス可能なリソースのリスト
 */
export const useAccessibleResources = (): PermissionResource[] => {
  const {myResource} = useMyInfo();

  if (!myResource) return [];

  // FIXME:APIからArray内に同じ値が返ってきたことがあるのでユニークになるようにまとめる
  const uniqueItemsMap = new Map<string, PermissionResource>();
  for (const item of myResource) {
    if (!uniqueItemsMap.has(item.resource.hashId)) {
      uniqueItemsMap.set(item.resource.hashId, item);
    }
  }

  const uniqueItemsList = Array.from(uniqueItemsMap.values());

  const accessibleResources = uniqueItemsList?.filter(
    (v) => v.resource.name !== 'login' && checkPermission(v.isReadOnly, v.canDelete) !== PermissionLevel.NONE
  );

  return accessibleResources ?? [];
};

/**
 * 権限の無いページのリダイレクト用として権限のあるページのパスを返す
 * @returns
 */
export const usePermittedRedirectPath = () => {
  const isWard = isWardOnlyUser();
  const isRental = isRentalOnlyUser();
  const defaultRedirectPath = isWard ? '/shared/reception_menu' : isRental ? '/shared' : '/account/profile';
  const headerNavLinkItem = useHeaderNavLinkItems();
  return headerNavLinkItem.find((v) => v.hasPermission)?.to || defaultRedirectPath;
};

/**
 * スマホは機器詳細と点検結果のみアクセス可能なので両方の権限で判別可能
 */
export const usePermittedForSp = () => {
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');
  const {canRead: canReadInspection} = useUserResourcesPermissions('INSPECTION');
  const {canRead: canReadWardService} = useUserResourcesPermissions('WARD_SERVICE');

  return {canReadProductList, canReadInspection, canReadWardService};
};
