import React from 'react';
import {Tab, Tabs} from '@material-ui/core';
import {HospitalUserPermission} from '@modules/auth/consts';

const tabMenu = [
  // {
  //   label: '機器関連コスト',
  //   value: 'cost_management',
  //   permissions: [HospitalUserPermission.ReadCostManagementDashboard],
  // },
  {
    label: '使用率',
    value: 'rental_ratio',
    permissions: [HospitalUserPermission.ReadRentalRatioManagementDashboard],
  },
  // {
  //   label: '年間故障率',
  //   value: 'repair_ratio',
  //   permissions: [HospitalUserPermission.ReadRepairRatioManagementDashboard],
  // },
] as const;

export type TabMenuKeys = (typeof tabMenu)[number]['value'];

export type DashboardMenuProps = {
  value: TabMenuKeys;
  onChangeTab: (value: TabMenuKeys) => void;
};

export const DashboardMenu: React.FC<DashboardMenuProps> = ({value, onChangeTab}) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: TabMenuKeys) => {
    onChangeTab(newValue);
  };

  return (
    <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange}>
      {tabMenu.map((item) => (
        <Tab key={item.value} label={item.label} value={item.value} />
      ))}
    </Tabs>
  );
};
