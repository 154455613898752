import {Box, Grid, Switch, SxProps, Typography} from '@mui/material';
import React, {useState} from 'react';
import {StyledSettingsTempPageSubTitle1} from '@components/templates/ContentLayout/InnerSidebarContentLayoutV5';
import {StyledFormHelperText, StyledSelectorTitleContainer, StyledTypography} from './FaultReceptionForm';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  putAcceptableProductsForReturnWaitingReceptions,
  useFetchAcceptableProductsForReturnWaitingReceptions,
} from '@modules/hospital_settings/api';
import {InnerLoading} from '@components/molecules/Loading';
import {ReturnWaitingReceptionCategory} from '@modules/hospital_settings/types';
import {openSnackBar} from '@front-libs/ui';
/**
 * インフォメーション部分
 */
const Information = () => {
  return (
    <Grid item>
      <StyledSettingsTempPageSubTitle1 variant={'h6'}>「返却待ち受付」の詳細設定</StyledSettingsTempPageSubTitle1>
      <StyledSelectorTitleContainer>
        <Typography>受付可能な機器（小分類）の設定</Typography>
        <StyledFormHelperText>機器（小分類）ごとに、返却待ち受付の可能/不可能を設定できます。</StyledFormHelperText>
      </StyledSelectorTitleContainer>
      <Box sx={{display: 'flex', alignItems: 'center', width: '300px', justifyContent: 'space-between'}}>
        <Typography>機器（大分類・小分類）</Typography>
        <StyledTypography>受付可能</StyledTypography>
      </Box>
    </Grid>
  );
};

type TextAndSwitchProp = {
  returnWaitingReceptionCategory: ReturnWaitingReceptionCategory;
  visibleChangeHandler: (hashId: string, visible: boolean) => void;
};

const TextAndSwitch = ({returnWaitingReceptionCategory, visibleChangeHandler}: TextAndSwitchProp) => {
  const [switchState, setSwitchState] = useState(returnWaitingReceptionCategory.isAcceptable);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
        height: '38px',
      }}>
      <Typography>{returnWaitingReceptionCategory.name}</Typography>
      <Switch
        checked={switchState}
        color="primary"
        onChange={() => {
          setSwitchState(!switchState);
          visibleChangeHandler(returnWaitingReceptionCategory.hashId, !switchState);
        }}
      />
    </Box>
  );
};

/**
 * 設定 > 病棟ユーザーメニュー > 「返却待ち受付」の詳細設定の 受付可能な機器（小分類）の設定
 * @returns
 */
export const ReturnWaitingReceptionDetailsForm = () => {
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useFetchAcceptableProductsForReturnWaitingReceptions(myInfo.hospitalHashId);

  const visibleChangeHandler = async (hashId: string, visible: boolean) => {
    try {
      await putAcceptableProductsForReturnWaitingReceptions(myInfo.hospitalHashId, {
        narrowCategoryHashId: hashId,
        isAcceptable: visible,
      });
      openSnackBar('設定内容を保存しました。');
    } catch (_e) {
      openSnackBar('設定内容の更新に失敗しました', 'error');
    }
  };

  return (
    <>
      <Information />
      {isLoading && <InnerLoading />}
      {data?.map((item, index) => (
        <React.Fragment key={`${item.rootCategoryHashId}${index}`}>
          <Typography>{item.rootCategoryName}</Typography>
          <Box sx={{margin: '8px 16px', width: 'calc(300px - 16px)'}}>
            {item.narrowCategories.map((childItem, childIndex) => (
              <TextAndSwitch
                key={`${childItem.hashId}${childIndex}`}
                returnWaitingReceptionCategory={childItem}
                visibleChangeHandler={visibleChangeHandler}
              />
            ))}
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};
