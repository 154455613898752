import React, {useCallback, useMemo} from 'react';
import {Theme, Grid, Button, makeStyles, styled} from '@material-ui/core';
import {getSymptomCategoryLabel} from '@modules/repairs/constants';
import {RepairIndex, RepairStatuses} from '@modules/repairs/types';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type Props = {
  repair: RepairIndex;
  repairStatuses: RepairStatuses[];
  onUpdateStatus: (toStatusHashId: string, toStatusName: React.ReactNode) => void;
  onClickRepairLink: (faultRepairHashId: string) => void;
};

export const RepairListItem = ({repair, repairStatuses, onUpdateStatus, onClickRepairLink}: Props) => {
  const {canEdit: canEditRepair} = useUserResourcesPermissions('REPAIR');
  const classes = useStyles();

  const symptom = useMemo(() => {
    const symptomLabel = getSymptomCategoryLabel(repair.symptomCategory ?? undefined);
    return symptomLabel !== '' ? symptomLabel : '未設定';
  }, [repair]);

  const requestForRepairAt = useMemo(
    () => (repair.requestForRepairAt ? dayjs(repair.requestForRepairAt).format('YYYY/MM/DD HH:mm') : ''),
    [repair]
  );

  const statusOptions = useMemo(
    () =>
      repairStatuses.map((item) => ({
        value: item.hashId,
        label: item.statusName,
      })),
    [repairStatuses]
  );

  const handleUpdateStatus = useCallback(
    (item: MenuItemType) => onUpdateStatus(item.value, item.label),
    [onUpdateStatus]
  );

  const handleClickSymptomButton = useCallback(() => {
    onClickRepairLink(repair.hashId);
  }, [onClickRepairLink, repair.hashId]);

  return (
    <RepairContent>
      <RepairTitleSection>
        <RepairTitleButton onClick={handleClickSymptomButton}>{symptom}</RepairTitleButton>
      </RepairTitleSection>
      <RepairStatus>
        ステータス：
        {canEditRepair ? (
          <PopperMenuButton
            onMenuClick={handleUpdateStatus}
            containerProps={{style: {display: 'inline-flex'}}}
            buttonProps={{color: 'inherit', className: clsx(classes.actionMenu, classes.link)}}
            menuItemList={statusOptions}>
            {repairStatuses.find((item) => item.hashId === repair?.statusHashId)?.statusName ?? ''}
          </PopperMenuButton>
        ) : (
          repairStatuses.find((item) => item.hashId === repair?.statusHashId)?.statusName ?? ''
        )}
      </RepairStatus>
      <RepairItemGrid>修理番号：{repair.repairRegistrationNumber}</RepairItemGrid>
      <RepairItemGrid>修理受付日時：{requestForRepairAt}</RepairItemGrid>
    </RepairContent>
  );
};

const RepairContent = styled(Grid)({
  marginBottom: '24px',
  fontSize: '14px',
});

const RepairTitleSection = styled(Grid)({
  marginBottom: '7px',
});

const RepairTitleButton = styled(Button)({
  padding: '0 4px',
  color: '#172B4D',
  fontWeight: 700,
  marginLeft: '-4px',
});

const RepairStatus = styled(Grid)({
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  height: '19px',
  lineHeight: '19px',
});

const RepairItemGrid = styled(Grid)({
  marginBottom: '8px',
});

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.dark,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  actionMenu: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));
