import React from 'react';
import {styled, Box, Button, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {Error} from '@mui/icons-material';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';

type Props = DialogProps & {
  title: string;
  content: React.ReactNode;
};

const Message = styled(Box)({
  marginTop: '24px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const AlertDialog = ({open, actions, title, content}: Props) => {
  const handleCancel = () => {
    actions.reject();
  };

  const handleSubmit = () => {
    actions.resolve(true);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={!!open}
      PaperProps={{
        sx: {minWidth: '634px', padding: '32px', fontSize: '14px', color: ' #172B4D'},
      }}>
      <DialogTitle sx={{display: 'flex', alignItems: 'center', padding: 0, fontWeight: 700}}>
        <Error sx={{marginRight: '8px', color: '#C7243A'}} />
        {title}
      </DialogTitle>
      <Message>{content}</Message>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleCancel}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#C7243A', '&:hover': {backgroundColor: '#C7243A'}}}
          onClick={handleSubmit}>
          実行する
        </Button>
      </Actions>
    </Dialog>
  );
};
