import React from 'react';
import {styled, Box, Table, TableHead, TableBody, Typography, Pagination} from '@mui/material';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';
import {TraineeListTableHeadRow} from './TraineeListTableHeadRow';
import {TraineeListTableBodyRow, TraineeListTableBodyNoRow} from './TraineeListTableBodyRow';
import {perPageOptions} from './constants';
import {TraineeDataRow} from './types';

type Props = {
  trainees: TraineeDataRow[];
  page: number;
  perPage: number;
  totalCount: number;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  onEditMenuClick: (hashId: string) => void;
  onDeleteMenuClick: (hashId: string) => void;
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  width: '100%',
});

const RoundedBox = styled(Box)({
  flex: 1,
  overflow: 'auto',
  borderRadius: '3px',
  width: '100%',
});
const StyledTable = styled(Table)({
  color: '#172B4D',
});
const StyledTableHead = styled(TableHead)({
  '& > tr': {
    height: '40px',
  },
});
const StyledTableBody = styled(TableBody)({
  '& > tr': {
    height: '40px',
  },
});

const PaginationBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '16px',
  width: '100%',
});
const OutOfTotal = styled(Box)({
  flex: 1,
  '& > p': {
    fontSize: '12px',
    color: '#65676B',
  },
});
const Pager = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});
const BlankBox = styled(Box)({
  flex: 1,
});

export const TraineeListPaginationTable = ({
  trainees,
  page,
  perPage,
  totalCount,
  onPageChange,
  onPerPageChange,
  onEditMenuClick,
  onDeleteMenuClick,
}: Props) => {
  const from = perPage * (page - 1) + 1;
  const fromCount = from > totalCount ? totalCount : from;
  const to = perPage * page;
  const toCount = to > totalCount ? totalCount : to;
  const totalPage = Math.ceil(totalCount / perPage) || 1;

  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    onPageChange(newPage);
  };
  const handlePerPageChange = (newPageSize: number) => {
    onPerPageChange(newPageSize);
  };

  const handleEditMenuClick = (hashId: string) => {
    onEditMenuClick(hashId);
  };
  const handleDeleteMenuClick = (hashId: string) => {
    onDeleteMenuClick(hashId);
  };

  return (
    <Container>
      <RoundedBox>
        <StyledTable stickyHeader>
          <StyledTableHead>
            <TraineeListTableHeadRow />
          </StyledTableHead>
          <StyledTableBody>
            {trainees.length === 0 && <TraineeListTableBodyNoRow />}
            {trainees.map((trainee) => (
              <TraineeListTableBodyRow
                key={trainee.hashId}
                trainee={trainee}
                onEditMenuClick={handleEditMenuClick}
                onDeleteMenuClick={handleDeleteMenuClick}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </RoundedBox>
      <PaginationBox>
        <OutOfTotal>
          <Typography>
            {totalCount}件のうち{fromCount}件目-{toCount}件目
          </Typography>
          <Typography>までを表示しています</Typography>
        </OutOfTotal>
        <Pager>
          <Pagination page={page} count={totalPage} shape="rounded" onChange={handlePageChange} />
          <DisplayNumberSelect pageSize={perPage} pageSizeMenuItems={perPageOptions} update={handlePerPageChange} />
        </Pager>
        <BlankBox />
      </PaginationBox>
    </Container>
  );
};
