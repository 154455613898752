import React, {useMemo} from 'react';
import Selector from 'react-select';
import {FormControl} from '@mui/material';
import {HospitalDepartMent} from '@modules/hospital_departments/types';
import {theme} from '@atoms/theme';

type Props = {
  placeholder?: string;
  departments: HospitalDepartMent[];
  selectedHashId: string | null;
  onSelect: (hashId: string | null) => void;
};

type Option = {
  label: string;
  value: string;
};

export const HospitalDepartmentSelector = ({placeholder, departments, selectedHashId, onSelect}: Props) => {
  const options: Option[] = useMemo(
    () =>
      departments.map((department) => ({
        label: department.name,
        value: department.hashId,
      })),
    [departments]
  );
  const selectedOption = useMemo(() => options.find((o) => o.value === selectedHashId), [options, selectedHashId]);

  const handleSelect = (option: Option | null) => {
    onSelect(option?.value ?? null);
  };

  return (
    <FormControl variant="outlined" size={'small'} fullWidth>
      <Selector<Option, false>
        isClearable
        menuPortalTarget={document.body}
        size="small"
        placeholder={placeholder}
        styles={{
          control: (provided) => ({...provided, minHeight: '40px'}),
          menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal * 10}),
        }}
        value={selectedOption}
        options={options}
        onChange={handleSelect}
        components={{IndicatorSeparator: null}}
      />
    </FormControl>
  );
};
