import React, {useState} from 'react';
import {styled, TableRow, TableCell, IconButton, Menu, MenuItem} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import {TraineeDataRow} from './types';

type Props = {
  trainee: TraineeDataRow;
  onEditMenuClick: (hashId: string) => void;
  onDeleteMenuClick: (hashId: string) => void;
};

const Tr = styled(TableRow)({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F4F6',
  },
});
const Td = styled(TableCell)({
  borderBottom: 0,
  borderTop: '1px solid #F0F2F5',
  padding: '0px 16px 0px 0px',
  backgroundColor: 'inherit',
  color: 'inherit',
});

const StyledMenuItem = styled(MenuItem)({
  color: '#172B4D',
  fontSize: '14px',
});

export const TraineeListTableBodyRow = ({
  trainee: {hashId, name, employeeNumber, departmentName},
  onEditMenuClick,
  onDeleteMenuClick,
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMnuClose = () => {
    closeMenu();
  };
  const handleEditMenuClick = () => {
    onEditMenuClick(hashId);
    closeMenu();
  };
  const handleDeleteMenuClick = () => {
    onDeleteMenuClick(hashId);
    closeMenu();
  };

  return (
    <Tr>
      <Td align="center" sx={{padding: 0, width: '64px', color: '#5D6B82'}}>
        <IconButton sx={{padding: 0}} onClick={handleMenuIconClick}>
          <MoreVert />
        </IconButton>
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMnuClose}>
          <StyledMenuItem onClick={handleEditMenuClick}>編集</StyledMenuItem>
          <StyledMenuItem onClick={handleDeleteMenuClick}>削除</StyledMenuItem>
        </Menu>
      </Td>
      <Td align="left">{employeeNumber}</Td>
      <Td align="left">{name}</Td>
      <Td align="left">{departmentName}</Td>
    </Tr>
  );
};

export const TraineeListTableBodyNoRow = () => (
  <TableRow>
    <TableCell colSpan={4} align="center" sx={{backgroundColor: '#FFFFFF', color: 'inherit', fontWeight: 700}}>
      研修対象者・受講者が登録されていません
    </TableCell>
  </TableRow>
);
