import {axios} from '@front-libs/core';
import {
  ListTrainingScheduleTraineesResponse,
  ListTrainingScheduleTraineesRequest,
  UpdateTrainingScheduleTraineeRequest,
  UpdateTrainingScheduleTraineeResponse,
} from '@modules/training_schedule_trainees/types';

/** 指定した研修予定の研修対象者・受講者一覧を返す。 */
export const listTrainingScheduleTrainees = async (
  hospitalHashId: string,
  trainingScheduleHashId: string,
  params: ListTrainingScheduleTraineesRequest
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/trainees`;
  const {data} = await axios.get<ListTrainingScheduleTraineesResponse>(url, {params});
  return data;
};

/** 指定した研修対象者・受講者を更新する。 */
export const updateTrainingScheduleTrainee = async (
  hashId: string,
  hospitalHashId: string,
  trainingScheduleHashId: string,
  req: UpdateTrainingScheduleTraineeRequest
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/trainees/${hashId}`;
  const {data} = await axios.put<UpdateTrainingScheduleTraineeResponse>(url, req);
  return data;
};

/** 指定した研修対象者・受講者を削除する。 */
export const deleteTrainingScheduleTrainee = async (
  hashId: string,
  hospitalHashId: string,
  trainingScheduleHashId: string
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/trainees/${hashId}`;
  await axios.delete(url);
};

/** 指定した研修予定の研修対象者・受講者を一括登録するためのテンプレート出力をリクエストする。 */
export const exportTemplateForImportTrainees = async (hospitalHashId: string, trainingScheduleHashId: string) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/trainees/tasks/export_template_for_import`;
  await axios.post(url);
};
