import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Box, Stack} from '@mui/material';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {PurchaseRequestHeader} from './components/PurchaseRequestHeader';
import {PurchaseRequestFooter} from './components/PurchaseRequestFooter';
import {PurchaseRequestPageHeader} from './components/PurchaseRequestPageHeader';
import {usePurchaseRequestState} from './hooks/usePurchaseRequestState';
import {useCurrentStepContent} from './hooks/useCurrentStepContent';
import {actionType} from './states';
import {useNextButtonLabel} from './hooks/useNextButtonLabel';
import {useCanShowPreviousButton} from './hooks/useCanShowPreviousButton';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useCurrentStepLabel} from './hooks/useCurrentStepLabel';
import {FormProvider, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {CreatePurchaseRequestFormData, createPurchaseRequestRequestSchema} from './types';
import {createPurchaseRequest} from '@modules/purchase_request/api';
import {isNullish} from '@front-libs/helpers';

export const PurchaseRequest = () => {
  const {hospitalProductHashId} = useParams();
  const navigate = useNavigate();
  const {myInfo, myHospitalInfo} = useMyInfo();

  const methods = useForm<CreatePurchaseRequestFormData>({
    resolver: zodResolver(createPurchaseRequestRequestSchema),
    mode: 'onChange',
    defaultValues: {
      hospitalName: '',
      requesterName: '',
      departmentName: '',
      contactMethod: 'email', // 初期値はメールを選択
      email: '',
      phoneNumber: '',
      contactRemarks: '',
    },
  });

  const {
    control,
    formState: {isValid, isDirty, isSubmitting},
    reset,
  } = methods;

  const canSubmit = isValid && isDirty && !isSubmitting;

  const content = useCurrentStepContent({
    control,
  });
  const nextButtonLabel = useNextButtonLabel();
  const {dispatch, purchaseRequestState} = usePurchaseRequestState();
  const showPreviousButton = useCanShowPreviousButton();
  const {stepLabel, showSubtitle} = useCurrentStepLabel();

  useEffect(() => {
    reset({
      hospitalName: myHospitalInfo?.name ?? '',
      requesterName: UserFormatter.getFullName(myInfo),
      departmentName: '',
      contactMethod: 'email', // 初期値はメールを選択
      email: '',
      phoneNumber: '',
      contactRemarks: '',
    });
    return () => {
      // unmount時にフォームをリセットする
      reset();
      dispatch({type: actionType.cancel});
    };
  }, [myHospitalInfo, myInfo]);

  const handleCancel = () => {
    dispatch({type: actionType.cancel});
    navigate('/products/purchase_candidates');
  };

  const handleNextButtonClick = async () => {
    // 最終ステップ以外の場合、次のステップに進む
    // 最終ステップの場合、dispatch前にAPIリクエストなどの処理を完了したい
    if (purchaseRequestState.currentStep !== 'confirmContent') {
      dispatch({type: actionType.proceedStep});

      return;
    }

    if (isNullish(myHospitalInfo) || isNullish(hospitalProductHashId)) {
      return;
    }

    // 最終ステップで送信ボタンを押した場合、APIリクエストを送信する
    const values = methods.getValues();
    await createPurchaseRequest({
      hospitalHashId: myHospitalInfo.hashId,
      hospitalProductHashId: hospitalProductHashId,
      ...values,
      email: values.email ?? '',
      phoneNumber: values.phoneNumber ?? '',
      contactRemarks: values.contactRemarks ?? '',
    });

    reset();
    navigate('/products/purchase_request/complete');

    // 最終ステップの場合、諸々処理が完了してからdispatchする
    dispatch({type: actionType.proceedStep});
  };

  return (
    <Box sx={{width: '100%', background: '#FFF', minHeight: 'calc(100vh - 56px)'}}>
      <PurchaseRequestHeader />

      <Stack sx={{justifyContent: 'flex-start', alignItems: 'center', marginBottom: '96px'}}>
        <Box>
          <PurchaseRequestPageHeader stepLabel={stepLabel} showSubtitle={showSubtitle} />
          <FormProvider {...methods}>{content}</FormProvider>
        </Box>
      </Stack>

      <PurchaseRequestFooter
        showPreviousButton={showPreviousButton}
        nextButtonLabel={nextButtonLabel}
        onCancelButtonClick={handleCancel}
        onPreviousButtonClick={() => dispatch({type: actionType.returnToPreviousStep})}
        onNextButtonClick={handleNextButtonClick}
        disabledNextButtonLabel={!canSubmit}
      />
    </Box>
  );
};
