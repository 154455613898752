import React, {useCallback, useMemo, useState} from 'react';
import {Button, Grid, makeStyles, Paper, Theme, Typography} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {FormFieldSection} from './FormFieldSection';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {getSymptomCategoryLabel, symptomCategoryOptions} from '@modules/repairs/constants';
import {RepairIndex, SymptomCategoryType} from '@modules/repairs/types';
import dayjs from 'dayjs';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';
import {formSection} from '../constants';
import {useFaultRepairPropertyRequirements, useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {deleteFaultRepair, exportFaultRepairPdf, updateFaultRepair} from '@modules/repairs/api';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {PropertyProposalDialog} from '@organisms/FaultRepairs/dialogs/PropertyProposalDialog';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {openSnackBar} from '@molecules/SnackBar';
import {CategoryFormatter} from '@modules/categories/helpers';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {isNullish} from '@front-libs/helpers';
import {useSelectableHospitalUsers} from '@modules/hospital_users/hooks/useSelectableHospitalUsers';
import {InnerLoading} from '@components/molecules/Loading';
import {useHospitalDepartmentsOptions} from '@modules/hospital_departments/hooks';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {fetchFileUrlByPath} from '@modules/files/api';

const actionMenuItems = [
  {
    label: 'PDFをダウンロード',
    value: 'download_pdf',
  },
  {
    label: '削除',
    value: 'delete',
  },
];

type Props = {
  faultRepair: RepairIndex | null;
  onUpdateComplete?: () => void;
};

/**
 * 修理詳細画面の左側ナビゲーション・メニュー
 * @param param0
 * @returns
 */
export const SideNav: React.FC<Props> = ({faultRepair, onUpdateComplete}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const {canEdit: canEditRepair, canDelete: canDeleteRepair} = useUserResourcesPermissions('REPAIR');
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');
  const {hospitalUsers} = useHospitalUsers();
  const selectableHashID = faultRepair?.personInChargeHashId ?? '';
  const {isLoading: selectableUsersIsLoading, selectableHospitalUsers} = useSelectableHospitalUsers(selectableHashID);
  const {data: faultRepairPropertyRequirements} = useFaultRepairPropertyRequirements();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const {data: repairStatuses} = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const {hospitalDepartmentOptions, isLoading: hospitalDepartmentIsLoading} = useHospitalDepartmentsOptions(
    faultRepair?.hospitalDepartment?.hashId,
    true
  );

  const statusOptions = useMemo(
    () =>
      repairStatuses.map((item) => ({
        value: item.hashId,
        label: item.statusName,
      })),
    [repairStatuses]
  );

  const status = useMemo(
    () => repairStatuses.find((item) => item.hashId === faultRepair?.statusHashId),
    [faultRepair?.statusHashId, repairStatuses]
  );

  const handleClickProductsLink = useCallback(() => {
    navigate('/repairs');
  }, [navigate]);

  const handleDownloadPdf = useCallback(async () => {
    if (isNullish(faultRepair)) return;

    try {
      const res = await exportFaultRepairPdf(myInfo.hospitalHashId, faultRepair.hashId);

      const {redirectUrl} = await fetchFileUrlByPath(res.data.s3DownloadPath);
      if (isNullish(redirectUrl)) return;

      const link = document.createElement('a');
      link.href = redirectUrl;
      link.target = 'downloadIframe';
      link.setAttribute('download', '');
      link.click();
    } catch (_e) {
      openSnackBar('修理情報のダウンロードに失敗しました', 'left', 'bottom', 'error');
    }
  }, [faultRepair, myInfo.hospitalHashId, navigate]);

  const handleDeleteProduct = useCallback(async () => {
    if (isNullish(faultRepair)) return;
    await deleteFaultRepair(myInfo.hospitalHashId, faultRepair.hashId);
    navigate('/repairs');
    openSnackBar('修理情報を削除しました');
  }, [faultRepair, myInfo.hospitalHashId, navigate]);

  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'download_pdf':
          handleDownloadPdf();
          break;
        case 'delete':
          setOpenDeleteDialog(true);
          break;
      }
    },
    [handleDownloadPdf]
  );

  const handleUpdateSymptomCategory = useCallback(
    async (item: MenuItemType) => {
      if (isNullish(faultRepair)) return;
      await updateFaultRepair(myInfo.hospitalHashId, faultRepair.hospitalProductHashId, faultRepair.hashId, {
        ...faultRepair,
        symptomCategory: item.value as SymptomCategoryType,
      });
      if (onUpdateComplete) onUpdateComplete();
      openSnackBar('事象区分を更新しました');
    },
    [faultRepair, myInfo.hospitalHashId, onUpdateComplete]
  );

  const handleUpdateStatus = useCallback(
    async (item: MenuItemType) => {
      if (isNullish(faultRepair) || isNullish(faultRepairPropertyRequirements)) return;

      const proposalProps = faultRepairPropertyRequirements
        .filter((proposal) => proposal.faultRepairStatusHashId === item.value)
        ?.sort((a, b) => a.sequence - b.sequence);

      const validatedRepair = await dialogHandler.open(PropertyProposalDialog, {
        statusName: item.label as string,
        propertyRequirements: proposalProps,
        defaultFaultRepair: faultRepair,
        hospitalUsers,
      });

      await updateFaultRepair(myInfo.hospitalHashId, faultRepair.hospitalProductHashId, faultRepair.hashId, {
        ...validatedRepair,
        statusHashId: item.value,
      });
      if (onUpdateComplete) onUpdateComplete();
      openSnackBar('ステータスを更新しました');
    },
    [faultRepair, faultRepairPropertyRequirements, hospitalUsers, myInfo.hospitalHashId, onUpdateComplete]
  );

  return (
    <Paper className={classes.root}>
      <AlertDialog
        title={'修理を削除しますか？'}
        positiveButtonLabel={'修理を削除'}
        content={`「${faultRepair?.faultHospitalProduct.displayName}」の修理を削除しようとしています。\n\nこのアクションは元に戻せません。`}
        open={openDeleteDialog}
        actions={{
          resolve: handleDeleteProduct,
          reject: async () => {
            setOpenDeleteDialog(false);
          },
        }}
      />
      <Grid container justifyContent={'space-between'} className={classes.actionMenuContainer}>
        <Grid item>
          <Button color="inherit" className={classes.actionMenu} onClick={handleClickProductsLink}>
            <ChevronLeft />
            <span>修理一覧</span>
          </Button>
        </Grid>
        <Grid item>
          <PopperMenuButton
            buttonProps={{color: 'inherit', className: classes.actionMenu}}
            menuItemList={canDeleteRepair ? actionMenuItems : actionMenuItems.filter((v) => v.value !== 'delete')}
            onMenuClick={handleActionMenuClick}>
            アクション
          </PopperMenuButton>
        </Grid>
      </Grid>
      <Grid container className={classes.productThumbnail} direction={'column'}>
        <Grid item>
          <Typography style={{fontWeight: 700, fontSize: '18px'}}>
            {faultRepair?.faultHospitalProduct.displayName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{fontSize: '12px'}}>
            修理番号：{faultRepair ? faultRepair?.repairRegistrationNumber ?? '' : ''}
          </Typography>
        </Grid>
        {faultRepair?.requestForRepairAt && (
          <Grid item>
            <Typography style={{fontSize: '12px'}}>
              修理受付日から{dayjs(new Date()).diff(faultRepair?.requestForRepairAt, 'day')}日
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.productProfile} direction={'column'}>
        <Grid item className={classes.marginY}>
          <Typography variant={'body2'}>
            大分類：
            {faultRepair ? CategoryFormatter.getRootCategory(faultRepair.faultHospitalProduct.categories)?.name : ''}
          </Typography>
        </Grid>
        <Grid item className={classes.marginY}>
          <Typography variant={'body2'}>
            小分類：
            {faultRepair ? CategoryFormatter.getNarrowCategory(faultRepair.faultHospitalProduct.categories)?.name : ''}
          </Typography>
        </Grid>
        <Grid item className={classes.marginY}>
          <Typography variant={'body2'}>
            管理番号：
            {canReadProductList ? (
              <NavLink className={classes.link} to={`/products/${faultRepair?.faultHospitalProduct.hashId ?? ''}`}>
                {faultRepair ? faultRepair?.faultHospitalProduct.managementId ?? '' : ''}
              </NavLink>
            ) : (
              faultRepair?.faultHospitalProduct.managementId ?? ''
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>
            事象区分：
            {canEditRepair ? (
              <PopperMenuButton
                onMenuClick={handleUpdateSymptomCategory}
                containerProps={{style: {display: 'inline-flex'}}}
                buttonProps={{color: 'inherit', className: clsx(classes.actionMenu, classes.link)}}
                menuItemList={symptomCategoryOptions}>
                {faultRepair ? getSymptomCategoryLabel(faultRepair.symptomCategory ?? undefined) : ''}
              </PopperMenuButton>
            ) : isNullish(faultRepair) ? (
              ''
            ) : (
              getSymptomCategoryLabel(faultRepair.symptomCategory ?? undefined)
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>
            ステータス：
            {canEditRepair ? (
              <PopperMenuButton
                onMenuClick={handleUpdateStatus}
                containerProps={{style: {display: 'inline-flex'}}}
                buttonProps={{color: 'inherit', className: clsx(classes.actionMenu, classes.link)}}
                menuItemList={statusOptions}>
                {status?.statusName ?? ''}
              </PopperMenuButton>
            ) : (
              status?.statusName ?? ''
            )}
          </Typography>
        </Grid>
        <Grid item className={classes.marginY}>
          <Typography variant={'body2'}>
            カード作成者：
            {faultRepair ? UserFormatter.getFullName(faultRepair?.createdBy) : '未設定'}
          </Typography>
        </Grid>
      </Grid>
      {selectableUsersIsLoading || hospitalDepartmentIsLoading ? (
        <InnerLoading />
      ) : (
        formSection(selectableHospitalUsers, hospitalDepartmentOptions).map((section, index) => {
          if (section.sectionName === '廃棄情報' && status?.statusType !== 'archive') {
            return null;
          }
          return (
            <FormFieldSection
              sectionName={section.sectionName}
              fields={section.fields}
              key={section.sectionName + index}
            />
          );
        })
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 82px)',
    paddingBottom: '82px',
    overflowY: 'auto',
  },
  link: {
    color: theme.palette.secondary.dark,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  actionMenuContainer: {
    paddingTop: '8px',
  },
  actionMenu: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  productThumbnail: {
    padding: '16px 16px 4px',
  },
  productProfile: {
    padding: '8px 16px',
  },
  marginY: {
    margin: '8px 0',
  },
}));
