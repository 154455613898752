import {useMemo, useReducer} from 'react';
import {useQuery} from 'react-query';
import {
  getFaultRepairKey,
  getFaultRepairLogsKey,
  getFaultRepairsKey,
  getFaultRepairsStatusesKey,
  getFaultRepairPropertyRequirementsKey,
  getFaultRepairRatioKey,
} from '@constants/api';
import {
  FetchFaultRepairPropertyRequrementsParams,
  RepairStatuses,
  FetchFaultRepairsParams,
  PropertyRequirements,
  RepairStatusActionType,
} from './types';
import {getFaultRepairs, getFaultRepairStatuses, listFaultRepairLog, getFaultRepairPropertyRequirements} from './api';
import {getFaultRepair} from './api/faultRepairApi';
import {useMyInfo} from '../hospital_users/hooks/useMyInfo';
import {listHospitalProductFaultRepairLog} from './api/faultRepairLogApi';
import {
  GetHospitalFaultRatioByRootCauseCategoryResult,
  GetHospitalFaultRatioBySymptomCategoryResult,
  GetHospitalFaultRatioParam,
  getHospitalFaultRatioByRootCauseCategory,
  getHospitalFaultRatioBySymptomCategory,
} from './api/faultRatioApi';
import {repairStatusReducer, repairStatusInitialState} from './reducers';
import {ReactQueryOption} from '@front-libs/core';

export const useFetchFaultRepairQuery = (hospitalHashId: string, faultRepairHashId: string) => {
  return useQuery([getFaultRepairKey, hospitalHashId, faultRepairHashId], () =>
    getFaultRepair(hospitalHashId, faultRepairHashId)
  );
};

export const useFetchFaultRepairsQuery = (hospitalHashId: string, params: FetchFaultRepairsParams) => {
  const query = useQuery([getFaultRepairsKey, hospitalHashId, params], () => getFaultRepairs(hospitalHashId, params));
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchHospitalProductFaultRepairLogsQuery = (
  hospitalHashId: string,
  params: {
    hospitalProductHashId: string;
    page?: number;
    perPage?: number;
  }
) => {
  const query = useQuery([getFaultRepairLogsKey, hospitalHashId, params], () =>
    listHospitalProductFaultRepairLog(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchFaultRepairLogsQuery = (
  hospitalHashId: string,
  faultRepairHashId: string,
  params: Partial<{
    page: number;
    perPage: number;
    order: string;
  }>
) => {
  const query = useQuery([getFaultRepairLogsKey, hospitalHashId, faultRepairHashId], () =>
    listFaultRepairLog(hospitalHashId, faultRepairHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchFaultRepairStatuses = (hospitalHashId: string) => {
  const query = useQuery([getFaultRepairsStatusesKey, hospitalHashId], () => getFaultRepairStatuses(hospitalHashId));
  const data = useMemo(() => query.data ?? {data: []}, [query]);
  return {...data};
};

export const useFaultRepairPropertyRequirements = (params: FetchFaultRepairPropertyRequrementsParams = {}) => {
  const {myInfo} = useMyInfo();
  return useQuery(
    [getFaultRepairPropertyRequirementsKey, myInfo.hospitalHashId, params],
    async () => await getFaultRepairPropertyRequirements(myInfo.hospitalHashId, params)
  );
};

export const useRepairStatusMap = (repairStatuses: RepairStatuses[]) => {
  const repairStatusesMap = useMemo(
    () => Object.fromEntries(repairStatuses.map((item) => [item.hashId, item.statusName])),
    [repairStatuses]
  );

  return {
    repairStatusesMap,
  };
};

export const useRepairPropertyRequirementsMap = (propertyRequirements: PropertyRequirements[]) => {
  const propertyRequirementsMap = useMemo(
    () => Object.fromEntries(propertyRequirements.map((item) => [item.hashId, item.property])),
    [propertyRequirements]
  );

  return {
    propertyRequirementsMap,
  };
};

type SymptomCategoryFaultRatioType = {
  type: 'symptomCategory';
  options?: ReactQueryOption<GetHospitalFaultRatioBySymptomCategoryResult>;
};

type RootCauseCategoryFaultRatioType = {
  type: 'rootCauseCategory';
  options?: ReactQueryOption<GetHospitalFaultRatioByRootCauseCategoryResult>;
};

export type FaultRatioQueryProps = {
  params: GetHospitalFaultRatioParam;
} & (SymptomCategoryFaultRatioType | RootCauseCategoryFaultRatioType);

export const useFaultRatioQuery = (props: FaultRatioQueryProps) => {
  const queryForSymptom = useQuery(
    [getFaultRepairRatioKey, props],
    () => {
      return getHospitalFaultRatioBySymptomCategory(props.params);
    },
    {
      ...(props.options as ReactQueryOption<GetHospitalFaultRatioBySymptomCategoryResult>),
      enabled: props.options?.enabled && props.type === 'symptomCategory',
    }
  );
  const queryForRootCause = useQuery(
    [getFaultRepairRatioKey, props],
    () => {
      return getHospitalFaultRatioByRootCauseCategory(props.params);
    },
    {
      ...(props.options as ReactQueryOption<GetHospitalFaultRatioByRootCauseCategoryResult>),
      enabled: props.options?.enabled && props.type === 'rootCauseCategory',
    }
  );
  return props.type === 'symptomCategory' ? queryForSymptom : queryForRootCause;
};

type RepairStatusStoreType = {
  dispatchListRepairStatuses: (hospitalHashId: string) => Promise<RepairStatuses[]>;
  get: (hashId: string) => RepairStatuses | null;
};

export const useRepairStatusStore = (): RepairStatusStoreType => {
  const [state, dispatch] = useReducer(repairStatusReducer, repairStatusInitialState);

  const dispatchListRepairStatuses = async (hospitalHashId: string) => {
    const res = await getFaultRepairStatuses(hospitalHashId);
    dispatch({
      type: RepairStatusActionType.REPAIR_STATUS_SET_MAP,
      payload: {map: new Map(res.data.map((item) => [item.hashId, item]))},
    });
    return res.data;
  };

  const get = (hashId: string) => {
    return state.map.get(hashId) ?? null;
  };

  return {
    dispatchListRepairStatuses,
    get,
  };
};
