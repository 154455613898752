import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {VerticalPairTable} from '@Apps/BaseSharedMenu/VerticalPairTable';
import {RentButton} from '@Apps/BaseSharedMenu/RentDevices/RentButton';
import {RentProductTable} from '@Apps/BaseSharedMenu/RentDevices/RentProductTable';
import {lenderNameAtom, rentalSettingAtom, rentRoomAtom, rentStepperAtom} from '@Apps/BaseSharedMenu/states';
import {useAsyncEffect} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {bulkCheckout, CheckoutRentalData, DeviceLender} from '@modules/rentals/api';
import {Box, SxProps, Theme} from '@mui/material';
import {contentFixedStyle} from '@templates/RentalTemplate';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {scannedRentalTargetHospitalProductsAtom} from '../states';

/**
 * カメラ読み取り後の貸出画面
 */
export const ConfirmCheckoutRentDevice: React.FC = () => {
  const {myInfo} = useMyInfo();
  const [rentalTargetHospitalProducts, setRentalTargetHospitalProducts] = useAtom(
    scannedRentalTargetHospitalProductsAtom
  );
  const rentRoom = useAtomValue(rentRoomAtom);
  const lenderName = useAtomValue(lenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? lenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? lenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, lenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      if (isSubmitting) {
        return;
      }

      setRentalTargetHospitalProducts(rentalTargetHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [rentalTargetHospitalProducts, setRentalTargetHospitalProducts]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };
  // 貸出登録処理の開始
  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    const rentData: CheckoutRentalData = {
      hospitalRoomHashId: rentRoom?.hashId,
      isForcedRental: false,
      checkoutDeviceLender: deviceLender,
      checkoutUserHashId: myInfo.hashId,
      hospitalProductHashIds: rentalTargetHospitalProducts.map((item) => item.hashId),
    };
    await bulkCheckout(myInfo.hospitalHashId, rentData);

    navigate('/shared/complete');
    // 状態変数の初期化
    setRentalStepper(3);
    setRentalTargetHospitalProducts([]);
  }, [isSubmitting]);

  useEffect(() => {
    setRentalStepper(2);
  }, [setRentalStepper]);

  return (
    <>
      <Header title={'貸出'} />
      <Box sx={contentFixedStyle}>
        <Box sx={boxContainerStyle}>
          <Box sx={rentalTableContainerStyle}>
            <RentProductTable
              rentRoom={rentRoom?.name ?? '未定'}
              rentalTargetHospitalProducts={rentalTargetHospitalProducts}
              onClickCancel={handleClickCancel}
            />
          </Box>
          <Box alignItems="flex-end" sx={operationContainerStyle}>
            {lenderName && <VerticalPairTable label="持出者" name={lenderName} />}
            <Box sx={{width: '100%'}}>
              <RentButton label={'貸出する'} onChange={handleSubmit} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer
        text={'貸出する医療機器を\n確認してください'}
        backButtonText={'読み取り画面へ戻る'}
        backButtonSize="large"
      />
    </>
  );
};

const boxContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  margin: '0 60px',
  gap: '24px',
};

const rentalTableContainerStyle: SxProps<Theme> = (theme) => ({
  maxHeight: '100%',
  overflow: 'auto',
  height: 'fit-content',
  width: '65%',
  [theme.breakpoints.up('laptop')]: {
    width: '70%',
  },
});

const operationContainerStyle: SxProps<Theme> = {
  width: '25%',
  minHeight: '408px',
  gap: '48px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
};
