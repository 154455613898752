import {Box, Link, Typography} from '@mui/material';
import React from 'react';
import {PointsAlert} from './PointsAlert';

export const OverviewIntroductionContent = () => {
  return (
    <>
      <Box sx={{color: '#172B4D', fontSize: '14px', lineHeight: '24px'}}>
        <Box sx={{my: '24px'}}>
          <PointsAlert />
        </Box>
        HITOTSU Assetによる
        <Typography component={'span'} sx={{fontSize: '14px', lineHeight: '24px', fontWeight: '700'}}>
          買取査定依頼機能
        </Typography>
        は、
        <br />
        HITOTSU株式会社および
        <Link
          href={'https://www.ume-fujita.com'}
          target={'_blank'}
          sx={{fontSize: '14px', lineHeight: '24px', fontWeight: '700'}}>
          株式会社FUJITA
        </Link>{' '}
        (以下、FUJITA社) により提供いたします。
      </Box>
      <Box sx={{color: '#172B4D', fontSize: '12px', lineHeight: '18px'}}>
        ※FUJITA社は、
        <Link href={'https://www.fgl.co.jp'} target={'_blank'} sx={{fontSize: '12px', lineHeight: '18px'}}>
          芙蓉総合リース株式会社
        </Link>{' '}
        (東京証券取引所プライム市場, コード 8424) のグループ会社です。
      </Box>
      <Box sx={{marginTop: '16px', color: '#172B4D', fontSize: '14px', lineHeight: '24px'}}>
        選択いただいた医療機器について、
        <Typography component={'span'} sx={{fontSize: '14px', lineHeight: '24px', fontWeight: '700'}}>
          買取査定実施に必要な情報のみ
        </Typography>
        を、HITOTSU Asset経由で
        <br />
        FUJITA社に提供することで、
        <Typography component={'span'} sx={{fontSize: '14px', lineHeight: '24px', fontWeight: '700'}}>
          FUJITA社からスムーズな買取査定を受けられます。
        </Typography>
      </Box>
      <Box sx={{color: '#172B4D', fontSize: '12px', lineHeight: '18px'}}>
        ※査定依頼をいただいた後、FUJITA社による内容確認の結果、買取査定・買取ができない場合があります。
        <br />
        ※次の画面で、FUJITA社へ提供が必要なデータを確認いただけます。
      </Box>
    </>
  );
};
