import {Box, SxProps} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {PmdaFileUploadIconButton} from './PmdaFileUploadIconButton';
import {
  deleteWholeProductPmdaFiles,
  postWholeProductPmdaFiles,
  useWholeProductPmdaFilesQuery,
} from '@modules/pmda_files/api';
import {isNullish} from '@front-libs/helpers';
import {FilePreview} from '@components/molecules/FilePreview';
import {NoPmdaFile} from '@components/organisms/PmdaFile';
import {AlertDialog, openSnackBar} from '@front-libs/ui';
import {uploadFile} from '@modules/files/api';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandlerV5';
import {UploadDocumentDialog} from '@components/organisms/Files/FileList/UploadDocumentDialogV5';
import {WholeProductPmdaPopperVersionButton} from '@components/organisms/PmdaPopperVersionButton/WholeProductPmdaPopperVersionButton';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const RootStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  // PDFViewerの高さを調整するためのスタイル
  '& > :nth-of-type(3)': {height: 'calc(100vh - 320px)'},
};
const FileHeaderStyle: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-end',
};

type Props = {
  hospitalHashId: string;
  wholeProductHashId: string;
};

/**
 * 院内機種の添付文書表示Component
 * @param wholeProductHashId
 * @param hospitalHashId
 * @returns
 */
export const PmdaContents = ({wholeProductHashId, hospitalHashId}: Props) => {
  const {canEdit: canEditDeviceList, canDelete: canDeleteDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  const {data, isLoading, refetch} = useWholeProductPmdaFilesQuery(hospitalHashId, wholeProductHashId);
  const isPdfDisplay = data?.data && data.data.length > 0 && !isLoading;
  const [fileHashId, setFileHashId] = useState<string | null>(null);

  // PMDAファイルデータを更新日時の降順(最新)でソート
  const sortedWholeProductPmdaFileData = useMemo(() => {
    if (!data) return [];
    return [...data.data].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }, [data]);

  const handleUploadDocument = async () => {
    const fileData = await dialogHandler.open(UploadDocumentDialog, {
      acceptFile: ['application/pdf'],
    });
    try {
      const newFile = await uploadFile({
        ...fileData,
        category: 'pmda',
      });

      await postWholeProductPmdaFiles(hospitalHashId, wholeProductHashId, {
        fileHashId: newFile.data.hashId,
        isUserUpload: true,
      });
      refetch();
      openSnackBar('ファイルをアップロードしました');
    } catch (_e) {
      openSnackBar('ファイルのアップロードに失敗しました', 'error');
    }
  };

  const onClickDelete = async (fileHashId: string) => {
    try {
      await dialogHandler.open(AlertDialog, {
        title: '添付文書の削除',
        content: '手動でアップロードされた添付文書を削除します。\nこの操作は元に戻せません。',
      });
    } catch (_e) {
      return;
    }

    try {
      await deleteWholeProductPmdaFiles(hospitalHashId, wholeProductHashId, fileHashId);
      refetch();
      openSnackBar('添付文書を削除しました');
    } catch (_e) {
      openSnackBar('添付文書を削除時に失敗しました', 'error');
    }
  };

  /** PMDAのバージョンセレクトボックスComponent  */
  const PmdaVersionContent = useMemo(() => {
    return (
      <Box sx={{display: 'flex', margin: '16px 0', alignItems: 'center'}}>
        バージョン：
        {isPdfDisplay && canDeleteDeviceList && (
          <WholeProductPmdaPopperVersionButton
            pmdaFiles={sortedWholeProductPmdaFileData || []}
            onClickSettingMenu={(fileHashId: string) => setFileHashId(fileHashId)}
            onClickDelete={onClickDelete}
          />
        )}
      </Box>
    );
  }, [sortedWholeProductPmdaFileData, isPdfDisplay, canDeleteDeviceList]);

  useEffect(() => {
    if (sortedWholeProductPmdaFileData.length === 0 || !sortedWholeProductPmdaFileData[0].fileHashId) {
      setFileHashId(null);
      return;
    }
    setFileHashId(sortedWholeProductPmdaFileData[0].fileHashId);
  }, [sortedWholeProductPmdaFileData]);

  return (
    <Box sx={RootStyle}>
      <Box sx={FileHeaderStyle}>
        {canEditDeviceList && <PmdaFileUploadIconButton onUploadFile={handleUploadDocument} />}
      </Box>
      {PmdaVersionContent}
      {fileHashId ? (
        <FilePreview fileHashId={fileHashId} fileName={'PMDA添付文書'} fileType={'application/pdf'} scale={1} />
      ) : (
        !isLoading && <NoPmdaFile />
      )}
    </Box>
  );
};
