import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ClickAwayListener,
  Button,
  Popper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {FormikProps, useFormikContext, Form} from 'formik';
import {Checkbox} from '@atoms/Checkbox';
import {NotificationSettingsFormType} from '.';
import {useNotificationSetting} from './consts';
import {NotificationSettingTableRow} from './NotificationSettingTableRow';

export const NotificationSettingTableForm: React.FC<FormikProps<NotificationSettingsFormType>> = ({handleChange}) => {
  const {setFieldValue, values} = useFormikContext<NotificationSettingsFormType>();
  const classes = useStyles();
  const notificationSetting = useNotificationSetting();
  const [isTheCheckAllChecked, setIsTheCheckAllChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleCheckTheCheckAll = useCallback(
    (isChecked: boolean) => {
      notificationSetting.forEach((item) => {
        item.isPermission && setFieldValue(item.formKey, isChecked, isChecked);
      });
    },
    [setFieldValue, isTheCheckAllChecked, notificationSetting]
  );

  useEffect(() => {
    const allValues = notificationSetting.map((item) => values[item.formKey]);
    // 全てのチェックボックスがチェックされているかどうかを判定
    setIsTheCheckAllChecked(allValues.every((value) => value === true));
  }, [values]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  }, []);

  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  const xDaysAgoAdditionalCondition = useMemo(
    () => (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Button
            aria-describedby="inspection-reminder-popper"
            onClick={handleClick}
            className={classes.xDaysAgoBtn}
            disabled={!values.isEnabledInspectionReminder}>
            {`${values.xDaysAgoInspectionReminder}日前`}
            <ArrowDropDown />
          </Button>
          <Popper id="inspection-reminder-popper" open={open} anchorEl={anchorEl}>
            <div className={classes.paper}>
              <Box className={classes.xDaysAgoBox}>
                <Typography variant="body1">点検リマインダー</Typography>
                <Grid container alignItems="center">
                  <TextField
                    type="number"
                    name={'xDaysAgoInspectionReminder'}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    value={values.xDaysAgoInspectionReminder}
                    className={classes.textField}
                    onChange={(e) => {
                      setFieldValue(
                        'xDaysAgoInspectionReminder',
                        e.target.value !== '' || Number(e.target.value) < 0 ? parseInt(e.target.value) : 0
                      );
                    }}
                  />
                  <Box component="span">日前</Box>
                </Grid>
              </Box>
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleClickAway,
      handleClick,
      classes.xDaysAgoBtn,
      classes.paper,
      classes.xDaysAgoBox,
      classes.textField,
      values,
      setFieldValue,
      open,
      anchorEl,
    ]
  );

  return (
    <Form className={classes.form}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              <TableCell className={classes.tableHeaderCell}>
                {notificationSetting.length > 0 && (
                  <Checkbox checked={isTheCheckAllChecked} onChange={(_, checked) => handleCheckTheCheckAll(checked)} />
                )}
              </TableCell>
              <TableCell align="left" className={classes.tableHeaderCell}>
                通知内容
              </TableCell>
              <TableCell align="left" className={classes.tableHeaderCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationSetting.map((item) => (
              <NotificationSettingTableRow
                key={item.type}
                typeKey={item.type}
                checked={values[item.formKey] as boolean}
                onCheckboxChange={handleChange}
                onCheckboxClick={() => setFieldValue(item.formKey, !(values[item.formKey] as boolean))}
                additionalConditionChild={item.type === 'inspectionReminder' ? xDaysAgoAdditionalCondition : undefined}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Form>
  );
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 16,
  },
  form: {
    paddingBottom: '48px',
  },
  table: {
    minWidth: 650,
    border: '1px solid #919BAB',
  },
  tableHeaderRow: {
    backgroundColor: '#F5F8FA',
  },
  tableHeaderCell: {
    borderBottom: '1px solid #919BAB',
  },
  paper: {
    border: '1px solid #DFE1E5',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    backgroundColor: theme.palette.background.paper,
    padding: '36px 48px 24px 24px',
  },
  textField: {
    width: '80px',
    paddingRight: 8,
  },
  xDaysAgoBtn: {
    color: theme.palette.rent.light,
    whiteSpace: 'nowrap',
  },
  xDaysAgoBox: {
    marginBottom: 24,
  },
}));
