import {Box, FormHelperText, Grid, Switch, Theme, Typography, styled} from '@mui/material';
import React from 'react';
import {
  StyledSettingsTempContent,
  StyledSettingsTempDivider,
  StyledSettingsTempFlex,
  StyledSettingsTempForm,
  StyledSettingsTempPageSubTitle1,
  StyledSettingsTempPageTitle,
  StyledSettingsTempSideBar,
} from '@components/templates/ContentLayout/InnerSidebarContentLayoutV5';
import {Sidebar} from '@components/organisms/Sidebar';
import {useFormikContext} from 'formik';
import {FaultReceptionData, FaultReceptionName} from '.';
import {openSnackBar} from '@front-libs/ui';
import {ReturnWaitingReceptionDetailsForm} from './ReturnWaitingReceptionDetailsForm';

export const StyledSelectorTitleContainer = styled(Box)({
  marginTop: 24,
});

export const StyledFormHelperText = styled(FormHelperText)(({theme}: {theme: Theme}) => ({
  fontSize: 14,
  color: theme.palette.grey[600],
  margin: '8px 0px 8px',
}));

const StyledSubtitle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '300px',
  justifyContent: 'space-between',
});

export const StyledTypography = styled(Typography)({
  padding: '12px',
});

const StyledItemContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
  height: '38px',
});

const StyledSelectorContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '300px',
});

/**
 * タイトル部分
 */
const Title = () => {
  return (
    <>
      <Grid container>
        <Grid item>
          <StyledSettingsTempPageTitle variant={'h5'}>病棟ユーザーメニュー</StyledSettingsTempPageTitle>
          <p>
            病棟ユーザーメニューに関する「Asset病棟ユーザー」共通の設定を管理します。
            <br />
            ※Asset病棟ユーザー以外のユーザー権限には影響しません。
          </p>
        </Grid>
        <StyledSettingsTempFlex />
      </Grid>
      <StyledSettingsTempDivider variant="middle" />
    </>
  );
};

/**
 * インフォメーション部分
 */
const Information = () => {
  return (
    <Grid item>
      <StyledSettingsTempPageSubTitle1 variant={'h6'}>セットアップ</StyledSettingsTempPageSubTitle1>
      <StyledSelectorTitleContainer>
        <Typography>メニューの表示・非表示設定</Typography>
        <StyledFormHelperText>病棟ユーザーメニューごとに表示・非表示を設定することができます。</StyledFormHelperText>
      </StyledSelectorTitleContainer>
      <StyledSubtitle>
        <Typography>メニュー</Typography>
        <StyledTypography>表示</StyledTypography>
      </StyledSubtitle>
    </Grid>
  );
};

/**
 * 病棟ユーザーメニューに関するユーザー共通設定を管理するページ
 */
export const FaultReceptionForm = () => {
  const {submitForm, values, setValues} = useFormikContext<FaultReceptionData[]>();

  const visibleChangeHandler = (name: FaultReceptionName, newVisible: boolean) => {
    let updatedValues = values.map((value) => (value.name === name ? {...value, visible: newVisible} : value));

    switch (name) {
      case 'showRentableDevices':
        if (!newVisible) {
          // 「貸出可能機器の閲覧」を非表示にした場合、「返却待ち受付」を非表示
          updatedValues = updatedValues.map((value) =>
            value.name === 'showReturnWaitingReception' ? {...value, visible: false} : value
          );
        }
        break;

      case 'showReturnWaitingReception':
        if (newVisible) {
          // 「返却待ち受付」を表示する場合、「貸出可能機器の閲覧」も表示
          updatedValues = updatedValues.map((value) =>
            value.name === 'showRentableDevices' ? {...value, visible: true} : value
          );
        }
        break;
      default:
        break;
    }

    const atLeastOneVisible = updatedValues.some((value) => value.visible);
    if (!atLeastOneVisible) {
      openSnackBar('すべてのメニューを非表示にすることはできません。', 'error');
      return;
    }
    setValues(updatedValues);
    submitForm();
  };

  return (
    <>
      <StyledSettingsTempSideBar>
        <Sidebar />
      </StyledSettingsTempSideBar>
      <StyledSettingsTempForm>
        <StyledSettingsTempContent>
          <Title />
          <Grid container>
            <Information />
          </Grid>
          <Grid container>
            <Grid item>
              {values.map((value) => (
                <StyledItemContainer key={value.label}>
                  <StyledSelectorContainer>
                    <Typography>{value.label}</Typography>
                    <Switch
                      checked={value.visible}
                      color="primary"
                      onChange={() => {
                        visibleChangeHandler(value.name, !value.visible);
                      }}
                    />
                  </StyledSelectorContainer>
                </StyledItemContainer>
              ))}
            </Grid>
          </Grid>
          {
            <Box>
              <ReturnWaitingReceptionDetailsForm />
            </Box>
          }
        </StyledSettingsTempContent>
      </StyledSettingsTempForm>
    </>
  );
};
