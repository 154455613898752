export type TrainingScheduleTrainee = {
  hashId: string;
  name: string;
  employeeNumber: string | null;
  hospitalDepartmentHashId: string | null;
};

export type ListTrainingScheduleTraineesRequest = {
  page: number;
  perPage: number;
  orders?: string;
};

export type ListTrainingScheduleTraineesResponse = {
  page: number;
  totalCount: number;
  data: TrainingScheduleTrainee[];
};

export type UpdateTrainingScheduleTraineeRequest = {
  name: string;
  employeeNumber: string | null;
  hospitalDepartmentHashId: string | null;
};

export type UpdateTrainingScheduleTraineeResponse = {
  data: TrainingScheduleTrainee;
};

export enum TrainingScheduleTraineeActionType {
  TRAINING_SCHEDULE_TRAINEE_SET_LIST = 'TRAINING_SCHEDULE_TRAINEE_SET_LIST',
}

export type TrainingScheduleTraineeSetListAction = {
  type: TrainingScheduleTraineeActionType.TRAINING_SCHEDULE_TRAINEE_SET_LIST;
  payload: {
    trainees: TrainingScheduleTrainee[];
  };
};

export type TrainingScheduleTraineeAction = TrainingScheduleTraineeSetListAction;

export type TrainingScheduleTraineeState = {
  trainees: TrainingScheduleTrainee[];
};
