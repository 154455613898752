import React from 'react';
import {Navigate} from 'react-router-dom';
import {usePermittedRedirectPath, useUserResourcesPermissions} from './modules/hospital_users/hooks/useUserPermissions';
import {PermissionResourceKey} from './modules/hospital_users/types';

type PermissionRouterRouteProps = {
  children: JSX.Element;
  resource: PermissionResourceKey;
  requireEdit?: boolean; // 編集権限を必要とするか
  requireDelete?: boolean; // 削除権限 を必要とするか
};

/**
 * 権限管理を行うルーティングコンポーネント
 * packages/front-app-pc/src/routes.tsxで使用することを想定
 *
 * 指定されたリソースと権限レベルに基づき、ユーザーがアクセス可能かどうかを判定します。
 * 権限が不足している場合、権限のあるページに遷移します
 *
 * @param children - 権限がある場合に表示するコンテンツ。
 * @param resource - 権限を確認する対象のリソース名（PermissionResourceKey 型）。
 * @param requireEdit - 編集権限が必要な場合は `true`（デフォルト: `false`）。
 * @param requireDelete - 削除権限が必要な場合は `true`（デフォルト: `false`）。
 *
 *
 * @example
 * ```tsx
 * export const mainRoutes = {
 *   path: '/',
 *   element: <BaseProductManager showHeader={true} />,
 *   children: [
 *     {
 *       key: 'inspection_v2_tables',
 *       path: '/inspection_v2/tables',
 *       element: (
 *         <PermissionRouter resource="INSPECTION">
 *           <InspectionTableList />
 *         </PermissionRouter>
 *       ),
 *     },>
 * ```
 */
export const PermissionRouter = ({
  children,
  resource,
  requireEdit = false,
  requireDelete = false,
}: PermissionRouterRouteProps) => {
  const {canRead, canEdit, canDelete} = useUserResourcesPermissions(resource);
  const redirectPath = usePermittedRedirectPath();

  if (!canRead) {
    return <Navigate to={redirectPath} replace />;
  }
  // 編集権限
  if (requireEdit && !canEdit) {
    return <Navigate to={redirectPath} replace />;
  }

  // 削除権限
  if (requireDelete && !canDelete) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
