import React from 'react';
import {ProductStatusConst} from '@modules/hospital_products/types';
import {Avatar, Grid, styled} from '@mui/material';
import {ProductStatus} from '@molecules/ProductStatus';
import {Assignment} from '@mui/icons-material';

const Container = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey[50],
  padding: '8px 16px 0px',
}));

const ProductThumbnail = styled(Avatar)({
  width: '64px',
  height: '64px',
});

const DisplayName = styled('div')({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#172B4D',
});
const ProductThumbnailImg = styled('img')({
  objectFit: 'contain',
});

export type MobileProductInfoProps = {
  managementId: string;
  name?: string;
  displayName: string;
  makerName?: string;
  status: ProductStatusConst;
  isForceRental: boolean;
  thumbnailUrl?: string;
  isShrink: boolean;
};

export const MobileProductInfo = (props: MobileProductInfoProps) => {
  const {status, managementId, isForceRental, name, makerName, isShrink, thumbnailUrl} = props;
  return (
    <Container>
      <Grid container alignItems="center" sx={{marginBottom: 1}}>
        <Grid item sx={{width: '100px'}}>
          <ProductStatus productStatus={status} isForcedRental={isForceRental} />
        </Grid>
        <Grid item sx={{fontSize: '16px', fontWeight: 700}}>
          {managementId}
        </Grid>
      </Grid>
      <Grid container sx={{gap: '16px'}}>
        {!isShrink && (
          <Grid item sx={{marginBottom: 2}}>
            <ProductThumbnail variant="rounded">
              {thumbnailUrl ? (
                <ProductThumbnailImg width="64px" src={thumbnailUrl} alt="サムネイル" loading="lazy" />
              ) : (
                <Assignment />
              )}
            </ProductThumbnail>
          </Grid>
        )}
        <Grid item>
          <Grid container sx={{gap: '4px'}} direction="column">
            <Grid item>
              <DisplayName>{props.displayName}</DisplayName>
            </Grid>
            {!isShrink && (
              <>
                <Grid item>
                  <div>{name}</div>
                </Grid>
                <Grid item>
                  <div>{makerName}</div>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
