import React, {useCallback, useMemo, useState} from 'react';
import {PopperSelectBoxButton} from '@components/molecules/Buttons/PopperSelectBoxButton';
import {Box, FormControlLabel, styled, Switch, TextField, Theme} from '@mui/material';
import {Search} from '@mui/icons-material';
import {productStatusType} from '@modules/hospital_products/constants';
import {ProductStatusConst} from '@modules/hospital_products/types';
import {CategoryIndex} from '@modules/categories/types';
import {CategoryFormatter} from '@modules/categories/helpers';
import {includeDisposedAtom} from '../state';
import {useAtom} from 'jotai';
import {isNullish} from '@front-libs/helpers';

const FontSize14Span = styled('span')({
  fontSize: 14,
});

const SearchTextField = styled(TextField)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));

type Props = {
  rootCategories: CategoryIndex[];
  narrowCategories: CategoryIndex[];
  onStatusChange: (status: ProductStatusConst[]) => void;
  onNameChange: (name: string) => void;
  onRootCategoriesChange: (rootCategories: string[]) => void;
  onNarrowCategoriesChange: (narrowCategories: string[]) => void;
  onClickDetailFilterButton: () => void;
};
export const FilterButtons = ({
  rootCategories,
  narrowCategories,
  onNameChange,
  onStatusChange,
  onNarrowCategoriesChange,
  onRootCategoriesChange,
}: Props) => {
  const [includeDisposed, setIncludeDisposed] = useAtom(includeDisposedAtom);
  const [searchStatuses, setSearchStatuses] = useState(productStatusType);

  const handleSwitchIncludeDisposed = useCallback(() => {
    setIncludeDisposed(!includeDisposed);

    // 次のステータスに合わせて、「廃棄を含まない」から判定
    if (includeDisposed) {
      setSearchStatuses(searchStatuses.filter((status) => status.value !== 'disabled'));
    } else {
      // 現在、!includeDisposedなら廃棄を含む様になるので、切り替え時点で廃棄ステータスを表示できるようにする

      // フィルタがかかっていない場合は自動的に廃棄も含むので何もしない
      if (searchStatuses.length === 0) {
        return;
      }
      const disposedOption = productStatusType.find((status) => status.value === 'disabled');
      if (isNullish(disposedOption)) {
        // unreachable
        return;
      }

      setSearchStatuses([...searchStatuses, disposedOption]);
    }
  }, [includeDisposed, searchStatuses, setIncludeDisposed, setSearchStatuses]);

  const statusOptions = useMemo(() => {
    if (includeDisposed) {
      return productStatusType;
    }
    return productStatusType.filter((item) => item.value !== 'disabled');
  }, [includeDisposed, searchStatuses]);

  return (
    <>
      <SearchTextField
        label={'機種名・型式・管理番号・シリアル番号・GS1で検索'}
        variant={'outlined'}
        fullWidth
        size={'small'}
        sx={{width: '400px'}}
        InputProps={{
          endAdornment: <Search />,
        }}
        InputLabelProps={{
          style: {
            fontSize: 14,
          },
        }}
        onChange={(e) => onNameChange(e.target.value)}
      />
      {/* TODO:PopperSelectBoxButtonをMuiV5対応にしジェネリクスでonChangeの型を指定する */}
      <PopperSelectBoxButton
        buttonLabel={'稼働状況'}
        options={statusOptions}
        isMulti={true}
        onChange={(val) => onStatusChange(val?.map((v) => v.value as ProductStatusConst) ?? [])}
        searchable={true}
      />
      <PopperSelectBoxButton
        buttonLabel={'大分類'}
        options={CategoryFormatter.getOptions(rootCategories)}
        isMulti={true}
        onChange={(val) => onRootCategoriesChange(val?.map((v) => v.value) ?? [])}
        searchable={true}
      />
      <PopperSelectBoxButton
        buttonLabel={'小分類'}
        options={CategoryFormatter.getOptions(narrowCategories)}
        isMulti={true}
        onChange={(val) => onNarrowCategoriesChange(val?.map((v) => v.value) ?? [])}
        searchable={true}
      />
      <Box sx={{display: 'inline-flex', justifyContent: 'flex-end', flexGrow: 1, marginRight: 0}}>
        <FormControlLabel
          control={
            <Switch checked={includeDisposed} onChange={handleSwitchIncludeDisposed} name="disposed" color="primary" />
          }
          label={<FontSize14Span>廃棄機器を含む</FontSize14Span>}
        />
      </Box>
    </>
  );
};
