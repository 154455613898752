import React, {useCallback} from 'react';
import {Table} from '@molecules/Table';
import {RowAction} from '@molecules/Table/props';
import {
  useSearchVariables,
  useTableData,
  useUpdateTrainingScheduleMutation,
  useDeleteTrainingScheduleMutation,
} from '../hooks';
import {ScheduleTableData} from '.';
import {useNavigate} from 'react-router-dom';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  TrainingScheduleDialog,
  TrainingScheduleDialogProps,
  TrainingScheduleDialogResult,
} from '@organisms/TrainingManagements/TrainingScheduleDialog';
import {openSnackBar} from '@components/molecules/SnackBar';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {DeleteTrainingScheduleParam} from '@modules/training_schedules/api';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export const ScheduleTable: React.FC = () => {
  const {canEdit: canEditTraining, canDelete: canDeleteTraining} = useUserResourcesPermissions('TRAINING');
  const {mutate} = useUpdateTrainingScheduleMutation();
  const {mutate: deleteTrainingScheduleMutation} = useDeleteTrainingScheduleMutation();
  const {tableColumns, scheduleData, isLoading} = useTableData();
  const {setOrder} = useSearchVariables();
  const navigate = useNavigate();

  const handleNavigateTrainingReport = useCallback(
    async (_e: React.MouseEvent, data: ScheduleTableData) => {
      navigate('/training_managements/reports', {
        state: {
          trainingScheduleHashId: data.hashId,
        },
      });
    },
    [navigate]
  );

  const handleCompleteStatus = useCallback(
    async (_e: React.MouseEvent, data: ScheduleTableData) => {
      const currentStatus = data.status;

      await dialogHandler.open(MessageDialog, {
        title: 'ステータスの変更',
        content: `研修（予定）「${data.name} - ${data.currentTimes}回目」のステータスを${
          currentStatus === 'done' ? '未完了に戻しますか' : '完了にしますか'
        }？`,
        positiveButtonLabel: currentStatus === 'done' ? '未完了に戻す' : '完了',
      });
      mutate({
        trainingScheduleHashId: data.hashId,
        status: currentStatus === 'done' ? 'incomplete' : 'done',
      });
    },
    [mutate]
  );

  const handleEditSchedule = useCallback(
    async (_e: React.MouseEvent, data: ScheduleTableData) => {
      const result = await dialogHandler.open<TrainingScheduleDialogProps, TrainingScheduleDialogResult>(
        TrainingScheduleDialog,
        {
          isEdit: true,
          // FIXME: data.hashIdとtrainingPlanOptionsのhashIdが一致しない
          sourceTrainingPlanHashId: data.hashId,
          initialValues: {
            trainingPlanHashId: data.hashId,
            trainingPlanName: data.name,
            trainingTypes: undefined,
            wholeProductHashIds: undefined,
            place: data.place,
            startAt: data.scheduleDateRange[0].toString(),
            finishAt: data.scheduleDateRange[1].toString(),
            numberOfTrainees: data.numberOfTrainees,
          },
        }
      );
      mutate({
        ...result,
        trainingScheduleHashId: data.hashId,
        startAt: result.startAt.toString(),
        finishAt: result.finishAt.toString(),
        numberOfTrainees: result.numberOfTrainees?.toString(),
        wholeProductHashIds: result.wholeProductHashIds?.length === 0 ? undefined : result.wholeProductHashIds,
      });
    },
    [mutate]
  );

  const handleDeleteSchedule = useCallback(
    async (_e: React.MouseEvent, data: ScheduleTableData) => {
      try {
        await dialogHandler.open(AlertDialog, {
          title: '研修予定を削除しますか？',
          content:
            'この研修予定を削除しようとしています。\n\n研修予定に紐づく、実施済みの研修も削除されます。\n\nこのアクションは元に戻せません。',
          positiveButtonLabel: '研修予定を削除',
        });
        const params: DeleteTrainingScheduleParam = {
          trainingScheduleHashId: data.hashId,
        };
        deleteTrainingScheduleMutation(params);
        openSnackBar('研修予定を削除しました');
      } catch (_e) {
        return;
      }
    },
    [deleteTrainingScheduleMutation]
  );

  const rowsActions: RowAction<ScheduleTableData>[] = [
    {
      type: 'menu',
      label: 'アクション',
      items: (() => {
        const baseItems = [{label: '実施済み一覧を表示', onClick: handleNavigateTrainingReport}];

        if (canDeleteTraining) {
          return [
            {label: '編集', onClick: handleEditSchedule},
            {label: '削除', onClick: handleDeleteSchedule},
            ...baseItems,
            {label: 'ステータスを変更', onClick: handleCompleteStatus},
          ];
        }

        if (canEditTraining) {
          return [
            {label: '編集', onClick: handleEditSchedule},
            ...baseItems,
            {label: 'ステータスを変更', onClick: handleCompleteStatus},
          ];
        }

        return baseItems;
      })(),
    },
  ];

  return (
    <Table<ScheduleTableData>
      data={scheduleData}
      isLoading={isLoading}
      columns={tableColumns}
      showSelection={false}
      rowActions={rowsActions}
      defaultOrder={{
        fieldId: 'scheduleDateRange',
        direction: 'asc',
      }}
      onOrderChange={(columnIndex, orderDirection) => {
        setOrder(tableColumns[columnIndex].field, orderDirection);
      }}
    />
  );
};
