import {useReducer} from 'react';
import {useHospitalDepartmentStore} from '@modules/hospital_departments/hooks';
import {HospitalDepartmentState, HospitalDepartMent} from '@modules/hospital_departments/types';
import {updateTrainingScheduleTrainee, deleteTrainingScheduleTrainee} from '@modules/training_schedule_trainees/api';
import {useTrainingScheduleTraineeStore} from '@modules/training_schedule_trainees/hooks';
import {
  TrainingScheduleTraineeState,
  ListTrainingScheduleTraineesRequest,
  UpdateTrainingScheduleTraineeRequest,
} from '@modules/training_schedule_trainees/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {localStorageKeys, perPageOptionValues} from './constants';
import {traineeListPaginationReducer, traineeListPaginationInitialState} from './reducers';
import {TraineeListPaginationState, TraineeListPaginationActionType} from './types';

type TraineeListStoreType = {
  departmentState: HospitalDepartmentState;
  traineeState: TrainingScheduleTraineeState;
  paginationState: TraineeListPaginationState;
  initTraineeListStore: (trainingScheduleHashId: string, page?: number) => Promise<void>;
  findHospitalDepartmentByHashId: (hashId: string) => HospitalDepartMent | null;
  updatePage: (trainingScheduleHashId: string, page: number) => Promise<void>;
  updatePerPage: (trainingScheduleHashId: string, perPage: number) => Promise<void>;
  updateTrainee: (
    hashId: string,
    trainingScheduleHashId: string,
    req: UpdateTrainingScheduleTraineeRequest
  ) => Promise<void>;
  deleteTrainee: (hashId: string, trainingScheduleHashId: string) => Promise<void>;
};

export const useTraineeListStore = (): TraineeListStoreType => {
  const {myInfo} = useMyInfo();
  const {
    state: departmentState,
    dispatchListHospitalDepartment,
    get: getHospitalDepartment,
  } = useHospitalDepartmentStore();
  const {state: traineeState, dispatchListTrainingScheduleTrainees} = useTrainingScheduleTraineeStore();
  const [paginationState, dispatch] = useReducer(traineeListPaginationReducer, traineeListPaginationInitialState);

  const listHospitalDepartment = async () => {
    await dispatchListHospitalDepartment(myInfo.hospitalHashId, {page: 0, perPage: 100, showDeleted: false});
  };

  const newListTrainingScheduleTraineesRequestByState = (): ListTrainingScheduleTraineesRequest => {
    return {
      page: paginationState.page - 1, // NOTE: APIのpageは0から始まるため、1を減算する。
      perPage: paginationState.perPage,
      orders: paginationState.orders,
    };
  };

  const listTraineeScheduleTrainees = async (
    trainingScheduleHashId: string,
    params: ListTrainingScheduleTraineesRequest
  ) => {
    const res = await dispatchListTrainingScheduleTrainees(myInfo.hospitalHashId, trainingScheduleHashId, params);
    dispatch({
      type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_TOTAL_COUNT,
      payload: {totalCount: res.totalCount},
    });
  };

  const initTraineeListStore = async (trainingScheduleHashId: string, page?: number) => {
    const params = newListTrainingScheduleTraineesRequestByState();

    if (page && page > 0) {
      dispatch({
        type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PAGE,
        payload: {page},
      });
      params.page = page - 1; // NOTE: APIのpageは0から始まるため、1を減算する。
    }

    const perPageStr = localStorage.getItem(localStorageKeys.traineesPerPage);
    const perPage = perPageStr ? Number(perPageStr) : null;
    if (perPage && perPageOptionValues.includes(perPage)) {
      dispatch({
        type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PER_PAGE,
        payload: {perPage},
      });
      params.perPage = perPage;
    }

    await Promise.all([listHospitalDepartment(), listTraineeScheduleTrainees(trainingScheduleHashId, params)]);
  };

  const findHospitalDepartmentByHashId = (hashId: string) => {
    return getHospitalDepartment(hashId);
  };

  const updatePage = async (trainingScheduleHashId: string, page: number) => {
    dispatch({
      type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PAGE,
      payload: {page},
    });
    const params = newListTrainingScheduleTraineesRequestByState();
    params.page = page - 1; // NOTE: APIのpageは0から始まるため、1を減算する。
    await listTraineeScheduleTrainees(trainingScheduleHashId, params);
  };

  const updatePerPage = async (trainingScheduleHashId: string, perPage: number) => {
    dispatch({
      type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PAGE,
      payload: {page: 1},
    });
    dispatch({
      type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PER_PAGE,
      payload: {perPage},
    });
    localStorage.setItem(localStorageKeys.traineesPerPage, String(perPage));
    const params = newListTrainingScheduleTraineesRequestByState();
    params.page = 0; // NOTE: APIのpageは0から始まる
    params.perPage = perPage;
    await listTraineeScheduleTrainees(trainingScheduleHashId, params);
  };

  const updateTrainee = async (
    hashId: string,
    trainingScheduleHashId: string,
    req: UpdateTrainingScheduleTraineeRequest
  ) => {
    await updateTrainingScheduleTrainee(hashId, myInfo.hospitalHashId, trainingScheduleHashId, req);
    await listTraineeScheduleTrainees(trainingScheduleHashId, newListTrainingScheduleTraineesRequestByState());
  };

  const deleteTrainee = async (hashId: string, trainingScheduleHashId: string) => {
    await deleteTrainingScheduleTrainee(hashId, myInfo.hospitalHashId, trainingScheduleHashId);
    await listTraineeScheduleTrainees(trainingScheduleHashId, newListTrainingScheduleTraineesRequestByState());
  };

  return {
    departmentState,
    traineeState,
    paginationState,
    initTraineeListStore,
    findHospitalDepartmentByHashId,
    updatePage,
    updatePerPage,
    updateTrainee,
    deleteTrainee,
  };
};
