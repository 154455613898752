import {Box, SxProps} from '@mui/material';
import React from 'react';
import {FormProvider} from 'react-hook-form';
import {WholeProductIndex} from '@modules/products/types';
import {SubmitDrawer} from './form/SubmitDrawer';
import {WholeProductForm} from './form/WholeProductForm';
import {useWholeProductForm} from './form/hooks';
import {ProductHeader} from './ui/ProductHeader';
import {WholeProductInfo} from './WholeProductInfo';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const containerStyle: SxProps = {
  display: 'flex',
  height: 'calc(100vh - 56px - 36px - 48px)',
  width: '100%',
  backgroundColor: 'white',
};

const columnLeftStyle: SxProps = {
  width: 'calc(50% + 16px)',
  padding: '0px',
  '@media (min-width: 1025px)': {
    flexGrow: 1,
  },
};

const columnRightStyle: SxProps = {
  position: 'relative', // NOTE: 研修タブの下線をデザインに合わせ、親要素の幅に合わせるために利用
  borderLeft: '1px solid #D1D5DB',
  width: 'calc(50% - 16px)', // 16pxはpaddingの分
  padding: '16px 32px 32px',
  '@media (min-width: 1025px)': {
    width: '600px',
  },
};

type Props = {
  wholeProduct: WholeProductIndex;
  refetch: () => void;
};

export const WholeProductContent = ({wholeProduct, refetch}: Props) => {
  const methods = useWholeProductForm(wholeProduct);
  const {canEdit} = useUserResourcesPermissions('PRODUCT_LIST');

  return (
    <FormProvider {...methods}>
      <ProductHeader wholeProduct={wholeProduct} />
      <Box sx={containerStyle}>
        <Box sx={columnLeftStyle}>
          <WholeProductForm />
        </Box>
        <Box sx={columnRightStyle}>
          <WholeProductInfo />
        </Box>
      </Box>
      {/* TODO: この対応だと保存ボタンが押せなくなるだけで各項目の変更ができるため、編集権限が無い時は項目単位でdisableにしたい */}
      {canEdit && <SubmitDrawer wholeProduct={wholeProduct} refetch={refetch} />}
    </FormProvider>
  );
};
