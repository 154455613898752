import React, {useCallback, useEffect, useMemo} from 'react';
import {Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import {Badge} from '@atoms/Badge';
import {useQuery} from 'react-query';
import {getNotifications} from '@modules/notifications/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {NotificationDrawer} from './NotificationDrawer';
import pmdaNotification from '@assets/pmda_notification.svg';
import {
  NOTIFICATION_INTERVAL,
  PMDA_NOTIFICATION_DRAWER_TITLE,
  enabled,
  pmdaNotificationCategories,
  pmdaNotificationOptionFilters,
} from './constants';

import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export const PmdaNotification: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [openNotificationDrawer, setOpenNotificationDrawer] = React.useState(false);
  const {canRead: canReadProductList} = useUserResourcesPermissions('PRODUCT_LIST');

  const notificationCountQ = useQuery(
    ['pmdaNotificationsCount', myInfo],
    () =>
      getNotifications(myInfo.hospitalHashId, myInfo.hashId, ['unread'], pmdaNotificationCategories, null, null, 0, 1),
    {
      enabled: enabled && canReadProductList,
      // dataプロパティの変更があったときのみ再レンダリングする
      notifyOnChangeProps: ['data'],
    }
  );

  useEffect(() => {
    if (!canReadProductList || !enabled) return;

    const intervalId = setInterval(() => {
      notificationCountQ.refetch();
    }, NOTIFICATION_INTERVAL);

    return () => clearInterval(intervalId);
  }, [canReadProductList, notificationCountQ]);

  const handleAllClear = useCallback(() => {
    notificationCountQ.refetch();
  }, [notificationCountQ]);

  const totalCount = useMemo(() => {
    return notificationCountQ.data?.totalCount ?? 0;
  }, [notificationCountQ]);

  return (
    <>
      <Button
        color="inherit"
        className={classes.linkedButtonIcon}
        onClick={() => setOpenNotificationDrawer(true)}
        size="small">
        <Badge badgeContent={totalCount} color={'secondary'}>
          <img src={pmdaNotification} alt="pmda_notification" />
        </Badge>
      </Button>
      {openNotificationDrawer && (
        <NotificationDrawer
          open={openNotificationDrawer}
          onClose={() => setOpenNotificationDrawer(false)}
          onAllClear={handleAllClear}
          masterCategories={canReadProductList ? pmdaNotificationCategories : []} //ユーザ通知、PMDA通知のマスターカテゴリー
          optionFilters={canReadProductList ? pmdaNotificationOptionFilters : []}
          drawerTitle={PMDA_NOTIFICATION_DRAWER_TITLE}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    linkedButtonIcon: {
      height: '100%',
      borderRadius: 0,
      paddingRight: '8px',
      paddingLeft: '8px',
      minWidth: '24px',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  })
);
