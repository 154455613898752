import {LabelAndText} from '@components/molecules/LabelAndText';
import {UnderlineTitle} from '@components/molecules/UnderlineTitle';
import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {openSnackBar} from '@front-libs/ui';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {postReturnsReservation} from '@modules/returns_reservation/api';
import {ReturnsReservationRequest, returnWaitingReceptionsSchema} from '@modules/returns_reservation/type';
import {Box, Paper, styled} from '@mui/material';
import {useAtomValue} from 'jotai';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReceptionSelectionMap} from '../consts';
import {ReceptButton} from '../FaultReceptionPreview/ReceptButton';
import {selectedReturnWaitingAreaInfoAtom, selectedReturnWaitingReceptionAtom} from '../states';

const DetailContainer = styled(Paper)({
  backgroundColor: 'white',
  width: 'calc(100% - 508px)',
  height: 'calc(100vh - 298px)',
  margin: '16px 32px',
  padding: '16px',
  borderRadius: '20px',
});

const StyledBox = styled(Box)({
  display: 'flex',
});
const StyledChileBox = styled(Box)({
  marginBottom: '32px',
  alignSelf: 'end',
});

const Container = styled(Box)({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  padding: '8px',
  overflowY: 'auto',
});

export const ReturnWaitingReceptionPreview = () => {
  const navigate = useNavigate();
  const selectItem = useAtomValue(selectedReturnWaitingReceptionAtom);
  const areaInfo = useAtomValue(selectedReturnWaitingAreaInfoAtom);
  const [validatedData, setValidatedData] = useState<ReturnsReservationRequest>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {myInfo} = useMyInfo();
  const label = ReceptionSelectionMap.label;

  useEffect(() => {
    try {
      const validatedData: ReturnsReservationRequest = returnWaitingReceptionsSchema.parse(selectItem);
      setValidatedData(validatedData);
    } catch (_error) {}
  }, []);

  const onSubmit = async () => {
    if (!validatedData) return;

    setIsLoading(true);
    try {
      await postReturnsReservation(myInfo.hospitalHashId, validatedData);
      navigate(ReceptionSelectionMap.path.complete);
    } catch (_error) {
      openSnackBar('エラーが発生しました', 'error');
      setIsLoading(false);
    }
  };

  return (
    <WardAssetMenuTemplate
      headerProps={{title: '返却待ち受付'}}
      footerProps={{
        text: ReceptionSelectionMap.footerTitle.preview,
        backButtonHandler: () => navigate(ReceptionSelectionMap.path.input),
        disabledBackButton: isLoading,
      }}>
      <StyledBox>
        <DetailContainer elevation={0}>
          <Container>
            <UnderlineTitle title="入力内容" />
            <LabelAndText label={label.narrowCategoryName} text={selectItem.narrowCategoryName || ''} />
            <LabelAndText label={label.roomName} text={areaInfo.room?.name || ''} />
            <LabelAndText label={label.reservationCount} text={`${selectItem.reservationCount}台`} />
            <LabelAndText label={label.contactInformation} text={selectItem.contactInformation || ''} />
            <LabelAndText label={label.transitionMessage} text={selectItem.transitionMessage || ''} />
          </Container>
        </DetailContainer>
        <StyledChileBox>
          <ReceptButton onSubmit={onSubmit} label={'返却待ち\n受付をする'} disabled={!validatedData || isLoading} />
        </StyledChileBox>
      </StyledBox>
    </WardAssetMenuTemplate>
  );
};
