export type HospitalDepartMent = {
  hashId: string;
  hospitalHashId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string | null;
};

export enum HospitalDepartmentActionType {
  HOSPITAL_DEPARTMENT_SET_LIST = 'HOSPITAL_DEPARTMENT_SET_LIST',
  HOSPITAL_DEPARTMENT_SET_MAP = 'HOSPITAL_DEPARTMENT_SET_MAP',
}

export type HospitalDepartmentSetListAction = {
  type: HospitalDepartmentActionType.HOSPITAL_DEPARTMENT_SET_LIST;
  payload: {
    departments: HospitalDepartMent[];
  };
};
export type HospitalDepartmentSetMapAction = {
  type: HospitalDepartmentActionType.HOSPITAL_DEPARTMENT_SET_MAP;
  payload: {
    map: Map<HospitalDepartMent['hashId'], HospitalDepartMent>;
  };
};

export type HospitalDepartmentAction = HospitalDepartmentSetListAction | HospitalDepartmentSetMapAction;

export type HospitalDepartmentState = {
  departments: HospitalDepartMent[];
  map: Map<HospitalDepartMent['hashId'], HospitalDepartMent>;
};
