import React, {CSSProperties} from 'react';
import {NavLink} from 'react-router-dom';
import {theme} from '@atoms/theme';
import {styled, Typography} from '@mui/material';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

const defaultText: CSSProperties = {
  padding: '0px',
  fontSize: '14px',
  display: 'inline',
};

const ManagementIdLink = styled(NavLink)({
  ...defaultText,
  color: theme.palette.info.dark,
  fontWeight: 'bold',
  cursor: 'pointer',
  textDecoration: 'none',
});

/**
 * 点検の管理番号表示コンポーネント
 * @param props
 * @returns
 */
export const InspectionResultTableManagementIdColumn: React.FC<{
  managementId: string;
  hospitalProductHashId: string;
  canReadProductList: boolean;
}> = (props) => {
  const {managementId, hospitalProductHashId, canReadProductList} = props;

  if (canReadProductList) {
    return <ManagementIdLink to={`/products/${hospitalProductHashId}`}>{managementId}</ManagementIdLink>;
  }

  return (
    <Typography component="span" sx={defaultText}>
      {managementId}
    </Typography>
  );
};
