import {EnhancedColumn} from '@front-libs/ui';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Button, styled, Typography} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {ProductStatus} from '@components/molecules/ProductStatus';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {isNullish, StrUtil} from '@front-libs/helpers';
import {CategoryFormatter} from '@modules/categories/helpers';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {getWaysOfPurchaseOptions} from '@Apps/ProductsList/pc/constants';
import {RequiredLabel} from '@components/molecules/FormRequiredLabel';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export type PurchaseCandidateProductComponent = HospitalProductIndex & {
  editButton?: string;
  rootCategory: string;
  narrowCategory: string;
};
type ColumnType = EnhancedColumn<PurchaseCandidateProductComponent>;

type EditButtonProps = {
  item: PurchaseCandidateProductComponent;
  onClick: (responseAddComponent: PurchaseCandidateProductComponent) => void;
  disabled?: boolean;
};
const EditButton = ({onClick, item, disabled}: EditButtonProps) => {
  return (
    <Button
      size="small"
      variant="outlined"
      disabled={disabled}
      sx={{color: '#0052CC'}}
      onClick={(e) => {
        e.stopPropagation();
        onClick(item);
      }}>
      買取査定依頼へ進む
    </Button>
  );
};

export const useColumns = ({
  handleClickEditButton,
}: {handleClickEditButton: (item: PurchaseCandidateProductComponent) => void}) => {
  const {canDelete: canDeleteDeviceList} = useUserResourcesPermissions('PRODUCT_LIST');
  const [tableLayout, setTableLayout] = useTableLayout('productList', {
    status: true,
    receptionAt: true,
    narrowCategoryName: true,
    useRoom: true,
  });
  const editButtonLayout: ColumnType[] = [
    {
      title: (
        <>
          <Typography
            sx={{
              borderRadius: '5px',
              border: '1px solid #003FB5',
              padding: '0px 8px',
              background: '#003FB5',
              fontSize: '14px',
              color: '#FFF',
              lineHeight: '20px',
              display: 'inline',
              marginRight: '8px',
            }}>
            無料
          </Typography>
          買取査定依頼
        </>
      ),
      field: 'editButton',
      sx: {width: '100px', borderRight: '1px solid #E0E0E0'},
      fieldValue: (item) => <EditButton item={item} onClick={handleClickEditButton} disabled={!canDeleteDeviceList} />,
    },
  ];
  const columns = React.useMemo(() => {
    if (!tableLayout.currentLayout || tableLayout.currentLayout.length === 0) return [];

    const _columns = Object.assign<ColumnType[], TableLayout[]>([], tableLayout.currentLayout).map((item) => {
      switch (item.field) {
        case 'status':
          item.fieldValue = ({status, isForcedRental}) => (
            <ProductStatus productStatus={status} isForcedRental={isForcedRental} />
          );
          break;
        case 'isOutsideOfHospital':
          item.fieldValue = ({isOutsideOfHospital}) => (isOutsideOfHospital ? '院外' : '院内');
          break;
        case 'rootCategory':
          item.fieldValue = ({categories}) => CategoryFormatter.getRootCategory(categories)?.name || '';
          break;
        case 'narrowCategory':
          item.fieldValue = ({categories}) => CategoryFormatter.getNarrowCategory(categories)?.name || '';
          break;
        case 'catalogPrice':
          item.fieldValue = ({catalogPrice}) => (isNullish(catalogPrice) ? '' : `${catalogPrice.toLocaleString()}円`);
          break;
        case 'maker':
          item.fieldValue = ({maker}) => maker?.name || '';
          break;
        case 'permanentlyAssigned':
          item.fieldValue = ({permanentlyAssigned}) =>
            isNullish(permanentlyAssigned) ? '' : permanentlyAssigned ? '貸出不可' : '貸出可';
          break;
        case 'isBaseUnit':
          item.fieldValue = ({isBaseUnit}) => (isNullish(isBaseUnit) ? '非該当' : isBaseUnit ? '親機' : '子機');
          break;
        case 'purchasedNationalExpense':
          item.fieldValue = ({purchasedNationalExpense}) => {
            return isNullish(purchasedNationalExpense) ? '' : purchasedNationalExpense ? '国費' : '院費';
          };
          break;
        case 'waysOfPurchase':
          item.fieldValue = ({waysOfPurchase}) => getWaysOfPurchaseOptions(waysOfPurchase);
          break;
        case 'deliveryPrice':
          item.fieldValue = ({deliveryPrice, waysOfPurchase}) =>
            waysOfPurchase === 'purchase' && !isNullish(deliveryPrice) ? `${StrUtil.addComma(deliveryPrice)}円` : '';
          break;
        case 'taxIncluded':
          item.fieldValue = ({taxIncluded, waysOfPurchase}) =>
            waysOfPurchase === 'purchase' && !isNullish(taxIncluded) ? (taxIncluded ? '税込' : '税抜き') : '';
          break;
        case 'taxRate':
          item.fieldValue = ({taxRate, waysOfPurchase}) =>
            waysOfPurchase === 'purchase' && !isNullish(taxRate) ? `${taxRate}%` : '';
          break;
        case 'isMaintenanceContract':
          item.fieldValue = ({isMaintenanceContract}) =>
            isNullish(isMaintenanceContract) ? '' : isMaintenanceContract ? '保守契約' : '保守契約外';
          break;
        case 'depreciationAmount':
          item.fieldValue = ({depreciationAmount}) =>
            isNullish(depreciationAmount) ? '' : StrUtil.addComma(depreciationAmount);
          item.justifyContentBody = 'right';
          break;
        case 'bookValue':
          item.fieldValue = ({bookValue}) => (isNullish(bookValue) ? '' : StrUtil.addComma(bookValue));
          item.justifyContentBody = 'right';
          break;
        case 'hospitalRoom':
          item.fieldValue = ({hospitalRoom}) => HospitalRoomFormatter.getFullRoom(hospitalRoom);
          break;
        case 'rentHospitalRoom':
          item.fieldValue = ({rentHospitalRoom}) => HospitalRoomFormatter.getFullRoom(rentHospitalRoom);
          break;
        case 'hospitalDealer':
          item.fieldValue = ({hospitalDealer}) => hospitalDealer?.name ?? '';
          break;
        case 'rentalFee':
          item.fieldValue = ({rentalFee}) => (isNullish(rentalFee) ? '' : `${rentalFee.toLocaleString()}円`);
          item.justifyContentBody = 'right';
          break;
        case 'leaseFee':
          item.fieldValue = ({leaseFee}) => (isNullish(leaseFee) ? '' : `${leaseFee.toLocaleString()}円`);
          item.justifyContentBody = 'right';
          break;
        case 'createdAt':
          item.fieldValue = ({createdAt}) => (createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : '');
          break;
        case 'createdBy':
          item.fieldValue = ({createdBy}) => UserFormatter.getFullName(createdBy);
          break;
        case 'hospitalDepartment':
          item.fieldValue = ({hospitalDepartment}) => hospitalDepartment?.name ?? '';
          break;
      }
      item.noBodyWrap = true;

      item.sorting = true;
      return item;
    });
    return [...editButtonLayout, ..._columns].map((column) => ({
      ...column,
      noBodyWrap: true,
    }));
  }, [tableLayout.currentLayout]);

  return {columns, tableLayout, setTableLayout} as const;
};
