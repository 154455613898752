import {useAtomValue} from 'jotai';
import {purchaseRequestStateAtom, PurchaseRequestStep} from '../states';

export const useCurrentStepLabel = () => {
  const {currentStep} = useAtomValue(purchaseRequestStateAtom);

  return {
    stepLabel: `${PurchaseRequestStep[currentStep].sequence}. ${PurchaseRequestStep[currentStep].label}`,
    showSubtitle: PurchaseRequestStep[currentStep].key === PurchaseRequestStep.contactInformation.key,
  };
};
