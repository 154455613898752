import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {ClickAwayListener, MenuItem, MenuList, Popper, Tooltip, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useCallback, useMemo, useState} from 'react';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {WholeProductIndex} from '@modules/products/types';
import {CategoryIndex} from '@modules/categories/types';
import {CategoryFormatter} from '@modules/categories/helpers';
import {isNullish} from '@front-libs/helpers';
import {useNavigate} from 'react-router-dom';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useAtom} from 'jotai';
import {defaultChannelHashIdAtom, dialogChannelNumberAtom, isOpenChannelDialogAtom} from '@Apps/Channel/states';
import {unassignChannel} from '@modules/channels/api';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {UpdateChannelType, useUpdateChannelSubject} from '@Apps/Channel/hooks';
import {useSetAtom} from 'jotai';
import {isRentalOnlyUser, useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export type ChannelType = {
  channelNumber: number;
  channelHashId: string;
  productName: string;
  placeName: string;
  placeHashId?: string;
  hospitalProduct?: HospitalProductDetail;
  wholeProduct?: WholeProductIndex & CategoryIndex[];
};

export const BandTable: React.FC<{rows: ChannelType[]; hospitalWardHashIds: string[]; zoneId: string}> = ({
  rows,
  hospitalWardHashIds,
  zoneId,
}) => {
  const navigate = useNavigate();
  const {canEdit} = useUserResourcesPermissions('CHANNEL_LIST');
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {notify} = useUpdateChannelSubject();
  const isSpecifiedPlace = (row: ChannelType) => {
    if (hospitalWardHashIds.length === 0) {
      return !!row.productName;
    } else if (row.placeHashId === undefined) {
      return false;
    } else {
      return hospitalWardHashIds.includes(row.placeHashId);
    }
  };

  const hoverTitle = (row: ChannelType) => {
    const categories = row.wholeProduct?.categories;
    let rootCategoryName: string | undefined;
    let narrowCategoryName: string | undefined;
    if (!isNullish(categories)) {
      rootCategoryName = CategoryFormatter.getRootCategory(categories)?.name;
      narrowCategoryName = CategoryFormatter.getNarrowCategory(categories)?.name;
    }

    return (
      <>
        {row.hospitalProduct && (
          <Typography className={classes.hoverTitle}>
            管理番号:{row.hospitalProduct.managementId}
            <br />
            シリアル番号：{row.hospitalProduct.serialNumber}
            <br />
          </Typography>
        )}
        {row.wholeProduct && (
          <Typography className={classes.hoverTitle}>
            型式: {row.wholeProduct.name}
            <br />
            機種名:{row.wholeProduct.displayName}
            <br />
          </Typography>
        )}
        {!isNullish(rootCategoryName) && !isNullish(narrowCategoryName) && (
          <Typography className={classes.hoverTitle}>
            大分類:{rootCategoryName}
            <br />
            小分類:{narrowCategoryName}
            <br />
          </Typography>
        )}
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = (productHashID: string) => {
    navigate(`/products/${productHashID}`);
  };

  const setDefaultChannelHashId = useSetAtom(defaultChannelHashIdAtom);
  const [dialogChannelNumber, setDialogChannelNumber] = useAtom(dialogChannelNumberAtom);
  const [isOpenChannelDialog, setIsOpenChannelDialog] = useAtom(isOpenChannelDialogAtom);
  const [count, setCount] = useState(0);
  const [openUnassignChannelDialog, setOpenUnassignChannelDialog] = useState(false);
  const [unassignedHospitalProductHashId, setUnassignedHospitalProductHashId] = useState('');
  const [unassignDialogChannelNumber, setUnassignDialogChannelNumber] = useState(null);

  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, row: ChannelType) => {
      e.preventDefault();
      if (row.productName) {
        setDialogChannelNumber(row.channelNumber);
      } else {
        setIsOpenChannelDialog(true);
        setDefaultChannelHashId(row.channelHashId);
        setDialogChannelNumber(row.channelNumber);
      }
    },
    [setDefaultChannelHashId, setDialogChannelNumber, setIsOpenChannelDialog]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent<Document, MouseEvent>) => {
      e.stopPropagation();
      setCount(count + 1);
      if (count === 0) return;

      if (!dialogChannelNumber) return;
      setCount(0);
      if (isOpenChannelDialog) return;
      setDialogChannelNumber(null);
    },
    [count, dialogChannelNumber, setDialogChannelNumber, isOpenChannelDialog]
  );

  const handleChangeInfoInChannel = useCallback(
    (row) => {
      setDefaultChannelHashId(row.channelHashId);
      setDialogChannelNumber(row.channelNumber);
      setIsOpenChannelDialog(true);
    },
    [setDefaultChannelHashId, setDialogChannelNumber, setIsOpenChannelDialog]
  );

  const handleUnassignChannel = useCallback(
    (row) => {
      setUnassignDialogChannelNumber(row.channelNumber);
      setOpenUnassignChannelDialog(true);
      setUnassignedHospitalProductHashId(row.hospitalProduct.hashId);
    },
    [setUnassignDialogChannelNumber]
  );

  const unassignChannelActions = {
    resolve: async () => {
      setOpenUnassignChannelDialog(false);
      try {
        await unassignChannel(myInfo.hospitalHashId, unassignedHospitalProductHashId);
      } catch {
        openSnackBar('チャンネル割当の解除に失敗しました。', 'left', 'bottom', 'error');
        return;
      }
      openSnackBar('チャンネル割当を解除しました。');
      setUnassignDialogChannelNumber(null);
      // todo: all→ZoneIDへ
      notify(zoneId as UpdateChannelType);
      return;
    },
    reject: async () => {
      setOpenUnassignChannelDialog(false);
    },
  };

  const tableBody = useMemo(() => {
    return rows.map((row, index) => (
      <TableRow key={`${row.channelNumber}_${index}`} className={classes.rowStyle}>
        <TableCell component="th" scope="row" className={classes.cellStyle} key={`channelButton-${row.channelNumber}`}>
          <div>
            <Typography
              id={`channelNumber-${row.channelNumber}`}
              className={`${classes.channelNumber}
                ${clsx(row.productName ? classes.activeChannelNumber : classes.nonActiveChannelNumber)}`}
              onClick={(e) => canEdit && handleOpen(e, row)}>
              {row.channelNumber}
            </Typography>
          </div>
        </TableCell>
        <ClickAwayListener onClickAway={handleClose} key={`clickAwayListener-${row.channelNumber}`}>
          <Popper
            open={row.channelNumber === dialogChannelNumber && !isOpenChannelDialog && !openUnassignChannelDialog}
            transition
            placement={'bottom-start'}
            key={`popper-${row.channelNumber}`}
            anchorEl={document.getElementById(`channelNumber-${row.channelNumber}`)}
            className={classes.popper}>
            <Paper id="menu-list-grow">
              <MenuList className={classes.menuListColumn}>
                <MenuItem
                  value={'changeChannel'}
                  className={classes.menuItemHovered}
                  onClick={() => {
                    handleChangeInfoInChannel(row);
                  }}>
                  チャンネルを変更
                </MenuItem>
                <MenuItem
                  value={'unassign'}
                  className={classes.menuItemHovered}
                  onClick={() => {
                    handleUnassignChannel(row);
                  }}>
                  チャンネルを解除
                </MenuItem>
              </MenuList>
            </Paper>
          </Popper>
        </ClickAwayListener>
        {row.hospitalProduct && row.wholeProduct && (
          <Tooltip title={hoverTitle(row)}>
            <TableCell className={classes.rowCellStyle}>
              <div
                className={classes.productTextContainer}
                onClick={() => handleClick(row.hospitalProduct?.hashId + '')}>
                <Typography className={classes.productName}> {row.productName}</Typography>
              </div>
            </TableCell>
          </Tooltip>
        )}
        <Tooltip title={row.placeName}>
          <TableCell className={classes.rowCellStyle}>
            <div className={classes.placeTextContainer}>
              <span className={clsx(!isSpecifiedPlace(row) && classes.greyStyleText)}>{row.placeName}</span>
            </div>
          </TableCell>
        </Tooltip>
      </TableRow>
    ));
  }, [
    rows,
    classes.rowStyle,
    classes.cellStyle,
    classes.channelNumber,
    classes.activeChannelNumber,
    classes.nonActiveChannelNumber,
    classes.popper,
    classes.menuListColumn,
    classes.menuItemHovered,
    classes.rowCellStyle,
    classes.productTextContainer,
    classes.productName,
    classes.placeTextContainer,
    classes.greyStyleText,
    handleClose,
    dialogChannelNumber,
    isOpenChannelDialog,
    openUnassignChannelDialog,
    hoverTitle,
    isSpecifiedPlace,
    handleOpen,
    handleChangeInfoInChannel,
    handleUnassignChannel,
    handleClick,
  ]);

  return (
    <TableContainer component={Paper} className={clsx(classes.table, zoneId === '11' && classes.outZoneHeight)}>
      <AlertDialog
        title={`チャンネル「${unassignDialogChannelNumber}」の紐付けを解除`}
        positiveButtonLabel={'解除'}
        content={`チャンネルの紐付けを解除しますか？\n\nこの操作は元に戻せません。`}
        open={openUnassignChannelDialog}
        actions={unassignChannelActions}
      />
      <Table>
        <TableHead className={classes.headStyle}>
          <TableRow>
            <TableCell className={classes.headChStyle}>ch</TableCell>
            <TableCell className={classes.headProductStyle}>型式</TableCell>
            <TableCell className={classes.headCellStyle}>機器管理場所</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  productTextContainer: {
    display: 'block',
    width: '180px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  placeTextContainer: {
    display: 'block',
    width: '72px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headStyle: {
    height: '29px',
    fontSize: '12px',
  },
  chHeadStyle: {
    width: '50px',
  },
  greyStyleText: {
    color: '#919BAB',
  },
  headChStyle: {
    fontSize: '12px',
    color: '#172B4D',
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '16px',
    width: '50px',
  },
  headProductStyle: {
    fontSize: '12px',
    color: '#172B4D',
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '16px',
    width: '200px',
  },
  headCellStyle: {
    fontSize: '12px',
    color: '#172B4D',
    paddingTop: '8px',
    paddingBottom: '8px',
    width: '72px',
  },
  rowStyle: {
    height: '15px',
    padding: 0,
  },
  cellStyle: {
    border: '0px none',
    height: '15px',
    paddingBottom: '2px',
    paddingTop: '2px',
  },
  rowCellStyle: {
    border: '0px none',
    fontSize: '12px',
    color: '#172B4D',
    height: '15px',
    paddingTop: '2px',
    paddingBottom: '2px',
    width: '200px',
  },
  activeChannelNumber: {
    color: '#2A96E8',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  nonActiveChannelNumber: {
    color: '#7A869A',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  table: {
    width: '400px',
    marginRight: '10px',
    height: '350px',
  },
  outZoneHeight: {
    height: '645px',
  },
  hoverTitle: {
    fontSize: '12px',
  },
  productName: {
    color: theme.palette.info.dark,
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  popper: {
    zIndex: 100000,
    maxHeight: 360,
    overflow: 'auto',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  },
  menuListColumn: {
    display: 'block',
  },
  menuItemHovered: {
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  linkContent: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
  channelNumber: {
    cursor: 'pointer',
  },
}));
