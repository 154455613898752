import React from 'react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {styled, Box, Button, Typography} from '@mui/material';
import {ChevronLeft, Notifications} from '@mui/icons-material';
import {appHeaderHeight} from '@templates/NoSideBarLayoutForPC';
import {Dropzone} from '@molecules/Dropzone';
import {openSnackBar} from '@molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {exportTemplateForImportTrainees} from '@modules/training_schedule_trainees/api';
import {ExportTemplateForImportTraineesMessages} from './constants';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${appHeaderHeight})`,
  width: '100%',
  color: '#172B4D',
  fontSize: '14px',
});
const Body = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '32px',
  backgroundColor: '#FFFFFF',
  color: 'inherit',
  fontSize: 'inherit',
});
const Footer = styled(Box)({
  padding: '24px 32px',
  height: '44px',
  backgroundColor: '#F2F6FC',
  color: 'inherit',
  fontSize: 'inherit',
});

const Title = styled(Typography)({
  fontSize: '24px',
});
const Description = styled(Typography)({
  marginTop: '8px',
  fontSize: 'inherit',
});

const ImportSteps = styled(Box)({
  padding: '48px 24px',
  '& > div:nth-of-type(n+2)': {
    marginTop: '32px',
  },
});
const ImportStep = styled(Box)({
  fontSize: '14px',
});
const ImportStepTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
});
const ImportStepDescription = styled(Box)({
  marginTop: '16px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});
const ImportStepForm = styled(Box)({
  marginTop: '16px',
  fontSize: 'inherit',
});

export const TrainingManagementScheduleTraineesImport = () => {
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();

  const handleTemplateDownload = async () => {
    if (!hashId) return;

    try {
      await exportTemplateForImportTrainees(myInfo.hospitalHashId, hashId);
      openSnackBar(ExportTemplateForImportTraineesMessages.succeeded);
    } catch (error) {
      openSnackBar(ExportTemplateForImportTraineesMessages.failed, 'left', 'bottom', 'error');
      console.error('Failed to export template for import trainees', error);
    }
  };

  const handleUpload = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    console.warn('unimplemented');
  };

  return (
    <Container>
      <Body>
        <Title variant="h1">研修対象者・受講者の一括登録</Title>
        <Description>所定のテンプレート（Excelファイル）を用いて、研修対象者・受講者を一括登録できます。</Description>
        <ImportSteps>
          {/* step. 1 */}
          <ImportStep>
            <ImportStepTitle variant="h2">1. Excelファイルの準備</ImportStepTitle>
            <ImportStepDescription>
              <Typography>
                下記ボタンをクリックして、所定テンプレート（Excelファイル）のダウンロードを開始してください。
              </Typography>
              <Typography sx={{display: 'inline'}}>ダウンロードの準備が完了すると、右上のベルマーク（</Typography>
              <Notifications sx={{fontSize: 'inherit'}} />
              <Typography sx={{display: 'inline'}}>）に通知が届きます。</Typography>
              <Typography>届いた通知内容をクリックすると、ダウンロードが完了します。</Typography>
            </ImportStepDescription>
            <ImportStepForm>
              <Button
                variant="contained"
                sx={{height: '40px', backgroundColor: '#0052CC'}}
                onClick={handleTemplateDownload}>
                テンプレートのダウンロードを開始
              </Button>
            </ImportStepForm>
          </ImportStep>
          {/* step. 2 */}
          <ImportStep>
            <ImportStepTitle variant="h2">2. Excelファイルのアップロード</ImportStepTitle>
            <ImportStepDescription>
              <Typography>
                所定のテンプレートに、登録したい研修対象者・受講者情報を入力した上でアップロードしてください。
              </Typography>
              <Typography>※研修対象者・受講者名は入力必須項目です。</Typography>
            </ImportStepDescription>
            <ImportStepForm>
              <Dropzone
                onDrop={handleUpload}
                accept={[
                  'text/csv',
                  'application/vnd.ms-excel',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ]}
              />
            </ImportStepForm>
          </ImportStep>
          {/* step. 3 */}
          <ImportStep>
            <ImportStepTitle variant="h2">3. データの確認・登録</ImportStepTitle>
            <ImportStepDescription>
              <Typography>
                アップロードされたファイルを基に、HITOTSU Assetにインポートする研修対象者・受講者データを表示します。
              </Typography>
            </ImportStepDescription>
            <ImportStepForm>{/* unimplemented */}</ImportStepForm>
          </ImportStep>
        </ImportSteps>
      </Body>
      <Footer>
        <Button
          component={Link}
          startIcon={<ChevronLeft />}
          variant="outlined"
          sx={{
            height: '44px',
            fontSize: '14px',
            color: '#0052CC',
            borderColor: '#0052CC',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              borderColor: '#0052CC',
              backgroundColor: '#FFFFFF',
            },
          }}
          to={`/training_managements/schedules/${hashId}?tab=traineeList`}>
          対象者・受講者リストに戻る
        </Button>
      </Footer>
    </Container>
  );
};
