import React, {useEffect, useState} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  SxProps,
  Theme,
  PaperProps,
  CircularProgress,
  Typography,
} from '@mui/material';
import {NoContentMessage} from './NoContentMessage';

export type TableOrder<T = string> = {
  fieldId: keyof T;
  direction: 'asc' | 'desc';
};

type EnhancedTableProps<T extends object> = {
  data: T[]; // テーブルに表示するデータ ジェネリクスで型を指定
  columns: EnhancedColumn<T>[]; // テーブルの列定義
  order?: TableOrder<T> | null; // ソート順を呼び出し元で制御する場合に使用
  defaultOrder?: TableOrder<T>; // 初期ソート順
  onOrderChange?: (column: keyof T, orderDirection: 'asc' | 'desc') => void; // ソートが変更された時のコールバック
  isLoading?: boolean; // trueの時はローディング中の表示を行う
  stickyHeader?: boolean; // ヘッダーを固定するかどうか
  stickyLeftColumnsCount?: number; // 左側に固定するカラムの数
  stickyColumnsRightCount?: number; // 右側に固定するカラムの数
  // stickyLeftColumnsCount と stickyColumnsRightCount は、テーブルの左右のカラムを固定する機能を提供します。
  // 両方を設定することで、テーブルの左側と右側にそれぞれ固定されたカラムを持つことができます。
  defaultAlignment?: 'left' | 'right' | 'center'; // テーブルカラムのデフォルトの揃え
  defaultWhiteSpace?: 'nowrap' | 'normal'; // テキストの改行設定のデフォルト値
  options?: TableOptions; // テーブル全体のオプション
  noDataComponent?: React.ReactNode; // データがない時に表示するコンポーネント 未指定ならデフォルトのメッセージを表示
  onClickRow?: (row: T) => void; // 行をクリックした時のコールバック
};

const wrapperStyle: SxProps = {
  display: 'inline-block',
  maxWidth: '40ch', // 日本語は20文字程度
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
};

export type EnhancedColumn<T extends object> = {
  title?: React.ReactNode;
  field: keyof T;
  //従来のrenderの機能 コンポーネントを表示したり変換したstring(末尾に円をつける等)を表示する時に使用
  fieldValue?: (row: T) => React.ReactNode;
  noBodyWrap?: boolean; //セル内で改行しないときはtrue
  sorting?: boolean; //ソートを有効にするかどうか
  justifyContentBody?: 'left' | 'right' | 'center';
  stickyHeader?: boolean;
  stickyColum?: boolean;
  sx?: SxProps<Theme>;
};

export type TableOptions = {
  size?: 'small' | 'medium';
  tableSx?: SxProps<Theme>;
  tableRowSx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
};

/**
 * 汎用的なテーブルコンポーネント。
 *
 * @todo チェックボックス マウスオーバーでボタンが出る対応
 * @typeParam T - テーブルの行データの型。
 * @param props.data - テーブルに表示するデータの配列。
 * @param props.columns - テーブルの列定義の配列。各列のプロパティやスタイルを指定します。
 * @param props.isLoading - データの読み込み中であることを示すフラグ。
 * @param props.noDataComponent - データがない場合に表示するコンポーネント。
 * @param props.order - 現在のソート状態を指定するオブジェクト。`{ fieldId: string, direction: 'asc' | 'desc' }` の形式。
 * @param props.defaultOrder - 初期のソート状態を指定するオブジェクト。`{ fieldId: string, direction: 'asc' | 'desc' }` の形式。
 * @param props.stickyHeader - ヘッダーを固定表示するかどうかを指定するフラグ (デフォルトは `false`)。
 * @param props.stickyLeftColumnsCount - 左側の固定カラム数 (デフォルトは `0`)。
 * @param props.stickyColumnsRightCount - 右側の固定カラム数 (デフォルトは `0`)。
 * @param props.defaultAlignment - セルのデフォルトのテキスト揃え方向 (`left` | `center` | `right`)。デフォルトは `left`。
 * @param props.defaultWhiteSpace - セル内のデフォルトの改行処理 (`normal` | `nowrap` | `pre` | `pre-wrap`)。デフォルトは `normal`。
 * @param props.onOrderChange - ソート状態が変更された際に呼び出されるコールバック関数。`(column: string, direction: 'asc' | 'desc') => void`。
 * @param props.options - テーブル全体のカスタマイズオプションを指定するオブジェクト。
 *
 * @example
 *
 * ```tsx
 * type DataType ={
 *  id: number;
 *  name: string;
 * }
 * const data:DataType[] = [
 *   { id: 1, name: 'Aさん', age: 30 },
 *   { id: 2, name: 'Bさん', age: 25 },
 * ];
 * const columns = EnhancedColumn<DataType>[] = [
 *   { title: 'ID', field: 'id', sorting: true },
 *   { title: 'Name', field: 'name',sx: {minWidth: '180px'}, },
 *   { title: 'Age', field: 'age' },
 * ];
 *
 * <EnhancedTable<DataType>
 *   data={data}
 *   columns={columns}
 *   defaultOrder={{ fieldId: 'name', direction: 'asc' }}
 *   onOrderChange={(column, direction) => console.log(column, direction)}
 * />
 * ```
 */
export const EnhancedTable = <T extends object>({
  data,
  columns,
  isLoading,
  noDataComponent,
  order,
  defaultOrder,
  stickyHeader = false,
  stickyLeftColumnsCount = 0, // デフォルトは0、つまり左側のカラムは固定しない
  stickyColumnsRightCount = 0, // デフォルトは0、つまり右側のカラムは固定しない
  defaultAlignment = 'left', // デフォルトは左揃え
  defaultWhiteSpace = 'normal', // デフォルトは通常の改行
  onOrderChange,
  onClickRow,
  options,
}: EnhancedTableProps<T>) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(defaultOrder?.direction ?? order?.direction ?? 'asc');
  const [orderBy, setOrderBy] = useState<keyof T | null>(
    (defaultOrder?.fieldId as keyof T) ?? (order?.fieldId as keyof T) ?? null
  );

  useEffect(() => {
    if (!order) return;
    setOrderBy(order.fieldId as keyof T);
    setSortOrder(order.direction);
  }, [order]);

  const handleSort = (field: keyof T) => {
    // orderが定義されていたら定義側の情報を優先する
    const isAsc = (order?.fieldId === field || orderBy === field) && (order?.direction || sortOrder) === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newOrder);
    setOrderBy(field);
    if (onOrderChange) {
      onOrderChange(field, newOrder);
    }
  };

  /** orderが未定義の時にdataをソートする */
  const sortedData = React.useMemo(() => {
    if (order || !orderBy) {
      return data;
    }

    return [...data].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [data, order, orderBy, sortOrder]);

  const hasData = !isLoading && data.length > 0;
  const isNoData = !isLoading && data.length === 0;
  const isDataLoading = !isNoData && isLoading;

  const getColumnValue = (column: EnhancedColumn<T>, row: T) => {
    return column.fieldValue ? column.fieldValue(row) : column.field && row[column.field];
  };

  const getWidthFromSx = (sx: SxProps<Theme> | undefined): number => {
    if (!sx) return 180;
    if (typeof sx === 'object' && 'width' in sx) {
      const width = (sx as Record<string, number | string>).width;
      if (typeof width === 'number') {
        return width;
      } else if (typeof width === 'string') {
        return parseInt(width, 10);
      }
    }
    return 180; // デフォルト幅
  };

  const calculateLeftPosition = (index: number) => {
    return columns.slice(0, index).reduce((total, col) => total + getWidthFromSx(col.sx), 0);
  };

  const calculateRightPosition = (index: number) => {
    return columns.slice(index + 1).reduce((total, col) => total + getWidthFromSx(col.sx), 0);
  };

  const calculateZIndex = (index: number, baseZIndex: number) => {
    return baseZIndex + index * 10; // ベースのzIndexに対して10ずつ積み上げる
  };

  return (
    <TableContainer component={Paper} sx={{...options?.paperSx}}>
      <Table
        size={options?.size ?? 'small'}
        sx={{...options?.tableSx}}
        stickyHeader={stickyHeader}
        aria-label="enhanced table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                align={column.justifyContentBody || defaultAlignment}
                sx={{
                  whiteSpace: column.noBodyWrap ? 'nowrap' : defaultWhiteSpace,
                  ...(index < stickyLeftColumnsCount
                    ? {
                        position: 'sticky',
                        backgroundColor: '#fff',
                        left: calculateLeftPosition(index),
                        zIndex: calculateZIndex(index, 1000), // Headerが手前に来るようにzIndexを設定
                      }
                    : {}),
                  ...(index >= columns.length - stickyColumnsRightCount
                    ? {
                        position: 'sticky',
                        backgroundColor: '#fff',
                        right: calculateRightPosition(index),
                        zIndex: calculateZIndex(index, 1000),
                      }
                    : {}),
                  ...column.sx,
                }}>
                <TableSortLabel
                  active={column.sorting && orderBy === column.field}
                  direction={sortOrder}
                  onClick={() => column.sorting && handleSort(column.field as keyof T)}
                  disabled={!column.sorting}
                  hideSortIcon={column.sorting === false || !column.sorting}>
                  {column.title || ''}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* ローディング中 */}
          {isDataLoading && (
            <CenterTableRow colSpan={columns.length}>
              <CircularProgress sx={{color: '#0052CC', margin: '16px 0'}} />
            </CenterTableRow>
          )}
          {/* 対象のデータなし */}
          {isNoData && (
            <CenterTableRow colSpan={columns.length}>
              {noDataComponent || <NoContentMessage title={'データがありません'} message={''} />}
            </CenterTableRow>
          )}

          {hasData &&
            sortedData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  '&:last-child td, &:last-child th': {border: 0},
                  ...options?.tableRowSx,
                  cursor: onClickRow && 'pointer',
                }}
                onClick={() => onClickRow && onClickRow(row)}>
                {columns.map((column, colIndex) => {
                  const value = getColumnValue(column, row);
                  return (
                    <TableCell
                      key={colIndex}
                      align={column.justifyContentBody || defaultAlignment}
                      sx={{
                        whiteSpace: column.noBodyWrap ? 'nowrap' : defaultWhiteSpace,
                        ...(colIndex < stickyLeftColumnsCount
                          ? {
                              position: 'sticky',
                              backgroundColor: '#fff',
                              left: calculateLeftPosition(colIndex),
                              zIndex: calculateZIndex(colIndex, 100),
                            }
                          : {}),
                        ...(colIndex >= columns.length - stickyColumnsRightCount
                          ? {
                              position: 'sticky',
                              backgroundColor: '#fff',
                              right: calculateRightPosition(colIndex),
                              zIndex: calculateZIndex(colIndex, 100),
                            }
                          : {}),
                        ...column.sx,
                      }}>
                      {typeof value === 'string' ? (
                        <Typography component="span" sx={wrapperStyle}>
                          {value}
                        </Typography>
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type CenterTableRowProps = {
  colSpan: number;
  children: React.ReactNode;
};
const CenterTableRow = ({colSpan, children}: CenterTableRowProps) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        {children}
      </TableCell>
    </TableRow>
  );
};
