import {EnhancedTable, NoContentMessage} from '@front-libs/ui';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Box, SxProps} from '@mui/material';
import {PaginationAndPerPage} from '@organisms/PaginationAndPerPage';
import React, {useMemo} from 'react';
import {PurchaseCandidateProductComponent, useColumns} from '../hooks/useColumns';
import {usePurchaseCandidateProductState} from '../hooks/usePurchaseCandidateProductState';
import {ListTableHeader} from './ListTableHeader';
import {usePurchaseCandidateProductsQuery} from '../hooks/usePurchaseCandidateProductsQuery';
import {ProductStatusConst} from '@modules/hospital_products/types';
import {DetailFilter} from '../types';
import {usePurchaseCandidateCategoriesQuery} from '../hooks/usePurchaseCandidateCategoriesQuery';
import {CategoryIndex} from '@modules/categories/types';
import {useNavigate} from 'react-router-dom';

const rootStyle: SxProps = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

/**
 * 買取一覧テーブル
 */
export const ListTable = React.memo(
  () => {
    const navigate = useNavigate();
    const handleClickEditButton = (item: PurchaseCandidateProductComponent) => {
      navigate(`/products/purchase_request/${item.hashId}`);
    };
    const {columns} = useColumns({handleClickEditButton});
    const {myInfo} = useMyInfo();
    const {dispatch, param, page, pageSize, setPageSize} = usePurchaseCandidateProductState();
    const {rootCategoryQuery, narrowCategoryQuery, descendantCategoriesQueries, selectRootCategory} =
      usePurchaseCandidateCategoriesQuery(myInfo.hospitalHashId);

    const {data, totalCount, isLoading} = usePurchaseCandidateProductsQuery(myInfo.hospitalHashId, param);

    const descendantCategories = useMemo(() => {
      if (descendantCategoriesQueries.length > 0) {
        let catQuery: CategoryIndex[] = [];
        for (const descendantCategoriesQuery of descendantCategoriesQueries) {
          const filteredCategories = descendantCategoriesQuery.data.filter((item) => item.depth === 1);
          catQuery = [...catQuery, ...filteredCategories];
        }
        return catQuery;
      } else {
        return narrowCategoryQuery.data;
      }
    }, [descendantCategoriesQueries, narrowCategoryQuery.data]);

    /** ページ変更 */
    const onPageChange = (newPage: number) => {
      dispatch({type: 'SET_PAGE', payload: newPage});
    };

    /** ページ件数変更 */
    const onPageSizeChange = (newPageSize: number) => {
      setPageSize(newPageSize);
      dispatch({type: 'SET_PAGE', payload: 1}); // ページサイズ変更時は1ページ目にリセット
    };

    const onOrderChange = (order: string, orderDirection: 'desc' | 'asc') => {
      const orderStr = `${orderDirection === 'desc' ? '-' : ''}${order === 'narrowCategoryName' ? 'narrowCategory' : order}`;
      dispatch({type: 'SET_ORDER', payload: orderStr});
    };

    /** ステータス変更 */
    const onStatusChange = (statusList: ProductStatusConst[]) => {
      dispatch({type: 'SET_STATUSES', payload: statusList});
    };

    /** 名前の検索条件の変更 */
    const onNameChange = (name: string) => {
      dispatch({type: 'SET_NAME', payload: name});
    };

    /** ステータス変更 */
    const onRootCategoriesChange = (rootCategories: string[]) => {
      selectRootCategory(rootCategories);
      dispatch({type: 'SET_ROOT_CATEGORIES', payload: rootCategories});
    };
    const onNarrowCategoriesChange = (narrowCategories: string[]) => {
      dispatch({type: 'SET_NARROW_CATEGORIES', payload: narrowCategories});
    };

    // 詳細で検索条件を変更
    const onDetailFilterChange = (detailFilter: Partial<DetailFilter>) => {
      dispatch({type: 'SET_DETAIL_FILTER', payload: detailFilter});
    };

    return (
      <Box sx={rootStyle}>
        <ListTableHeader
          rootCategories={rootCategoryQuery?.data ?? []}
          narrowCategories={descendantCategories}
          onStatusChange={onStatusChange}
          onNameChange={onNameChange}
          onRootCategoriesChange={onRootCategoriesChange}
          onNarrowCategoriesChange={onNarrowCategoriesChange}
          totalCount={totalCount}
          onDetailFilterChange={onDetailFilterChange}
        />
        <EnhancedTable<PurchaseCandidateProductComponent>
          data={data ?? []}
          columns={columns}
          isLoading={isLoading}
          stickyHeader
          stickyLeftColumnsCount={1}
          noDataComponent={<NoContentMessage title={'買取対象機器が存在していません'} />}
          onOrderChange={(order, orderDirection) => order !== 'editButton' && onOrderChange(order, orderDirection)}
          options={{
            tableRowSx: {
              // MEMO:テーブルの最後の行はデフォルトでborder 0が指定されているのを上書きする
              '&:last-child td:first-of-type': {borderRight: '1px solid #E0E0E0'},
            },
          }}
          onClickRow={(item) => navigate(`/products/${item.hashId}`)}
        />
        <PaginationAndPerPage
          totalCount={totalCount ?? 0}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </Box>
    );
  },
  () => true
);
