import React, {useCallback} from 'react';
import {withSuspense, useSearchParams} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@molecules/Loading';
import {useInspectionResult} from '@Apps/InspectionResult/common/hooks';
import {ViewInspectionResult} from '@Apps/InspectionResult/pc/ViewInspectionResult';
import {EditInspectionResult} from '@Apps/InspectionResult/pc/EditInspectionResult';
import {useLocation, useParams} from 'react-router-dom';
import {NextUpdateInspectionStatusParam} from '@modules/inspection_results/api';
import {InspectionResultStatus} from '@modules/inspection_results/enum';
import {useInspection} from '@modules/inspections/hooks';

const _InspectionResult: React.FC = () => {
  const {state} = useLocation();
  const queryParams = useSearchParams(); // NOTE: 別タブで遷移する場合などはstateを引き継げないのでクエリパラメータを利用する。
  const buildNextUpdateInspectionStatusParam = (): NextUpdateInspectionStatusParam | null => {
    if (queryParams.status && queryParams.inspectorHashId) {
      return {
        status: queryParams.status as InspectionResultStatus,
        inspectorHashId: queryParams.inspectorHashId as string,
      };
    }
    if (state !== null) {
      return {
        status: state.status,
        inspectorHashId: state.inspectorHashId,
      };
    }
    return null;
  };
  const {inspectionHashId, inspectionResultHashId} = useParams();
  const {myInfo} = useMyInfo();
  const {data: inspection, isLoading: isInspectionLoading} = useInspection(
    myInfo.hospitalHashId,
    inspectionHashId ? inspectionHashId : '',
    true // HIT-4116 点検表の論理削除で deleted_at をきちんと埋め込むようになったため
  );

  const {
    data: inspectionResult,
    isLoading: isInspectionResultLoading,
    refetch: refetchResult,
  } = useInspectionResult(
    myInfo.hospitalHashId,
    inspectionHashId ? inspectionHashId : '',
    inspectionResultHashId ? inspectionResultHashId : '',
    {noCache: true}
  );

  const handleResultUpdate = useCallback(() => {
    refetchResult();
  }, [refetchResult]);

  if (isInspectionLoading || isInspectionResultLoading) {
    return <InnerLoading />;
  }
  if (!inspection || !inspectionResult) {
    return null;
  }

  const inspectionTable = inspectionResult.inspectionTable ?? inspection.table;

  if (inspectionResult.status === 'completed') {
    return (
      <ViewInspectionResult
        key={inspection?.table.hashId}
        inspection={inspection}
        inspectionTable={inspectionTable}
        inspectionResult={inspectionResult}
        onResultUpdate={handleResultUpdate}
      />
    );
  } else {
    return (
      <EditInspectionResult
        key={inspection?.table.hashId}
        inspection={inspection}
        inspectionTable={inspectionTable}
        inspectionResult={inspectionResult}
        onResultUpdate={handleResultUpdate}
        nextUpdateInspectionStatusParam={buildNextUpdateInspectionStatusParam()}
      />
    );
  }
};

export const InspectionResult = withSuspense(_InspectionResult, null);
