import React, {useState} from 'react';
import {styled, Box, Button, Dialog, DialogTitle, DialogActions, IconButton, TextField} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {HospitalDepartMent} from '@modules/hospital_departments/types';
import {TrainingScheduleTrainee, UpdateTrainingScheduleTraineeRequest} from '@modules/training_schedule_trainees/types';
import {HospitalDepartmentSelector} from './HospitalDepartmentSelector';

type Props = DialogProps & {
  trainee: TrainingScheduleTrainee;
  departments: HospitalDepartMent[];
  defaultDepartmentHashId: string | null;
  onSubmit: (req: UpdateTrainingScheduleTraineeRequest) => void;
};

const InputForm = styled(Box)({
  marginTop: '24px',
  '& > div:nth-of-type(n+2)': {
    marginTop: '24px',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

const Label = styled('label')({
  display: 'block',
  marginBottom: '2px',
});

export const TraineeEditDialog = ({open, actions, trainee, departments, defaultDepartmentHashId, onSubmit}: Props) => {
  const [name, setName] = useState<string>(trainee.name);
  const [employeeNumber, setEmployeeNumber] = useState<string | null>(trainee.employeeNumber);
  const [hospitalDepartmentHashId, setHospitalDepartmentHashId] = useState<string | null>(defaultDepartmentHashId);
  const [edited, setEdited] = useState<boolean>(false);
  const canSubmit = edited && name !== '';

  // フォーム入力
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setEdited(true);
  };
  const handleEmployeeNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmployeeNumber(event.target.value);
    setEdited(true);
  };
  const handleDepartmentSelect = (hashId: string | null) => {
    setHospitalDepartmentHashId(hashId);
    setEdited(true);
  };

  // ボタンアクション
  const handleCancel = () => {
    actions.reject();
  };
  const handleSubmit = () => {
    if (!canSubmit) return;

    onSubmit({
      name,
      employeeNumber,
      hospitalDepartmentHashId,
    });
    actions.resolve(true);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={!!open}
      PaperProps={{
        sx: {position: 'relative', minWidth: '504px', padding: '32px', fontSize: '14px', color: '#172B4D'},
      }}>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          fontSize: '24px',
          right: '16px',
          top: '24px',
          color: 'inherit',
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{padding: 0, fontWeight: 700}}>研修対象者・受講者情報を編集</DialogTitle>
      <InputForm>
        <Box>
          <Label>職員番号</Label>
          <TextField
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            value={employeeNumber || ''}
            onChange={handleEmployeeNumberChange}
          />
        </Box>
        <Box>
          <RequiredLabel>研修対象者・受講者名</RequiredLabel>
          <TextField
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            value={name || ''}
            onChange={handleNameChange}
          />
        </Box>
        <Box>
          <Label>所属部署</Label>
          <HospitalDepartmentSelector
            placeholder="所属部署を検索"
            departments={departments}
            selectedHashId={hospitalDepartmentHashId}
            onSelect={handleDepartmentSelect}
          />
        </Box>
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleCancel}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!canSubmit}
          onClick={handleSubmit}>
          保存
        </Button>
      </Actions>
    </Dialog>
  );
};
