import {isNullish} from '@front-libs/helpers';
import {useLogout} from '@modules/auth/hooks';
import {useFetchHospitalQuery} from '@modules/hospital/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useEffect} from 'react';
import {isMobileOnly} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';

/**
 * ユーザーが初期状態であれば対象のページにリダイレクトする
 */
export const useInitialSettingRedirect = () => {
  const {myInfo} = useMyInfo();
  const {canAccessAsset} = useMyRole();
  const navigate = useNavigate();
  const logout = useLogout();
  const hasInitialSettingAlerted = sessionStorage.getItem('hasInitialSettingAlerted');

  useEffect(() => {
    // スマートフォン端末ではSPページにリダイレクト
    if (isMobileOnly && !location.pathname.includes('/sp')) {
      navigate('/sp/products', {replace: true});
      return;
    }

    // 初期設定が済んでいないユーザーは別システムへ遷移
    if (!isNullish(myInfo?.initialized) && !myInfo.initialized && !isMobileOnly) {
      if (!hasInitialSettingAlerted) {
        sessionStorage.setItem('hasInitialSettingAlerted', 'true');
        window.alert(`ユーザー情報の初回設定のため、HITOTSU Hospitalに遷移します`);
      }
      window.location.href = import.meta.env.VITE_ACCOUNT_SERVICE_URL;
      return;
    }

    if (!canAccessAsset) {
      alert('このユーザーではAssetを利用できません。Asset利用設定済のユーザーでログインしてください。');
      logout();
    }
  }, [myInfo, isMobileOnly]);
};
