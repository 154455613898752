import React from 'react';
import {Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from '@mui/material';
import {Done} from '@mui/icons-material';

export const PointsAlert = () => {
  return (
    <Stack
      sx={{
        backgroundColor: '#F2F6FC',
        border: '1px solid #0052CC',
        borderRadius: '10px',
        padding: '0px',
        width: '100%',
      }}>
      {/* タイトル部分 */}
      <Box
        sx={{
          color: '#FFF',
          borderRadius: '8px 8px 0 0',
          backgroundColor: '#0052CC',
          fontWeight: 'bold',
          fontSize: '14px',
          padding: '8px 16px',
          lineHeight: '24px',
          textAlign: 'center',
        }}>
        買取査定３つのポイント
      </Box>

      {/* 本文（リスト） */}
      <List sx={{padding: '16px'}}>
        <ListItem disableGutters sx={{padding: '0', color: '#0052CC'}}>
          <ListItemIcon sx={{minWidth: 'auto', marginRight: 1}}>
            <Done sx={{color: '#0052CC'}} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{fontSize: '14px'}} variant="body2">
                買取査定依頼、および、その後の買取成立による、
                <Typography component={'span'} sx={{fontSize: '14px', fontWeight: '700'}}>
                  ご利用者様の費用負担は一切ございません。
                </Typography>
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters sx={{padding: '0', color: '#0052CC'}}>
          <ListItemIcon sx={{minWidth: 'auto', marginRight: 1}}>
            <Done sx={{color: '#0052CC'}} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{fontSize: '14px'}} variant="body2">
                査定依頼後、査定結果が出る前に
                <Typography component={'span'} sx={{fontSize: '14px', fontWeight: '700'}}>
                  査定を取り止めても問題ありません。
                </Typography>
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters sx={{padding: '0', color: '#0052CC'}}>
          <ListItemIcon sx={{minWidth: 'auto', marginRight: 1}}>
            <Done sx={{color: '#0052CC'}} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{fontSize: '14px'}} variant="body2">
                査定結果が出た後、
                <Typography component={'span'} sx={{fontSize: '14px', fontWeight: '700'}}>
                  売却を行わなくても問題ありません。
                </Typography>
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Stack>
  );
};
