import React from 'react';
import {Button, Typography, styled} from '@material-ui/core';
import {AddCommentIconButton} from '@molecules/Buttons/AddCommentIconButton';
import {FaultReceptionStatus} from '@modules/fault_receptions/types';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

/**
 * 修理の詳細、コメント一覧のコンテナ
 */
const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const RightContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

const StyledTypography = styled(Typography)({
  fontWeight: 700,
});

const RepairButton = styled(Button)({
  marginLeft: '16px',
});

type ToolBarProps = {
  handleClickAddComment: VoidFunction;
  handleClickRepair: VoidFunction;
  status?: FaultReceptionStatus | undefined;
};

/**
 * 不具合受付詳細の対応履歴リストがあるカラムのヘッド部分
 * タイトルと修理を新規登録ボタンが表示される
 *
 * @param handleClickAddComment
 * @param handleClickRepair
 */
export const ToolBar = ({handleClickAddComment, handleClickRepair, status}: ToolBarProps) => {
  const {canEdit} = useUserResourcesPermissions('REPAIR');
  const isCompletedConfirmation = status && status === 'completed_confirmation';
  return (
    <Container>
      <StyledTypography>対応履歴</StyledTypography>
      <RightContainer>
        {canEdit && (
          <>
            <AddCommentIconButton onAddComment={handleClickAddComment} />
            <RepairButton
              variant={'contained'}
              color={'primary'}
              onClick={handleClickRepair}
              disabled={isCompletedConfirmation}>
              修理を新規登録
            </RepairButton>
          </>
        )}
      </RightContainer>
    </Container>
  );
};
