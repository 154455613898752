import React from 'react';
import {Button, Stack} from '@mui/material';
import {styles} from './styles';
import {purchaseRequestStateAtom} from '@Apps/PurchaseRequest/states';
import {useAtomValue} from 'jotai';

export type PurchaseRequestFooterProps = {
  showPreviousButton: boolean;
  nextButtonLabel: string;
  disabledNextButtonLabel?: boolean;
  onCancelButtonClick: () => void;
  onPreviousButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const PurchaseRequestFooter = React.memo((props: PurchaseRequestFooterProps) => {
  const {
    disabledNextButtonLabel,
    showPreviousButton,
    nextButtonLabel,
    onCancelButtonClick,
    onPreviousButtonClick,
    onNextButtonClick,
  } = props;
  const {currentStep} = useAtomValue(purchaseRequestStateAtom);

  return (
    <Stack sx={styles.root}>
      <Button variant="outlined" onClick={onCancelButtonClick}>
        キャンセル
      </Button>
      {showPreviousButton && (
        <Button variant="outlined" onClick={onPreviousButtonClick}>
          前の画面に戻る
        </Button>
      )}
      <Button
        variant="contained"
        onClick={onNextButtonClick}
        disabled={currentStep === 'contactInformation' && disabledNextButtonLabel}>
        {nextButtonLabel}
      </Button>
    </Stack>
  );
});
