import {Sidebar} from '@components/organisms/Sidebar';
import {updateHospitalSettings, useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {UpdateHospitalSettingsParams, rentalSettings} from '@modules/hospital_settings/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {TextField} from '@molecules/Formik/fields';
import {InnerLoading} from '@molecules/Loading';
import {openSnackBar} from '@molecules/SnackBar';
import {
  Box,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {getSettingsContentTemplate as templateClasses} from '@templates/ContentLayout/InnerSidebarContentLayoutV5';
import {Form, Formik, useFormikContext} from 'formik';
import React, {useCallback, useState} from 'react';
import {LabeledCheckbox} from '@molecules/LabeledCheckbox';

export const SettingsHospitalRental = () => {
  return (
    <Grid container sx={templateClasses.grid}>
      <RentalContainer>
        <RentalForm />
      </RentalContainer>
    </Grid>
  );
};

export type RentalSettings = {
  hashId: string;
  needManageMedicalDeviceLenderStatus: string;
  allowRentalRoomUndefined: boolean;
  allowSkipRentalFloorSelection: boolean;
  enableInspectionRentalRestriction: boolean;
  preInspectionRentalRestrictionPeriod: string;
  allowForceRentalForPreInspectionRestrictionPeriod: boolean;
  restrictLendingOnOverdueInspection: boolean;
  allowForceRentalForRestrictionOverdueInspection: boolean;
};

type RentalContainerProps = {
  children: React.ReactNode;
};

const RentalContainer = ({children}: RentalContainerProps) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  if (isLoading) {
    return <InnerLoading />;
  }

  const initialData: RentalSettings = {
    hashId: myInfo.hospitalHashId,
    needManageMedicalDeviceLenderStatus:
      data?.data?.find((x) => x.key === rentalSettings.use_device_lender.key)?.value ?? 'not_need',
    allowRentalRoomUndefined:
      data?.data?.find((x) => x.key === rentalSettings.allow_undefined_room.key)?.value === 'true',
    allowSkipRentalFloorSelection:
      data?.data?.find((x) => x.key === rentalSettings.skip_floor_selection.key)?.value === 'true',
    enableInspectionRentalRestriction:
      (data?.data?.find((x) => x.key === rentalSettings.pre_inspection_rental_restriction_period.key)?.value || '0') !==
      '0',
    preInspectionRentalRestrictionPeriod:
      data?.data?.find((x) => x.key === rentalSettings.pre_inspection_rental_restriction_period.key)?.value || '0',
    allowForceRentalForPreInspectionRestrictionPeriod:
      data?.data?.find((x) => x.key === rentalSettings.allow_force_rental_for_pre_inspection_restriction_period.key)
        ?.value === 'true',
    restrictLendingOnOverdueInspection:
      data?.data?.find((x) => x.key === rentalSettings.restrict_lending_on_overdue_inspection.key)?.value === 'true',
    allowForceRentalForRestrictionOverdueInspection:
      data?.data?.find((x) => x.key === rentalSettings.allow_force_rental_for_restriction_overdue_inspection.key)
        ?.value === 'true',
  };

  const handleSubmit = async (res: RentalSettings) => {
    const keys: (keyof RentalSettings)[] = Object.keys(initialData) as (keyof RentalSettings)[];
    const updatedKeys = keys.filter((item: keyof RentalSettings) => {
      if (item === 'enableInspectionRentalRestriction') {
        return false;
      }

      return initialData[item] !== res[item];
    });

    try {
      const updatedData: UpdateHospitalSettingsParams[] = updatedKeys.map((key: string) => {
        let value = `${res[key as keyof RentalSettings]}`;
        if (
          key === rentalSettings.pre_inspection_rental_restriction_period.field &&
          res[rentalSettings.enable_pre_inspection_rental_restriction.field] === false
        ) {
          value = '0';
        }

        return {
          hospitalHashId: res.hashId,
          key: key.replace(/[A-Z]/g, (s) => `_${s.toLowerCase()}`) as typeof rentalSettings.use_device_lender.key,
          value: value,
        };
      });

      await Promise.all(
        updatedData.map((d) => {
          return updateHospitalSettings(res.hashId, d);
        })
      );
      await refetch();
      openSnackBar('貸出・返却の設定を更新しました');
    } catch (error) {
      openSnackBar('貸出・返却の設定の更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  return (
    <Formik initialValues={initialData} onSubmit={handleSubmit} enableReinitialize={true}>
      {children}
    </Formik>
  );
};

const menuItems = [
  {
    label: '持出者・返却者管理は有効ではありません',
    value: 'not_need',
    needManagement: false,
  },
  {
    label: 'バーコード入力',
    value: 'by_barcode',
    needManagement: true,
  },
  {
    label: '直接入力',
    value: 'by_direct_input',
    needManagement: true,
  },
];

const RentalForm = () => {
  const context = useFormikContext<RentalSettings>();
  const [needManagement, setNeedManagement] = useState(
    context.values.needManageMedicalDeviceLenderStatus !== 'not_need'
  );

  return (
    <Box sx={templateClasses.form}>
      <Form>
        <Grid container sx={templateClasses.grid}>
          <Grid item sx={templateClasses.sideBar}>
            <Sidebar />
          </Grid>
          <Grid item sx={{...templateClasses.content, height: 'auto'}}>
            <Grid container>
              <Grid item>
                <Typography variant={'h5'} sx={templateClasses.pageTitle}>
                  貸出・返却
                </Typography>
                <p>貸出・返却に関するユーザー共通設定を管理します。</p>
              </Grid>
              <Box sx={templateClasses.flex} />
            </Grid>
            <Divider variant="middle" sx={templateClasses.divider} />
            <Grid container>
              <Grid item>
                <Grid sx={{marginBottom: '24px'}}>
                  <Typography variant={'h6'} sx={templateClasses.pageSubTitle1}>
                    セットアップ
                  </Typography>
                  <LabeledCheckbox
                    label={'持出者・返却者の管理を有効にする'}
                    subLabel={'貸出・返却時に持出者・返却者を登録できるようになります。'}
                    checked={needManagement}
                    onChange={(_, checked) => {
                      setNeedManagement(checked);
                      if (checked) context.setFieldValue(rentalSettings.use_device_lender.field, 'by_barcode');
                      else context.setFieldValue(rentalSettings.use_device_lender.field, 'not_need');
                    }}
                    data-testid={'rental-settings-need-management'}
                  />
                </Grid>
                <Grid sx={{marginLeft: '32px', marginBottom: '16px'}}>
                  <FormControl variant="outlined" disabled={!needManagement}>
                    <FormGroup>
                      <Typography>持出者・返却者の登録方法</Typography>
                      <Select
                        data-testid={'rental-settings-registration-device-lender-way'}
                        sx={selectBoxStyles}
                        value={context.values.needManageMedicalDeviceLenderStatus}
                        onChange={(e) => {
                          context.setFieldValue(rentalSettings.use_device_lender.field, e.target.value as string);
                        }}>
                        {menuItems
                          .filter((item) => needManagement === item.needManagement)
                          .map((item, index) => {
                            return (
                              <MenuItem key={`MenuItem_${index}`} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid sx={{marginBottom: '24px'}}>
                  <LabeledCheckbox
                    label={'「貸出先を選択せず貸出」を有効にする'}
                    subLabel={'貸出先を選択せずに貸出できるようになります。'}
                    checked={context.values.allowRentalRoomUndefined}
                    onChange={() =>
                      context.setFieldValue(
                        rentalSettings.allow_undefined_room.field,
                        !context.values.allowRentalRoomUndefined
                      )
                    }
                    data-testid={'rental-settings-allow-undefined-room'}
                  />
                </Grid>
                <Grid sx={{marginBottom: '24px'}}>
                  <LabeledCheckbox
                    label={'「貸出先の階数選択をスキップ」を有効にする'}
                    subLabel={'貸出先の階数を選択せずに、小エリアを選択できるようになります。'}
                    checked={context.values.allowSkipRentalFloorSelection}
                    onChange={() =>
                      context.setFieldValue(
                        rentalSettings.skip_floor_selection.field,
                        !context.values.allowSkipRentalFloorSelection
                      )
                    }
                    data-testid={'rental-settings-skip-floor-selection'}
                  />
                </Grid>
                <Grid sx={{marginBottom: '24px'}}>
                  <LabeledCheckbox
                    label={'定期点検予定日を超過した機器の貸出を不可にする'}
                    // 定期点検が近い機器の貸出を不可にするがオンの場合は、この設定はオフになる
                    disabled={context.values.enableInspectionRentalRestriction}
                    checked={context.values.restrictLendingOnOverdueInspection}
                    onChange={(_e, check) => {
                      context.setFieldValue(
                        rentalSettings.restrict_lending_on_overdue_inspection.field,
                        !context.values.restrictLendingOnOverdueInspection
                      );
                      // この設定がオフになった場合、強制貸出を許可する設定もオフにする
                      if (!check) {
                        context.setFieldValue(
                          rentalSettings.allow_force_rental_for_restriction_overdue_inspection.field,
                          false
                        );
                      }
                    }}
                    data-testid={'rental-settings-restrict-lending-on-overdue-inspection'}
                  />
                  <Box sx={{width: '100%', mb: 4, marginLeft: '32px'}}>
                    <LabeledCheckbox
                      label={'強制貸出を許可する（機器一覧画面での貸出登録のみ有効）'}
                      disabled={
                        !context.values.restrictLendingOnOverdueInspection ||
                        context.values.enableInspectionRentalRestriction
                      }
                      subLabel={
                        '「貸出・返却」機能に「閲覧・追加編集・削除」権限が付与されたユーザーにのみ、定期点検予定日を超過した機器の貸出を許可します。\n' +
                        '強制貸出は、機器一覧画面での貸出登録でのみ有効となります。貸出・返却（専用画面）から強制貸出は実施できません。'
                      }
                      checked={context.values.allowForceRentalForRestrictionOverdueInspection}
                      onChange={() => {
                        context.setFieldValue(
                          rentalSettings.allow_force_rental_for_restriction_overdue_inspection.field,
                          !context.values.allowForceRentalForRestrictionOverdueInspection
                        );
                      }}
                    />
                  </Box>
                </Grid>
                <Grid sx={{marginBottom: '24px'}}>
                  <LabeledCheckbox
                    label={'定期点検が近い機器の貸出を不可にする'}
                    subLabel={'定期点検予定が近い機器の貸出をできないようにします'}
                    checked={context.values.enableInspectionRentalRestriction}
                    onChange={useCallback(
                      (_e, check) => {
                        context.setFieldValue(
                          rentalSettings.enable_pre_inspection_rental_restriction.field,
                          !context.values.enableInspectionRentalRestriction
                        );

                        if (context.values.enableInspectionRentalRestriction) {
                          context.setFieldValue(rentalSettings.pre_inspection_rental_restriction_period.field, '');
                          context.setFieldValue(
                            rentalSettings.allow_force_rental_for_pre_inspection_restriction_period.field,
                            false
                          );
                        }
                        // この設定がオンになった場合、定期点検が近い機器の貸出を不可にする設定もオフにする
                        if (check) {
                          if (
                            context.values.preInspectionRentalRestrictionPeriod === '' ||
                            context.values.preInspectionRentalRestrictionPeriod === '0'
                          ) {
                            context.setFieldValue(rentalSettings.pre_inspection_rental_restriction_period.field, '1');
                          }
                          context.setFieldValue(rentalSettings.restrict_lending_on_overdue_inspection.field, true);
                        }
                        // この設定がオフになった場合、「定期点検予定日を超過した機器の貸出」の強制貸出を許可する設定を同期する
                        context.setFieldValue(
                          rentalSettings.allow_force_rental_for_restriction_overdue_inspection.field,
                          context.values.allowForceRentalForPreInspectionRestrictionPeriod
                        );
                      },
                      [context]
                    )}
                    data-testid={'rental-settings-enable-inspection-rental-restriction'}
                  />
                  <Box sx={{width: '100%', mb: 1, marginLeft: '32px'}}>
                    <Grid container sx={{alignItems: 'center', gap: '8px'}}>
                      <Grid item>・定期点検予定日</Grid>
                      <Grid item>
                        <TextField
                          data-testid={'rental-settings-pre-inspection-rental-restriction-period'}
                          disabled={!context.values.enableInspectionRentalRestriction}
                          name="preInspectionRentalRestrictionPeriod"
                          type="number"
                          size="small"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              style: {fontSize: '14px', width: '2rem'},
                            },
                            style: {fontSize: '14px'},
                            endAdornment: (
                              <InputAdornment position="end" style={{fontSize: '14px'}}>
                                日以内
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item>から機器の貸出を不可にする</Grid>
                    </Grid>
                  </Box>
                  <Box sx={{width: '100%', mb: 4, marginLeft: '32px'}}>
                    <LabeledCheckbox
                      label={'強制貸出を許可する（機器一覧画面での貸出登録のみ有効）'}
                      disabled={!context.values.enableInspectionRentalRestriction}
                      subLabel={
                        '「貸出・返却」機能に「閲覧・追加編集・削除」権限が付与されたユーザーにのみ、上記設定で貸出不可となった機器の貸出を許可します。\n' +
                        '強制貸出は、機器一覧画面での貸出登録でのみ有効となります。貸出・返却（専用画面）から強制貸出は実施できません。'
                      }
                      checked={context.values.allowForceRentalForPreInspectionRestrictionPeriod}
                      onChange={(_, check) => {
                        context.setFieldValue(
                          rentalSettings.allow_force_rental_for_pre_inspection_restriction_period.field,
                          !context.values.allowForceRentalForPreInspectionRestrictionPeriod
                        );
                        context.setFieldValue(
                          rentalSettings.allow_force_rental_for_restriction_overdue_inspection.field,
                          check
                        );
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FormikFormSubmitDrawer />
      </Form>
    </Box>
  );
};

const selectBoxStyles: SxProps<Theme> = {
  height: '32px',
  width: '384px',
  mt: 1,
  mb: 4,
};
