import {useAtom, useAtomValue} from 'jotai';
import {useReducer, useMemo} from 'react';
import {includeDisposedAtom, pageSizeAtom} from '../state';
import {FetchHospitalProductsParams} from '@modules/hospital_products/api';
import {ProductStatusConst} from '@modules/hospital_products/types';
import {DetailFilter, PurchaseCandidateProductListStateType} from '../types';
import {productStatusType} from '@modules/hospital_products/constants';
import {isNullish} from '@front-libs/helpers';
import {narrowCategoryHashIds} from '../constants';

const initialState: PurchaseCandidateProductListStateType = {
  page: 1,
  order: 'management_id',
};

type Action =
  | {type: 'SET_PAGE'; payload: number}
  | {type: 'SET_ORDER'; payload: string}
  | {type: 'SET_STATUSES'; payload: ProductStatusConst[]}
  | {type: 'SET_NAME'; payload: string}
  | {type: 'SET_ROOT_CATEGORIES'; payload: string[]}
  | {type: 'SET_NARROW_CATEGORIES'; payload: string[]}
  | {type: 'SET_DETAIL_FILTER'; payload: Partial<DetailFilter>};

const reducer = (state: typeof initialState, action: Action): typeof initialState => {
  switch (action.type) {
    case 'SET_PAGE':
      return {...state, page: action.payload};
    case 'SET_ORDER':
      return {...state, order: action.payload};
    case 'SET_STATUSES':
      return {...state, page: 1, statuses: action.payload.length > 0 ? action.payload : []};
    case 'SET_NAME':
      return {...state, page: 1, name: action.payload === '' ? undefined : action.payload};
    case 'SET_ROOT_CATEGORIES':
      return {...state, page: 1, rootCategories: action.payload.length > 0 ? action.payload : undefined};
    case 'SET_NARROW_CATEGORIES':
      return {...state, page: 1, narrowCategories: action.payload.length > 0 ? action.payload : undefined};
    case 'SET_DETAIL_FILTER':
      return {...state, page: 1, ...action.payload};
    default:
      return state;
  }
};

/**
 * 買取画面の状態を管理するカスタムフック
 * @returns
 */
export const usePurchaseCandidateProductState = () => {
  // useReducerので複数stateを一括管理
  const [state, dispatch] = useReducer(reducer, initialState);
  // AtomはuseReducerに含められないのでここで定義
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);
  const includeDisposed = useAtomValue(includeDisposedAtom);

  const {page, statuses, rootCategories, narrowCategories, hospitalDealerHashIds, hospitalDepartmentHashIds, ...rest} =
    state;

  const isForcedRental = statuses?.includes('forceRental');

  const searchStatuses =
    (statuses?.length ?? 0) > 0
      ? (includeDisposed ? statuses : statuses?.filter((item) => item !== 'disabled'))
          ?.map((item) => (item === 'forceRental' ? 'working' : item))
          .join(',')
      : includeDisposed
        ? undefined
        : productStatusType
            ?.filter((item) => item.value !== 'disabled')
            ?.map((item) => (item.value === 'forceRental' ? 'working' : item.value))
            ?.join(',');

  const categoryHashIds = (
    isNullish(narrowCategories)
      ? isNullish(rootCategories)
        ? narrowCategoryHashIds
        : rootCategories
      : narrowCategories
  ).join(',');

  const param = useMemo<FetchHospitalProductsParams>(
    () => ({
      perPage: pageSize,
      page: page - 1,
      categoryHashIds, // カテゴリーは絞り込みがあればnarrowCategoriesを、なければrootCategoriesを使う
      statuses: searchStatuses,
      hospitalDealerHashIds: hospitalDealerHashIds?.join(','),
      hospitalDepartmentHashIds: hospitalDepartmentHashIds?.join(','),
      isForcedRental,
      ...rest,
    }),
    [page, pageSize, rest]
  );

  return {
    dispatch,
    param,
    page,
    pageSize,
    setPageSize,
  };
};
