import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import LocalHospital from '@mui/icons-material/LocalHospitalOutlined';
import {InspectionSVG} from '@components/atoms/Icons/inspectionIcons';

import {IconButton, Grid, Typography, SxProps} from '@mui/material';
import {usePermittedForSp} from '@modules/hospital_users/hooks/useUserPermissions';

const activeColor = '#0052CC';
const inactiveColor = '#A2A2A2';

type FooterNavItemProps = {
  to: string;
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
};

/**
 * スマホフッターの各種アイテム
 *
 * @returns
 */
const FooterNavItem: React.FC<FooterNavItemProps> = ({to, icon, label, isActive}) => {
  return (
    <Grid item>
      <IconButton component={Link} to={to} sx={{margin: 0, padding: 0}}>
        {React.cloneElement(icon as React.ReactElement, {color: isActive ? activeColor : inactiveColor})}
      </IconButton>
      <Grid container justifyContent="center">
        <Typography variant="caption" sx={{margin: 0, color: isActive ? activeColor : inactiveColor}}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

const rootGridStyle: SxProps = {
  padding: '8px',
  textAlign: 'center',
  marginTop: 'auto',
  width: '100%',
  backgroundColor: 'white',
  position: 'fixed',
  height: '58px',
  bottom: 0,
  borderTop: '1px solid #F0F2F5',
};

/**
 * スマホ用フッター
 */
export const Footer = () => {
  const {canReadProductList, canReadInspection} = usePermittedForSp();
  const location = useLocation();
  const activeProductList = location.pathname === '/sp/products';
  return (
    <Grid container justifyContent="space-around" alignItems="center" sx={rootGridStyle}>
      <Grid item />
      {canReadProductList && (
        <FooterNavItem
          to="/sp/products"
          icon={<LocalHospital sx={{color: activeProductList ? activeColor : inactiveColor}} />}
          label="機器"
          isActive={activeProductList}
        />
      )}
      {canReadInspection && (
        <FooterNavItem
          to="/sp/inspection/results"
          icon={<InspectionSVG color={activeProductList ? inactiveColor : activeColor} />}
          label="点検"
          isActive={!activeProductList}
        />
      )}
      <Grid item />
    </Grid>
  );
};
