import React from 'react';
import {AlertDialog, DialogProps} from '@front-libs/ui';
import {Typography} from '@mui/material';

export type ForceRentalConfirmDialogProps = {
  includeNearInspection: boolean;
} & DialogProps;

export const ForceRentalAlertDialog = (props: ForceRentalConfirmDialogProps) => {
  const {open, actions, includeNearInspection} = props;
  return (
    <AlertDialog
      open={open}
      title="強制貸出の確認"
      positiveButtonLabel="実行する"
      negativeButtonLabel="キャンセル"
      actions={actions}
      content={
        includeNearInspection ? (
          <Typography>
            選択された機器の中に、定期点検予定日を超過した機器が含まれています。
            <br />
            強制貸出を実行しますか？
          </Typography>
        ) : (
          <Typography>
            選択された機器の中に、定期点検予定日を超過した機器、あるいは定期点検が近い機器が含まれています。
            <br />
            強制貸出を実行しますか？
          </Typography>
        )
      }
    />
  );
};
