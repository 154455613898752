import React from 'react';
import {tabValues} from './constants';

export type TabValue = (typeof tabValues)[number];

export type Tab = {
  icon: React.ReactElement;
  label: string;
  value: TabValue;
};

export type TraineeDataRow = {
  hashId: string;
  name: string;
  employeeNumber: string | null;
  departmentName: string | null;
};

export enum TraineeListPaginationActionType {
  TRAINEE_LIST_PAGINATION_SET_TOTAL_COUNT = 'TRAINEE_LIST_PAGINATION_SET_TOTAL_COUNT',
  TRAINEE_LIST_PAGINATION_SET_PAGE = 'TRAINEE_LIST_PAGINATION_SET_PAGE',
  TRAINEE_LIST_PAGINATION_SET_PER_PAGE = 'TRAINEE_LIST_PAGINATION_SET_PER_PAGE',
}

export type TraineeListPaginationSetPageAction = {
  type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PAGE;
  payload: {
    page: number;
  };
};
export type TraineeListPaginationSetPerPageAction = {
  type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_PER_PAGE;
  payload: {
    perPage: number;
  };
};
export type TraineeListPaginationSetTotalCountAction = {
  type: TraineeListPaginationActionType.TRAINEE_LIST_PAGINATION_SET_TOTAL_COUNT;
  payload: {
    totalCount: number;
  };
};

export type TraineeListPaginationAction =
  | TraineeListPaginationSetPageAction
  | TraineeListPaginationSetPerPageAction
  | TraineeListPaginationSetTotalCountAction;

export type TraineeListPaginationState = {
  page: number;
  perPage: number;
  totalCount: number;
  orders: string | undefined;
};
