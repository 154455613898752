import React from 'react';
import {Box, Stack, Step, StepLabel, Stepper, Typography} from '@mui/material';
import {useActiveStepCount} from '../hooks/useActiveStepCount';

const steps = ['買取査定概要', '情報共有承諾', '連絡先入力', '内容確認'];

export const PurchaseRequestHeader = () => {
  const activeStep = useActiveStepCount();
  return (
    <Stack sx={{flexDirection: 'row', background: '#FFF', height: '52px', padding: '24px 32px'}}>
      <Box sx={{width: '252px'}}>
        <Typography sx={{color: '#172B4D', fontSize: '20px', lineHeight: '24px'}}>買取査定の依頼</Typography>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{width: '252px'}} />
    </Stack>
  );
};
