import React, {useCallback, useMemo} from 'react';
import {DisplayNumberSelect} from '@molecules/DisplayNumberSelect';
import {Pagination} from '@material-ui/lab';

import {PageHeader} from './PageHeader';
import {makeStyles, Grid, Divider, styled} from '@material-ui/core';
import {Toolbar} from './Toolbar';
import {useHospitalFiles} from './hooks';
import {useDebounceCallback} from '@front-libs/core';
import {FileTable} from './Table';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {uploadFile} from '@modules/files/api';
import {openSnackBar} from '@molecules/SnackBar';
import {
  MultipleUploadDialog,
  MultipleUploadDialogProps,
  MultipleUploadFormProps,
} from '@organisms/Files/MultipleUploadDialog';
import {isNullish} from '@front-libs/helpers';
import {useQueryVariables} from './state';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const initialNoDataComponent = (
  <div>
    <p style={{fontWeight: 'bold'}}>ファイルは登録されていません。</p>
    <p>右上のボタンからファイルをアップロードできます。</p>
  </div>
);
/* 未使用関数
const noSearchedDataComponent = (
  <div>
    <p style={{fontWeight: 'bold'}}>現在の検索条件に一致するファイルは登録されていません。</p>
    <p>検索条件を変えて、再度検索してみてください。</p>
  </div>
); */

export const GeneralFileList: React.FC = () => {
  const classes = useStyles();
  const {page, perPage, searchWord, updatePage, updatePerPage, updateSearchWord, updateOrder} = useQueryVariables();
  // TODO:カスタム内部権限に置き換え
  const {isReadOnly, isAdmin} = useMyRole();
  const {fileData, isLoading, refetch} = useHospitalFiles({
    initialVariables: {
      // PMDAのファイルは除外するためにカテゴリを設定
      categories: [
        'general',
        'profile',
        'inspection_result',
        'whole_product_thumbnail',
        'hospital_product',
        'fault_repair',
        'hospital_user_import_file',
        'hospital_product_import_file',
        'training_schedule_material',
        'training_schedule_trainee_list',
        'training_report_name_list',
        'maker_inspection_result',
        'inspection_manual',
      ],
    },
  });
  const noDataComponent = initialNoDataComponent;

  const startDisplayPosition = useMemo(() => {
    if (isNullish(fileData)) return 0;

    return fileData.totalCount === 0 ? 0 : Math.ceil((fileData.page - 1) * (perPage ?? 20) + 1);
  }, [fileData, perPage]);

  const endDisplayPosition = useMemo(() => {
    if (isNullish(fileData)) return 0;
    const endPosition = Math.ceil(fileData.page * (perPage ?? 20));
    return endPosition > fileData.totalCount ? fileData.totalCount : endPosition;
  }, [fileData, perPage]);

  const totalPage = useMemo(() => {
    if (isNullish(fileData)) return 0;
    return Math.ceil(fileData.totalCount / (perPage ?? 20));
  }, [fileData, perPage]);

  const handleChangeName = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateSearchWord(event.target.value);
    },
    300,
    [updateSearchWord]
  );

  const handleUploadDocument = useCallback(async () => {
    const newFiles = await dialogHandler.open<MultipleUploadDialogProps, MultipleUploadFormProps[]>(
      MultipleUploadDialog,
      {}
    );
    const filePromises = newFiles.map((file) => {
      return uploadFile({
        ...file,
        category: 'general',
      });
    });

    await Promise.all(filePromises);
    refetch();
    openSnackBar('ファイルをアップロードしました');
  }, [refetch]);

  const onSuccess = useCallback(
    async (_fileHashId: string) => {
      refetch();
    },
    [refetch]
  );

  const handleOrderChange = useCallback(
    async (newOrder: string | undefined) => {
      updateOrder(newOrder);
    },
    [updateOrder]
  );

  const handleChangePage = useCallback(
    async (_e: React.ChangeEvent<unknown>, newPage: number) => {
      updatePage(newPage - 1);
    },
    [updatePage]
  );

  const handleChangePageSize = useCallback(
    async (pageSize: number) => {
      updatePerPage(pageSize);
      updatePage(0);
    },
    [updatePage, updatePerPage]
  );

  return (
    <Grid container className={classes.root}>
      <PageHeader />
      <Divider variant="middle" className={classes.divider} />
      <Toolbar
        files={fileData?.data ?? []}
        searchName={searchWord ?? ''}
        onChangeSearchName={handleChangeName}
        onUploadFile={handleUploadDocument}
        isReadOnly={isReadOnly}
      />
      <FileTable
        files={fileData?.data ?? []}
        isLoading={isLoading}
        noDataComponent={noDataComponent}
        disableEdit={isReadOnly}
        disableDelete={!isAdmin}
        onEditFileName={onSuccess}
        onDeleteFile={onSuccess}
        onOrderChange={handleOrderChange}
      />
      <Grid container justifyContent={'space-between'}>
        <PageDescriptionGrid item>
          {fileData?.totalCount ?? 0}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
        </PageDescriptionGrid>
        <PaginationContainerGrid item>
          <Pagination page={(page ?? 0) + 1} count={totalPage} shape="rounded" onChange={handleChangePage} />
          <DisplayNumberSelect pageSize={perPage ?? 20} update={handleChangePageSize} />
        </PaginationContainerGrid>
        <EmptyGrid />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((_theme) => ({
  root: {
    padding: '32px 24px',
  },
  divider: {
    margin: '16px 0',
    width: '100%',
  },
}));

const EmptyGrid = styled(Grid)({
  flex: 1,
});

const PaginationContainerGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
});

const PageDescriptionGrid = styled(Grid)({
  margin: 'auto 0px',
  flex: 1,
});
